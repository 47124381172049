/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useEffect, useState } from "react"
import useInput from "../../../Common/Form/use-input"
import EditorDraftJs from "../../../Common/Editor/EditorDraftJs"
import ConfirmationModal from "../../../Common/Modal/ConfirmationModal"
import MySnackbarAlert from "../../../Common/AlertMessage/MySnackbarAlert"
import Agent from "../../../../api/agent"
import { UNI_TYPES } from "../../../../Util/Constants"
import { Countries } from "../../../../Util/Util"
import DoneIcon from "@mui/icons-material/Done"
import SchoolIcon from "@mui/icons-material/School"
import InfoIcon from "@mui/icons-material/Info"
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents"
import HandshakeIcon from "@mui/icons-material/Handshake"
import DescriptionIcon from "@mui/icons-material/Description"
import HistoryEduIcon from "@mui/icons-material/HistoryEdu"
import PeopleIcon from "@mui/icons-material/People"
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary"
import EventNoteIcon from "@mui/icons-material/EventNote"
import LanguageIcon from "@mui/icons-material/Language"
import PublicIcon from "@mui/icons-material/Public"
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/Delete"
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople"
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer"
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import { connect } from "react-redux"

const RANKING_TYPES = [
  { id: 1, name: "QS World University Rankings" },
  { id: 2, name: "Times Higher Education" },
  { id: 3, name: "Academic Ranking of World Universities" },
  { id: 4, name: "U.S. News & World Report" },
  { id: 5, name: "Other" },
]

const monthNames = {
  Jan: 1,
  Feb: 2,
  Mar: 4,
  Apr: 8,
  May: 16,
  Jun: 32,
  Jul: 64,
  Aug: 128,
  Sep: 256,
  Oct: 512,
  Nov: 1024,
  Dec: 2048,
}

const previousYear = new Date().getFullYear() - 1
const intakeYearList = Array.from({ length: 8 }, (_, i) => ({
  id: i + 1,
  year: previousYear + i,
}))

const isNotEmptyTrim = (value) => {
  if (value === null || value === undefined) return false
  return typeof value === "string" ? value.trim() !== "" : Boolean(value)
}

const isValidNumber = (value) => {
  return value !== null && value !== undefined && value !== ""
}

const isNotEmpty = (value) => value && value !== ""

const StudentVideoTestimonial = ({ testimonials = [], onChange }) => {
  const [items, setItems] = useState(
    testimonials.length > 0
      ? testimonials.map((t) => ({
          text: t.text || "",
          testimonialVideo: t.testimonialVideo || "",
        }))
      : [{ text: "", testimonialVideo: "" }],
  )

  useEffect(() => {
    console.log("Testimonials in component:", items)
    onChange(items)
  }, [items, onChange])

  const handleAddItem = () => {
    setItems([...items, { text: "", testimonialVideo: "" }])
  }

  const handleRemoveItem = (index) => {
    const newItems = [...items]
    newItems.splice(index, 1)
    setItems(newItems.length > 0 ? newItems : [{ text: "", testimonialVideo: "" }])
  }

  const handleItemChange = (index, field, value) => {
    console.log(`Changing ${field} at index ${index} to:`, value)
    const newItems = [...items]
    newItems[index] = {
      ...newItems[index],
      [field]: value,
    }
    console.log("Updated items:", newItems)
    setItems(newItems)
  }

  return (
    <div className="space-y-4">
      {items.map((item, index) => (
        <div
          key={index}
          className="p-5 border border-gray-200 rounded-lg bg-gray-50 hover:bg-gray-100 transition-colors duration-200"
        >
          <div className="flex justify-between items-center mb-4">
            <h4 className="text-sm font-medium text-gray-700">Testimonial #{index + 1}</h4>
            {items.length > 1 && (
              <button
                type="button"
                onClick={() => handleRemoveItem(index)}
                className="text-red-500 hover:text-red-700 transition-colors p-1 rounded-full hover:bg-red-50"
                aria-label="Remove testimonial"
              >
                <DeleteIcon fontSize="small" />
              </button>
            )}
          </div>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Testimonial Text</label>
              <textarea
                value={item.text || ""}
                onChange={(e) => handleItemChange(index, "text", e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500 transition-colors"
                rows={3}
                placeholder="Enter student testimonial here"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Testimonial Video URL</label>
              <div className="relative">
                <input
                  type="text"
                  value={item.testimonialVideo || ""}
                  onChange={(e) => handleItemChange(index, "testimonialVideo", e.target.value)}
                  placeholder="Enter video URL here"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500 transition-colors"
                />
              </div>
            </div>
          </div>
        </div>
      ))}
      <button
        type="button"
        onClick={handleAddItem}
        className="flex items-center text-sky-600 hover:text-sky-800 transition-colors px-4 py-2 rounded-md hover:bg-sky-50"
      >
        <AddIcon fontSize="small" className="mr-1" />
        Add Another Testimonial
      </button>
    </div>
  )
}

const UniversityRankings = ({ rankings = [], onChange }) => {
  const [items, setItems] = useState(rankings.length > 0 ? rankings : [{ rankType: "", rankingValue: "", remarks: "" }])

  const handleAddItem = () => {
    const newItems = [...items, { rankType: "", rankingValue: "", remarks: "" }]
    setItems(newItems)
    onChange(newItems)
  }

  const handleRemoveItem = (index) => {
    const newItems = [...items]
    newItems.splice(index, 1)
    const updatedItems = newItems.length > 0 ? newItems : [{ rankType: "", rankingValue: "", remarks: "" }]
    setItems(updatedItems)
    onChange(updatedItems)
  }

  const handleItemChange = (index, field, value) => {
    const newItems = [...items]
    newItems[index] = {
      ...newItems[index],
      [field]: field === "rankType" && value === "" ? 0 : value,
    }
    setItems(newItems)
    onChange(newItems)
  }

  return (
    <div className="space-y-4">
      {items.map((item, index) => {
        const selectedRankingType = RANKING_TYPES.find((rt) => rt.id === item.rankType) || null
        const hasRankingValue = Boolean(item.rankingValue)
        const hasRankType = Boolean(item.rankType)
        const isInvalid = hasRankingValue && !hasRankType

        return (
          <div
            key={index}
            className="p-5 border border-gray-200 rounded-lg bg-gray-50 hover:bg-gray-100 transition-colors duration-200"
          >
            <div className="flex justify-between items-center mb-4">
              <h4 className="text-sm font-medium text-gray-700">Ranking #{index + 1}</h4>
              {items.length > 1 && (
                <button
                  type="button"
                  onClick={() => handleRemoveItem(index)}
                  className="text-red-500 hover:text-red-700 transition-colors p-1 rounded-full hover:bg-red-50"
                >
                  <DeleteIcon fontSize="small" />
                </button>
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Ranking Type {hasRankingValue && <span className="text-red-500">*</span>}
                </label>
                <Autocomplete
                  options={RANKING_TYPES}
                  getOptionLabel={(option) => option.name}
                  value={selectedRankingType}
                  onChange={(event, newValue) => {
                    handleItemChange(index, "rankType", newValue ? newValue.id : "")
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select ranking type"
                      variant="outlined"
                      required={hasRankingValue}
                      size="small"
                      error={isInvalid}
                      InputProps={{
                        ...params.InputProps,
                        className:
                          "bg-white h-[38px] border-gray-300 hover:border-gray-500 focus:border-sky-500 transition-colors",
                      }}
                    />
                  )}
                  className="w-full"
                />
                {isInvalid && (
                  <p className="text-xs text-red-500 mt-1">Ranking type is required when position is provided</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Ranking Position</label>
                <input
                  type="text"
                  value={item.rankingValue || ""}
                  onChange={(e) => handleItemChange(index, "rankingValue", e.target.value)}
                  placeholder="e.g., 150"
                  className={`w-full px-3 py-2 border ${isInvalid ? "border-red-300" : "border-gray-300"} rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500 transition-colors`}
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Remarks</label>
                <input
                  type="text"
                  value={item.remarks || ""}
                  onChange={(e) => handleItemChange(index, "remarks", e.target.value)}
                  placeholder="e.g., Rank Year 2025"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500 transition-colors"
                />
              </div>
            </div>
          </div>
        )
      })}
      <button
        type="button"
        onClick={handleAddItem}
        className="flex items-center text-sky-600 hover:text-sky-800 transition-colors px-4 py-2 rounded-md hover:bg-sky-50"
      >
        <AddIcon fontSize="small" className="mr-1" />
        Add Another Ranking
      </button>
    </div>
  )
}

const UniversityFAQ = ({ faq, onChange }) => {
  const [faqs, setFaqs] = useState(
    faq || {
      q1: "",
      ans1: "",
      q2: "",
      ans2: "",
      q3: "",
      ans3: "",
      q4: "",
      ans4: "",
      q5: "",
      ans5: "",
    },
  )

  const handleChange = (field, value) => {
    const updatedFaqs = {
      ...faqs,
      [field]: value,
    }
    setFaqs(updatedFaqs)
    onChange(updatedFaqs)
  }

  return (
    <div className="space-y-6">
      {[1, 2, 3, 4, 5].map((num) => (
        <div
          key={num}
          className="p-5 border border-gray-200 rounded-lg bg-gray-50 hover:bg-gray-100 transition-colors duration-200"
        >
          <h4 className="text-sm font-medium text-gray-700 mb-4">FAQ #{num}</h4>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Question</label>
              <input
                type="text"
                value={faqs[`q${num}`] || ""}
                onChange={(e) => handleChange(`q${num}`, e.target.value)}
                placeholder="Enter question here"
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500 transition-colors"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Answer</label>
              <textarea
                value={faqs[`ans${num}`] || ""}
                onChange={(e) => handleChange(`ans${num}`, e.target.value)}
                placeholder="Enter answer here"
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500 transition-colors"
                rows={3}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

const UniversityProfile = ({ profile, updateProfileParent, role, permissions, setAlert }) => {
  const [isInValid, setIsInValid] = useState(true)
  const [validationErrors, setValidationErrors] = useState({})
  const [selectedIntakes, setSelectedIntakes] = useState(profile.intakeList || [])
  const [selectedYears, setSelectedYears] = useState(
    profile.intakeInfo ? profile.intakeInfo.split(",").map((year) => year.trim()) : [],
  )
  const [openConfirm, setOpenConfirm] = useState(false)
  const [confirmAction, setConfirmAction] = useState(null)
  const [confirmMessage, setConfirmMessage] = useState("")
  const [summaryHtml, setSummaryHtml] = useState(profile.summary)
  const [scholarshipHtml, setScholarshipHtml] = useState(profile.scholarshipInfo)
  const [updating, setUpdating] = useState(false)
  const [openSuccess, setOpenSuccess] = useState(false)
  const [openFailure, setOpenFailure] = useState(false)
  const [alertMsg, setAlertMsg] = useState("")
  const [formSubmitted, setFormSubmitted] = useState(false)

  const [universityTestimonials, setUniversityTestimonials] = useState(() => {
    console.log("Initial profile testimonials:", profile.universityTestimonials)

    if (Array.isArray(profile.universityTestimonials) && profile.universityTestimonials.length > 0) {
      return profile.universityTestimonials.map((t) => ({
        text: t.text || "",
        testimonialVideo: t.testimonialVideo || "",
      }))
    }
    return [{ text: "", testimonialVideo: "" }]
  })

  const [universityRanks, setUniversityRanks] = useState(
    profile.universityRanks && Array.isArray(profile.universityRanks) && profile.universityRanks.length > 0
      ? profile.universityRanks
      : [{ rankType: "", rankingValue: "", remarks: "" }],
  )

  const [universityFaqs, setUniversityFaqs] = useState(
    profile.universityFaqs || {
      q1: "",
      ans1: "",
      q2: "",
      ans2: "",
      q3: "",
      ans3: "",
      q4: "",
      ans4: "",
      q5: "",
      ans5: "",
    },
  )

  const sortedCountries = [...Countries].sort((a, b) => a.order - b.order)

  useEffect(() => {
    let totalIntakes = 0
    selectedIntakes.forEach((intake) => {
      totalIntakes += monthNames[intake]
    })

    console.log("Testimonials being sent to parent:", universityTestimonials)

    updateProfileParent({
      ...profile,
      intakes: totalIntakes,
      intakeInfo: selectedYears.join(","),
      universityTestimonials: universityTestimonials,
      universityRanks,
      universityFaqs,
    })
  }, [
    selectedIntakes,
    selectedYears,
    universityTestimonials,
    profile,
    updateProfileParent,
    universityRanks,
    universityFaqs,
  ])

  useEffect(() => {
    updateProfileParent({ ...profile, summary: summaryHtml, scholarshipInfo: scholarshipHtml })
  }, [summaryHtml, scholarshipHtml, profile, updateProfileParent])

  const onChangeHandler = (name, value) => {
    updateProfileParent({ ...profile, [name]: value })
  }

  const showAlert = (msg = "Successfully updated", isSuccess = true) => {
    setAlertMsg(msg)
    setOpenSuccess(isSuccess)
    setOpenFailure(!isSuccess)
  }

  const validateForm = () => {
    const errors = {}

    if (!profile.name || profile.name.trim() === "") {
      errors.name = "Institution name is required"
    }

    if (!profile.type) {
      errors.type = "Institution type is required"
    }

    if (!profile.city || profile.city.trim() === "") {
      errors.city = "City is required"
    }

    if (!profile.countryId) {
      errors.countryId = "Country is required"
    }

    if (!profile.website || profile.website.trim() === "") {
      errors.website = "Website URL is required"
    }

    if (!foundedYear.value) {
      errors.foundedYear = "Founded year is required"
    }

    if (!currency.value) {
      errors.currency = "Currency is required"
    }

    if (!summaryHtml || summaryHtml.trim() === "") {
      errors.summary = "University summary is required"
    }

    if (isScholarshipAvailable.value && (!scholarshipHtml || scholarshipHtml.trim() === "")) {
      errors.scholarshipInfo = "Scholarship details are required when scholarship is available"
    }

    if (selectedIntakes.length === 0) {
      errors.intakes = "At least one intake month is required"
    }

    if (selectedYears.length === 0) {
      errors.intakeYears = "At least one intake year is required"
    }

    const hasInvalidRanking = universityRanks.some(
      (rank) => rank.rankingValue && (!rank.rankType || rank.rankType === 0),
    )
    if (hasInvalidRanking) {
      errors.rankings = "Ranking type is required when position is provided"
    }

    setValidationErrors(errors)
    return Object.keys(errors).length === 0
  }

  const handleSave = () => {
    setFormSubmitted(true)

    if (!validateForm()) {
      setAlertMsg("Please fill in all required fields")
      setOpenSuccess(false)
      setOpenFailure(true)

      window.scrollTo({ top: 0, behavior: "smooth" })
      return
    }

    setUpdating(true)

    console.log("Final testimonials being saved:", universityTestimonials)

    const processedRanks = universityRanks.map((rank) => ({
      ...rank,
      rankType: rank.rankType === "" ? 0 : rank.rankType,
    }))

    const profileToSave = {
      ...profile,
      universityTestimonials: universityTestimonials,
      universityRanks: processedRanks,
    }

    Agent.Universities.saveUniversity({ university: profileToSave })
      .then((id) => {
        setUpdating(false)

        // Show success message
        const isNewUniversity = !profile.id || profile.id === 0
        const successMessage = isNewUniversity ? "University created successfully!" : "University updated successfully!"

        setAlertMsg(successMessage)
        setOpenSuccess(true)
        setOpenFailure(false)

        // Update profile with new ID
        const updatedProfile = {
          ...profileToSave,
          id: id,
        }

        updateProfileParent(updatedProfile)

        // If this is a new university, redirect to the university profile page
        if (isNewUniversity) {
          setTimeout(() => {
            window.location.href = `/universities/${id}/manage`
          }, 2000)
        }
      })
      .catch((error) => {
        setUpdating(false)
        console.log(error)

        let errorMessage = "Error: Failed to save the university."

        if (error.response && error.response.data) {
          if (error.response.data.message) {
            errorMessage = error.response.data.message
          } else if (typeof error.response.data === "string") {
            errorMessage = error.response.data
          }
        }

        if (errorMessage.toLowerCase().includes("already exists")) {
          errorMessage = "Error: A university with this name already exists."
        }

        setAlertMsg(errorMessage)
        setOpenSuccess(false)
        setOpenFailure(true)
      })
  }

  // const confirmDeleteUniversity = () => {
  //   setConfirmMessage("Are you sure that you would like to remove this university from the system?")
  //   setConfirmAction(() => deleteUniversity)
  //   setOpenConfirm(true)
  // }

  // const deleteUniversity = () => {
  //   Agent.Universities.deleteUniversity(profile.id)
  //     .then(() => {
  //       setOpenConfirm(false)
  //       window.location.href = "/universities"
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //       setOpenConfirm(false)

  //       let errorMessage = "Error: Failed to delete the university."

  //       if (error.response && error.response.data) {
  //         if (error.response.data.message) {
  //           errorMessage = error.response.data.message
  //         } else if (typeof error.response.data === "string") {
  //           errorMessage = error.response.data
  //         }
  //       }

  //       setAlertMsg(errorMessage)
  //       setOpenSuccess(false)
  //       setOpenFailure(true)
  //     })
  // }

  const { obj: name } = useInput(profile.name, isNotEmptyTrim, null, onChangeHandler)
  const { obj: city } = useInput(profile.city, isNotEmptyTrim, null, onChangeHandler)
  const { obj: countryId } = useInput(profile.countryId, isNotEmpty, null, onChangeHandler)
  const { obj: type } = useInput(profile.type, isNotEmpty, null, onChangeHandler)
  const { obj: isScholarshipAvailable } = useInput(profile?.isScholarshipAvailable, isNotEmpty, null, onChangeHandler)
  const { obj: isTop } = useInput(profile?.isTop, isNotEmpty, null, onChangeHandler)
  const { obj: isDirect } = useInput(profile?.isDirect, isNotEmpty, null, onChangeHandler)
  const { obj: isAgent } = useInput(profile?.isAgent, isNotEmpty, null, onChangeHandler)
  const { obj: website } = useInput(profile?.website || "", isNotEmpty, null, onChangeHandler)
  const { obj: foundedYear } = useInput(profile?.foundedYear || "", isValidNumber, null, onChangeHandler)
  const { obj: currency } = useInput(profile?.currency || "", isNotEmptyTrim, null, onChangeHandler)

  useEffect(() => {
    const isScholarshipValid =
      !isScholarshipAvailable.value ||
      (isScholarshipAvailable.value && scholarshipHtml && scholarshipHtml.trim() !== "")

    const hasInvalidRanking = universityRanks.some((rank) => rank.rankingValue && !rank.rankType)

    setIsInValid(
      updating ||
        !profile.name ||
        profile.name.length === 0 ||
        profile.type == null ||
        !profile.city ||
        profile.city.length === 0 ||
        profile.countryId == null ||
        !summaryHtml ||
        summaryHtml.trim() === "" ||
        selectedIntakes.length === 0 ||
        selectedYears.length === 0 ||
        !isScholarshipValid ||
        !foundedYear.value ||
        !currency.value ||
        hasInvalidRanking,
    )
  }, [
    profile,
    updating,
    summaryHtml,
    selectedIntakes,
    selectedYears,
    isScholarshipAvailable.value,
    scholarshipHtml,
    foundedYear.value,
    currency.value,
    universityRanks,
  ])

  const selectedCountry = sortedCountries.find((country) => country.id === countryId.value) || null

  useEffect(() => {
    
    try {
      setSummaryHtml(profile.summary || "")
      setScholarshipHtml(profile.scholarshipInfo || "")
    } catch (error) {
      console.error("Error initializing editor content:", error)

      setSummaryHtml("")
      setScholarshipHtml("")
    }
  }, [profile.id])

  return (
    <div className="bg-gradient-to-b from-sky-50 to-white min-h-screen p-6">
      <h1 className="text-3xl font-bold text-sky-800 mb-6 flex items-center">
        <SchoolIcon className="mr-3" fontSize="large" />
        {profile.id && profile.id !== 0 ? "Edit University Profile" : "Add New University"}
      </h1>

      {formSubmitted && Object.keys(validationErrors).length > 0 && (
        <div className="mb-6 p-4 border border-red-300 bg-red-50 rounded-md">
          <h3 className="text-red-700 font-medium mb-2">Please correct the following errors:</h3>
          <ul className="list-disc pl-5 text-red-600">
            {Object.values(validationErrors).map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </div>
      )}

      <div className="space-y-6 max-w-7xl mx-auto">
        <section className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
          <div className="bg-gradient-to-r from-sky-100 to-sky-50 px-6 py-4 border-b border-sky-200 flex items-center">
            <InfoIcon className="text-sky-700 mr-2" />
            <h2 className="text-lg font-semibold text-sky-800">General Information</h2>
          </div>
          <div className="p-6">
            <div className="grid grid-cols-1 gap-6">
              <div className="col-span-1">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Institution Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  value={name.value || ""}
                  onChange={(e) => name.valueChangeHandler(e.target.value, "name")}
                  onBlur={name.valueInputBlurHandler}
                  className={`w-full px-4 py-2 border ${
                    (formSubmitted && validationErrors.name) || (!name.value && name.hasError)
                      ? "border-red-300"
                      : "border-gray-300"
                  } rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500 transition-colors`}
                  placeholder="Enter institution name"
                  required
                />
                {(formSubmitted && validationErrors.name) || (!name.value && name.hasError) ? (
                  <p className="text-xs text-red-500 mt-1">Institution name is required</p>
                ) : null}
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Institution Type <span className="text-red-500">*</span>
                  </label>
                  <select
                    value={type.value || ""}
                    onChange={(e) => type.valueChangeHandler(e.target.value, "type")}
                    onBlur={type.valueInputBlurHandler}
                    className={`w-full px-4 py-2 border ${
                      (formSubmitted && validationErrors.type) || (!type.value && type.hasError)
                        ? "border-red-300"
                        : "border-gray-300"
                    } rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500 transition-colors`}
                    required
                  >
                    <option value="">Select Type</option>
                    {UNI_TYPES.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.type}
                      </option>
                    ))}
                  </select>
                  {(formSubmitted && validationErrors.type) || (!type.value && type.hasError) ? (
                    <p className="text-xs text-red-500 mt-1">Institution type is required</p>
                  ) : null}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Website URL <span className="text-red-500">*</span>
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <LanguageIcon fontSize="small" className="text-gray-400" />
                    </div>
                    <input
                      type="url"
                      value={website.value || ""}
                      onChange={(e) => website.valueChangeHandler(e.target.value, "website")}
                      onBlur={website.valueInputBlurHandler}
                      placeholder="https://www.university.edu"
                      className={`w-full pl-10 px-4 py-2 border ${
                        (formSubmitted && validationErrors.website) || (!website.value && website.hasError)
                          ? "border-red-300"
                          : "border-gray-300"
                      } rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500 transition-colors`}
                      required
                    />
                  </div>
                  {(formSubmitted && validationErrors.website) || (!website.value && website.hasError) ? (
                    <p className="text-xs text-red-500 mt-1">Website URL is required</p>
                  ) : null}
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    City <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    value={city.value || ""}
                    onChange={(e) => city.valueChangeHandler(e.target.value, "city")}
                    onBlur={city.valueInputBlurHandler}
                    className={`w-full px-4 py-2 border ${
                      (formSubmitted && validationErrors.city) || (!city.value && city.hasError)
                        ? "border-red-300"
                        : "border-gray-300"
                    } rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500 transition-colors`}
                    placeholder="Enter city"
                    required
                  />
                  {(formSubmitted && validationErrors.city) || (!city.value && city.hasError) ? (
                    <p className="text-xs text-red-500 mt-1">City is required</p>
                  ) : null}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Country <span className="text-red-500">*</span>
                  </label>
                  <Autocomplete
                    options={sortedCountries}
                    getOptionLabel={(option) => option.name}
                    value={selectedCountry}
                    onChange={(event, newValue) => {
                      countryId.valueChangeHandler(newValue ? newValue.id : null, "countryId")
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search for a country"
                        variant="outlined"
                        required
                        size="small"
                        error={
                          (formSubmitted && validationErrors.countryId) || (!countryId.value && countryId.hasError)
                        }
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <React.Fragment>
                              <PublicIcon className="text-gray-400 mr-2" />
                              {params.InputProps.startAdornment}
                            </React.Fragment>
                          ),
                          className:
                            "bg-white h-[40px] border-gray-300 hover:border-gray-500 focus:border-sky-500 transition-colors",
                        }}
                      />
                    )}
                    className="w-full"
                  />
                  {(formSubmitted && validationErrors.countryId) || (!countryId.value && countryId.hasError) ? (
                    <p className="text-xs text-red-500 mt-1">Country is required</p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
          <div className="bg-gradient-to-r from-sky-100 to-sky-50 px-6 py-4 border-b border-sky-200 flex items-center">
            <HistoryEduIcon className="text-sky-700 mr-2" />
            <h2 className="text-lg font-semibold text-sky-800">Additional Information</h2>
          </div>
          <div className="p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Founded Year <span className="text-red-500">*</span>
                </label>
                <input
                  type="number"
                  min="1000"
                  max={new Date().getFullYear()}
                  value={foundedYear.value || ""}
                  onChange={(e) => {
                    foundedYear.valueChangeHandler(e.target.value, "foundedYear")
                  }}
                  onBlur={foundedYear.valueInputBlurHandler}
                  className={`w-full px-4 py-2 border ${
                    (formSubmitted && validationErrors.foundedYear) || (!foundedYear.value && foundedYear.hasError)
                      ? "border-red-300"
                      : "border-gray-300"
                  } rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500 transition-colors`}
                  placeholder="Enter year (e.g., 1950)"
                  required
                />
                {(formSubmitted && validationErrors.foundedYear) || (!foundedYear.value && foundedYear.hasError) ? (
                  <p className="text-xs text-red-500 mt-1">Founded year is required</p>
                ) : null}
                <p className="text-xs text-gray-500 mt-1">Enter a year between 1000 and {new Date().getFullYear()}</p>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Currency <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  value={currency.value || ""}
                  onChange={(e) => currency.valueChangeHandler(e.target.value, "currency")}
                  onBlur={currency.valueInputBlurHandler}
                  placeholder="£, $, €, USD, GBP, etc."
                  className={`w-full px-4 py-2 border ${
                    (formSubmitted && validationErrors.currency) || (!currency.value && currency.hasError)
                      ? "border-red-300"
                      : "border-gray-300"
                  } rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500 transition-colors`}
                  required
                />
                {(formSubmitted && validationErrors.currency) || (!currency.value && currency.hasError) ? (
                  <p className="text-xs text-red-500 mt-1">Currency is required</p>
                ) : null}
                <p className="text-xs text-gray-500 mt-1">Enter currency symbol or code (e.g., £, $, €, USD, GBP)</p>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Number of International Students</label>
                <input
                  type="number"
                  value={profile.numOfIntStud || ""}
                  onChange={(e) => onChangeHandler("numOfIntStud", e.target.value)}
                  placeholder="Enter number"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500 transition-colors"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Acceptance Rate (%)</label>
                <input
                  type="text"
                  value={profile.acceptanceRate || ""}
                  onChange={(e) => onChangeHandler("acceptanceRate", e.target.value)}
                  placeholder="Enter percentage"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500 transition-colors"
                />
                <p className="text-xs text-gray-500 mt-1">Enter a value between 0 and 100</p>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
          <div className="bg-gradient-to-r from-sky-100 to-sky-50 px-6 py-4 border-b border-sky-200 flex items-center">
            <EmojiPeopleIcon className="text-sky-700 mr-2" />
            <h2 className="text-lg font-semibold text-sky-800">University Rankings</h2>
          </div>
          <div className="p-6">
            <p className="text-sm text-gray-600 mb-4">
              Add university rankings from different ranking systems. If you provide a ranking position, you must also
              select the ranking type.
            </p>
            {formSubmitted && validationErrors.rankings && (
              <p className="text-xs text-red-500 mb-4">{validationErrors.rankings}</p>
            )}
            <UniversityRankings rankings={universityRanks} onChange={setUniversityRanks} />
          </div>
        </section>

        <section className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
          <div className="bg-gradient-to-r from-sky-100 to-sky-50 px-6 py-4 border-b border-sky-200 flex items-center">
            <EmojiEventsIcon className="text-sky-700 mr-2" />
            <h2 className="text-lg font-semibold text-sky-800">Other Info</h2>
          </div>
          <div className="p-6">
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
              <div className="flex items-center p-3 hover:bg-gray-50 rounded-md transition-colors">
                <input
                  type="checkbox"
                  id="isTop"
                  checked={isTop.value}
                  onChange={(e) => isTop.valueChangeHandler(e.target.checked, "isTop")}
                  className="w-4 h-4 text-sky-600 border-gray-300 rounded focus:ring-sky-500"
                />
                <label htmlFor="isTop" className="ml-2 text-sm text-gray-700">
                  Top University
                </label>
              </div>
              <div className="flex items-center p-3 hover:bg-gray-50 rounded-md transition-colors">
                <input
                  type="checkbox"
                  id="isDirect"
                  checked={isDirect.value}
                  onChange={(e) => isDirect.valueChangeHandler(e.target.checked, "isDirect")}
                  className="w-4 h-4 text-sky-600 border-gray-300 rounded focus:ring-sky-500"
                />
                <label htmlFor="isDirect" className="ml-2 text-sm text-gray-700">
                  Direct Agreement
                </label>
              </div>
              <div className="flex items-center p-3 hover:bg-gray-50 rounded-md transition-colors">
                <input
                  type="checkbox"
                  id="isAgent"
                  checked={isAgent.value}
                  onChange={(e) => isAgent.valueChangeHandler(e.target.checked, "isAgent")}
                  className="w-4 h-4 text-sky-600 border-gray-300 rounded focus:ring-sky-500"
                />
                <label htmlFor="isAgent" className="ml-2 text-sm text-gray-700">
                  Available For Agent CRM
                </label>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
          <div className="bg-gradient-to-r from-sky-100 to-sky-50 px-6 py-4 border-b border-sky-200 flex items-center">
            <DescriptionIcon className="text-sky-700 mr-2" />
            <h2 className="text-lg font-semibold text-sky-800">
              University Summary <span className="text-red-500">*</span>
            </h2>
          </div>
          <div className="p-6">
            <EditorDraftJs
              editorOnChange={(rawHtml, valid) => {
                try {
                  setSummaryHtml(rawHtml || "")
                } catch (error) {
                  console.error("Error in summary editor:", error)
                }
              }}
              height={250}
              placeHolder="University summary goes here"
              html={summaryHtml || ""}
              required
            />
            {(formSubmitted && validationErrors.summary) || !summaryHtml || summaryHtml.trim() === "" ? (
              <p className="text-xs text-red-500 mt-1">University summary is required</p>
            ) : null}
          </div>
        </section>

        <section className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
          <div className="bg-gradient-to-r from-sky-100 to-sky-50 px-6 py-4 border-b border-sky-200 flex items-center">
            <HandshakeIcon className="text-sky-700 mr-2" />
            <h2 className="text-lg font-semibold text-sky-800">Scholarship Info</h2>
          </div>
          <div className="p-6">
            <div className="flex items-center mb-4 p-3 hover:bg-gray-50 rounded-md transition-colors">
              <input
                type="checkbox"
                id="isScholarshipAvailable"
                checked={isScholarshipAvailable.value}
                onChange={(e) => isScholarshipAvailable.valueChangeHandler(e.target.checked, "IsScholarshipAvailable")}
                className="w-4 h-4 text-sky-600 border-gray-300 rounded focus:ring-sky-500"
              />
              <label htmlFor="isScholarshipAvailable" className="ml-2 text-sm text-gray-700">
                Scholarship Available
              </label>
            </div>
            {isScholarshipAvailable.value && (
              <div className="mt-4">
                <h3 className="text-md font-medium text-gray-700 mb-2">
                  Scholarship Details <span className="text-red-500">*</span>
                </h3>
                <EditorDraftJs
                  editorOnChange={(rawHtml, valid) => {
                    try {
                      setScholarshipHtml(rawHtml || "")
                    } catch (error) {
                      console.error("Error in scholarship editor:", error)
                    }
                  }}
                  height={150}
                  placeHolder="Scholarship info goes here"
                  html={scholarshipHtml || ""}
                  required
                />
                {(formSubmitted && validationErrors.scholarshipInfo) ||
                (isScholarshipAvailable.value && (!scholarshipHtml || scholarshipHtml.trim() === "")) ? (
                  <p className="text-xs text-red-500 mt-1">
                    Scholarship details are required when scholarship is available
                  </p>
                ) : null}
              </div>
            )}
          </div>
        </section>

        <section className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
          <div className="bg-gradient-to-r from-sky-100 to-sky-50 px-6 py-4 border-b border-sky-200 flex items-center">
            <PeopleIcon className="text-sky-700 mr-2" />
            <h2 className="text-lg font-semibold text-sky-800">Student Testimonials</h2>
          </div>
          <div className="p-6">
            <div className="grid grid-cols-1 gap-6">
              <div>
                <h3 className="text-md font-medium text-gray-700 mb-3">Testimonials</h3>
                <p className="text-sm text-gray-600 mb-4">
                  Add testimonials from students about their experience at the university and the total journey with TCL.
                </p>
                <StudentVideoTestimonial testimonials={universityTestimonials} onChange={setUniversityTestimonials} />
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
          <div className="bg-gradient-to-r from-sky-100 to-sky-50 px-6 py-4 border-b border-sky-200 flex items-center">
            <HistoryEduIcon className="text-sky-700 mr-2" />
            <h2 className="text-lg font-semibold text-sky-800">Facilities & Specialties</h2>
          </div>
          <div className="p-6">
            <p className="text-sm text-gray-600 mb-4">
              Describe the unique facilities and academic specialties of this institution.
            </p>
            <textarea
              value={profile.facilitiesSpecialities || ""}
              onChange={(e) => onChangeHandler("facilitiesSpecialities", e.target.value)}
              placeholder="Enter details about campus facilities, equipment, labs, and academic strengths"
              className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500 transition-colors"
              rows={4}
            />
          </div>
        </section>

        <section className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
          <div className="bg-gradient-to-r from-sky-100 to-sky-50 px-6 py-4 border-b border-sky-200 flex items-center">
            <QuestionAnswerIcon className="text-sky-700 mr-2" />
            <h2 className="text-lg font-semibold text-sky-800">Frequently Asked Questions</h2>
          </div>
          <div className="p-6">
            <p className="text-sm text-gray-600 mb-4">
              Add up to 5 frequently asked questions and answers about the university.
            </p>
            <UniversityFAQ faq={universityFaqs} onChange={setUniversityFaqs} />
          </div>
        </section>

        <section className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
          <div className="bg-gradient-to-r from-sky-100 to-sky-50 px-6 py-4 border-b border-sky-200 flex items-center">
            <VideoLibraryIcon className="text-sky-700 mr-2" />
            <h2 className="text-lg font-semibold text-sky-800">University Video Information</h2>
          </div>
          <div className="p-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Video URL</label>
              <input
                type="url"
                value={profile.videoUrl || ""}
                onChange={(e) => onChangeHandler("videoUrl", e.target.value)}
                placeholder="https://www.youtube.com/watch?v=..."
                className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500 transition-colors"
              />
              <p className="text-xs text-gray-500 mt-1">Enter a valid YouTube or Onedrive URL</p>
            </div>
          </div>
        </section>

        <section className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
          <div className="bg-gradient-to-r from-sky-100 to-sky-50 px-6 py-4 border-b border-sky-200 flex items-center">
            <EventNoteIcon className="text-sky-700 mr-2" />
            <h2 className="text-lg font-semibold text-sky-800">
              Intakes <span className="text-red-500">*</span>
            </h2>
            <p className="text-sm text-sky-600 mt-1 ml-2">Multiple intakes can be selected</p>
          </div>
          <div className="p-6">
            <div className="space-y-6">
              <div>
                <h3 className="text-md font-medium text-gray-700 mb-3">
                  Intake Months <span className="text-red-500">*</span>
                </h3>
                <div className="flex flex-wrap gap-2">
                  {Object.keys(monthNames).map((month) => (
                    <button
                      key={month}
                      type="button"
                      onClick={() => {
                        if (selectedIntakes.includes(month)) {
                          setSelectedIntakes(selectedIntakes.filter((i) => i !== month))
                        } else {
                          setSelectedIntakes([...selectedIntakes, month])
                        }
                      }}
                      className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                        selectedIntakes.includes(month)
                          ? "bg-sky-600 text-white shadow-md"
                          : "bg-sky-100 text-sky-700 hover:bg-sky-200"
                      }`}
                    >
                      {month}
                    </button>
                  ))}
                </div>
                <div className="text-sm text-gray-600 mt-2">
                  Selected months: {selectedIntakes.join(", ") || "None"}
                </div>
                {(formSubmitted && validationErrors.intakes) || selectedIntakes.length === 0 ? (
                  <p className="text-xs text-red-500 mt-1">At least one intake month is required</p>
                ) : null}
              </div>

              <div>
                <h3 className="text-md font-medium text-gray-700 mb-3">
                  Intake Years <span className="text-red-500">*</span>
                </h3>
                <div className="flex flex-wrap gap-2">
                  {intakeYearList.map((yearObj) => (
                    <button
                      key={yearObj.id}
                      type="button"
                      onClick={() => {
                        const yearStr = yearObj.year.toString()
                        if (selectedYears.includes(yearStr)) {
                          setSelectedYears(selectedYears.filter((y) => y !== yearStr))
                        } else {
                          setSelectedYears([...selectedYears, yearStr])
                        }
                      }}
                      className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                        selectedYears.includes(yearObj.year.toString())
                          ? "bg-sky-600 text-white shadow-md"
                          : "bg-sky-100 text-sky-700 hover:bg-sky-200"
                      }`}
                    >
                      {yearObj.year}
                    </button>
                  ))}
                </div>
                <div className="text-sm text-gray-600 mt-2">Selected years: {selectedYears.join(", ") || "None"}</div>
                {(formSubmitted && validationErrors.intakeYears) || selectedYears.length === 0 ? (
                  <p className="text-xs text-red-500 mt-1">At least one intake year is required</p>
                ) : null}
              </div>
            </div>
          </div>
        </section>
      </div>

      {(role.isSuperAdmin || permissions.CanManageUniversity) && (
        <div className="fixed bottom-6 right-6">
          <button
            onClick={handleSave}
            disabled={isInValid || updating}
            className={`flex items-center justify-center px-5 py-3 rounded-full text-white shadow-lg transition-all duration-300 ${
              isInValid || updating
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-sky-600 hover:bg-sky-700 hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
            }`}
          >
            {updating ? (
              <>
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Saving...
              </>
            ) : (
              <>
                <DoneIcon className="mr-2" />
                Save
              </>
            )}
          </button>
        </div>
      )}

      <ConfirmationModal
        open={openConfirm}
        cancel={() => setOpenConfirm(false)}
        confirm={confirmAction}
        confirmText="Confirm Action"
        content={confirmMessage}
      />

      {(openSuccess || openFailure) && (
        <MySnackbarAlert
          opnSuccess={openSuccess}
          opnFailure={openFailure}
          message={alertMsg}
          opnSuccessParent={setOpenSuccess}
          opnFailureParent={setOpenFailure}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  profile: ownProps.profile,
  updateProfileParent: ownProps.updateProfileParent,
  role: state.auth.role,
  permissions: state.auth.permissions,
  setAlert: ownProps.setAlert,
})

export default connect(mapStateToProps, null)(UniversityProfile)

