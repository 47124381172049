/* eslint-disable import/namespace */
import React, { useState, useCallback, useEffect } from "react"
import { connect } from "react-redux"
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Autocomplete,
  Chip,
  CircularProgress,
  Paper,
  Grid,
  Typography,
} from "@mui/material"
import { Bar, Doughnut } from "react-chartjs-2"
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from "chart.js"
import Agent from "../../../api/agent"
import { INTAKES, intakeYearList, STUDENTSOURCES } from "../../../Util/Constants"
import { Countries } from "../../../Util/Util"
import ErrorPopup from "../RegionalReport/ErrorPopUp"

ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend)

const statusTypes = [
  { id: 1, type: "Lead Organic", key: "organicLead" },
  { id: 2, type: "Lead Paid", key: "paidLead" },
  { id: 3, type: "Total Lead", key: "totalLead" },
  { id: 4, type: "Potential Lead", key: "potentialLead" },
  { id: 5, type: "Qualified", key: "qualified" },
  { id: 6, type: "Applied", key: "applied" },
  { id: 7, type: "Applied Ratio", key: "appliedRatio", isRatio: true },
  { id: 8, type: "Conditional Offer", key: "conditionalOffer" },
  { id: 9, type: "Unconditional Offer", key: "unconditionalOffer" },
  { id: 10, type: "Total Offer", key: "totalOffer" },
  { id: 11, type: "Offer Ratio", key: "offerRatio", isRatio: true },
  { id: 12, type: "Rejection", key: "rejection" },
  { id: 13, type: "Rejection Ratio", key: "rejectionRatio", isRatio: true },
  { id: 14, type: "Deposit", key: "deposite" },
  { id: 15, type: "Num of CAS", key: "numOfCAS" },
  { id: 16, type: "Enrolled", key: "enrolled" },
  { id: 17, type: "Enrolled Ratio", key: "enrolledRatio", isRatio: true },
]

const staticTargets = {
  organicLead: 50,
  paidLead: 400,
  totalLead: 100,
  potentialLead: 100,
  qualified: 100,
  applied: 60,
  appliedRatio: 30,
  conditionalOffer: 40,
  unconditionalOffer: 30,
  totalOffer: 70,
  offerRatio: 70,
  rejection: 10,
  rejectionRatio: 10,
  deposite: 50,
  numOfCAS: 40,
  enrolled: 30,
  enrolledRatio: 50,
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

const GlobalSalesProgressGraph = ({ permissions }) => {
  const [intakeYears, setIntakeYears] = useState([])
  const [intakeMonths, setIntakeMonths] = useState([])
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [sources, setSources] = useState([])
  const [branches, setBranches] = useState([])
  const [selectedBranches, setSelectedBranches] = useState([])
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [year, setYear] = useState("")
  const [citizenship, setCitizenship] = useState([])
  const [loadingBranches, setLoadingBranches] = useState(true)
  const [type, setType] = useState(1)
  const [selectedMetric, setSelectedMetric] = useState("totalLead")

  const hasAccess = permissions.CanAccessGlobalSalesReportB2C

  useEffect(() => {
    if (hasAccess) {
      const fetchBranches = async () => {
        setLoadingBranches(true)
        try {
          const response = await Agent.Cache.getBranchOffices()
          if (Array.isArray(response)) {
            setBranches(
              response.map((branch) => ({
                id: branch.id.toString(),
                label: branch.name,
              })),
            )
          } else {
            console.error("Unexpected response format for branch offices:", response)
          }
        } catch (err) {
          console.error("Error fetching branch offices:", err)
          setError("Failed to fetch branch offices. Please try again later.")
        } finally {
          setLoadingBranches(false)
        }
      }

      fetchBranches()
    }
  }, [hasAccess])

  const fetchData = useCallback(async () => {
    if (!hasAccess) return

    setLoading(true)
    setError(null)
    try {
      const response = await Agent.Report.globalSalesProgress({
        intakeYears,
        intakeMonths,
        startDate,
        endDate,
        sources,
        branches: selectedBranches.map((branchId) => Number.parseInt(branchId, 10)),
        year: year ? [year] : [],
        citizenship,
        type,
      })
      if (Array.isArray(response) && response.length > 0) {
        setData(response)
      } else {
        setError("No data available for the selected criteria.")
      }
    } catch (err) {
      setError(err.message || "Failed to fetch data. Please try again.")
      console.error("Error fetching data:", err)
    } finally {
      setLoading(false)
    }
  }, [hasAccess, intakeYears, intakeMonths, startDate, endDate, sources, selectedBranches, year, citizenship, type])

  const handleGenerateReport = () => {
    fetchData()
  }

  const handleResetFilters = () => {
    setIntakeYears([])
    setIntakeMonths([])
    setStartDate("")
    setEndDate("")
    setSources([])
    setSelectedBranches([])
    setYear("")
    setCitizenship([])
    setData([])
    setError(null)
    setType(1)
    setSelectedMetric("totalLead")
  }

  const barChartData = {
    labels: data.map((item) => months[item.month - 1]),
    datasets: [
      {
        label: "Target",
        data: data.map(() => staticTargets[selectedMetric]),
        backgroundColor: "rgba(255, 99, 132, 0.7)",
      },
      {
        label: "Achievement",
        data: data.map((item) => item[selectedMetric]),
        backgroundColor: "rgba(53, 162, 235, 0.7)",
      },
    ],
  }

  const barChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `Monthly ${statusTypes.find((status) => status.key === selectedMetric)?.type || selectedMetric}`,
      },
    },
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: false,
        beginAtZero: true,
      },
    },
  }

  const calculateTotalAchievement = () => {
    return data.reduce((sum, item) => sum + (item[selectedMetric] || 0), 0)
  }

  const calculateTotalTarget = () => {
    return staticTargets[selectedMetric] * data.length
  }

  const gaugeChartData = {
    labels: ["Achievement", "Remaining"],
    datasets: [
      {
        data: [calculateTotalAchievement(), calculateTotalTarget() - calculateTotalAchievement()],
        backgroundColor: ["rgba(53, 162, 235, 0.5)", "rgba(255, 99, 132, 0.5)"],
      },
    ],
  }

  const gaugeChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `Overall ${statusTypes.find((status) => status.key === selectedMetric)?.type || selectedMetric} Progress`,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const value = context.raw
            const total = calculateTotalTarget()
            const percentage = ((value / total) * 100).toFixed(2)
            return `${context.label}: ${value} (${percentage}%)`
          },
        },
      },
    },
    circumference: 180,
    rotation: 270,
    cutout: "70%",
  }

  if (!hasAccess) {
    return (
      <Paper elevation={3} className="p-6 bg-gray-50">
        <ErrorPopup error="You do not have permission to access the Global Sales Progress Graph!" />
      </Paper>
    )
  }

  return (
    <Paper elevation={3} className="p-6 bg-gray-50">
      <Typography variant="h4" className="mb-6 text-center">
        Global Sales Progress Graph
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth size="small">
            <InputLabel id="year-select-label">Year</InputLabel>
            <Select
              labelId="year-select-label"
              id="year-select"
              value={year}
              label="Year"
              onChange={(e) => setYear(e.target.value)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {intakeYearList.map((y) => (
                <MenuItem key={y.year} value={y.year.toString()}>
                  {y.year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            multiple
            id="intake-years"
            options={intakeYearList.map((year) => ({
              id: year.year.toString(),
              label: year.year.toString(),
            }))}
            value={intakeYears.map((id) => ({ id, label: id }))}
            onChange={(_, newValue) => setIntakeYears(newValue.map((v) => v.id))}
            renderInput={(params) => <TextField {...params} label="Intake Years" placeholder="Select intake years" />}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip key={index} variant="outlined" label={option.label} size="small" {...getTagProps({ index })} />
              ))
            }
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            multiple
            id="intake-months"
            options={[
              { id: "", label: "All Months" },
              ...INTAKES.map((month) => ({
                id: month.id.toString(),
                label: month.type,
              })),
            ]}
            value={intakeMonths.map((id) => ({
              id,
              label: INTAKES.find((m) => m.id.toString() === id)?.type || id,
            }))}
            onChange={(_, newValue) => setIntakeMonths(newValue.map((v) => v.id))}
            renderInput={(params) => <TextField {...params} label="Intake Months" placeholder="Select intake months" />}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip key={index} variant="outlined" label={option.label} size="small" {...getTagProps({ index })} />
              ))
            }
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            multiple
            id="sources"
            options={STUDENTSOURCES.map((source) => ({
              id: source.id.toString(),
              label: source.type,
            }))}
            value={sources.map((id) => ({
              id,
              label: STUDENTSOURCES.find((s) => s.id.toString() === id)?.type || id,
            }))}
            onChange={(_, newValue) => setSources(newValue.map((v) => v.id))}
            renderInput={(params) => <TextField {...params} label="Sources" placeholder="Select sources" />}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip key={index} variant="outlined" label={option.label} size="small" {...getTagProps({ index })} />
              ))
            }
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            multiple
            id="branches"
            options={branches}
            loading={loadingBranches}
            value={selectedBranches.map((id) => branches.find((b) => b.id === id) || { id, label: id })}
            onChange={(_, newValue) => setSelectedBranches(newValue.map((v) => v.id))}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Branches"
                placeholder={loadingBranches ? "Loading branches..." : "Select branches"}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loadingBranches ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip key={index} variant="outlined" label={option.label} size="small" {...getTagProps({ index })} />
              ))
            }
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            multiple
            id="citizenship"
            options={Countries.map((country) => ({
              id: country.id.toString(),
              label: country.name,
            }))}
            value={citizenship.map((id) => ({
              id,
              label: Countries.find((c) => c.id.toString() === id)?.name || id,
            }))}
            onChange={(_, newValue) => setCitizenship(newValue.map((v) => v.id))}
            renderInput={(params) => <TextField {...params} label="Citizenship" placeholder="Select citizenship" />}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip key={index} variant="outlined" label={option.label} size="small" {...getTagProps({ index })} />
              ))
            }
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth size="small">
            <InputLabel id="type-select-label">Report Type</InputLabel>
            <Select
              labelId="type-select-label"
              id="type-select"
              value={type}
              label="Report Type"
              onChange={(e) => setType(e.target.value)}
            >
              <MenuItem value={1}>Overall</MenuItem>
              <MenuItem value={2}>B2C</MenuItem>
              <MenuItem value={3}>B2B</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth size="small">
            <InputLabel id="metric-select-label">Status Type</InputLabel>
            <Select
              labelId="metric-select-label"
              id="metric-select"
              value={selectedMetric}
              label="Status Type"
              onChange={(e) => setSelectedMetric(e.target.value)}
            >
              {statusTypes.map((status) => (
                <MenuItem key={status.id} value={status.key}>
                  {status.type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <div className="mt-6 flex justify-end space-x-4">
        <button
          onClick={handleResetFilters}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        >
          Reset Filters
        </button>
        <button
          onClick={handleGenerateReport}
          disabled={loading}
          className={`px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
            loading ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          {loading ? "Generating..." : "Generate Graph"}
        </button>
      </div>
      {loading ? (
        <Paper elevation={3} className="mt-6 p-4">
          <Typography variant="body1" className="mb-2 text-center">
            Generating graph...
          </Typography>
          <CircularProgress className="mx-auto block" />
        </Paper>
      ) : error ? (
        <Paper elevation={3} className="mt-6 p-4">
          <ErrorPopup error={error} />
        </Paper>
      ) : (
        data.length > 0 && (
          <Paper elevation={3} className="mt-6 p-4">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Bar options={barChartOptions} data={barChartData} />
              </Grid>
              <Grid item xs={12}>
                <div className="h-[300px]">
                  <Doughnut data={gaugeChartData} options={gaugeChartOptions} />
                </div>
              </Grid>
            </Grid>
          </Paper>
        )
      )}
    </Paper>
  )
}

const mapStateToProps = (state) => ({
  permissions: state.auth.permissions,
})

export default connect(mapStateToProps)(GlobalSalesProgressGraph)

