/* eslint-disable import/namespace */
import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import HomePage from '../HomePage/HomePage';
import Students from '../Students/Students';
import Users from '../Users/Users';
import Auth from '../Auth/Auth';
import Tasks from '../Tasks/Tasks';
import Agents from '../Agents/Agents';
import Report from '../Report/Reports';
import Register from '../Auth/Register';
import NotFound from '../Errors/NotFound';
import Message from '../Messages/Message';
import Privacy from '../../zExtra/Privacy';
import Programs from '../Programs/Programs';
import MyAccount from '../Profile/MyAccount';
import { styled } from '@mui/material/styles';
import VerifyEmail from '../Auth/VerifyEmail';
import ServerError from '../Errors/ServerError';
import Student from '../Students/Student/Student';
import Employees from '../HR/Employees/Employees';
import WhatsAppWrapper from '../Notifications/Whatsapp/Public/WhatsAppWrapper';
import ResetPassword from '../Auth/ResetPassword';
import UserContext from '../../context/user-context';
import RegisterSuccess from '../Auth/RegisterSuccess';
import Application from '../Applications/Application';
import Courses from '../Universities/Courses/Courses';
import Appointments from '../Appointments/Appointments';
import University from '../Universities/University/University';
import NewAgentForm from '../Agents/AgentRegister/NewAgentForm';
import Departments from '../Universities/Departments/Departments';
import Preferences from '../Students/Student/Preferences/Preferences';
import RegistrationStudent from '../Auth/StudentRegistration/RegistrationStudent';
import AgentProfile from '../Agents/AgentProfile';
import Dashboard from '../Students/Student/Dashboard/Dashboard';
import StudentProvider from '../Students/Context/StudentProvider';
import StudentHomePage from '../Students/Student/HomePage/HomePage';
import ReferralHomePage from '../Referrals/HomePage/ReferralHomePage';
import CommunicationsMenu from '../CommunicationsMenu/CommunicationsMenu';
import Universities from './../Universities/Universities';
import CommunicationHistory from '../CommunicationsMenu/StudentCom/CommunicationHistory';
import Applications from './../Applications/Applications';
import LeadsMenu from './../Leads/LeadsMenu/LeadsMenu';
import LeadProfile from '../Leads/StudentLead/LeadProfile';
import StudentsLeadCommsHistory from '../CommunicationsMenu/StudentLeadComms/StudentsLeadCommsHistory';
import Notifications from '../Notifications/Notifications';
import EventsMenu from './../Events/EventsMenu/EventsMenu';
import AddEventForm from './../Events/LeadEvents/AddEventForm';
import LeadEvents from '../Events/LeadEvents/LeadEvents';
import EventAttendanceForm from '../Events/LeadEvents/EventAttendanceForm';
import PublicAddForm from '../Leads/LeadUtils/PublicLeadForm';
import Registration from '../Referrals/Registration/Registration';
import Partnerships from '../Partnerships/PartnershipsMenu/Partnerships';
import ReferralDashboard from '../Referrals/Dashboard/ReferralDashboard';
import ReferralGeneralInfo from '../Referrals/Profile/ReferralGeneralInfo';
import LeadComOptions from '../CommunicationsMenu/StudentLeadComms/LeadComOptions/LeadComOptions';
import ReferralProfileEdit from '../Partnerships/Referrals/ReferralProfileEdit';
import StudentLead from '../Leads/StudentLead/StudentLead';
import StudentBulkEmail from '../CommunicationsMenu/StudentCom/StudentComOptions/StudentBulkEmail/StudentBulkEmail';
import StudentComOptions from '../CommunicationsMenu/StudentCom/StudentComOptions/StudentComOptions';
import LeadComOptionsOld from '../CommunicationsMenu/StudentLeadCommsOld/LeadComOptions/LeadComOptionsOld';
// import Programs from './../Programs/Programs';
import ActivityMenu from './../UserActivity/ActivityMenu/ActivityMenu';
import AgentComOptions from '../CommunicationsMenu/AgentComms/AgentComOptions/AgentComOptions';
import ReferralComOptions from '../CommunicationsMenu/ReferralComms/ReferralComOptions/ReferralComOptions';
import Contracts from '../Referrals/Contracts/Contracts';
import AdminPanelMenu from '../AdminPanel/AdminPanelMenu';
import BulkUploadMenu from '../AdminPanel/BulkUploads/BulkUploadMenu';
import AddContact from './../ContactManagement/AddContact/AddContact';
import ContactsMenu from './../ContactManagement/ContactsMenu/ContactsMenu';
import UpdateTemplate from '../AdminPanel/ManageTemplates/UpdateTemplate';
// eslint-disable-next-line import/default
import CreateTemplate from './../AdminPanel/ManageTemplates/CreateTemplate';
import FromEmailList from '../AdminPanel/ManageFromEmail/FromEmailList';
import CreateFromEmail from '../AdminPanel/ManageFromEmail/CreateFromEmail';
import TemplateList from './../AdminPanel/ManageTemplates/TemplateList';
// eslint-disable-next-line import/no-unresolved
import ContactComOptions from '../CommunicationsMenu/ContactComms/ContactComOptions';
import ContactProfile from '../ContactManagement/ContactProfile/ContactProfile';
import UnsubscribedEmailList from './../AdminPanel/ManageUnsubscribedEmail/UnsubscribedEmailList';
import UnsubscribeMailInput from '../AdminPanel/ManageUnsubscribedEmail/UnsubscribeMailInput';
import AnnouncementList from '../AdminPanel/Announcement/AnnouncementList';
import CreateAnnouncement from '../AdminPanel/Announcement/CreateAnnouncement';
import UpdateUnsubscribeEmail from './../AdminPanel/ManageUnsubscribedEmail/UpdateUnsubscribeEmail';
import Announcementmenu from '../AdminPanel/Announcement/Announcementmenu';
import ReportingPanel from '../ReportingPanel/ReportingPanel';
import GlobalSalesProgressReport from './../ReportingPanel/GlobalReport/GlobalSalesReport';
import BranchReport from '../ReportingPanel/RegionalReport/BranchReport';
import RegionalReport from '../ReportingPanel/RegionalReport/RegionalReport';
import SourcePerformanceReport from '../ReportingPanel/SourcePerformanceReport/SourcePerformanceReport';
import RegionalSourceReport from '../ReportingPanel/SourcePerformanceReport/RegionalSourceReport';
import CountrySourceReport from '../ReportingPanel/SourcePerformanceReport/CountrySourceReport';
import AllBranchesSourceReport from '../ReportingPanel/SourcePerformanceReport/AllBranchesSourceReport';
import BranchSourceReport from '../ReportingPanel/SourcePerformanceReport/BranchSourceReport';
import CounselorPerformanceReport from '../ReportingPanel/CounselorPerformanceReport/CounselorPerformanceReport';
import AddLeadForm from './../Leads/StudentLead/AddLeadForm';
import SourcePerformanceChart from '../ReportingPanel/SourcePerformanceReport/SourcePerformanceChart';
import GlobalSalesProgressGraph from '../ReportingPanel/GlobalReport/GlobalSalesProgressGraph';
import AdmissionProjectionReport from '../ReportingPanel/AdmissionProjectionReport/AdmissionProjectionReport';
import UniversityProfile from '../Universities/University/Profile/UniversityProfile';
import UniversityPerformanceReport from '../ReportingPanel/UniversityReport/UniversityReport';
import StudentInsightForm from '../Common/StudentInsightForm/StudentInsightForm';

const PREFIX = 'Contents';

const classes = {
  grow: `${PREFIX}-grow`,
  root: `${PREFIX}-root`,
  hide: `${PREFIX}-hide`,
  title: `${PREFIX}-title`,
  toolbar: `${PREFIX}-toolbar`,
  content: `${PREFIX}-content`,
  inputRoot: `${PREFIX}-inputRoot`,
  contentStudent: `${PREFIX}-padding`,
  contentfull: `${PREFIX}-contentfull`,
  shiftTextLeft: `${PREFIX}-shiftTextLeft`,
  shiftTextRight: `${PREFIX}-shiftTextRight`,
};

const Root = styled('main')(({ theme }) => ({
  [`& .${classes.grow}`]: {
    flexGrow: 1,
  },
  [`& .${classes.root}`]: {
    display: 'flex',
  },

  [`& .${classes.hide}`]: {
    display: 'none',
  },

  // necessary for content to be below app bar
  [`& .${classes.toolbar}`]: {
    ...theme.mixins.toolbar,
  },
  [`&.${classes.content}`]: {
    padding: theme.spacing(3),
    flexGrow: 1,
    marginLeft: `${drawerWidth}px`,
    height: '100vh',
  },
  [`&.${classes.contentStudent}`]: {
    flexGrow: 1,
    // marginLeft: `${drawerWidth}px`,
  },

  [`&.${classes.contentfull}`]: {
    flexGrow: 1,
    backgroundImage: 'linear-gradient(0deg, #FFFFFF 100%, #FFFFFF 100%)',
    [theme.breakpoints.up('lg')]: {
      height: '100vh',
      backgroundImage: 'linear-gradient(2deg, #FFFFFF 10%, #0066b2 90%)',
    },
  },
  [`& .${classes.title}`]: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  [`& .${classes.inputRoot}`]: {
    color: 'inherit',
  },
  [`& .${classes.shiftTextLeft}`]: {
    marginLeft: `-${
      drawerWidth - theme.spacing(7).substring(0, theme.spacing(7).length - 2)
    }px`, // this is working out how much drawer is shifting to the left
  },
}));

const drawerWidth = 240; // 58
// const screenWidth = screen.width;

const Contents = ({ isLoggedIn, role, permissions, drawerOpen, appUserId }) => {
  let userContext = useContext(UserContext);
  const location = useLocation();
  const isLoggedOut = !isLoggedIn;
  useEffect(() => {
    // if (
    //   location.pathname == '/' ||
    //   location.pathname == '/home' ||
    //   location.pathname == '/Home'
    // ) {
    //   userContext.setUserContext({
    //     ...userContext,
    //     backgroundColor: '#eeeeee',
    //   });
    // } else {
    //   userContext.setUserContext({
    //     ...userContext,
    //     backgroundColor: '#ffffff',
    //   });
    // }
  }, [location.pathname]);

  return (
    <Root
      className={
        isLoggedIn && (role?.isStudent || role?.isReferral)
          ? classes.contentStudent
          : isLoggedIn && (!role?.isStudent || !role?.isReferral)
          ? classes.content
          : classes.contentfull
      }
      // style={{ backgroundColor: userContext.backgroundColor }}
    >
      <div
        className={drawerOpen ? classes.shiftTextRight : classes.shiftTextLeft}
      >
        {/* Apply the toolbar class conditionally */}
        {isLoggedOut ? null : (
          <div
            className={
              (!role?.isStudent || !role?.isReferral) && classes.toolbar
            }
          />
        )}

        {/* <div className={!role?.isStudent   &&  classes.toolbar} /> */}
        {isLoggedOut ? (
          <Routes>
            <Route
              path="/event/attendanceform"
              element={<EventAttendanceForm />}
            />
            <Route path="/studentform" element={<PublicAddForm />} />
            <Route
              path="/register/partner"
              element={<NewAgentForm externalRole="Agent" />}
            />
            {/* <Route
              path="/info"
              element={<PublicAddForm  externalRole="isStudent"/> }
            /> */}
            <Route path="/register/student" element={<RegistrationStudent />} />
            <Route path="/referral/signup" element={<Registration />} />
            <Route path="/registerSuccess" element={<RegisterSuccess />} />
            <Route path="/verifyEmail" element={<VerifyEmail />} />
            <Route path="/referral/signup" element={<Registration />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route path="/privacy" element={<Privacy />} />

            {/* <Route path="/testagent" element={AgentRegister} /> */}

            <Route path="/" element={<Auth />} />

            <Route path="*" element={<Auth />} />
          </Routes>
        ) : (
          <Routes>
            <Route
              path="/"
              element={<Navigate to="/home" replace />}
              component={
                role.isStudent
                  ? StudentHomePage
                  : role.isReferral
                  ? ReferralHomePage
                  : HomePage
              }
            />
            <Route
              path="/home"
              element={
                role.isStudent ? (
                  <StudentHomePage />
                ) : role.isReferral ? (
                  <ReferralHomePage />
                ) : (
                  <HomePage />
                )
              }
            />
            {/* <Route path="/home" element={role.isAgent ? <Dashboard />}/> */}

            {/* <Route path="/home" element={<HomePage />} /> */}
            <Route path="/student/home" element={<StudentHomePage />} />
            <Route path="/preferences" element={<Preferences />} />
            <Route path="/register" element={<Register />} />
            {/* <Route path='/students/register' element={<Student />} /> */}
            {role.isStudent ? (
              <>
                <Route path="/profile" element={<Student />} />
              </>
            ) : (
              <>
                <Route
                  path={'/agents/:agentId'}
                  element={
                    <AgentProfile permissions={permissions} role={role} />
                  }
                />
                <Route path={'/profile'} element={<AgentProfile />} />
              </>
            )}

            <Route path="/programs/:state" element={<Programs />} />

            {!role.isAgent && !role.isAgentStaff && (
              <Route
                path={'/referral/dashboard'}
                element={<ReferralDashboard />}
              />
            )}

            <Route path={'/referral/registration'} element={<Registration />} />

            {!role.isAgent && !role.isAgentStaff && (
              <Route
                path={'/referral/profile'}
                element={<ReferralGeneralInfo />}
              />
            )}

            <Route path={'/referral/contract'} element={<Contracts />} />
            <Route path="/users/:load" element={<Users />} />

            {(permissions.CanAccessAdminPanel || role.isSuperAdmin) && (
              <Route path="/users" element={<Users />} />
            )}

            {!role.isAgent &&
              !role.isAgentStaff &&
              !role.isReferral &&
              !role.isStudent &&
              permissions.CanAccessPartnershipSection && (
                <Route path="/agents" element={<Agents />} />
              )}

            {!role.isAgent &&
              !role.isAgentStaff &&
              !role.isReferral &&
              !role.isStudent &&
              permissions.CanAccessPartnershipSection && (
                <Route path="/partnerships" element={<Partnerships />} />
              )}

            {!role.isReferral && !role.isStudent && (
              <Route path="/messages" element={<Message />} />
            )}
            {!role.isReferral && !role.isStudent && (
              <Route path="/notifications" element={<Notifications />} />
            )}

            <Route path="/settings" element={<MyAccount />} />

            {!role.isReferral && !role.isStudent && (
              <Route path="/Students" element={<Students />} />
            )}

            {/* <Route path="/programs" element={<Programs />} /> */}

            {(role.isStudent ||
              role.isAgent ||
              role.isSuperAdmin ||
              role.isAgentStaff ||
              permissions.CanAccessProgramsSection) && (
              <Route path="/programs" element={<Programs />} />
            )}

            <Route path="/applications" element={<Applications />} />

            {!role.isAgent &&
              !role.isAgentStaff &&
              permissions.CanAccessReferral && (
                <Route
                  path="/referral/:id/referralProfile"
                  element={<ReferralProfileEdit />}
                />
              )}

            {/* <Route path="/students/:id/dashboard" element={<Dashboard />} /> */}
            <Route path="/students/:id/dashboard" element={<Dashboard />} />

            {!role.isAgent &&
              !role.isAgentStaff &&
              !role.isReferral &&
              !role.isStudent && (
                <Route
                  path="/leads/:id/leadprofile"
                  element={<LeadProfile />}
                />
              )}

            {!role.isAgent &&
              !role.isAgentStaff &&
              !role.isReferral &&
              !role.isStudent &&
              permissions.CanManageContact && (
                <Route
                  path="/contacts/:id/contactprofile"
                  element={<ContactProfile />}
                />
              )}

            <Route path="templates/:id/" element={<UpdateTemplate />} />
            <Route path="/createtemplate" element={<CreateTemplate />} />
            <Route
              path="/createannouncement"
              element={<CreateAnnouncement />}
            />
            {/* <Route
              path="/event/:id/eventleadlist"
              element={<EventLeadList />}
            /> */}
            <Route path="/event/:id/eventleadlist" element={<StudentLead />} />
            <Route path="/events/:id/events" element={<LeadEvents />} />
            <Route path="/students/*" element={<Student />} />

            {!role.isAgent &&
              !role.isAgentStaff &&
              !role.isReferral &&
              !role.isStudent &&
              permissions.CanAccessPartnershipSection && (
                <Route path="/partnerships" element={<Partnerships />} />
              )}

            {/* <Route
              path="/event/attendanceform"
              element={<EventAttendanceForm />}
            /> */}
            {/* <Route path="/students/register" element={<Student />} /> */}

            <Route
              path="/students/register/:agentid"
              element={
                <StudentProvider>
                  <Student />
                </StudentProvider>
              }
            />
            <Route
              path="/students/register/referraladdstudent/:referralid"
              element={
                <StudentProvider>
                  <Student />
                </StudentProvider>
              }
            />
            <Route
              path="/students/register"
              element={
                <StudentProvider>
                  <Student />
                </StudentProvider>
              }
            />

            <Route
              path="/students/:id/manage/:agentid"
              element={
                <StudentProvider>
                  <Student />
                </StudentProvider>
              }
            />
            <Route
              path="/students/:id/manage"
              element={
                <StudentProvider>
                  <Student permissions={permissions} />
                </StudentProvider>
              }
            />
            <Route
              path="/students/:id/applications/:applicationid/:agentid"
              element={
                <StudentProvider>
                  <Student />
                </StudentProvider>
              }
            />

            <Route
              path="/students/:id/applications/:applicationid"
              element={
                <StudentProvider>
                  <Student />
                </StudentProvider>
              }
            />

            <Route path="/applications/:id/manage" element={<Application />} />

            {permissions.CanAccessReportSection && (
              <Route
                path="/Report"
                element={
                  <Report
                    manageAutomatedReport={
                      permissions.CanManageAutomatedReports
                    }
                  />
                }
              />
            )}



{permissions.CanAccessReportPanel && (
              <Route
                path="/universityperformancereport"
                element={<UniversityPerformanceReport />}
              />
            )}


            {permissions.CanAccessUniversityReport && (
              <Route
                path="/universityperformancereport"
                element={<UniversityPerformanceReport />}
              />
            )}


{permissions.CanAccessReportPanel && (
              <Route
                path="/admissionprojectionreport"
                element={<AdmissionProjectionReport />}
              />
            )}


            {permissions.CanAccessAdmissionProjectionReport && (
              <Route
                path="/admissionprojectionreport"
                element={<AdmissionProjectionReport />}
              />
            )}





{permissions.CanAccessReportPanel && (
              <Route
                path="/counselorperformance"
                element={<CounselorPerformanceReport />}
              />
            )}

{permissions.CanAccessReportPanel && (
              <Route
                path="/globalsalesgraph"
                element={<GlobalSalesProgressGraph />}
              />
            )}






{permissions.CanAccessReportPanel && (
              <Route
                path="/globalsalesreport"
                element={<GlobalSalesProgressReport />}
              />
            )}


            {permissions.CanAccessGlobalSalesReportB2C && (
              <Route
                path="/globalsalesreport"
                element={<GlobalSalesProgressReport />}
              />
            )}


            {permissions.CanAccessRegionalReport && (
              <Route path="/regionalreport" element={<RegionalReport />} />
            )}

            {permissions.CanAccessRegionalReport && (
              <Route
                path="/regionalreport/:country"
                element={<RegionalReport />}
              />
            )}

            {permissions.CanAccessRegionalReport && (
              <Route
                path="/branchreport/:branchId"
                element={<BranchReport />}
              />
            )}





         {permissions.CanAccessReportPanel && (
              <Route
                path="/sourceperformancereport"
                element={<SourcePerformanceReport />}
              />
            )}
         {permissions.CanAccessReportPanel && (
              <Route
                path="/sourceperformancegraph"
                element={<SourcePerformanceChart />}
              />
            )}



            {permissions.CanAccessGlobalLeadSourcePerformanceReportB2C &&  (
              <Route
                path="/sourceperformancereport"
                element={<SourcePerformanceReport />}    //global lead source performance report
              />
            )}
            



         
            {permissions.CanAccessReportPanel && (
              <Route
                path="/regionalsourcereport"
                element={<RegionalSourceReport />}
              />
            )}

            {permissions.CanAccessRegionalSourcePerformanceReportB2C && (
              <Route
                path="/regionalsourcereport/:country"
                element={<RegionalSourceReport />}
              />
            )}

            {permissions.CanAccessRegionalSourcePerformanceReportB2C && (
              <Route
                path="/countrysourcereport/:country"
                element={<CountrySourceReport />}
              />
            )}

            {permissions.CanAccessRegionalSourcePerformanceReportB2C && (
              <Route
                path="/allbranchessourcereport/:country"
                element={<AllBranchesSourceReport />}
              />
            )}

            {permissions.CanAccessRegionalSourcePerformanceReportB2C && (
              <Route
                path="/branchsourcereport/:branchId"
                element={<BranchSourceReport />}
              />
            )}


            {permissions.CanAccessUniversitySection && (
              <Route path="/universities" element={<Universities />} />
            )}

            <Route path="/universities/:id/manage" element={<University />} />

            {!role.isAgent &&
              !role.isAgentStaff &&
              !role.isReferral &&
              (role.isStudent || permissions.CanAccessAppointmentSection) && (
                <Route path="/appointments" element={<Appointments />} />
              )}

            <Route path="/Comms" element={<CommunicationsMenu />} />
            {!role.isAgent &&
              !role.isAgentStaff &&
              !role.isReferral &&
              !role.isStudent &&
              permissions.CanManageEvents && (
                <Route path="/events" element={<EventsMenu />} />
              )}

            {!role.isAgent &&
              !role.isAgentStaff &&
              !role.isReferral &&
              !role.isStudent &&
              permissions.CanManageLeads && (
                <Route path="/leads" element={<LeadsMenu />} />
              )}

            {permissions.CanAccessActivityLog && (
              <Route path="/usersactivity" element={<ActivityMenu />} />
            )}

            {!role.isAgent &&
              !role.isAgentStaff &&
              !role.isReferral &&
              !role.isStudent &&
              permissions.CanManageLeads && (
                <Route path="/studentinsightform" element={<StudentInsightForm />} />
              )}



            {!role.isAgent &&
              !role.isAgentStaff &&
              !role.isReferral &&
              !role.isStudent &&
              permissions.CanManageLeads && (
                <Route path="/addleadform" element={<AddLeadForm />} />
              )}

          

            {!role.isAgent &&
              !role.isAgentStaff &&
              !role.isReferral &&
              !role.isStudent &&
              permissions.CanAddEvent && (
                <Route path="/addeventform" element={<AddEventForm />} />
              )}

            {permissions.CanAccessTasksSection && (
              <Route path="/tasks" element={<Tasks />} />
            )}

            {/* 
            {(permissions.CanManageAllEmployees ||
              permissions.CanManageOwnEmployees) && (
              <Route path="/employees" element={<Employees />} />
            )} */}

            {(permissions.CanManageWhatsAppUK ||
              permissions.CanManageWhatsAppBD ||
              permissions.CanManageWhatsAppIndia ||
              permissions.CanManageWhatsAppPak ||
              permissions.CanManageWhatsAppSA ||
              permissions.CanManageWhatsAppMor) && (
              <Route path="/whatsapp" element={<WhatsAppWrapper />} />
            )}

            {(permissions.CanAccessAdminPanel || role.isSuperAdmin) && (
              <Route path="/adminpanel" element={<AdminPanelMenu />} />
            )}

            {permissions.CanAccessReportPanel && (
              <Route path="/reportpanel" element={<ReportingPanel />} />
            )}

            {(permissions.CanAccessAdminPanel || role.isSuperAdmin) && (
              <Route
                path="/unsubscribeemail"
                element={<UnsubscribeMailInput appUserId={appUserId} />}
              />
            )}

            {(permissions.CanAccessAdminPanel || role.isSuperAdmin) && (
              <Route
                path="/unsubscribedmenu"
                element={<UnsubscribedEmailList />}
              />
            )}
            {(permissions.CanAccessAdminPanel || role.isSuperAdmin) && (
              <Route path="/announcementmenu" element={<Announcementmenu />} />
            )}

            {(permissions.CanAccessAdminPanel || role.isSuperAdmin) && (
              <Route path="/templatsmenu" element={<TemplateList />} />
            )}

            {(permissions.CanAccessAdminPanel || role.isSuperAdmin) && (
              <Route path="/managefromemail" element={<FromEmailList />} />
            )}
            {(permissions.CanAccessAdminPanel || role.isSuperAdmin) && (
              <Route path="/createfromemail" element={<CreateFromEmail />} />
            )}

            {(permissions.CanAccessAdminPanel || role.isSuperAdmin) && (
              <Route
                path="updateemail/:id/"
                element={<UpdateUnsubscribeEmail />}
              />
            )}

            {(permissions.CanAccessAdminPanel || role.isSuperAdmin) && (
              <Route
                path="/unsubscribedmenu"
                element={<UnsubscribedEmailList />}
              />
            )}

            {(permissions.CanAccessAdminPanel || role.isSuperAdmin) && (
              <Route path="/templatsmenu" element={<TemplateList />} />
            )}

            {(permissions.CanAccessAdminPanel || role.isSuperAdmin) && (
              <Route path="/managefromemail" element={<FromEmailList />} />
            )}

            {(permissions.CanAccessAdminPanel || role.isSuperAdmin) && (
              <Route path="/createfromemail" element={<CreateFromEmail />} />
            )}

            {/* <Route
              path="/universities/:id/summary"
              component={UniversitySummary}
            /> */}

            {permissions.CanAddContact && (
              <Route path="/addcontact" element={<AddContact />} />
            )}

            {!role.isAgent &&
              !role.isAgentStaff &&
              !role.isReferral &&
              !role.isStudent &&
              permissions.CanManageContact && (
                <Route
                  path="/contact"
                  element={<ContactsMenu permissions={permissions} />}
                />
              )}

            <Route path="/courses" element={<Courses />} />
            <Route path="/departments" element={<Departments />} />
            {/* <Route path="/managecourses" component={Courses} /> */}

            {!role.isAgent &&
              !role.isAgentStaff &&
              !role.isReferral &&
              !role.isStudent && (
                <Route path="/bulkuploadmenu" element={<BulkUploadMenu />} />
              )}

            {!role.isAgent &&
              !role.isAgentStaff &&
              !role.isReferral &&
              !role.isStudent &&
              permissions.CanUseComms && (
                <Route path="/studentcom" element={<StudentBulkEmail />} />
              )}

            {/* <Route path="/studentsLeadComms" element={<StudentsLeadComms />} /> */}
            {!role.isAgent &&
              !role.isAgentStaff &&
              !role.isReferral &&
              !role.isStudent &&
              permissions.CanUseComms && (
                <Route path="/comhistory" element={<CommunicationHistory />} />
              )}

            {!role.isAgent &&
              !role.isAgentStaff &&
              !role.isReferral &&
              !role.isStudent &&
              permissions.CanUseComms && (
                <Route
                  path="/globalcontactcomsmenu"
                  element={<ContactComOptions />}
                />
              )}

            {!role.isAgent &&
              !role.isAgentStaff &&
              !role.isReferral &&
              !role.isStudent &&
              permissions.CanUseComms && (
                <Route path="/leadcomsmenu" element={<LeadComOptions />} />
              )}

            {!role.isAgent &&
              !role.isAgentStaff &&
              !role.isReferral &&
              !role.isStudent &&
              permissions.CanUseComms && (
                <Route
                  path="/leadcomsmenuold"
                  element={<LeadComOptionsOld />}
                />
              )}

            {!role.isAgent &&
              !role.isAgentStaff &&
              !role.isReferral &&
              !role.isStudent &&
              permissions.CanUseComms && (
                <Route path="/agentcomms" element={<AgentComOptions />} />
              )}

            {!role.isAgent &&
              !role.isAgentStaff &&
              !role.isReferral &&
              !role.isStudent &&
              permissions.CanUseComms && (
                <Route path="/referralcomms" element={<ReferralComOptions />} />
              )}

            {!role.isAgent &&
              !role.isAgentStaff &&
              !role.isReferral &&
              !role.isStudent &&
              permissions.CanUseComms && (
                <Route
                  path="/studentcomsmenu"
                  element={<StudentComOptions />}
                />
              )}

            {!role.isAgent &&
              !role.isAgentStaff &&
              !role.isReferral &&
              !role.isStudent &&
              permissions.CanUseComms && (
                <Route
                  path="/studentsLeadHistory"
                  element={<StudentsLeadCommsHistory />}
                />
              )}

            {/* <Route path="/testui" element={<TestUi />} /> */}
            <Route path="/server-error" element={<ServerError />} />

            <Route path="*" element={<Navigate to="/" />} />
            <Route element={<NotFound />} />
          </Routes>
        )}
      </div>
    </Root>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    role: state.auth.role,
    permissions: state.auth.permissions,
    drawerOpen: ownProps.drawerOpen,
  };
};

export default connect(mapStateToProps, null)(Contents);
