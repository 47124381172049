/* eslint-disable import/namespace */
import React, { useState, useCallback, useEffect } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  TextField,
  Select,
  MenuItem,
  Chip,
  Box,
  FormControl,
  InputLabel,
  CircularProgress,
  Autocomplete,
  Paper,
  ToggleButtonGroup,
  ToggleButton,
  Checkbox,
  ListItemText,
} from "@mui/material"
import {
  FilterList,
  ArrowBack,
  BarChart as BarChartIcon,
  TableChart as TableChartIcon,
  BarChartOutlined,
  LineAxisOutlined,
  PieChart,
} from "@mui/icons-material"

import { Bar, Pie, Line } from "react-chartjs-2"

import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"

import { intakeYearList, INTAKES } from "../../../Util/Constants"
import { Countries } from "../../../Util/Util"
import Agent from "../../../api/agent"
import { connect } from "react-redux"
import SourcePerformanceDialog from "./SourcePerformanceDialog"
import ErrorPopup from "../RegionalReport/ErrorPopUp"

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend)

const months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"]

const MonthSelector = ({ selectedMonth, onMonthChange, selectedYear, onYearChange }) => (
  <div className="flex flex-wrap gap-2 mb-6">
    <button
      key="all"
      onClick={() => onMonthChange("all")}
      className={`px-4 py-2 rounded-full text-sm font-medium transition-all duration-200 ease-in-out ${
        selectedMonth === "all" ? "bg-blue-600 text-white shadow-md" : "bg-gray-200 text-gray-700 hover:bg-gray-300"
      }`}
    >
      ALL
    </button>
    {months.map((month) => (
      <button
        key={month.toLowerCase()}
        onClick={() => onMonthChange(month.toLowerCase())}
        className={`px-4 py-2 rounded-full text-sm font-medium transition-all duration-200 ease-in-out ${
          selectedMonth === month.toLowerCase()
            ? "bg-blue-600 text-white shadow-md"
            : "bg-gray-200 text-gray-700 hover:bg-gray-300"
        }`}
      >
        {month.toUpperCase()}
      </button>
    ))}
    {selectedYear && (
      <button
        onClick={() => onYearChange("")}
        className="px-4 py-2 rounded-full text-sm font-medium bg-red-500 text-white hover:bg-red-600 transition-all duration-200 ease-in-out"
      >
        Clear {selectedYear}
      </button>
    )}
  </div>
)

const SourcePerformanceReport = ({ permissions }) => {
  const [sourceData, setSourceData] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingBranches, setLoadingBranches] = useState(true)
  const [error, setError] = useState(null)
  const [branches, setBranches] = useState([])
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    year: "",
    leadType: "2",
    intakeYears: [],
    intakeMonths: [],
    citizenship: [],
    selectedBranches: [],
  })
  const [showFilters, setShowFilters] = useState(true)
  const [selectedMonth, setSelectedMonth] = useState("all")
  const [viewMode, setViewMode] = useState("both")
  const [selectedSource, setSelectedSource] = useState(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [chartType, setChartType] = useState("bar")
  const [isInitialLoad, setIsInitialLoad] = useState(true)

  const hasAccess = permissions.CanAccessGlobalLeadSourcePerformanceReportB2C

  useEffect(() => {
    if (hasAccess) {
      const fetchBranches = async () => {
        setLoadingBranches(true)
        try {
          const response = await Agent.Cache.getBranchOffices()
          if (Array.isArray(response)) {
            setBranches(response.map((branch) => ({ id: branch.id.toString(), label: branch.name })))
          } else {
            console.error("Unexpected response format for branch offices:", response)
          }
        } catch (err) {
          console.error("Error fetching branch offices:", err)
          setError("Failed to fetch branch offices. Please try again later.")
        } finally {
          setLoadingBranches(false)
        }
      }

      fetchBranches()
    }
  }, [hasAccess])

  const fetchData = useCallback(async () => {
    if (!hasAccess) return

    setLoading(true)
    setError(null)
    try {
      const params = {
        intakeYears: filters.intakeYears,
        intakeMonths: filters.intakeMonths,
        startDate: filters.startDate,
        endDate: filters.endDate,
        branches: filters.selectedBranches.map(Number),
        citizenship: filters.citizenship,
        year: filters.year ? [filters.year] : [],
        type: Number.parseInt(filters.leadType, 10),
      }

      const [sourceResponse, targetResponse] = await Promise.all([
        Agent.Report.leadsBySource(params),
        Agent.Report.leadsBySourceTarget(params),
      ])

      if (
        Array.isArray(sourceResponse) &&
        sourceResponse.length > 0 &&
        Array.isArray(targetResponse) &&
        targetResponse.length > 0
      ) {
        const combinedData = sourceResponse.map((source) => {
          const target = targetResponse.find((t) => t.id === source.id) || {}
          return {
            ...source,
            ...Object.fromEntries(months.map((month) => [`${month}Target`, target[month] || 0])),
          }
        })
        setSourceData(combinedData)
      } else {
        setSourceData([])
        setError("No data available for the selected criteria.")
      }
    } catch (err) {
      setError(err.message || "Failed to fetch data. Please try again.")
      console.error("Error fetching data:", err)
    } finally {
      setLoading(false)
      setIsInitialLoad(false)
    }
  }, [hasAccess, filters])

  const handleGenerateReport = () => {
    fetchData()
  }

  const handleResetFilters = () => {
    setFilters({
      startDate: "",
      endDate: "",
      year: "",
      leadType: "2",
      intakeYears: [],
      intakeMonths: [],
      citizenship: [],
      selectedBranches: [],
    })
    setSourceData([])
    setError(null)
    setSelectedMonth("all")
    setIsInitialLoad(true)
  }

  const handleMonthChange = (month) => {
    setSelectedMonth(month)
  }

  const handleChartClick = (event, elements) => {
    if (elements.length > 0 && sourceData.length > 0) {
      const { index } = elements[0]
      const source = sourceData[index]
      setSelectedSource(source)
      setDialogOpen(true)
    }
  }

  const getMonthData = (item, month) => {
    if (month === "all") {
      return months.reduce((sum, m) => sum + (item[m] || 0), 0)
    }
    return item[month] || 0
  }

  const getMonthTarget = (item, month) => {
    if (month === "all") {
      return months.reduce((sum, m) => sum + (item[`${m}Target`] || 0), 0)
    }
    return item[`${month}Target`] || 0
  }

  const chartData = {
    labels: sourceData.map((item) => item.source),
    datasets: [
      {
        label: "Target",
        data: sourceData.map((item) => getMonthTarget(item, selectedMonth)),
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
      {
        label: "Achieve",
        data: sourceData.map((item) => getMonthData(item, selectedMonth)),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  }

  const pieChartData = {
    labels: sourceData.map((item) => item.source),
    datasets: [
      {
        data: sourceData.map((item) => getMonthData(item, selectedMonth)),
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
          "rgba(255, 159, 64, 0.6)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  }

  const lineChartData = {
    labels: months,
    datasets: sourceData.map((source, index) => ({
      label: source.source,
      data: months.map((month) => getMonthData(source, month)),
      borderColor: `hsl(${index * 30}, 70%, 50%)`,
      backgroundColor: `hsla(${index * 30}, 70%, 50%, 0.5)`,
      fill: false,
    })),
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          padding: 20,
          font: {
            size: 12,
            weight: "bold",
          },
        },
      },
      title: {
        display: true,
        text: `Source Performance Analysis - ${selectedMonth.toUpperCase()}`,
        font: {
          size: 20,
          weight: "bold",
        },
        padding: {
          top: 10,
          bottom: 20,
        },
        color: "#333",
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || ""
            const value = context.parsed.y
            return `${label}: ${value}`
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 10,
            weight: "bold",
          },
          color: "#555",
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          font: {
            size: 10,
            weight: "bold",
          },
          color: "#555",
        },
        grid: {
          color: "rgba(0, 0, 0, 0.1)",
        },
      },
    },
    onClick: handleChartClick,
  }

  const pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: true,
        text: `Source Performance Analysis - ${selectedMonth.toUpperCase()} ${filters.year}`,
        font: {
          size: 20,
          weight: "bold",
        },
        padding: {
          top: 10,
          bottom: 20,
        },
        color: "#333",
      },
    },
  }

  const lineChartOptions = {
    ...chartOptions,
    scales: {
      ...chartOptions.scales,
      y: {
        ...chartOptions.scales.y,
        title: {
          display: true,
          text: "Achievement",
        },
      },
    },
  }

  const formatValue = (value) => {
    return value > 0 ? value.toString() : ""
  }

  const formatRate = (achieved, target) => {
    if (target === 0) return "N/A"
    const rate = (achieved / target) * 100
    return isFinite(rate) ? `${rate.toFixed(2)}%` : "N/A"
  }

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const handleFilterChange = (newFilters) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }))
  }

  if (!hasAccess) {
    return <ErrorPopup error="You do not have permission to access the Source Performance Analysis." />
  }

  return (
    <Card className="max-w-full mx-auto mt-4 bg-white shadow-xl rounded-lg">
      <CardContent className="p-8">
        <Box className="flex justify-between items-center mb-8">
          <Typography variant="h4" component="h1" className="text-gray-800 font-bold">
            Source Performance Analysis
          </Typography>
          <Box className="flex space-x-4">
            <Link
              to="/reportpanel"
              className="flex items-center px-4 py-2 text-sm font-medium text-blue-600 bg-blue-100 rounded-md hover:bg-blue-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <ArrowBack className="w-5 h-5 mr-2" />
              Back to Reporting Panel
            </Link>
            <Button
              variant="outlined"
              startIcon={<FilterList />}
              onClick={() => setShowFilters(!showFilters)}
              className="text-gray-700 bg-white border border-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200 ease-in-out"
            >
              {showFilters ? "Hide Filters" : "Show Filters"}
            </Button>
            <ToggleButtonGroup
              value={viewMode}
              exclusive
              onChange={(event, newMode) => {
                if (newMode !== null) {
                  setViewMode(newMode)
                }
              }}
              aria-label="view mode"
            >
              <ToggleButton value="chart" aria-label="chart view">
                <BarChartIcon />
              </ToggleButton>
              <ToggleButton value="table" aria-label="table view">
                <TableChartIcon />
              </ToggleButton>
              <ToggleButton value="both" aria-label="both views">
                <BarChartIcon />
                <TableChartIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
        {showFilters && (
          <Box className="mb-8 bg-gray-50 p-6 rounded-lg shadow-inner">
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Start Date"
                  type="date"
                  value={filters.startDate}
                  onChange={(e) => setFilters({ ...filters, startDate: e.target.value })}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  size="small"
                  className="bg-white"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="End Date"
                  type="date"
                  value={filters.endDate}
                  onChange={(e) => setFilters({ ...filters, endDate: e.target.value })}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  size="small"
                  className="bg-white"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth size="small">
                  <InputLabel id="year-select-label">Year</InputLabel>
                  <Select
                    labelId="year-select-label"
                    id="year-select"
                    value={filters.year}
                    label="Year"
                    onChange={(e) => {
                      setFilters({ ...filters, year: e.target.value })
                      setSelectedMonth("all")
                    }}
                    className="bg-white"
                  >
                    <MenuItem value="">
                      <em>All Years</em>
                    </MenuItem>
                    {intakeYearList.map((y) => (
                      <MenuItem key={y.year} value={y.year.toString()}>
                        {y.year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth size="small">
                  <InputLabel id="lead-type-select-label">Lead Type</InputLabel>
                  <Select
                    labelId="lead-type-select-label"
                    id="lead-type-select"
                    value={filters.leadType}
                    label="Lead Type"
                    onChange={(e) => setFilters({ ...filters, leadType: e.target.value })}
                    className="bg-white"
                  >
                    <MenuItem value="1">Paid Leads</MenuItem>
                    <MenuItem value="2">Organic Leads</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth size="small">
                  <InputLabel id="intake-years-label">Intake Years</InputLabel>
                  <Select
                    labelId="intake-years-label"
                    id="intake-years"
                    multiple
                    value={filters.intakeYears}
                    label="Intake Years"
                    onChange={(e) => handleFilterChange({ intakeYears: e.target.value })}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} size="small" />
                        ))}
                      </Box>
                    )}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
                          width: 250,
                        },
                      },
                    }}
                  >
                    {intakeYearList.map((year) => (
                      <MenuItem key={year.year} value={year.year.toString()}>
                        <Checkbox checked={filters.intakeYears.indexOf(year.year.toString()) > -1} />
                        <ListItemText primary={year.year} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl fullWidth size="small">
                  <InputLabel id="intake-month-label">Intake Months</InputLabel>
                  <Select
                    labelId="intake-month-label"
                    id="intake-month"
                    multiple
                    value={filters.intakeMonths}
                    label="Intake Months"
                    onChange={(e) => handleFilterChange({ intakeMonths: e.target.value })}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={INTAKES.find((intake) => intake.id.toString() === value)?.type}
                            size="small"
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: ITEM_HEIGHT * 12 + ITEM_PADDING_TOP,
                          width: 250,
                        },
                      },
                    }}
                  >
                    {INTAKES.map((intake) => (
                      <MenuItem key={intake.id} value={intake.id.toString()}>
                        <Checkbox checked={filters.intakeMonths.indexOf(intake.id.toString()) > -1} />
                        <ListItemText primary={intake.type} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  multiple
                  id="citizenship"
                  options={Countries.map((country) => ({ id: country.id.toString(), label: country.name }))}
                  value={filters.citizenship.map((id) => ({
                    id,
                    label: Countries.find((c) => c.id.toString() === id)?.name || id,
                  }))}
                  onChange={(_, newValue) => setFilters({ ...filters, citizenship: newValue.map((v) => v.id) })}
                  renderInput={(params) => (
                    <TextField {...params} label="Citizenship" placeholder="Select citizenship" className="bg-white" />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option.id}
                        variant="outlined"
                        label={option.label}
                        size="small"
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  multiple
                  id="branches"
                  options={branches}
                  loading={loadingBranches}
                  value={filters.selectedBranches.map((id) => branches.find((b) => b.id === id) || { id, label: id })}
                  onChange={(_, newValue) => setFilters({ ...filters, selectedBranches: newValue.map((v) => v.id) })}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Branches"
                      placeholder={loadingBranches ? "Loading branches..." : "Select branches"}
                      className="bg-white"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingBranches ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option.id}
                        variant="outlined"
                        label={option.label}
                        size="small"
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  size="small"
                />
              </Grid>
            </Grid>
            <Box className="mt-6 flex justify-end space-x-4">
              <Button
                onClick={handleResetFilters}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors duration-200 ease-in-out"
              >
                Reset Filters
              </Button>
              <Button
                onClick={handleGenerateReport}
                disabled={loading}
                className={`px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200 ease-in-out ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {loading ? "Generating..." : "Generate Report"}
              </Button>
            </Box>
          </Box>
        )}
        {loading ? (
          <Box className="flex justify-center my-8">
            <CircularProgress size={60} thickness={4} />
          </Box>
        ) : (
          <>
            <MonthSelector
              selectedMonth={selectedMonth}
              onMonthChange={handleMonthChange}
              selectedYear={filters.year}
              onYearChange={(year) => {
                setFilters({ ...filters, year })
                setSelectedMonth("all")
              }}
            />
            {isInitialLoad ? (
              <Typography className="mt-4 text-center p-4">Use the filters above to generate a report.</Typography>
            ) : sourceData.length > 0 ? (
              <>
                {(viewMode === "chart" || viewMode === "both") && (
                  <>
                    <Box className="flex justify-end mb-4">
                      <div className="flex space-x-2">
                        <button
                          className={`px-4 py-2 rounded-md ${
                            chartType === "bar" ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-700"
                          }`}
                          onClick={() => setChartType("bar")}
                        >
                          <BarChartOutlined className="mr-2" />
                          Bar
                        </button>
                        <button
                          className={`px-4 py-2 rounded-md ${
                            chartType === "line" ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-700"
                          }`}
                          onClick={() => setChartType("line")}
                        >
                          <LineAxisOutlined className="mr-2" />
                          Line
                        </button>
                        <button
                          className={`px-4 py-2 rounded-md ${
                            chartType === "pie" ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-700"
                          }`}
                          onClick={() => setChartType("pie")}
                        >
                          <PieChart className="mr-2" />
                          Pie
                        </button>
                      </div>
                    </Box>
                    <Box className="h-[600px] mt-4 bg-white p-4 rounded-lg shadow-md">
                      {chartType === "bar" && <Bar options={chartOptions} data={chartData} />}
                      {chartType === "pie" && <Pie options={pieChartOptions} data={pieChartData} />}
                      {chartType === "line" && <Line options={lineChartOptions} data={lineChartData} />}
                    </Box>
                  </>
                )}
                {(viewMode === "table" || viewMode === "both") && (
                  <Paper elevation={3} className="mt-6 overflow-x-auto max-w-full shadow-lg rounded-lg">
                    <Box className="p-4">
                      <div className="overflow-x-auto">
                        <table className="w-full text-xs border-collapse">
                          <thead className="bg-gray-100">
                            <tr>
                              <th
                                className="py-3 px-2 text-left sticky left-0 z-20 bg-gray-100 font-bold text-sm border-b-2 border-r-2 border-gray-300 w-40"
                                rowSpan={2}
                              >
                                Source
                              </th>
                              {months.map((month) => (
                                <th
                                  key={month.toLowerCase()}
                                  className="py-2 px-1 text-center font-bold text-xs border-b-2 border-gray-300 bg-blue-200"
                                  colSpan={2}
                                >
                                  {month.toUpperCase()}
                                </th>
                              ))}
                              <th
                                className="py-2 px-1 text-center font-bold text-xs border-b-2 border-gray-300 bg-amber-200"
                                colSpan={2}
                              >
                                Total
                              </th>
                            </tr>
                            <tr>
                              {[...months, "Total"].map((month) => (
                                <React.Fragment key={`header-${month.toLowerCase()}`}>
                                  <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-red-100">
                                    Target
                                  </th>
                                  <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-green-100">
                                    Achieve
                                  </th>
                                </React.Fragment>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {sourceData.map((source, index) => (
                              <tr
                                key={source.id}
                                className={index % 2 === 0 ? "bg-white hover:bg-sky-50" : "bg-gray-50 hover:bg-sky-100"}
                              >
                                <td
                                  className="py-2 px-2 font-semibold text-xs sticky left-0 z-10 border-r border-gray-300"
                                  style={{ backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F9FAFB" }}
                                >
                                  {source.source}
                                </td>
                                {months.map((month) => (
                                  <React.Fragment key={`${source.id}-${month.toLowerCase()}`}>
                                    <td className="py-1 px-1 text-center border-r border-gray-300">
                                      <div className="font-medium text-red-600 text-xs">
                                        {formatValue(getMonthTarget(source, month))}
                                      </div>
                                    </td>
                                    <td className="py-1 px-1 text-center border-r border-gray-300">
                                      <div className="font-medium text-green-600 text-xs">
                                        {formatValue(getMonthData(source, month))}
                                      </div>
                                    </td>
                                  </React.Fragment>
                                ))}
                                <td className="py-1 px-1 text-center border-r border-gray-300">
                                  <div className="font-medium text-red-600 text-xs">
                                    {formatValue(getMonthTarget(source, "all"))}
                                  </div>
                                </td>
                                <td className="py-1 px-1 text-center border-r border-gray-300">
                                  <div className="font-medium text-green-600 text-xs">
                                    {formatValue(getMonthData(source, "all"))}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Box>
                  </Paper>
                )}
              </>
            ) : (
              <Typography className="mt-4 text-center p-4 bg-yellow-100 border border-yellow-300 rounded-md">
                No data available for the selected criteria.
              </Typography>
            )}
           
          </>
        )}
      </CardContent>
      <SourcePerformanceDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        sourceData={selectedSource}
        selectedMonth={selectedMonth.toUpperCase()}
      />
    </Card>
  )
}

const mapStateToProps = (state) => ({
  permissions: state.auth.permissions,
})

export default connect(mapStateToProps)(SourcePerformanceReport)

