"use client"

import React,{ useState } from "react"
import {
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import { LoadingButton } from "@mui/lab"
import Agent from "../../../api/agent"

const Documents = ({
  files,
  handleFileDelete,
  agentId,
  studentLeadId,
  applicationId = 0,
  disabled = false,
  softDelete = false,
  documentTypes = {},
}) => {
  const [downloading, setDownloading] = useState(false)
  const [deletingFiles, setDeletingFiles] = useState({})
  const [fileToDownload, setFileToDownload] = useState()
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
  const [fileToDelete, setFileToDelete] = useState(null)

  const handleDeleteClick = (file) => {
    setFileToDelete(file)
    setDeleteConfirmOpen(true)
  }

  const confirmDelete = () => {
    if (fileToDelete) {
      deleteFile(fileToDelete.fileName, fileToDelete.id)
      setDeleteConfirmOpen(false)
    }
  }

  const cancelDelete = () => {
    setDeleteConfirmOpen(false)
    setFileToDelete(null)
  }

  const deleteFile = (fileName, fileId) => {
    if (softDelete) {
      handleFileDelete(fileName)
      return
    }

    // Set deleting state for this specific file
    setDeletingFiles((prev) => ({ ...prev, [fileId]: true }))

    Agent.CloudFiles.delete(fileName, agentId, studentLeadId, applicationId)
      .then(() => {
        handleFileDelete(fileName)
        setDeletingFiles((prev) => {
          const newState = { ...prev }
          delete newState[fileId]
          return newState
        })
      })
      .catch((error) => {
        console.log(error)
        setDeletingFiles((prev) => {
          const newState = { ...prev }
          delete newState[fileId]
          return newState
        })
      })
  }

  const downloadFile = async (fileNameLocal, fileName, containerName) => {
    setDownloading(true)
    setFileToDownload(fileName)

    await Agent.CloudFiles.download(fileNameLocal, fileName, containerName)
      .then((response) => {
        var link = document.createElement("a")
        link.href = window.URL.createObjectURL(new Blob([response.data], { type: "application/octet-stream" }))
        link.download = fileNameLocal ? fileNameLocal : fileName
        document.body.appendChild(link)
        link.click()
        setTimeout(() => {
          window.URL.revokeObjectURL(link)
        }, 200)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setDownloading(false)
        setFileToDownload(null)
      })
  }

  return (
    <>
      <div className="bg-white shadow-md rounded-lg overflow-hidden border border-gray-200">
        <table className="w-full table-auto">
          <thead className="bg-gradient-to-r from-blue-50 to-blue-100">
            <tr>
              <th className="px-4 py-3 text-left text-sm font-semibold text-gray-700">File Name</th>
              <th className="px-4 py-3 text-left text-sm font-semibold text-gray-700">Document Type</th>
              <th className="px-4 py-3 text-right text-sm font-semibold text-gray-700">Actions</th>
            </tr>
          </thead>
          <tbody>
            {files.map((file, index) => (
              <tr key={index} className={index % 2 === 0 ? "bg-gray-50 hover:bg-blue-50" : "bg-white hover:bg-blue-50"}>
                <td className="px-4 py-3 text-sm text-gray-800">{file.fileNameLocal || file.fileName}</td>
                <td className="px-4 py-3 text-sm text-gray-800">
                  {documentTypes[file.id] || file.documentTypeDesc || "Unknown"}
                </td>
                <td className="px-4 py-3 text-right">
                  <ButtonGroup size="small" color="primary" className="shadow-sm">
                    <LoadingButton
                      disabled={file.state === 2}
                      loading={downloading && fileToDownload === file.fileName}
                      onClick={() => {
                        setFileToDownload(file.fileName)
                        downloadFile(file.fileNameLocal, file.fileName, file.containerName)
                      }}
                      startIcon={<CloudDownloadIcon />}
                      className="bg-blue-500 hover:bg-blue-600 text-white px-3 py-1.5 text-xs rounded-l transition-colors duration-200"
                    >
                      Download
                    </LoadingButton>
                    <LoadingButton
                      disabled={disabled || deletingFiles[file.id]}
                      color="secondary"
                      loading={deletingFiles[file.id]}
                      startIcon={<DeleteForeverIcon />}
                      onClick={() => handleDeleteClick(file)}
                      className="bg-red-500 hover:bg-red-600 text-white px-3 py-1.5 text-xs rounded-r transition-colors duration-200"
                    >
                      Delete
                    </LoadingButton>
                  </ButtonGroup>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Dialog
        open={deleteConfirmOpen}
        onClose={cancelDelete}
        aria-labelledby="delete-dialog-title"
        PaperProps={{
          className: "rounded-lg shadow-xl",
        }}
      >
        <DialogTitle id="delete-dialog-title" className="bg-red-50 text-red-700 font-bold">
          Confirm Delete
        </DialogTitle>
        <DialogContent className="pt-4">
          <DialogContentText className="text-gray-700">
            Are you sure you want to delete this document?
            {fileToDelete && (
              <span className="block font-semibold mt-2 text-gray-900">
                {fileToDelete.fileNameLocal || fileToDelete.fileName}
              </span>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="p-3 bg-gray-50">
          <Button onClick={cancelDelete} className="bg-gray-200 hover:bg-gray-300 text-gray-800 px-4 py-1.5 rounded-md">
            Cancel
          </Button>
          <Button
            onClick={confirmDelete}
            color="error"
            variant="contained"
            className="bg-red-600 hover:bg-red-700 text-white px-4 py-1.5 rounded-md"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Documents

