"use client"

import React,{ useState, useEffect, useContext } from "react"
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
  Select,
  MenuItem,
} from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import { styled } from "@mui/material/styles"
import { connect } from "react-redux"
import LoadingView from "../Common/Loading/LoadingView"
import { startOfDay, addHours, format, addMinutes } from "date-fns"
import { useNavigate, useSearchParams } from "react-router-dom"
import UserContext from "../../context/user-context"
import {
  ADMIN,
  APPLICATIONTEAM,
  APPLICATIONTEAMLEADER,
  APPLICATION_STATUSES,
  ENTRY_YEARS,
  INTAKES,
  SENIORADMIN,
  STUDY_LEVELS,
  SUPERADMIN,
  STUDENT_DOCUMENT_TYPES,
} from "../../Util/Constants"
import Conversations from "../Messages/Conversations"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Box from "@mui/material/Box"
import MyTabLabel from "../Common/Tab/MyTabLabel"
import PhotoWidgetDropzone from "../Common/ImageUpload/PhotoWidgetDropzone"
import AddIcon from "@mui/icons-material/Add"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import EditorDraftJs from "../Common/Editor/EditorDraftJs"
import { LoadingButton } from "@mui/lab"
import { Link } from "react-router-dom"
import MySnackbarAlert from "../Common/AlertMessage/MySnackbarAlert"
import Documents from "../Common/Documents/Documents"
import MyTooltip from "../Common/Tooltip/MyTooltip"
import * as actions from "../../store/actions"
import { Countries } from "../../Util/Util"
import Agent from "../../api/agent"
import "react-quill/dist/quill.snow.css"

const PREFIX = "Application"

const classes = {
  courseArea: `${PREFIX}-courseArea`,
  studentArea: `${PREFIX}-studentArea`,
}

const Root = styled("div")(() => ({
  [`& .${classes.courseArea}`]: {
    marginTop: 5,
  },

  [`& .${classes.studentArea}`]: {
    marginTop: 5,
    paddingLeft: 24,
  },
}))

const GreenCheckbox = (props) => <Checkbox color="secondary" {...props} />

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

export const modulesNote = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
    ["link", "video"],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ["clean"],
  ],
}

export const formatsNote = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "video",
  "color",
  "background",
  "font",
  "align",
  "clean",
]

const LAST_FOUR_DOCUMENT_TYPES = STUDENT_DOCUMENT_TYPES.slice(-6)

const Application = ({ Id, name, role, roleStd, isInternal, partners, loadPartners, permissions }) => {
  const navigate = useNavigate()
  const userContext = useContext(UserContext)
  const [courseStartWarning, setCourseStartWarning] = useState(false)
  const [openSuccess, setOpenSuccess] = useState(false)
  const [openFailure, setOpenFailure] = useState(false)
  const [alertMsg, setAlertMsg] = useState()
  const [app, setApp] = useState({})
  const [notes, setNotes] = useState([])
  const [messages, setMessages] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [syncing, setSyncing] = useState(false)
  const [oldAppId, setOldAppId] = useState()
  const [status, setStatus] = useState()
  const [applicationPartnerId, setApplicationPartnerId] = useState(0)
  const [entryYear, setEntryYear] = useState(0)
  const [uploadMode, setUploadMode] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [applicationRef, setApplicationRef] = useState()
  const [courseStartDate, setCourseStartDate] = useState(null)
  const [courseEndDate, setCourseEndDate] = useState(null)
  const [login, setLogin] = useState()
  const [password, setPassword] = useState()
  const [addMessageMode, setAddMessageMode] = useState(false)
  const [addNodeMode, setAddNodeMode] = useState(false)
  const [editorValidState, setEditorValidState] = useState(false)
  const [rawHtml, setRawHtml] = useState()
  const [sendEmail, setSendEmail] = useState(true)
  const [sendStatusEmail, setSendStatusEmail] = useState(false)
  const [file, setFile] = useState("")
  const [searchParams] = useSearchParams()
  const tab = searchParams.get("tab")
  const [noteWarningMsg, setNoteWarningMsg] = useState(false)
  const [tabValue, setTabValue] = useState(tab ? 1 : 0)
  const isAdmin =
    role === SUPERADMIN ||
    role === ADMIN ||
    role === SENIORADMIN ||
    role == APPLICATIONTEAM ||
    role == APPLICATIONTEAMLEADER
  const [subject, setSubject] = useState()
  const [readyToSave, setReadyToSave] = useState(false)
  const [noteCount, setNoteCount] = useState()
  const [messageCount, setMessageCount] = useState()
  const [files, setFiles] = useState([])
  const [originalStatus, setOriginalStatus] = useState()
  const [selectedDocType, setSelectedDocType] = useState(null)
  const [documentTypes, setDocumentTypes] = useState({})

  useEffect(() => {
    if (partners.length == 0) {
      loadPartners()
    }
  }, [partners])

  useEffect(() => {
    Agent.Applications.getApplication(Id)
      .then((app) => {
        console.log(app)
        setOriginalStatus(app.status)
        setStatus(app.status)
        setEntryYear(app.entryYear)
        setApplicationPartnerId(app.applicationPartnerId)
        setApplicationRef(app.applicationRef)
        setCourseStartDate(app.courseStartDate)
        setCourseEndDate(app.courseEndDate)
        setLogin(app.loginName)
        setPassword(app.loginPassword)
        setApp(app)
        setOldAppId(app.oldApplicationId)
        const temp = app.applicationNotes
        setFiles(app.applicationCloudFiles)

        setSubject(`${app.studentId} - ${app.firstName} ${app.lastName} - ${app.universityName}`)

        const appNotes = temp.filter((note) => note.noteType == 2)
        const appMessages = temp.filter((note) => note.noteType == 1)

        appNotes.sort((a, b) => new Date(b.dateSent) - new Date(a.dateSent))
        appMessages.sort((a, b) => new Date(b.dateSent) - new Date(a.dateSent))
        setNotes(appNotes)
        setNoteCount(appNotes.length)

        setMessages(appMessages)
        setMessageCount(appMessages.length)

        // Set document types
        const docTypes = {}
        app.applicationCloudFiles.forEach((file) => {
          docTypes[file.id] = STUDENT_DOCUMENT_TYPES.find((type) => type.id === file.documentType)?.type || "Unknown"
        })
        setDocumentTypes(docTypes)

        setLoaded(true)
      })
      .catch((error) => {
        console.log(error)
        setLoaded(true)
      })
  }, [])

  useEffect(() => {
    if (app && app.id > 0) {
      setApp({ ...app, ...{ status, courseStartDate, courseEndDate } })
    }
  }, [status, courseStartDate, courseEndDate])

  const addAppNote = () => {
    var msg = {
      content: rawHtml,
      applicationId: Id,
      sendEmail: false,
      file: "",
      attachmentFileName: "",
      noteType: 2,
    }
    addNote(msg)
  }

  const sendMsgToAgent = () => {
    var msg = {
      content: rawHtml,
      applicationId: Id,
      sendEmail,
      noteType: 1,
      attachmentFileName: "",
      subject,
      file: file,
    }
    addNote(msg)
  }

  const addNote = (msg) => {
    setUpdating(true)
    Agent.Messages.addApplicationNoteAtt(msg)
      .then(() => {
        if (msg.noteType == 1) {
          const temp = messages.concat({
            content: rawHtml,
            from: name,
            attachmentFileName: file.name,
            dateSent: format(addMinutes(new Date(), new Date().getTimezoneOffset()), "MMMM d, yyyy h:mm a"),
          })
          temp.sort((a, b) => new Date(b.dateSent) - new Date(a.dateSent))
          setMessages(temp)
          setFile("")
          setMessageCount(temp.length)
          setUpdating(false)
          setAddMessageMode(false)
          userContext.setUserContext({
            ...userContext,
            ...{
              draft: "",
            },
          })
        } else if (msg.noteType == 2) {
          const temp = notes.concat({
            content: rawHtml,
            from: name,
            dateSent: format(addMinutes(new Date(), new Date().getTimezoneOffset()), "MMMM d, yyyy h:mm a"),
          })
          temp.sort((a, b) => new Date(b.dateSent) - new Date(a.dateSent))
          setNotes(temp)
          setNoteCount(temp.length)
          setUpdating(false)
          setAddNodeMode(false)
        }

        setEditorValidState(false)
      })
      .catch((error) => {
        setUpdating(false)
        console.log(error)
      })
  }

  // Replace the current upload function with this improved version that refetches data after upload
  const upload = (file) => {
    // Store the current document type for this upload
    const currentDocType = selectedDocType

    setUploading(true)
    Agent.CloudFiles.upload(file, currentDocType.id, 0, app.studentId, "na", app.id)
      .then((response) => {
        // Reset upload mode and selected document type
        setUploadMode(false)
        setSelectedDocType(null)
        setUploading(false)

        // After successful upload, refresh the application data
        Agent.Applications.getApplication(Id)
          .then((refreshedApp) => {
            // Update only the files and document types, not the entire app state
            setFiles(refreshedApp.applicationCloudFiles)

            // Rebuild document types
            const docTypes = {}
            refreshedApp.applicationCloudFiles.forEach((file) => {
              docTypes[file.id] =
                STUDENT_DOCUMENT_TYPES.find((type) => type.id === file.documentType)?.type || "Unknown"
            })
            setDocumentTypes(docTypes)

            setAlertMsg("Document successfully uploaded")
            setOpenSuccess(true)
            setOpenFailure(false)
          })
          .catch((err) => {
            console.error("Could not refresh files after upload:", err)
            // Even if refresh fails, the upload was successful
            setAlertMsg("Document uploaded, but some data may not be up to date")
            setOpenSuccess(true)
            setOpenFailure(false)
          })
      })
      .catch((error) => {
        console.log(error)
        setAlertMsg("Failed to upload the file")
        setOpenSuccess(false)
        setOpenFailure(true)
        setUploading(false)
      })
  }

  const deleteFile = (file) => {
    return new Promise((resolve, reject) => {
      // Start by updating the local state first for immediate UI feedback
      setFiles((prevFiles) => prevFiles.filter((f) => f.id !== file.id))
      setDocumentTypes((prevTypes) => {
        const newTypes = { ...prevTypes }
        delete newTypes[file.id]
        return newTypes
      })

      // Then perform the API call
      Agent.CloudFiles.delete(file.containerName, file.fileName)
        .then(() => {
          // After successful deletion, refresh the application data
          Agent.Applications.getApplication(Id)
            .then((refreshedApp) => {
              // Update only the files and document types, not the entire app state
              setFiles(refreshedApp.applicationCloudFiles)

              // Rebuild document types
              const docTypes = {}
              refreshedApp.applicationCloudFiles.forEach((file) => {
                docTypes[file.id] =
                  STUDENT_DOCUMENT_TYPES.find((type) => type.id === file.documentType)?.type || "Unknown"
              })
              setDocumentTypes(docTypes)

              setAlertMsg("Document successfully deleted")
              setOpenSuccess(true)
              setOpenFailure(false)
              resolve()
            })
            .catch((err) => {
              console.error("Could not refresh files after deletion:", err)
              // Even if refresh fails, the deletion was successful
              setAlertMsg("Document deleted, but some data may not be up to date")
              setOpenSuccess(true)
              setOpenFailure(false)
              resolve()
            })
        })
        .catch((error) => {
          console.error("Error deleting file:", error)

          // If deletion fails, revert the state changes
          Agent.Applications.getApplication(Id)
            .then((app) => {
              setFiles(app.applicationCloudFiles)

              // Rebuild document types
              const docTypes = {}
              app.applicationCloudFiles.forEach((file) => {
                docTypes[file.id] =
                  STUDENT_DOCUMENT_TYPES.find((type) => type.id === file.documentType)?.type || "Unknown"
              })
              setDocumentTypes(docTypes)
            })
            .catch((err) => console.error("Could not refresh files after deletion error:", err))

          setAlertMsg("Failed to delete the file")
          setOpenSuccess(false)
          setOpenFailure(true)
          reject(error)
        })
    })
  }

  const save = () => {
    setUpdating(true)
    const application = {
      applicationEditDto: {
        id: app.id,
        entryYear,
        applicationPartnerId,
        status,
        applicationRef,
        courseStartDate,
        courseEndDate,
        sendEmail: sendStatusEmail,
        loginName: login,
        loginPassword: password,
      },
    }
    Agent.Applications.edit(application)
      .then(() => {
        setUpdating(false)
        setAlertMsg("Successfully saved")
        setOpenSuccess(true)
        setOpenFailure(false)
        setReadyToSave(false)
        setOriginalStatus(application.applicationEditDto.status)
      })
      .catch((error) => {
        console.log(error)
        setUpdating(false)
        setAlertMsg("Failed to save the application")
        setOpenSuccess(false)
        setOpenFailure(true)
        setReadyToSave(false)
      })
  }

  const editorOnChange = (rawHtml, valid) => {
    setRawHtml(rawHtml)
    setEditorValidState(valid)
    userContext.setUserContext({
      ...userContext,
      ...{
        draft: rawHtml,
      },
    })
  }

  const sync = () => {
    setSyncing(true)
    Agent.Maintenance.syncApplication(app?.id)
      .then((oldId) => {
        setOldAppId(oldId)
        setAlertMsg("Successfully sent this application to TCL portal")
        setOpenSuccess(true)
        setOpenFailure(false)
        setSyncing(false)
      })
      .catch((error) => {
        console.log(error)
        setSyncing(false)
        setAlertMsg(
          "Failed to send. Make sure the student has been synced with TCL portal first. You can do that via student list in the left menu",
        )
        setOpenSuccess(false)
        setOpenFailure(true)
      })
  }

  const reApply = () => {
    userContext.setUserContext({
      ...userContext,
      ...{
        applicationId: Number.parseInt(app?.id),
        studentId: Number.parseInt(app?.studentId),
        studentName: `${app?.firstName} ${app?.lastName}`,
      },
    })
    navigate(`/programs/${app.studentId}`)
  }

  // Function to toggle upload mode and reset document type selection
  const toggleUploadMode = () => {
    const newMode = !uploadMode
    setUploadMode(newMode)

    // If turning off upload mode, reset the selected document type
    if (!newMode) {
      setSelectedDocType(null)
    }
  }

  return loaded ? (
    <Root>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            variant="fullWidth"
            value={tabValue}
            onChange={(_, newVal) => {
              setTabValue(newVal)
            }}
            aria-label="basic tabs example"
          >
            <Tab label={"Application Summary"} {...a11yProps(0)} />
            <Tab
              label={
                <MyTabLabel
                  label={`${
                    isInternal ? (app?.isDirect ? "Email to Student" : "Email to Agent") : "Application Messages"
                  } ${messageCount > 0 ? " (" + messageCount + ")" : ""}`}
                />
              }
              {...a11yProps(1)}
            />
            {roleStd.isStudent
              ? ""
              : isInternal && (
                  <Tab
                    label={<MyTabLabel label={`Application Notes ${noteCount > 0 ? " (" + noteCount + ")" : ""}`} />}
                    {...a11yProps(2)}
                  />
                )}
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <Grid container>
            <Grid item xs={12} container direction="column" spacing={1} className={classes.courseArea}>
              <Grid item container direction="column" xs={2}>
                <Grid item xs={9}>
                  <Typography color="secondary" variant="h6">
                    {app?.universityName}
                  </Typography>
                  <Typography color="primary">{app?.courseName}</Typography>
                  <Typography variant="caption" color="primary">{`${app?.city ? app?.city + ", " : ""}${
                    Countries.find(({ id }) => id == app?.countryId)?.name
                  }`}</Typography>
                  <Typography style={{ marginTop: 5 }}>{`Level: ${
                    STUDY_LEVELS.find(({ id }) => id == app?.studyLevelId)?.type
                  }, Intake: ${
                    INTAKES.find(({ id }) => id == app?.intake)?.type
                  } ${app?.year ? ", " + app?.year : ""}`}</Typography>
                  {app?.partner && isInternal && (
                    <Typography color="secondary">{`Partner: ${app?.partner}`}</Typography>
                  )}

                  {!roleStd.isStudent && isInternal && (
                    <Grid item xs={2} className="pl-80">
                      <MyTooltip title="Do you want to change program?">
                        <IconButton onClick={reApply}>
                          <EditIcon color="success" />
                        </IconButton>
                      </MyTooltip>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <hr className="bg-gray-900" />
              <br />
              {roleStd.isStudent ? (
                <>
                  <Grid item container spacing={1}>
                    <Grid item xs={12}>
                      <Typography>Entry</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Select
                        disabled={!isInternal}
                        fullWidth
                        size="small"
                        native
                        value={entryYear}
                        onChange={(e) => {
                          setEntryYear(e.target.value)
                          setReadyToSave(courseStartDate && courseEndDate)
                        }}
                      >
                        <option value="0">Select Entry Year</option>
                        {ENTRY_YEARS.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.type}
                          </option>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid item container spacing={1}>
                    <Grid item xs={12}>
                      <Typography>Status</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Select
                        disabled={!isInternal}
                        size="small"
                        native
                        value={status}
                        onChange={(e) => {
                          setStatus(e.target.value)
                          setReadyToSave(courseStartDate && courseEndDate)
                          setSendStatusEmail(false)
                        }}
                      >
                        {APPLICATION_STATUSES.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.type}
                          </option>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                  {originalStatus && originalStatus != status && (
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={sendStatusEmail}
                            onChange={(e) => setSendStatusEmail(e.target.checked)}
                            name="Status"
                          />
                        }
                        label={
                          sendStatusEmail
                            ? "Email will be sent for status change"
                            : "Click to send email for status change"
                        }
                      />
                    </Grid>
                  )}
                  <Grid item container spacing={1}>
                    <Grid item xs={12}>
                      <Typography>Application Ref.</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Application Ref."
                        value={applicationRef}
                        onChange={(e) => {
                          setApplicationRef(e.target.value)
                          setReadyToSave(courseStartDate && courseEndDate)
                        }}
                        fullWidth
                        size="small"
                        inputProps={{
                          maxLength: 50,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={1}>
                    <Grid item xs={12}>
                      <Typography>Start Date</Typography>
                    </Grid>
                    <Grid item xs={12} container direction="column">
                      <Grid item>
                        <DatePicker
                          popperClassName={classes.reactDatepickerPopper}
                          autoComplete="off"
                          locale="enGB"
                          dateFormat="dd-MM-yyyy"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          error={true}
                          selected={(courseStartDate && new Date(courseStartDate)) || null}
                          onChange={(newVal) => {
                            if (newVal) {
                              newVal = addHours(startOfDay(newVal), 12)
                              setCourseStartWarning(app.intake != newVal.getMonth() + 1)
                            }
                            setCourseStartDate(newVal)
                            setReadyToSave(newVal && courseEndDate)
                          }}
                          customInput={
                            <TextField
                              label="Course Start Date"
                              fullWidth
                              value={courseStartDate}
                              variant="outlined"
                              size="small"
                            />
                          }
                        />
                      </Grid>
                      {courseStartWarning && (
                        <Grid item>
                          <Typography variant="caption" color="secondary">
                            FYI: Course Start month does not match with intake
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item container spacing={1}>
                    <Grid item xs={12}>
                      <Typography>End Date</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <DatePicker
                        popperClassName={classes.reactDatepickerPopper}
                        autoComplete="off"
                        locale="enGB"
                        dateFormat="dd-MM-yyyy"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        error={true}
                        selected={(courseEndDate && new Date(courseEndDate)) || null}
                        onChange={(newVal) => {
                          if (newVal) {
                            newVal = addHours(startOfDay(newVal), 12)
                          }
                          setCourseEndDate(newVal)
                          setReadyToSave(newVal && courseStartDate)
                        }}
                        customInput={
                          <TextField
                            label="Course End Date"
                            fullWidth
                            value={courseEndDate}
                            variant="outlined"
                            size="small"
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item container spacing={1}>
                    <Grid item xs={2}>
                      <Typography>Entry</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Select
                        disabled={!isInternal}
                        fullWidth
                        size="small"
                        native
                        value={entryYear}
                        onChange={(e) => {
                          setEntryYear(e.target.value)
                          setReadyToSave(courseStartDate && courseEndDate)
                        }}
                      >
                        <option value="0">Select Entry Year</option>
                        {ENTRY_YEARS.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.type}
                          </option>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid item container spacing={1}>
                    <Grid item xs={2}>
                      <Typography>Status</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Select
                        disabled={!isInternal}
                        size="small"
                        native
                        value={status}
                        onChange={(e) => {
                          setStatus(e.target.value)
                          setReadyToSave(courseStartDate && courseEndDate)
                          setSendStatusEmail(true)
                        }}
                      >
                        {APPLICATION_STATUSES.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.type}
                          </option>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                  {originalStatus && originalStatus != status && (
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={sendStatusEmail}
                            onChange={(e) => setSendStatusEmail(e.target.checked)}
                            name="Status"
                          />
                        }
                        label={
                          sendStatusEmail
                            ? "Email will be sent for status change"
                            : "Click to send email for status change"
                        }
                      />
                    </Grid>
                  )}
                  <Grid item container spacing={1}>
                    <Grid item xs={2}>
                      <Typography>Application Ref.</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        label="Application Ref."
                        value={applicationRef}
                        onChange={(e) => {
                          setApplicationRef(e.target.value)
                          setReadyToSave(courseStartDate && courseEndDate)
                        }}
                        fullWidth
                        size="small"
                        inputProps={{
                          maxLength: 50,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item container spacing={1}>
                    <Grid item xs={2}>
                      <Typography>Course Start Date</Typography>
                    </Grid>
                    <Grid item xs={6} container direction="column">
                      <Grid item>
                        <DatePicker
                          popperClassName={classes.reactDatepickerPopper}
                          autoComplete="off"
                          locale="enGB"
                          dateFormat="dd-MM-yyyy"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          error={true}
                          selected={(courseStartDate && new Date(courseStartDate)) || null}
                          onChange={(newVal) => {
                            if (newVal) {
                              newVal = addHours(startOfDay(newVal), 12)
                              setCourseStartWarning(app.intake != newVal.getMonth() + 1)
                              setCourseStartWarning(app.intake != newVal.getMonth() + 1)
                            }
                            setCourseStartDate(newVal)
                            setReadyToSave(newVal && courseEndDate)
                          }}
                          customInput={<TextField fullWidth value={courseStartDate} variant="outlined" size="small" />}
                        />
                      </Grid>
                      {courseStartWarning && (
                        <Grid item>
                          <Typography variant="caption" color="secondary">
                            FYI: Course Start month does not match with intake
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item container spacing={1}>
                    <Grid item xs={2}>
                      <Typography>Course End Date</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <DatePicker
                        popperClassName={classes.reactDatepickerPopper}
                        autoComplete="off"
                        locale="enGB"
                        dateFormat="dd-MM-yyyy"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        error={true}
                        selected={(courseEndDate && new Date(courseEndDate)) || null}
                        onChange={(newVal) => {
                          if (newVal) {
                            newVal = addHours(startOfDay(newVal), 12)
                          }
                          setCourseEndDate(newVal)
                          setReadyToSave(newVal && courseStartDate)
                        }}
                        customInput={<TextField fullWidth value={courseEndDate} variant="outlined" size="small" />}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              {roleStd.isStudent
                ? ""
                : isInternal && (
                    <Grid item container spacing={1}>
                      <Grid item xs={2}>
                        <Typography>Partner</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Select
                          disabled={!isInternal}
                          fullWidth
                          size="small"
                          native
                          value={applicationPartnerId}
                          onChange={(e) => {
                            setApplicationPartnerId(e.target.value)
                            setReadyToSave(courseStartDate && courseEndDate)
                          }}
                        >
                          <option value="0"> </option>
                          {partners
                            .filter((item) => !item.isInactive)
                            .map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                        </Select>
                      </Grid>
                    </Grid>
                  )}
              {isInternal && (
                <Grid item container spacing={1}>
                  {roleStd.isStudent ? (
                    <>
                      <Grid item xs={12}>
                        <TextField
                          label="Login Username"
                          value={login}
                          onChange={(e) => {
                            setLogin(e.target.value)
                            setReadyToSave(courseStartDate && courseEndDate)
                          }}
                          fullWidth
                          size="small"
                          inputProps={{
                            maxLength: 50,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Login Password"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value)
                            setReadyToSave(courseStartDate && courseEndDate)
                          }}
                          fullWidth
                          size="small"
                          inputProps={{
                            maxLength: 50,
                          }}
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={3}>
                        <TextField
                          label="Login Username"
                          value={login}
                          onChange={(e) => {
                            setLogin(e.target.value)
                            setReadyToSave(courseStartDate && courseEndDate)
                          }}
                          fullWidth
                          size="small"
                          inputProps={{
                            maxLength: 50,
                          }}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextField
                          label="Login Password"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value)
                            setReadyToSave(courseStartDate && courseEndDate)
                          }}
                          fullWidth
                          size="small"
                          inputProps={{
                            maxLength: 50,
                          }}
                        />
                      </Grid>
                    </>
                  )}
                  {!roleStd.isStudent && permissions.CanAccessAppMethod && (
                    <Grid item xs={12}>
                      <Link
                        className="text-blue-700 font-semibold uppercase underline"
                        to={`/universities/${app.universityId}/manage?tab=2`}
                        target="_blank"
                      >
                        Methods
                      </Link>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} container direction="column" spacing={2} className={classes.studentArea}>
              <br />
              <Grid item>
                <Typography textAlign="center" color="primary">
                  Application Documents
                </Typography>
              </Grid>
              {files.length > 0 && (
                <Grid item style={{ marginTop: 10 }}>
                  <Documents
                    files={files}
                    handleFileDelete={deleteFile}
                    agentId={0}
                    studentId={0}
                    applicationId={app.id}
                    disabled={!isAdmin}
                    sizes={[9, 0]}
                    documentTypes={documentTypes}
                  />
                </Grid>
              )}

              {!app?.fileUrl && isAdmin && (
                <Grid item style={{ marginTop: 15 }}>
                  <Grid item xs={12}>
                    <Typography>Document Type</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Select
                      fullWidth
                      size="small"
                      value={selectedDocType ? selectedDocType.id : ""}
                      onChange={(e) => {
                        const selected = LAST_FOUR_DOCUMENT_TYPES.find(
                          (doc) => doc.id === Number.parseInt(e.target.value, 10),
                        )
                        setSelectedDocType(selected)
                      }}
                    >
                      <MenuItem value="">Select Document Type</MenuItem>
                      {LAST_FOUR_DOCUMENT_TYPES.map((docType) => (
                        <MenuItem key={docType.id} value={docType.id}>
                          {docType.type}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={toggleUploadMode}
                    disabled={!selectedDocType && !uploadMode}
                  >
                    {uploadMode ? "Cancel Upload?" : "Click to Upload Document"}
                  </Button>
                </Grid>
              )}
              {uploading && (
                <Grid item>
                  <LoadingView />
                </Grid>
              )}
              {uploadMode && selectedDocType && (
                <Grid item>
                  <PhotoWidgetDropzone
                    crop={false}
                    upload={upload}
                    message={`Drop the ${selectedDocType.type} document to upload`}
                    disabled={app?.fileUrl}
                    height={180}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
          {isAdmin && (
            <Grid container style={{ marginTop: 20 }}>
              <Grid item xs={12}>
                <LoadingButton
                  disabled={!readyToSave}
                  loading={updating}
                  fullWidth
                  className="bg-sky-400 hover:bg-sky-500"
                  variant="contained"
                  onClick={save}
                >
                  Save Application
                </LoadingButton>
              </Grid>
            </Grid>
          )}
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Grid container direction="column">
            <Grid item className="mb-2">
              <Button
                endIcon={!addMessageMode ? <AddIcon /> : null}
                onClick={() => {
                  setAddMessageMode((prevState) => !prevState)
                  setSendEmail(true)
                }}
              >
                {addMessageMode ? "Hide  Option" : "Send Email"}
              </Button>
            </Grid>
            {addMessageMode && (
              <>
                <Grid item>
                  <TextField
                    fullWidth
                    size="small"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    label="Subject"
                    required
                  />
                </Grid>
                <Grid item className="pt-2">
                  <EditorDraftJs editorOnChange={editorOnChange} height={250} html={userContext.draft} />
                </Grid>
                <div className="flex flex-row justify-between">
                  <Grid item>
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          checked={sendEmail}
                          onChange={() => {
                            setSendEmail((prev) => !prev)
                          }}
                        />
                      }
                      label="Untick if you do not want to send an email with this message"
                    />
                  </Grid>

                  {isInternal && (
                    <div className="mb-4 mt-0 text-gray-700 px-2 py-1 rounded-md ">
                      <span className="font-semibold text-gray-700">Attachment &nbsp;</span>
                      <input type="file" onChange={(e) => setFile(e.target.files[0])} />
                    </div>
                  )}
                </div>
                <Grid item>
                  <LoadingButton
                    loading={updating}
                    disabled={!editorValidState}
                    onClick={sendMsgToAgent}
                    variant="outlined"
                  >
                    {sendEmail ? "Send" : "Add Message"}
                  </LoadingButton>
                </Grid>
              </>
            )}
          </Grid>
          <Grid item container>
            {loaded && (
              <Conversations
                isDirect={app?.isDirect}
                messages={messages}
                applicationId={Id}
                user={name}
                setNoteCount={setMessageCount}
                setSendEmailParent={setSendEmail}
                hideReplyOption={true}
              />
            )}
          </Grid>
        </TabPanel>
        {isInternal && (
          <TabPanel value={tabValue} index={2}>
            <Grid container direction="column">
              <Grid item>
                <Button
                  endIcon={!addNodeMode ? <AddIcon /> : null}
                  onClick={() => {
                    setAddNodeMode((prevState) => !prevState)
                  }}
                >
                  {addNodeMode ? "Hide Note Option" : "Add Note"}
                </Button>
              </Grid>
              {addNodeMode && (
                <>
                  <Grid item>
                    <EditorDraftJs editorOnChange={editorOnChange} height={200} />
                  </Grid>
                  <Grid item className="py-2">
                    <LoadingButton
                      loading={updating}
                      disabled={!editorValidState}
                      onClick={addAppNote}
                      variant="outlined"
                    >
                      Add Application Note
                    </LoadingButton>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item container>
              {loaded && (
                <Conversations
                  messages={notes}
                  applicationId={Id}
                  user={name}
                  setNoteCount={setNoteCount}
                  setSendEmailParent={setSendEmail}
                  hideReplyOption={true}
                />
              )}
            </Grid>
          </TabPanel>
        )}
      </Box>
      {(openSuccess || openFailure) && (
        <MySnackbarAlert
          opnSuccess={openSuccess}
          opnFailure={openFailure}
          message={alertMsg}
          opnSuccessParent={setOpenSuccess}
          opnFailureParent={setOpenFailure}
        />
      )}
      <Dialog
        open={!!noteWarningMsg}
        aria-labelledby="error-dialog-title"
        PaperProps={{
          className: "bg-gray-200",
        }}
      >
        <DialogTitle id="error-dialog-title" className="text-blue-500">
          warning!!
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="text-gray-900 text-lg">
            {noteWarningMsg && (
              <span className="text-blue-500 text-lg">
                Please, do not use bullet point and Bold text at the same time
              </span>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setNoteWarningMsg(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Root>
  ) : (
    <LoadingView />
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    name: state.auth.name,
    isInternal: state.auth.isInternal,
    role: state.auth.roleName.replaceAll(" ", ""),
    roleStd: state.auth.role,
    Id: ownProps.id,
    partners: state.management.partners,
    permissions: state.auth.permissions,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadPartners: () => dispatch(actions.loadPartners()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Application)

