/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';

import { Link, useNavigate } from 'react-router-dom';

import LoadingView from '../Common/Loading/LoadingView';

import Agent from '../../api/agent';

import { MUI_LICENSE_KEY, UNI_TYPES } from '../../Util/Constants';

import { connect } from 'react-redux';

import { LicenseInfo, DataGridPro } from '@mui/x-data-grid-pro';

import { Countries } from '../../Util/Util';
import CancelIcon from '@mui/icons-material/Cancel';
import { CheckCircle } from '@mui/icons-material';

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);


const classes = {
  // courseArea: `${PREFIX}-courseArea`,
  // studentArea: `${PREFIX}-studentArea`,
};

const Universities = ({ role, permissions }) => {
  // const classes = useStyles();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [universities, setUniversities] = useState([]);

  const [name, setName] = useState('');

  const [country, setCountry] = useState(0);

  const [page, setPage] = useState(1);

  const [totalRows, setTotalRows] = useState();

  const pageSize = 50;

  const handlePageChange = (params) => {
    setPage(params + 1);
  };

  const getData = (filter = false) => {
    setLoading(true);

    Agent.Universities.listWithParams(getParams(filter)).then((pageData) => {
      const { pagination, data } = pageData;

      setTotalRows(pagination.totalItems);

      getRows(data);
      console.log('datauni', pagination);
    });
  };

  const getParams = (filter) => {
    const params = new URLSearchParams();

    params.append('pageNumber', page.toString());

    params.append('pageSize', pageSize.toString());

    params.append('name', filter ? name.trim() : '');

    params.append('country', filter ? country.toString() : '');

    return params;
  };

  const resetFilter = () => {
    setName('');

    setCountry(0);

    getData(false);
  };

  useEffect(() => {
    getData();
  }, [page]);

  const getRows = (rows = []) => {
    let universityRows = [];

    rows.map((university) => {
      universityRows.push({
        id: university.id,

        name: university.name,

        type: UNI_TYPES.find(({ id }) => id == university.type).type,

        location: `${university.city ? university.city + ', ' : ''} ${
          Countries.find(({ id }) => id == university.countryId).name
        }`,

        isInactive: university.isInactive,

        fileUrl: university.fileUrl,

        isTop: university.isTop,
        fileUrlBackground: university.fileUrlBackground ? true : false,
      });
    });

    setUniversities(universityRows);

    setLoading(false);
  };

  let columns = [
    {
      field: 'id',

      headerName: 'Id',

      width: 85,

      hide: true,
    },

    {
      field: 'name',

      headerName: 'University Name',
      width: 450, //liton vai's changes
      renderCell: (params) => (
        <Link className='text-blue-500' to={`/universities/${params.row.id}/manage`} target="_blank">
          {params.row.name}
        </Link>
      ),
    },

    {
      field: 'type',

      headerName: 'Type',

      width: 100,
    },

    {
      field: 'location',

      headerName: 'Location',

      width: 300,
    },

    {
      field: 'isTop',

      headerName: 'Marked Top',

      width: 120,

      renderCell: (params) => (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Typography>
            {params.row.isTop ? (
              <div className="flex justify-center text-green-500 pr-6">
                <CheckCircle className=" w-full h-5 " size={'20px'} />
              </div>
            ) : (
              ''
            )}
          </Typography>
        </div>
      ),
    },

    {
      field: 'isInactive',

      headerName: 'Status',

      width: 100,

      renderCell: (params) => (
        <Typography className={`font-semibold text-md ${params.row.isInactive?'text-red-600':'text-green-600 font-semibold'}`}>
          {params.row.isInactive ? 'Inactive' : 'Active'}
        </Typography>
      ),
    },

    {
      field: 'fileUrlBackground',
      headerName: 'Cover Image',
      width: 120,
      renderCell: (params) => (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Typography>
            {params.row.fileUrlBackground ? (
              <div className="flex justify-center text-green-500 pr-6">
                <CheckCircle className=" w-full h-5 " size={'20px'} />
              </div>
            ) : (
              <div className="flex justify-center text-red-500 pr-6">
                <CancelIcon className=" w-full h-5 " size={'20px'} />
              </div>
            )}
          </Typography>
        </div>
      ),
    },
    // {
    //   field: 'fileUrl',
    //   headerName: 'Logo', // liton vai's changes added
    //   width: 80,
    //   renderCell: (params) => (
    //     <div style={{ width: '100%', textAlign: 'center' }}>
    //       <Typography>
    //         {params.row.fileUrlBackground ? (
    //           <div className="flex justify-center text-green-500 pr-6">
    //             <BsCheckCircleFill className=" w-full h-5 " size={'20px'} />
    //           </div>
    //         ) : (
    //           <div className="flex justify-center text-red-500 pr-6">
    //             <BsFillXCircleFill className=" w-full h-5 " size={'20px'} />
    //           </div>
    //         )}
    //       </Typography>
    //     </div>
    //   ),
    // },

    {
      field: 'fileUrl',

      headerName: 'Logo',

      width: 80,

      renderCell: (params) => (
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Typography>
            {params.row.fileUrl ? (
              <div className="flex justify-center text-green-500 pr-6">
                <CheckCircle className=" w-full h-5 " size={'20px'} />
              </div>
            ) : (
              <div className="flex justify-center text-red-500 pr-6">
                <CancelIcon className=" w-full h-5 " size={'20px'} />
              </div>
            )}
          </Typography>
        </div>
      ),
    },
  ];

  return loading ? (
    <LoadingView />
  ) : (
    // <StyledDiv>

    <Grid
      container
      direction="column"
      spacing={1}
      style={{ backgroundColor: 'white' }}
    >
      {(role.isSuperAdmin || permissions.CanManageUniversity) && (
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Button
              LinkComponent
              variant="contained"
              className="bg-sky-400 hover:bg-sky-500"
              // className={classes.button}

              startIcon={<SettingsSuggestIcon />}
              onClick={() => navigate('/courses')}
            >
              Global Course Management
            </Button>
          </Grid>

          <Grid item>
            <Button
              LinkComponent
              variant="contained"
              className="bg-sky-400 hover:bg-sky-500"

              startIcon={<AddIcon />}
              onClick={() => navigate('/universities/0/manage')}
            >
              Add New University
            </Button>
          </Grid>
        </Grid>
      )}

      <Grid item container spacing={1}>
        <Grid item xs={4}>
          <TextField
            fullWidth
            size="small"
            label="University Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>

        <Grid item xs={2}>
          <FormControl fullWidth>
            <InputLabel>Country</InputLabel>

            <Select
              value={country}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
              size="small"
              label="Intake"
            >
              <MenuItem value={0}>All Countries</MenuItem>

              {Countries.filter((item) => item.id!=0).map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={2}>
          <Button
            fullWidth
            onClick={getData}
            variant="contained"
            className="bg-sky-400 hover:bg-sky-500"
          >
            Apply Filters
          </Button>
        </Grid>

        <Grid item xs={2}>
          <Button
            fullWidth
            onClick={resetFilter}
            className="bg-rose-200 hover:bg-rose-300 text-gray-600 hover:text-gray-700"
          >
            Reset Filters
          </Button>
        </Grid>
      </Grid>

      <Grid item>
        <DataGridPro
          disableSelectionOnClick
          rows={universities}
          pagination
          pageSize={pageSize}
          rowCount={totalRows}
          paginationMode="server"
          columns={columns}
          onPageChange={handlePageChange}
          loading={loading}
          className={classes.root}
          disableColumnFilter
          autoHeight
          density="compact"
          page={page - 1}
        />
      </Grid>
    </Grid>

    // </StyledDiv>
  );
};

const mapStateToProps = (state) => {
  return {
    role: state.auth.role,

    permissions: state.auth.permissions,
  };
};

export default connect(mapStateToProps, null)(Universities);
