/* eslint-disable import/namespace */

import React, { useMemo, useCallback } from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import {
  BarChartOutlined,
  LineAxisOutlined,
  PieChart,
} from '@mui/icons-material';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const ProjectionComparisonGraph = ({
  data = [],
  statusTypes = [],
  months = [],
  monthlyTargets = [],
  monthlyForecasts = [],
  selectedMetric = 'organicLead',
  onMetricChange = () => {},
  chartType = 'bar',
  onChartTypeChange = () => {},
}) => {
  const capValue = useCallback(
    (value) => Math.min(Number(value) || 0, 100),
    []
  );

  const chartData = useMemo(() => {
    return {
      labels: months,
      datasets: [
        {
          label: 'Target',
          data: months.map((_, index) => {
            const monthData = monthlyTargets.find((t) => t.month === index + 1);
            return capValue(monthData ? monthData[selectedMetric] : 0);
          }),
          backgroundColor: 'rgba(255, 99, 132, 0.5)', // red-100
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
        },
        {
          label: 'Achieve',
          data: months.map((_, index) => {
            const monthData = data.find((d) => d.month === index + 1);
            return capValue(monthData ? monthData[selectedMetric] : 0);
          }),
          backgroundColor: 'rgba(134, 239, 172, 0.5)', // light green
          borderColor: 'rgba(34, 197, 94, 1)',
          borderWidth: 1,
        },
        {
          label: 'Forecast',
          data: months.map((_, index) => {
            const monthData = monthlyForecasts.find(
              (f) => f.month === index + 1
            );
            return capValue(monthData ? monthData[selectedMetric] : 0);
          }),
          backgroundColor: 'rgba(186, 230, 253, 0.5)', // sky blue
          borderColor: 'rgba(14, 165, 233, 1)',
          borderWidth: 1,
        },
      ],
    };
  }, [
    data,
    monthlyTargets,
    monthlyForecasts,
    months,
    selectedMetric,
    capValue,
  ]);

  const pieChartData = useMemo(() => {
    const totalTarget = capValue(
      monthlyTargets.reduce(
        (sum, month) => sum + (month[selectedMetric] || 0),
        0
      ) / 12
    );
    const totalAchieve = capValue(
      data.reduce((sum, month) => sum + (month[selectedMetric] || 0), 0) / 12
    );
    const totalForecast = capValue(
      monthlyForecasts.reduce(
        (sum, month) => sum + (month[selectedMetric] || 0),
        0
      ) / 12
    );

    return {
      labels: ['Target', 'Achieve', 'Forecast'],
      datasets: [
        {
          data: [totalTarget, totalAchieve, totalForecast],
          backgroundColor: [
            'rgba(255, 99, 132, 0.5)', // red-100
            'rgba(134, 239, 172, 0.5)', // light green
            'rgba(186, 230, 253, 0.5)', // sky blue
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(34, 197, 94, 1)',
            'rgba(14, 165, 233, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
  }, [data, monthlyTargets, monthlyForecasts, selectedMetric, capValue]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: { stacked: false },
      y: {
        stacked: false,
        beginAtZero: true,
        max: 100,
        ticks: {
          callback: (value) => `${value}%`,
        },
      },
    },
    plugins: {
      legend: { position: 'top' },
      title: {
        display: true,
        text: `${
          statusTypes.find((s) => s.key === selectedMetric)?.type ||
          'Organic Lead'
        } - Comparison`,
        font: { size: 16, weight: 'bold' },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || '';
            const value = context.parsed.y;
            return `${label}: ${value.toFixed(2)}%`;
          },
        },
      },
    },
  };

  const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: 'top' },
      title: {
        display: true,
        text: `${
          statusTypes.find((s) => s.key === selectedMetric)?.type ||
          'Organic Lead'
        } - Overall Comparison`,
        font: { size: 16, weight: 'bold' },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || '';
            const value = context.parsed;
            return `${label}: ${value.toFixed(2)}%`;
          },
        },
      },
    },
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <FormControl size="small" className="min-w-[200px]">
          <InputLabel id="metric-select-label">Metric</InputLabel>
          <Select
            labelId="metric-select-label"
            id="metric-select"
            value={selectedMetric}
            label="Metric"
            onChange={(e) => onMetricChange(e.target.value)}
          >
            {statusTypes.map((type) => (
              <MenuItem key={type.key} value={type.key}>
                {type.type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className="flex space-x-2">
          <button
            className={`px-4 py-2 rounded-md ${
              chartType === 'bar'
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700'
            }`}
            onClick={() => onChartTypeChange('bar')}
          >
            <BarChartOutlined className="mr-2" />
            Bar
          </button>
          <button
            className={`px-4 py-2 rounded-md ${
              chartType === 'line'
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700'
            }`}
            onClick={() => onChartTypeChange('line')}
          >
            <LineAxisOutlined className="mr-2" />
            Line
          </button>
          <button
            className={`px-4 py-2 rounded-md ${
              chartType === 'pie'
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700'
            }`}
            onClick={() => onChartTypeChange('pie')}
          >
            <PieChart className="mr-2" />
            Pie
          </button>
        </div>
      </div>
      <div className="h-[400px] bg-white p-4 rounded-lg shadow-md">
        {chartType === 'bar' && <Bar data={chartData} options={options} />}
        {chartType === 'line' && <Line data={chartData} options={options} />}
        {chartType === 'pie' && (
          <Pie data={pieChartData} options={pieOptions} />
        )}
      </div>
    </div>
  );
};

export default ProjectionComparisonGraph;
