import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ApproveAgent from './ApproveAgent';
import AssignAdmin from './AssignAdmin';
import { LoadingButton } from '@mui/lab';
import Agent from '../../../../api/agent';
import { connect } from 'react-redux';
import ConfirmationModal from '../../../Common/Modal/ConfirmationModal';
import { useNavigate } from 'react-router';
import OtherActions from './OtherActions';

const PREFIX = 'AdminActions';

const classes = {
  root: `${PREFIX}-root`,
  heading: `${PREFIX}-heading`,
  panelHeader: `${PREFIX}-panelHeader`,
  panelBody: `${PREFIX}-panelBody`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: '100%',
  },

  [`& .${classes.heading}`]: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium,
    color: 'white',
  },

  [`& .${classes.panelHeader}`]: {
    backgroundColor: theme.palette.primary.light,
  },

  [`& .${classes.panelBody}`]: {
    paddingTop: 15,
  },
}));

const AdminActions = ({
  agent,
  appUserId,
  adminName,
  updateAdminInfo,
  accountName,
  updateAccountInfo,
  updateStatus,
  updateAlert,
  role,
  permissions,
}) => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const navigate = useNavigate();
  const deleteAgent = () => {
    Agent.Agents.disableAgent(agent.id)
      .then(() => {
        navigate('/agents');
      })
      .catch((error) => {
        console.log(error);
        debugger;
      });
  };

  return (
    <>
      <Root className={classes.root}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classes.panelHeader}
          >
            <Typography className={classes.heading}>Agent Approval</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.panelBody}>
            <ApproveAgent
              agent={agent}
              appUserId={appUserId}
              updateStatus={updateStatus}
              updateAlert={updateAlert}
              role={role}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className={classes.panelHeader}
          >
            <Typography className={classes.heading}>
              Admin Assignment
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.panelBody}>
            <AssignAdmin
              agent={agent}
              adminName={adminName}
              updateAdminInfo={updateAdminInfo}
              accountName={accountName}
              updateAccountInfo={updateAccountInfo}
              updateAlert={updateAlert}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className={classes.panelHeader}
          >
            <Typography className={classes.heading}>Other Actions</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.panelBody}>
            <OtherActions agent={agent} />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            className={classes.panelHeader}
          >
            <Typography className={classes.heading}>Remove Agent</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.panelBody}>
            <LoadingButton
              disabled={!(role === 'SuperAdmin' || permissions.CanDeleteAgent)}
              onClick={() => setOpenConfirm(true)}
              variant="contained"
              className='bg-rose-400 hover:bg-rose-500'
            >
              Remove Agent
            </LoadingButton>
          </AccordionDetails>
        </Accordion>
      </Root>
      <ConfirmationModal
        open={openConfirm}
        cancel={setOpenConfirm}
        confirm={deleteAgent}
        confirmText="Confirm Action"
        content="Are you sure that you would like to remove this agent record completely?"
      />
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    role: state.auth.roleName.replaceAll(' ', ''),
    permissions: state.auth.permissions,
    agent: ownProps.agent,
    appUserId: ownProps.appUserId,
    adminName: ownProps.adminName,
    updateAdminInfo: ownProps.updateAdminInfo,
    accountName: ownProps.accountName,
    updateAccountInfo: ownProps.updateAccountInfo,
    updateStatus: ownProps.updateStatus,
    updateAlert: ownProps.updateAlert,
  };
};

export default connect(mapStateToProps, null)(AdminActions);
