/* eslint-disable import/namespace */
import React from "react"
import { useMemo, useState } from "react"
import { Bar, Line, Pie } from "react-chartjs-2"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import SourceDetailDialog from "./SourceDetailDialog"
import { BarChartOutlined, LineAxisOutlined, PieChart } from "@mui/icons-material"

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, ArcElement, Title, Tooltip, Legend)

const ComparisonGraph = ({
  data = [],
  statusTypes = [],
  months = [],
  monthlyTargets = [],
  monthlyForecasts = [],
  selectedMetric = "organicLead",
  onMetricChange = () => {},
  chartType = "bar",
  onChartTypeChange = () => {},
}) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedSource, setSelectedSource] = useState(null)

  const chartData = useMemo(() => {
    return {
      labels: months,
      datasets: [
        {
          label: "Target",
          data: months.map((_, index) => {
            const monthData = monthlyTargets.find((t) => t.month === index + 1)
            return monthData ? monthData[selectedMetric] : 0
          }),
          backgroundColor: "rgba(255, 99, 132, 0.7)",
          borderColor: "rgba(255, 99, 132, 1)",
          borderWidth: 1,
        },
        {
          label: "Achieve",
          data: months.map((_, index) => {
            const monthData = data.find((d) => d.month === index + 1)
            return monthData ? monthData[selectedMetric] : 0
          }),
          backgroundColor: "rgba(54, 162, 235, 0.7)",
          borderColor: "rgba(54, 162, 235, 1)",
          borderWidth: 1,
        },
        {
          label: "Forecast",
          data: months.map((_, index) => {
            const monthData = monthlyForecasts.find((f) => f.month === index + 1)
            return monthData ? monthData[selectedMetric] : 0
          }),
          backgroundColor: "rgba(255, 206, 86, 0.7)",
          borderColor: "rgba(255, 206, 86, 1)",
          borderWidth: 1,
        },
      ],
    }
  }, [data, monthlyTargets, monthlyForecasts, months, selectedMetric])

  const pieChartData = useMemo(() => {
    const totalTarget = monthlyTargets.reduce((sum, month) => sum + (month[selectedMetric] || 0), 0)
    const totalAchieve = data.reduce((sum, month) => sum + (month[selectedMetric] || 0), 0)
    const totalForecast = monthlyForecasts.reduce((sum, month) => sum + (month[selectedMetric] || 0), 0)

    return {
      labels: ["Target", "Achieve", "Forecast"],
      datasets: [
        {
          data: [totalTarget, totalAchieve, totalForecast],
          backgroundColor: ["rgba(255, 99, 132, 0.7)", "rgba(54, 162, 235, 0.7)", "rgba(255, 206, 86, 0.7)"],
          borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)"],
          borderWidth: 1,
        },
      ],
    }
  }, [data, monthlyTargets, monthlyForecasts, selectedMetric])

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: { stacked: false },
      y: { stacked: false, beginAtZero: true },
    },
    plugins: {
      legend: { position: "top" },
      title: {
        display: true,
        text: `${statusTypes.find((s) => s.key === selectedMetric)?.type || "Organic Lead"} - Comparison`,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || ""
            const value = context.parsed.y
            return `${label}: ${value}`
          },
        },
      },
    },
    onClick: (_, elements) => {
      if (elements.length > 0) {
        const { index } = elements[0]
        const monthData = data[index]
        setSelectedSource(monthData)
        setDialogOpen(true)
      }
    },
  }

  const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: "top" },
      title: {
        display: true,
        text: `${statusTypes.find((s) => s.key === selectedMetric)?.type || "Organic Lead"} - Overall Comparison`,
      },
    },
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <FormControl size="small" className="min-w-[200px]">
          <InputLabel id="metric-select-label">Metric</InputLabel>
          <Select
            labelId="metric-select-label"
            id="metric-select"
            value={selectedMetric}
            label="Metric"
            onChange={(e) => onMetricChange(e.target.value)}
          >
            {statusTypes.map((type) => (
              <MenuItem key={type.key} value={type.key}>
                {type.type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className="flex space-x-2">
          <button
            className={`px-4 py-2 rounded-md ${
              chartType === "bar" ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-700"
            }`}
            onClick={() => onChartTypeChange("bar")}
          >
            <BarChartOutlined className="mr-2" />
            Bar
          </button>
          <button
            className={`px-4 py-2 rounded-md ${
              chartType === "line" ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-700"
            }`}
            onClick={() => onChartTypeChange("line")}
          >
            <LineAxisOutlined className="mr-2" />
            Line
          </button>
          <button
            className={`px-4 py-2 rounded-md ${
              chartType === "pie" ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-700"
            }`}
            onClick={() => onChartTypeChange("pie")}
          >
            <PieChart className="mr-2" />
            Pie
          </button>
        </div>
      </div>
      <div className="h-[400px]">
        {chartType === "bar" && <Bar data={chartData} options={options} />}
        {chartType === "line" && <Line data={chartData} options={options} />}
        {chartType === "pie" && <Pie data={pieChartData} options={pieOptions} />}
      </div>
      <SourceDetailDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        sourceData={selectedSource}
        statusTypes={statusTypes}
        months={months}
      />
    </div>
  )
}

export default ComparisonGraph

