/* eslint-disable import/namespace */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Agent from '../../../api/agent';
import { INTAKES, intakeYearList } from '../../../Util/Constants';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Autocomplete,
  Paper,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  Box,
  Chip,
  Checkbox,
  ListItemText,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@mui/material';
import { Countries } from '../../../Util/Util';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterListIcon from '@mui/icons-material/FilterList';
import TableChartIcon from '@mui/icons-material/TableChart';
import BarChartOutlined from '@mui/icons-material/BarChartOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ErrorPopup from '../RegionalReport/ErrorPopUp';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { motion } from 'framer-motion';
import { Bar, Pie, Line, Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  PointElement,
} from 'chart.js';

import {
  BarChartOutlined as BarChartOutlinedIcon,
  LineAxisOutlined,
  PieChart as PieChartIcon,
} from '@mui/icons-material';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  PointElement
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const statusTypes = [
  { id: 16, type: 'Enrolled', key: 'enrolled' },
  { id: 17, type: 'Enroll. Ratio', key: 'enrolledRatio', isRatio: true },
  { id: 1, type: 'Organic Lead', key: 'organicLead' },
  { id: 2, type: 'Paid lead', key: 'paidLead' },
  { id: 18, type: 'Partner Agent', key: 'qualified' },
  { id: 3, type: 'Total Lead', key: 'totalLead' },
  { id: 4, type: 'Profile Ready', key: 'potentialLead' },
  { id: 6, type: 'Applied', key: 'applied' },
  { id: 7, type: 'Apply Ratio', key: 'appliedRatio', isRatio: true },
  { id: 12, type: 'Rejection', key: 'rejection' },
  { id: 9, type: 'Uncond. Offer', key: 'unconditionalOffer' },
  { id: 10, type: 'Total Offer', key: 'totalOffer' },
  { id: 11, type: 'Offer Ratio', key: 'offerRatio', isRatio: true },
  { id: 8, type: 'Cond. Offer', key: 'conditionalOffer' },
  { id: 13, type: 'Reject. Ratio', key: 'rejectionRatio', isRatio: true },
  { id: 14, type: 'Deposit', key: 'deposite' },
  { id: 15, type: 'Num of CAS', key: 'numOfCAS' },
];

const professionalColors = [
  'rgba(63, 81, 181, 0.7)', // Indigo
  'rgba(3, 169, 244, 0.7)', // Light Blue
  'rgba(0, 150, 136, 0.7)', // Teal
  'rgba(76, 175, 80, 0.7)', // Green
  'rgba(255, 193, 7, 0.7)', // Amber
  'rgba(255, 87, 34, 0.7)', // Deep Orange
  'rgba(233, 30, 99, 0.7)', // Pink
  'rgba(156, 39, 176, 0.7)', // Purple
];

const ComparisonGraph = ({
  data,
  statusTypes,
  selectedMetric,
  onMetricChange,
  chartType,
  onChartTypeChange,
  onBarClick,
}) => {
  const chartData = {
    labels: data.map((counselor) => counselor.userName),
    datasets: [
      {
        label:
          statusTypes.find((status) => status.key === selectedMetric)?.type ||
          '',
        data: data.map((counselor) => counselor[selectedMetric]),
        backgroundColor: professionalColors,
        borderColor: professionalColors.map((color) =>
          color.replace('0.7', '1')
        ),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: `Counselor Performance - ${
          statusTypes.find((status) => status.key === selectedMetric)?.type ||
          ''
        }`,
        font: {
          size: 18,
          weight: 'bold',
        },
        padding: {
          top: 10,
          bottom: 20,
        },
        align: 'center',
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || '';
            const value = context.parsed.y;
            return `${label}: ${value}`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => (value < 1 ? value.toFixed(2) : value),
        },
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const index = elements[0].index;
        onBarClick(data[index]);
      }
    },
  };

  if (chartType === 'pie') {
    options.plugins.tooltip.callbacks.label = (context) => {
      const label = context.label || '';
      const value = context.parsed;
      const total = context.dataset.data.reduce((a, b) => a + b, 0);
      const percentage = ((value / total) * 100).toFixed(2);
      return `${label}: ${value} (${percentage}%)`;
    };
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between items-center mb-4">
        <FormControl size="small" className="min-w-[200px]">
          <InputLabel id="metric-select-label">Select Metric</InputLabel>
          <Select
            labelId="metric-select-label"
            id="metric-select"
            value={selectedMetric}
            label="Select Metric"
            onChange={(e) => onMetricChange(e.target.value)}
          >
            {statusTypes.map((status) => (
              <MenuItem key={status.key} value={status.key}>
                {status.type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className="flex space-x-2">
          <button
            className={`px-4 py-2 rounded-md ${
              chartType === 'bar'
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700'
            }`}
            onClick={() => onChartTypeChange('bar')}
          >
            <BarChartOutlinedIcon className="mr-2" />
            Bar
          </button>
          <button
            className={`px-4 py-2 rounded-md ${
              chartType === 'line'
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700'
            }`}
            onClick={() => onChartTypeChange('line')}
          >
            <LineAxisOutlined className="mr-2" />
            Line
          </button>
          <button
            className={`px-4 py-2 rounded-md ${
              chartType === 'pie'
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700'
            }`}
            onClick={() => onChartTypeChange('pie')}
          >
            <PieChartIcon className="mr-2" />
            Pie
          </button>
        </div>
      </div>
      <div
        className="flex-grow"
        style={{ height: chartType === 'pie' ? '600px' : '500px' }}
      >
        {chartType === 'bar' && <Bar data={chartData} options={options} />}
        {chartType === 'pie' && <Pie data={chartData} options={options} />}
        {chartType === 'line' && <Line data={chartData} options={options} />}
      </div>
    </div>
  );
};

const LoadingAnimation = () => (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
    <motion.div
      className="w-24 h-24 flex items-center justify-center bg-white rounded-full"
      animate={{
        rotate: [0, 360],
      }}
      transition={{
        duration: 2,
        ease: 'linear',
        repeat: Number.POSITIVE_INFINITY,
      }}
    >
      <motion.span
        className="block w-16 h-16 border-t-4 border-blue-500 rounded-full"
        animate={{
          scale: [1, 1.5, 1],
          opacity: [1, 0.5, 1],
        }}
        transition={{
          duration: 1.5,
          ease: 'easeInOut',
          repeat: Number.POSITIVE_INFINITY,
        }}
      />
    </motion.div>
  </div>
);

const UserDetailDialog = ({ open, onClose, userData }) => {
  if (!userData) return null;

  const [selectedStatus, setSelectedStatus] = useState(null);

  const donutData = {
    labels: statusTypes.map((status) => status.type),
    datasets: [
      {
        data: statusTypes.map((status) => userData[status.key] || 0),
        backgroundColor: professionalColors,
        borderColor: professionalColors.map((color) =>
          color.replace('0.7', '1')
        ),
        borderWidth: 1,
      },
    ],
  };

  const donutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '70%',
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || '';
            const value = context.parsed || 0;
            const total = context.dataset.data.reduce(
              (acc, data) => acc + data,
              0
            );
            const percentage = ((value / total) * 100).toFixed(2);
            return `${label}: ${value} (${percentage}%)`;
          },
        },
      },
    },
    onClick: (_, elements) => {
      if (elements.length > 0) {
        const index = elements[0].index;
        setSelectedStatus(
          selectedStatus === statusTypes[index].key
            ? null
            : statusTypes[index].key
        );
      }
    },
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{ style: { height: '80vh' } }}
    >
      <DialogTitle className="text-center font-bold text-xl pb-2">
        {userData.userName} - Performance Details
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="flex flex-col items-center justify-start p-4">
        <div className="w-full md:w-2/3 h-[300px] relative mb-4">
          <Doughnut data={donutData} options={donutOptions} />
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
            <h3 className="text-lg font-bold">{userData.userName}</h3>
            <p className="text-sm text-gray-600">Counselor</p>
          </div>
        </div>
        <div className="w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2 text-center">
          {statusTypes.map((status, index) => (
            <div
              key={status.key}
              className={`flex flex-col items-center cursor-pointer p-2 rounded-lg transition-colors duration-200 ${
                selectedStatus === status.key
                  ? 'bg-gray-200'
                  : 'hover:bg-gray-100'
              }`}
              onClick={() =>
                setSelectedStatus(
                  selectedStatus === status.key ? null : status.key
                )
              }
            >
              <div
                className="w-3 h-3 rounded-full mb-1"
                style={{
                  backgroundColor:
                    professionalColors[index % professionalColors.length],
                }}
              ></div>
              <span className="text-xs font-medium">{status.type}</span>
              <span className="text-sm font-bold">
                {userData[status.key] || 0}
              </span>
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};

const CounselorPerformanceReport = ({ permissions }) => {
  const [intakeYears, setIntakeYears] = useState([]);
  const [intakeMonths, setIntakeMonths] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [year, setYear] = useState('');
  const [type, setType] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showFilters, setShowFilters] = useState(true);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [loadingBranches, setLoadingBranches] = useState(false);
  const [selectedCitizenships, setSelectedCitizenships] = useState([]);
  const [showFilterMessage, setShowFilterMessage] = useState(false);
  const [selectedMetric, setSelectedMetric] = useState('enrolled');
  const [chartType, setChartType] = useState('bar');
  const [filtersChanged, setFiltersChanged] = useState(false);
  const [viewMode, setViewMode] = useState('both');
  const [selectedUser, setSelectedUser] = useState(null);
  const [userDialogOpen, setUserDialogOpen] = useState(false);
  const [showChart, setShowChart] = useState(false);
  const [showTable, setShowTable] = useState(false);

  const hasAccess = permissions.CanAccessCounselorPerformanceReport;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const users = await Agent.Users.list();
        setUserOptions(
          users
            .filter((user) => user.isDisabled === false)
            .filter((user) => [1, 2, 3].includes(user.userType))
            .map((user) => ({ id: user.id.toString(), label: user.name }))
        );
      } catch (err) {
        console.error('Error fetching users:', err);
        setError('Failed to fetch users. Please try again later.');
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    if (hasAccess) {
      const fetchBranches = async () => {
        setLoadingBranches(true);
        try {
          const response = await Agent.Cache.getBranchOffices();
          if (Array.isArray(response)) {
            setBranches(
              response.map((branch) => ({
                id: branch.id.toString(),
                label: branch.name,
              }))
            );
          } else {
            console.error(
              'Unexpected response format for branch offices:',
              response
            );
          }
        } catch (err) {
          console.error('Error fetching branch offices:', err);
          setError('Failed to fetch branch offices. Please try again later.');
        } finally {
          setLoadingBranches(false);
        }
      };

      fetchBranches();
    }
  }, [hasAccess]);

  const fetchData = useCallback(async () => {
    if (!hasAccess) return;

    if (
      intakeYears.length === 0 &&
      intakeMonths.length === 0 &&
      !startDate &&
      !endDate &&
      !year &&
      selectedUsers.length === 0 &&
      selectedBranches.length === 0 &&
      selectedCitizenships.length === 0
    ) {
      setData([]);
      setShowFilterMessage(true);
      return;
    } else {
      setShowFilterMessage(false);
    }

    setLoading(true);
    setError(null);
    try {
      const response = await Agent.Report.counselorPerformance({
        intakeYear: intakeYears,
        intakeMonth: intakeMonths,
        startDate,
        endDate,
        branchId: selectedBranches.map((branch) => branch.id),
        citizenship: selectedCitizenships.map((citizenship) => citizenship.id),
        userId: selectedUsers.map((user) => user.id),
        year: year ? Number.parseInt(year, 10) : undefined,
        type,
      });
      if (Array.isArray(response) && response.length > 0) {
        setData(response);
        setShowChart(true);
        setTimeout(() => setShowTable(true), 500);
      } else {
        setError('No data available for the selected criteria.');
        setData([]);
      }
    } catch (err) {
      setError(err.message || 'Failed to fetch data. Please try again.');
      setShowErrorPopup(true);
      console.error('Error fetching data:', err);
    } finally {
      setLoading(false);
    }
    setFiltersChanged(false);
  }, [
    hasAccess,
    intakeYears,
    intakeMonths,
    startDate,
    endDate,
    selectedBranches,
    selectedCitizenships,
    selectedUsers,
    year,
    type,
  ]);

  const handleGenerateReport = () => {
    setShowChart(false);
    setShowTable(false);
    fetchData();
  };

  const handleResetFilters = () => {
    setIntakeYears([]);
    setIntakeMonths([]);
    setStartDate('');
    setEndDate('');
    setYear('');
    setType(1);
    setData([]);
    setError(null);
    setShowErrorPopup(false);
    setSelectedUsers([]);
    setSelectedBranches([]);
    setSelectedCitizenships([]);
    setShowFilterMessage(false);
    setFiltersChanged(false);
    setShowChart(false);
    setShowTable(false);
  };

  const handleFilterChange = () => {
    setFiltersChanged(true);
  };

  const formatValue = (value, isRatio) => {
    if (value === undefined || value === null || value === '' || value === 0)
      return '';
    if (isRatio) {
      const formattedValue = Number(value).toFixed(2);
      return formattedValue === '0.00'
        ? ''
        : `${formattedValue.replace(/\.00$/, '')}%`;
    }
    const numValue = Number(value);
    if (!isNaN(numValue) && numValue >= 1000) {
      return numValue.toLocaleString('en-US', { maximumFractionDigits: 0 });
    }
    return value.toString();
  };

  const columns = [
    {
      field: 'userName',
      headerName: 'Counselor',
      width: 200,
      sortable: true,
      filterable: true,
      hide: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'text-xs font-semibold text-white bg-sky-600 py-2 px-2',
      cellClassName: 'text-xs py-1 px-2 font-bold',
    },
    ...statusTypes.map((status) => ({
      field: status.key,
      headerName: status.type,
      width: '80',
      sortable: true,
      filterable: true,
      type: status.isRatio ? 'number' : 'string',
      valueFormatter: (params) => formatValue(params.value, status.isRatio),
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'text-xs font-semibold text-white bg-sky-600 py-2 px-2',
      cellClassName: 'text-xs py-1 px-2',
      renderHeader: (params) => (
        <div className="flex flex-col items-center justify-center h-full w-full">
          {params.colDef.headerName.split(' ').map((word, index) => (
            <div
              key={index}
              className="text-center whitespace-nowrap leading-tight"
            >
              {word}
            </div>
          ))}
        </div>
      ),
    })),
  ];

  const rows = data.map((counselor) => ({
    id: counselor.userId,
    userName: counselor.userName,
    target: counselor.target,
    ...statusTypes.reduce(
      (acc, status) => ({
        ...acc,
        [status.key]: counselor[status.key],
      }),
      {}
    ),
  }));

  const handleBarClick = (userData) => {
    setSelectedUser(userData);
    setUserDialogOpen(true);
  };

  if (!hasAccess) {
    return (
      <div className="p-4 bg-gray-50 rounded-lg shadow-md">
        <ErrorPopup error="You do not have permission to access the Counselor Performance Report!" />
      </div>
    );
  }

  return (
    <div className="bg-gray-100 min-h-screen px-auto sm:px-auto py-2 sm:py-3">
      <div className="w-full px-4 sm:px-6 lg:px-auto">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-xl sm:text-2xl font-bold text-gray-800">
            Counselor Performance Report
          </h1>
          <Link
            to="/reportpanel"
            className="flex items-center text-blue-600 hover:text-blue-800"
          >
            <ArrowBackIcon className="h-5 w-5 mr-1" />
            <span className="hidden sm:inline">Back to Reporting Panel</span>
          </Link>
        </div>
        <Paper
          elevation={3}
          className="bg-white rounded-lg shadow-lg p-2 sm:p-3 mx-auto w-full"
        >
          <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-4">
            <div className="flex items-center space-x-2 mb-2 sm:mb-0">
              <FormControl size="small" className="min-w-[150px]">
                <InputLabel id="type-select-label">Report Type</InputLabel>
                <Select
                  labelId="type-select-label"
                  id="type-select"
                  value={type}
                  label="Report Type"
                  onChange={(e) => {
                    setType(e.target.value);
                    handleFilterChange();
                  }}
                >
                  <MenuItem value={1}>Assessment</MenuItem>
                  <MenuItem value={2}>Followup</MenuItem>
                  <MenuItem value={3}>Compliance</MenuItem>
                  <MenuItem value={4}>Regional Manager</MenuItem>
                  <MenuItem value={5}>Country Manager</MenuItem>
                  <MenuItem value={6}>Branch Manager</MenuItem>
                  <MenuItem value={7}>Team Leader</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="flex items-center space-x-2">
              <ToggleButtonGroup
                value={viewMode}
                exclusive
                onChange={(_, newMode) => setViewMode(newMode)}
                aria-label="view mode"
                size="small"
              >
                <ToggleButton value="chart" aria-label="chart view">
                  <BarChartOutlined className="h-4 w-4" />
                </ToggleButton>
                <ToggleButton value="table" aria-label="table view">
                  <TableChartIcon className="h-4 w-4" />
                </ToggleButton>
                <ToggleButton value="both" aria-label="both views">
                  <BarChartOutlined className="h-4 w-4" />
                  <TableChartIcon className="h-4 w-4" />
                </ToggleButton>
              </ToggleButtonGroup>
              <Button
                variant="outlined"
                startIcon={<FilterListIcon />}
                onClick={() => setShowFilters(!showFilters)}
                className="text-gray-700 border-gray-300 hover:bg-gray-50"
                size="small"
              >
                {showFilters ? 'Hide Filters' : 'Show Filters'}
              </Button>
            </div>
          </div>
          {showFilters && (
            <div className="mb-4">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    label="Start Date"
                    type="date"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                      handleFilterChange();
                    }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    label="End Date"
                    type="date"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                      handleFilterChange();
                    }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="year-select-label">Year</InputLabel>
                    <Select
                      labelId="year-select-label"
                      id="year-select"
                      value={year}
                      label="Year"
                      onChange={(e) => {
                        setYear(e.target.value);
                        handleFilterChange();
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {intakeYearList.map((y) => (
                        <MenuItem key={y.year} value={y.year.toString()}>
                          {y.year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="intake-years-label">
                      Intake Years
                    </InputLabel>
                    <Select
                      labelId="intake-years-label"
                      id="intake-years"
                      multiple
                      value={intakeYears}
                      label="Intake Years"
                      onChange={(e) => {
                        setIntakeYears(e.target.value);
                        handleFilterChange();
                      }}
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip key={value} label={value} size="small" />
                          ))}
                        </Box>
                      )}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
                            width: 250,
                          },
                        },
                      }}
                    >
                      {intakeYearList.map((year) => (
                        <MenuItem key={year.year} value={year.year.toString()}>
                          <Checkbox
                            checked={
                              intakeYears.indexOf(year.year.toString()) > -1
                            }
                          />
                          <ListItemText primary={year.year} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="intake-month-label">
                      Intake Months
                    </InputLabel>
                    <Select
                      labelId="intake-month-label"
                      id="intake-month"
                      multiple
                      value={intakeMonths}
                      label="Intake Months"
                      onChange={(e) => {
                        setIntakeMonths(e.target.value);
                        handleFilterChange();
                      }}
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                        >
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={
                                INTAKES.find(
                                  (intake) => intake.id.toString() === value
                                )?.type
                              }
                              size="small"
                            />
                          ))}
                        </Box>
                      )}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: ITEM_HEIGHT * 12 + ITEM_PADDING_TOP,
                            width: 250,
                          },
                        },
                      }}
                    >
                      {INTAKES.map((intake) => (
                        <MenuItem key={intake.id} value={intake.id.toString()}>
                          <Checkbox
                            checked={
                              intakeMonths.indexOf(intake.id.toString()) > -1
                            }
                          />
                          <ListItemText primary={intake.type} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    multiple
                    id="citizenship"
                    options={Countries.map((country) => ({
                      id: country.id.toString(),
                      label: country.name,
                    }))}
                    getOptionLabel={(option) => option.label}
                    value={selectedCitizenships}
                    onChange={(_, newValue) => {
                      setSelectedCitizenships(newValue);
                      handleFilterChange();
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Citizenship" />
                    )}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Autocomplete
                    multiple
                    id="users"
                    options={userOptions}
                    getOptionLabel={(option) => option.label}
                    value={selectedUsers}
                    onChange={(_, newValue) => {
                      setSelectedUsers(newValue);
                      handleFilterChange();
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Users" />
                    )}
                    size="small"
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <Autocomplete
                    multiple
                    id="branches"
                    options={branches}
                    getOptionLabel={(option) => option.label}
                    value={selectedBranches}
                    onChange={(_, newValue) => {
                      setSelectedBranches(newValue);
                      handleFilterChange();
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Branches" />
                    )}
                    size="small"
                    loading={loadingBranches}
                  />
                </Grid>
              </Grid>
              <div className="mt-4 flex justify-end space-x-2">
                <Button
                  variant="outlined"
                  onClick={handleResetFilters}
                  className="text-gray-700 border-gray-300 hover:bg-gray-50"
                  size="small"
                >
                  Reset Filters
                </Button>
                <Button
                  variant="contained"
                  onClick={handleGenerateReport}
                  disabled={loading || !filtersChanged}
                  className={`${
                    loading || !filtersChanged
                      ? 'bg-gray-300 cursor-not-allowed'
                      : 'bg-blue-600 hover:bg-blue-700'
                  }`}
                  size="small"
                >
                  {loading ? 'Generating...' : 'Generate Report'}
                </Button>
              </div>
            </div>
          )}

          {error && (
            <div
              className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4 rounded-md shadow-md"
              role="alert"
            >
              <div className="flex items-center">
                <div className="py-1">
                  <svg
                    className="fill-current h-6 w-6 text-red-500 mr-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                </div>
                <div>
                  <p className="font-bold">Error</p>
                  <p className="text-sm">{error}</p>
                </div>
              </div>
            </div>
          )}

          {loading ? (
            <LoadingAnimation />
          ) : (
            !error &&
            data.length > 0 && (
              <div className="space-y-4">
                {(viewMode === 'chart' || viewMode === 'both') && showChart && (
                  <Paper elevation={3} className="p-4">
                    <div className="h-[500px]">
                      <ComparisonGraph
                        data={data}
                        statusTypes={statusTypes}
                        selectedMetric={selectedMetric}
                        onMetricChange={setSelectedMetric}
                        chartType={chartType}
                        onChartTypeChange={setChartType}
                        onBarClick={handleBarClick}
                      />
                    </div>
                  </Paper>
                )}
                {(viewMode === 'table' || viewMode === 'both') && showTable && (
                  <Paper elevation={3} className="overflow-x-auto rounded-lg">
                    <div
                      style={{ height: 'calc(100vh - 300px)', width: '100%' }}
                      className="custom-data-grid"
                    >
                      <DataGridPro
                        rows={rows}
                        columns={columns}
                        disableSelectionOnClick
                        components={{
                          Toolbar: GridToolbar,
                        }}
                        componentsProps={{
                          toolbar: {
                            showQuickFilter: false,
                            printOptions: { disableToolbarButton: true },
                            className: 'bg-gray-100 p-2',
                          },
                        }}
                        initialState={{
                          sorting: {
                            sortModel: [{ field: 'userName', sort: 'asc' }],
                          },
                          pagination: {
                            pageSize: 25,
                          },
                        }}
                        pagination
                        className="w-full h-full bg-white rounded-lg overflow-hidden text-sm"
                        sx={{
                          '& .MuiDataGrid-cell': {
                            borderRight: '1px solid #e0e0e0',
                          },
                          '& .MuiDataGrid-columnHeaders': {
                            borderBottom: '2px solid #e0e0e0',
                            backgroundColor: '#f3f4f6',
                          },
                          '& .MuiDataGrid-row': {
                            borderBottom: '1px solid #e0e0e0',
                          },
                        }}
                      />
                    </div>
                  </Paper>
                )}
              </div>
            )
          )}
        </Paper>
      </div>
      <UserDetailDialog
        open={userDialogOpen}
        onClose={() => setUserDialogOpen(false)}
        userData={selectedUser}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  permissions: state.auth.permissions,
});

export default connect(mapStateToProps)(CounselorPerformanceReport);
