"use client"

import React, { useEffect, useState } from "react"
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Paper,
  Select,
  TextField,
  Typography,
  IconButton,
  Alert,
  Snackbar,
  Divider,
  Box,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import { LoadingButton } from "@mui/lab"
import { NumberFormatBase } from "react-number-format"
import LinearIndeterminate from "../../../../Common/Progress/LinearIndeterminate"
import { INTAKES, STUDY_LEVELS, intakeYearList } from "../../../../../Util/Constants"
import { Countries } from "../../../../../Util/Util"
import Agent from "../../../../../api/agent"
import Draggable from "react-draggable"
import { connect } from "react-redux"
import * as actions from "../../../../../store/actions/index"

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

const UniversityCourseAllocation = ({
  course,
  universityId,
  setAddMode,
  updateParent,
  courses,
  loadCourses,
  role,
  permissions,
  countryId,
}) => {
  const currentYear = new Date().getFullYear()
  const [selectedYear, setSelectedYear] = useState(currentYear)
  const [selectedIntake, setSelectedIntake] = useState(INTAKES[0].id)
  const [open, setOpen] = useState(true)
  const [updating, setUpdating] = useState(false)
  const [existingCourses, setExistingCourses] = useState([])
  const [allocations, setAllocations] = useState([])
  const [studyLevelId, setStudyLevelId] = useState(course?.studyLevelId || 0)
  const [tuitionFee, setTuitionFee] = useState("")
  const [courseName, setCourseName] = useState("")
  const [infoLink, setInfoLink] = useState("")
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [openCourses, setOpenCourses] = useState(false)
  const coursesLoading = openCourses && existingCourses.length === 0

  const [errorMessage, setErrorMessage] = useState("")
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    if (course?.id > 0) {
      const mappedIntakes = course.courseIntakes || course.intakes || []
      const processedIntakes = mappedIntakes.map((intake) => ({
        id: course.id,
        name: course.name,
        year: intake.year,
        intake: intake.intake,
        tuitionFee: intake.tuitionFee,
        allocationId: intake.allocationId || intake.id || Math.floor(Math.random() * 1000000) + 1,
      }))

      setAllocations(processedIntakes)
      setCourseName(course.name)
      setInfoLink(course.infoLink)
      setSelectedCourse({
        id: course.id,
        name: course.name,
      })
      setStudyLevelId(course.studyLevelId)
    } else {
      setAllocations([])
      setCourseName("")
      setInfoLink("")
      setSelectedCourse(null)
      setStudyLevelId(0)
    }
  }, [course])

  useEffect(() => {
    if (!openCourses || existingCourses.length > 0) {
      return
    }

    let isActive = true

    const fetchCourses = async () => {
      try {
        if (courses.length > 0) {
          if (isActive) {
            const uniqueCourses = [
              ...new Map(courses.filter((item) => !item.isInactive).map((item) => [item.id, item])).values(),
            ]
            setExistingCourses(uniqueCourses)
          }
        } else {
          const list = await loadCourses()
          if (isActive) {
            const uniqueCourses = [
              ...new Map(list.filter((item) => !item.isInactive).map((item) => [item.id, item])).values(),
            ]
            setExistingCourses(uniqueCourses)
          }
        }
      } catch (error) {
        console.log(error)
      }
    }

    fetchCourses()

    return () => {
      isActive = false
    }
  }, [openCourses, courses, loadCourses, existingCourses.length])

  const handleClose = () => {
    setOpen(false)
    setAddMode(false)
  }

  const handleFormCancel = () => {
    setOpen(false)
    setAddMode(false)
  }

  const handleErrorClose = () => {
    setShowError(false)
  }

  const addAllocation = () => {
    if (!selectedCourse || !tuitionFee) return

    if (allocations.some((item) => item.year == selectedYear && item.intake == selectedIntake)) {
      setErrorMessage("This allocation already exists. Please use a different year or intake.")
      setShowError(true)
      return
    }

    // Generate a unique allocationId for new allocations
    const allocationId = Math.floor(Math.random() * 1000000) + 1

    const newAllocation = {
      id: selectedCourse.id,
      name: selectedCourse.name.trim(),
      year: selectedYear,
      intake: selectedIntake,
      intakeType: INTAKES.find(({ id }) => id == selectedIntake)?.type || "",
      allocationId: allocationId,
      tuitionFee: Number.parseFloat(
        tuitionFee
          .toString()
          .replace(Countries.find(({ id }) => id == countryId)?.currency || "£", "")
          .replace(",", "")
          .trim(),
      ),
    }

    setAllocations((prev) => [...prev, newAllocation])
    setTuitionFee("")
  }

  const removeAllocation = (allocationId) => {
    setAllocations((prev) => prev.filter((item) => item.allocationId !== allocationId))
  }

  const saveCourse = (event) => {
    event.preventDefault()
    if (studyLevelId == 0) return
    if (allocations.length == 0 && course?.id == 0) return

    setUpdating(true)

    const apiAllocations = allocations.map((allocation) => ({
      year: allocation.year,
      intake: allocation.intake,
      tuitionFee: allocation.tuitionFee,
      id: allocation.id,
      name: allocation.name,
      intakeType: allocation.intakeType || INTAKES.find(({ id }) => id == allocation.intake)?.type || "",
    }))

    if (course?.id == 0) {
      const courseList = {
        courses: {
          courseId: selectedCourse?.id || 0,
          universityId,
          allocations: apiAllocations,
          studyLevelId,
          infoLink,
        },
      }

      console.log("API Payload for new course:", JSON.stringify(courseList))

      Agent.Courses.addCourseAllocations(courseList)
        .then(() => {
          setUpdating(false)
          updateParent({
            type: "add",
          })
          setOpen(false)
        })
        .catch((error) => {
          console.log("API Error:", error)
          setUpdating(false)
          setErrorMessage(error.message || "An error occurred while saving")
          setShowError(true)
        })
    } else {
      const modifiedCourse = {
        saveCourseDto: {
          courseId: course.id,
          universityId,
          studyLevelId,
          allocations: apiAllocations,
          infoLink,
        },
      }

      console.log("API Payload for existing course:", JSON.stringify(modifiedCourse))

      Agent.Courses.save(modifiedCourse)
        .then(() => {
          setUpdating(false)
          updateParent({
            type: "save",
            course: {
              id: modifiedCourse.saveCourseDto.courseId,
              name: course.name,
              infoLink,
              studyLevelId: modifiedCourse.saveCourseDto.studyLevelId,
              courseIntakes: allocations,
            },
          })
          setOpen(false)
        })
        .catch((error) => {
          console.log("API Error:", error)
          setUpdating(false)
          setErrorMessage(error.message || "An error occurred while saving")
          setShowError(true)
        })
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose()
          }
        }}
        aria-labelledby="draggable-dialog-title"
        PaperComponent={PaperComponent}
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "8px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          },
        }}
      >
        <DialogTitle
          style={{
            cursor: "move",
            padding: "16px 24px",
            backgroundColor: "#f8fafc",
          }}
          id="draggable-dialog-title"
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" color="primary" sx={{ fontWeight: 600 }}>
              {course?.id ? course.name : "Allocate Course to University"}
            </Typography>
            <IconButton aria-label="close" onClick={handleFormCancel} size="medium" sx={{ color: "text.secondary" }}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ padding: "24px", paddingBottom: "16px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
            <Card variant="outlined" sx={{ marginBottom: "8px" }}>
              <CardContent sx={{ padding: "16px !important" }}>
                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 500, color: "primary.main" }}>
                  Course Details
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                      gap: 2,
                    }}
                  >
                    <FormControl fullWidth size="small">
                      <InputLabel id="study-level-label">Study Level</InputLabel>
                      <Select
                        labelId="study-level-label"
                        disabled={course?.id > 0}
                        value={studyLevelId}
                        label="Study Level"
                        onChange={(e) => {
                          setStudyLevelId(e.target.value)
                        }}
                      >
                        <MenuItem value={0}>--Select Level--</MenuItem>
                        {STUDY_LEVELS.filter((level) => level.display == true && level.id > 0).map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.type}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      fullWidth
                      size="small"
                      label="Course Info Link"
                      variant="outlined"
                      value={infoLink}
                      onChange={(e) => {
                        setInfoLink(e.target.value)
                      }}
                    />
                  </Box>
                  <Autocomplete
                    disabled={course?.id > 0}
                    open={openCourses && courseName.length > 1}
                    onOpen={() => {
                      setOpenCourses(true)
                    }}
                    onClose={() => {
                      setOpenCourses(false)
                    }}
                    value={selectedCourse}
                    onChange={(_, newVal) => {
                      if (newVal) {
                        setSelectedCourse(newVal)
                        setCourseName(newVal.name)
                      }
                    }}
                    loading={coursesLoading}
                    size="small"
                    fullWidth
                    filterSelectedOptions
                    options={existingCourses}
                    getOptionLabel={(option) => `${option.name}`}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        size="small"
                        {...params}
                        label="Course Name"
                        variant="outlined"
                        value={courseName}
                        onChange={(e) => {
                          setCourseName(e.target.value)
                          if (selectedCourse && e.target.value !== selectedCourse.name) {
                            setSelectedCourse(null)
                          }
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {coursesLoading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Box>
              </CardContent>
            </Card>

            <Card variant="outlined" sx={{ marginBottom: "8px" }}>
              <CardContent sx={{ padding: "16px !important" }}>
                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 500, color: "primary.main" }}>
                  Add New Intake
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    gap: 2,
                    alignItems: "center",
                  }}
                >
                  <FormControl fullWidth size="small">
                    <InputLabel id="year-label">Year</InputLabel>
                    <Select
                      labelId="year-label"
                      value={selectedYear}
                      label="Year"
                      onChange={(e) => {
                        setSelectedYear(e.target.value)
                      }}
                    >
                      {intakeYearList.map((item) => (
                        <MenuItem key={item.id} value={item.year}>
                          {item.year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth size="small">
                    <InputLabel id="intake-label">Intake</InputLabel>
                    <Select
                      labelId="intake-label"
                      value={selectedIntake}
                      label="Intake"
                      onChange={(e) => {
                        setSelectedIntake(e.target.value)
                      }}
                    >
                      {INTAKES.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <TextField
                    fullWidth
                    size="small"
                    label={`Tuition Fee ${Countries.find(({ id }) => id == countryId)?.currency || "£"}`}
                    value={tuitionFee}
                    onChange={(e) => {
                      setTuitionFee(e.target.value)
                    }}
                    InputProps={{
                      inputComponent: NumberFormatBase,
                      inputProps: {
                        thousandSeparator: true,
                        isNumericString: true,
                      },
                    }}
                  />

                  <LoadingButton
                    loading={updating}
                    fullWidth
                    disabled={!selectedCourse || !tuitionFee}
                    onClick={addAllocation}
                    variant="contained"
                    className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded transition duration-150 ease-in-out h-10"
                  >
                    Add to list
                  </LoadingButton>
                </Box>
              </CardContent>
            </Card>

            {allocations.length > 0 && (
              <Card variant="outlined">
                <CardContent sx={{ padding: "16px !important" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "16px",
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: 500, color: "primary.main" }}>
                      Course Allocations
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {`Total: ${allocations.length} ${allocations.length === 1 ? "allocation" : "allocations"}`}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      backgroundColor: "#f8fafc",
                      padding: "8px 16px",
                      borderRadius: "4px",
                      marginBottom: "8px",
                      display: "flex",
                    }}
                  >
                    <Typography variant="body2" color="text.secondary" fontWeight={500} sx={{ width: "20%" }}>
                      Year
                    </Typography>
                    <Typography variant="body2" color="text.secondary" fontWeight={500} sx={{ width: "20%" }}>
                      Intake
                    </Typography>
                    <Typography variant="body2" color="text.secondary" fontWeight={500} sx={{ width: "50%" }}>
                      Tuition Fee
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      fontWeight={500}
                      sx={{ width: "10%", textAlign: "center" }}
                    >
                      Action
                    </Typography>
                  </Box>

                  <Box sx={{ maxHeight: "300px", overflowY: "auto" }}>
                    {allocations.map((allocation, index) => (
                      <Box
                        key={allocation.allocationId || index}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          padding: "12px 16px",
                          borderBottom: index < allocations.length - 1 ? "1px solid #e2e8f0" : "none",
                          "&:hover": { backgroundColor: "#f1f5f9" },
                          borderRadius: "4px",
                        }}
                      >
                        <Box sx={{ width: "20%" }}>
                          <Typography variant="body2">{allocation.year}</Typography>
                        </Box>
                        <Box sx={{ width: "20%" }}>
                          <Typography variant="body2">
                            {INTAKES.find(({ id }) => id == allocation.intake).type}
                          </Typography>
                        </Box>
                        <Box sx={{ width: "50%" }}>
                          <NumberFormatBase
                            value={allocation.tuitionFee}
                            displayType="text"
                            thousandSeparator={true}
                            prefix={`${Countries.find(({ id }) => id == countryId)?.currency || "£"} `}
                            renderText={(value) => (
                              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                {value}
                              </Typography>
                            )}
                          />
                        </Box>
                        <Box sx={{ width: "10%", textAlign: "center" }}>
                          <IconButton
                            size="small"
                            color="error"
                            onClick={() => removeAllocation(allocation.allocationId)}
                            title="Remove allocation"
                          >
                            <DeleteOutlineIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </CardContent>
              </Card>
            )}
          </Box>

          {updating && (
            <Box sx={{ marginTop: "20px", marginBottom: "20px" }}>
              <LinearIndeterminate />
            </Box>
          )}
        </DialogContent>

        {(role?.isSuperAdmin || permissions?.CanManageUniversity) && (
          <DialogActions
            sx={{
              padding: "16px 24px",
              justifyContent: "space-between",
              backgroundColor: "#f8fafc",
            }}
          >
            <Button
              onClick={() => handleFormCancel()}
              className="text-gray-600 hover:text-gray-800 border border-gray-300 hover:bg-gray-50 rounded px-4 py-2 transition-colors"
            >
              Cancel
            </Button>
            <Button
              disabled={
                (allocations.length == 0 && course?.id == 0) || (studyLevelId == 0 && course?.id == 0) || !courseName
              }
              onClick={saveCourse}
              className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded transition duration-150 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {course?.id == 0 ? "Submit" : "Submit Changes"}
            </Button>
          </DialogActions>
        )}
      </Dialog>

      <Snackbar
        open={showError}
        autoHideDuration={6000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleErrorClose} severity="error" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    courses: state.courses,
    isInternal: state.auth.isInternal,
    role: state.auth.role,
    permissions: state.auth.permissions,
    course: ownProps.course,
    universityId: ownProps.universityId,
    countryId: ownProps.countryId,
    setAddMode: ownProps.setAddMode,
    updateParent: ownProps.updateParent,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadCourses: () => dispatch(actions.loadCourses()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UniversityCourseAllocation)

