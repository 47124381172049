import React from 'react';
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid-pro';

const CustomToolbar = (show, isInternal) => {
  return (
    <GridToolbarContainer className="bg-gray-100 p-2 rounded-t-lg">
      <div className="flex space-x-2">
        {isInternal && (
          <>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton  />
          </>
        )}
        <GridToolbarDensitySelector  />
        {show && <GridToolbarExport />}
      </div>
    </GridToolbarContainer>
  );
};

export default CustomToolbar;

