
import React,{ useEffect, useState } from "react"
import { Button, FormControl, TextField, Autocomplete, Select, InputLabel } from "@mui/material"
import { AGENTSTATUS } from "../../../../Util/Constants"
import { connect } from "react-redux"
import Agent from "../../../../api/agent"
import { Countries } from "../../../../Util/Util"
import { Mail } from "@mui/icons-material"

const AgentComFilters = ({
  bdmId,
  setBdmId,
  agentId,
  company,
  status,
  setAgentId,
  setCompany,
  setStatus,
  appUpdateEmail,
  setAppUpdateEmail,
  handleClearFilters,
  handleApplyFilters,
  handleSendBulkEmail,
  totalSelected,
  handleCompanyHqChange,
  companyHq,
  agentNationality,
  handleNationalityChange,
}) => {
  console.log(bdmId)

  const [bdmusers, setBdmUsers] = useState([])

  useEffect(() => {
    Agent.Users.list()
      .then((res) => {
        var tempUser = res.filter((item) => !item.isDisabled)
        setBdmUsers(tempUser)
        console.log("user List", tempUser)
      })
      .catch((err) => {
        console.log("err", err)
      })
  }, [])

  const handleCompanyChange = (e) => {
    if (typeof setCompany === "function") {
      setCompany(e.target.value)
    } else {
      console.error("setCompany is not a function")
    }
  }

  return (
    <>
      <div className="bg-white rounded-lg shadow-sm p-3 mb-4">
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-3 mb-3">
          <div className="p-0.5">
            <TextField
              size="small"
              label="Partner Id"
              value={agentId}
              onChange={(e) => setAgentId(e.target.value)}
              className="w-full bg-gray-50"
            />
          </div>

          <div className="p-0.5">
            <TextField
              size="small"
              label="Company Name"
              value={company}
              onChange={handleCompanyChange}
              className="w-full bg-gray-50"
            />
          </div>

          <div className="p-0.5">
            <FormControl className="w-full">
              <InputLabel>Status</InputLabel>
              <Select
                size="small"
                native
                label="Status"
                placeholder="Status"
                className="w-full bg-gray-50"
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value)
                }}
              >
                <option value={0}>All</option>
                {AGENTSTATUS.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.type}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="p-0.5">
            <FormControl className="w-full">
              <Autocomplete
                size="small"
                value={Countries.find((country) => country.id == companyHq) || null}
                onChange={handleCompanyHqChange}
                options={Countries}
                getOptionLabel={(country) => country.name}
                renderInput={(params) => (
                  <TextField {...params} size="small" label="Company HQ" variant="outlined" className="bg-gray-50" />
                )}
              />
            </FormControl>
          </div>

          <div className="p-0.5">
            <FormControl className="w-full">
              <Autocomplete
                size="small"
                multiple
                value={
                  Array.isArray(agentNationality)
                    ? agentNationality.map((id) => Countries.find((country) => country.id == id)).filter(Boolean)
                    : agentNationality
                      ? [Countries.find((country) => country.id == agentNationality)].filter(Boolean)
                      : []
                }
                onChange={(_, newValue) => {
                  const nationalityIds = newValue.map((item) => item.id)
                  handleNationalityChange(null, nationalityIds)
                }}
                options={Countries}
                getOptionLabel={(country) => country.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    label="Agent Nationality"
                    variant="outlined"
                    className="bg-gray-50"
                  />
                )}
              />
            </FormControl>
          </div>

          <div className="p-0.5">
            <FormControl className="w-full">
              <InputLabel>App Update Email</InputLabel>
              <Select
                size="small"
                native
                label="App Update Email"
                placeholder="App Update Email"
                className="w-full bg-gray-50"
                value={appUpdateEmail}
                onChange={(e) => setAppUpdateEmail(e.target.value)}
              >
                <option value={0}>All</option>
                <option value={1}>Update Email Exist</option>
                <option value={2}>Update Email Not Exist</option>
              </Select>
            </FormControl>
          </div>

          <div className="p-0.5">
            <FormControl className="w-full">
              <Autocomplete
                size="small"
                value={bdmusers.find((bdm) => bdm.id.toString() === bdmId) || null}
                onChange={(_, newValue) => {
                  setBdmId(newValue ? newValue.id.toString() : "")
                }}
                options={bdmusers}
                getOptionLabel={(bdm) => bdm.name || ""}
                renderInput={(params) => (
                  <TextField {...params} size="small" label="BDM" variant="outlined" className="bg-gray-50" />
                )}
              />
            </FormControl>
          </div>

          <div className="p-0.5 flex justify-end space-x-2 md:col-span-3 lg:col-span-4">
            <Button
              variant="outlined"
              className="border-gray-300 text-gray-700 hover:bg-gray-50"
              onClick={handleClearFilters}
            >
              Clear
            </Button>

            <Button
              variant="contained"
              className="bg-blue-500 hover:bg-blue-600 text-white"
              onClick={handleApplyFilters}
            >
              Apply Filter
            </Button>

            <Button
              variant="contained"
              className="bg-sky-800 hover:bg-sky-900 text-white"
              onClick={handleSendBulkEmail}
              disabled={totalSelected === 0}
            >
              <Mail className="mr-1" />
              Send {totalSelected > 0 ? `(${totalSelected})` : ""}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    appUserId: state.auth.id,
    appUserDetailsId: state.auth.appUserDetailsId,
    role: state.auth.roleName,
    permissions: state.auth.permissions,
    users: state.users,
  }
}

export default connect(mapStateToProps, null)(AgentComFilters)

