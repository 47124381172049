/* eslint-disable import/namespace */

import React,{ useState, useEffect } from "react"
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Button,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Breadcrumbs,
  Link,
  Card,
  CardContent,
  Chip,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Alert,
  Snackbar,
  Checkbox,
  ListItemText,
  Grid,
  OutlinedInput,
  Autocomplete,
} from "@mui/material"
import {
  School,
  FilterList,
  Home,
  Download,
  CheckCircle,
  Assignment,
  Visibility,
  Flight,
  Person,
  Close,
  LocalLibrary,
  BarChart as BarChartIcon,
  TableChart as TableChartIcon,
  ViewComfy as CombinedViewIcon,
  TrendingUp,
  Groups,
  InsertChart,
  FilterAlt,
  Refresh,
  LineAxis,
  Radar,

} from "@mui/icons-material"
import { motion } from "framer-motion"
import Agent from "../../../api/agent"
import { INTAKES, intakeYearList, STUDENTSOURCES, STUDY_LEVELS, UNI_TYPES } from "../../../Util/Constants"
import { ApplicationsBarChart, ConversionRateLineChart } from "./UniversityPerformanceChart"
import { Countries } from "../../../Util/Util"
import ErrorPopup from "../RegionalReport/ErrorPopUp"
import { Bar } from "react-chartjs-2"
import { Chart, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from "chart.js"
Chart.register(BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend)

import { connect } from "react-redux"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 12.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const UniversityReport = ({ permissions,role}) => {
  const [loading, setLoading] = useState(false)
  const [universities, setUniversities] = useState([])
  const [filteredUniversities, setFilteredUniversities] = useState([])
  const [selectedUniversity, setSelectedUniversity] = useState(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dataLoaded, setDataLoaded] = useState(false)
  const [showFilters, setShowFilters] = useState(true)
  const [error, setError] = useState(null)
  const [branches, setBranches] = useState([])
  const [loadingBranches, setLoadingBranches] = useState(false)


  const [allInstitutions, setAllInstitutions] = useState([])
  const [selectedUniversities, setSelectedUniversities] = useState([])
  const [selectedChartUniversity, setSelectedChartUniversity] = useState(null)

  const hasAccess = permissions?.CanAccessUniversityReport
  const [viewMode, setViewMode] = useState("both")
  const [chartType, setChartType] = useState("bar")
  const [activeChart, setActiveChart] = useState("bar")

  const [dateType, setDateType] = useState(1)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [selectedYear, setSelectedYear] = useState("")
  const [intakeYears, setIntakeYears] = useState([])
  const [intakeMonths, setIntakeMonths] = useState([])
  const [selectedDestinations, setSelectedDestinations] = useState([])
  const [selectedStudyLevels, setSelectedStudyLevels] = useState([])
  const [selectedSources, setSelectedSources] = useState([])
  const [selectedBranches, setSelectedBranches] = useState([])
  const [institutionType, setInstitutionType] = useState("")
  const [agreementType, setAgreementType] = useState("")
  const [searchQuery, setSearchQuery] = useState("")
  const [filtersChanged, setFiltersChanged] = useState(false)
  const [orderBy, setOrderBy] = useState(3)

  const [summaryMetrics, setSummaryMetrics] = useState({
    totalApplications: 0,
    totalOffers: 0,
    totalEnrollments: 0,
    totalVisas: 0,
  })

  //const [selectedUniversity, setSelectedUniversity] = useState(null)

  useEffect(() => {
    fetchBranches()
  }, [])

  useEffect(() => {
    if (allInstitutions.length === 0) {
      Agent.Universities.list()
        .then((universities) => {
          setAllInstitutions(universities)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [allInstitutions.length])

  useEffect(() => {
    setFiltersChanged(true)
  }, [
    dateType,
    startDate,
    endDate,
    selectedYear,
    intakeYears,
    intakeMonths,
    selectedDestinations,
    selectedStudyLevels,
    selectedSources,
    selectedBranches,
    institutionType,
    agreementType,
    orderBy,
    selectedUniversities,
  ])

  const fetchBranches = async () => {
    setLoadingBranches(true)
    try {
      const response = await Agent.Cache.getBranchOffices()
      if (Array.isArray(response)) {
        setBranches(
          response.map((branch) => ({
            id: branch.id.toString(),
            name: branch.name,
          })),
        )
      } else {
        console.error("Unexpected response format for branch offices:", response)
      }
    } catch (err) {
      console.error("Error fetching branch offices:", err)
      setError("Failed to fetch branch offices. Please try again later.")
    } finally {
      setLoadingBranches(false)
    }
  }

  const applyFilters = async () => {
    if (!hasAccess) {
      setError("You do not have permission to access this report.")
      return
    }

    if (!dateType) {
      setError("Please select a Date Type.")
      return
    }

    setLoading(true)
    try {
      const payload = {
        studylevel:
          selectedStudyLevels.length > 0 ? selectedStudyLevels.map((level) => level.id.toString()) : undefined,
        intakeYears: intakeYears.length > 0 ? intakeYears : undefined,
        intakeMonths: intakeMonths.length > 0 ? intakeMonths : undefined,
        branches: selectedBranches.length > 0 ? selectedBranches.map((branch) => branch.id) : undefined,
        sources: selectedSources.length > 0 ? selectedSources.map((source) => source.id.toString()) : undefined,
        destination:
          selectedDestinations.length > 0 ? selectedDestinations.map((dest) => dest.id.toString()) : undefined,
        agreementType: agreementType ? [agreementType] : undefined,
        institutionType: institutionType ? [institutionType] : undefined,
        startDate: startDate || undefined,
        endDate: endDate || undefined,
        year: selectedYear ? [selectedYear] : undefined,
        dateType: dateType,
        type: 1,
        orderBy: orderBy,
        university: selectedUniversities.length > 0 ? selectedUniversities.map((uni) => uni.id) : undefined,
      }

      const response = await Agent.Report.UniversityPerformanceReport(payload)

      const data = response

      let filteredData = data
      if (searchQuery) {
        const query = searchQuery.toLowerCase()
        filteredData = data.filter(
          (uni) =>
            uni.uniName.toLowerCase().includes(query) ||
            (uni.destination && uni.destination.toLowerCase().includes(query)),
        )
      }

      setUniversities(data)
      setFilteredUniversities(filteredData)
      setDataLoaded(true)
      setFiltersChanged(false)

      const totalApplications = data.reduce((sum, uni) => sum + uni.applied, 0)
      const totalOffers = data.reduce((sum, uni) => sum + uni.conditionalOffer + uni.unconditionalOffer, 0)
      const totalEnrollments = data.reduce((sum, uni) => sum + uni.enrolled, 0)
      const totalVisas = data.reduce((sum, uni) => sum + uni.visaIssued, 0)

      setSummaryMetrics({
        totalApplications,
        totalOffers,
        totalEnrollments,
        totalVisas,
      })
    } catch (error) {
      console.error("Error applying filters:", error)
      setError("Failed to apply filters. Please try again later.")
    } finally {
      setLoading(false)
    }
  }

  const handleSearchChange = (e) => {
    const query = e.target.value
    setSearchQuery(query)

    if (dataLoaded) {
      if (query) {
        const lowercaseQuery = query.toLowerCase()
        const filtered = universities.filter(
          (uni) =>
            uni.uniName.toLowerCase().includes(lowercaseQuery) ||
            (uni.destination && uni.destination.toLowerCase().includes(lowercaseQuery)),
        )
        setFilteredUniversities(filtered)
      } else {
        setFilteredUniversities(universities)
      }
    }
  }

  const resetFilters = () => {
    setDateType(1)
    setStartDate("")
    setEndDate("")
    setSelectedYear("")
    setIntakeYears([])
    setIntakeMonths([])
    setSelectedDestinations([])
    setSelectedStudyLevels([])
    setSelectedSources([])
    setSelectedBranches([])
    setInstitutionType("")
    setAgreementType("")
    setOrderBy(3) 
    setSearchQuery("")
    setDataLoaded(false)
    setUniversities([])
    setFilteredUniversities([])
    setFiltersChanged(false)
    setSelectedUniversities([])
    setSelectedChartUniversity(null)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number.parseInt(event.target.value, 10))
    setPage(0)
  }

  const openUniversityDetails = (university) => {
    setSelectedUniversity(university)
    setDialogOpen(true)
  }

  const closeUniversityDetails = () => {
    setDialogOpen(false)
  }

  const exportReport = () => {
    let csvContent =
      "University,Applications,Conditional Offers,Unconditional Offers,Deposits,CAS,Enrolled,Visas Issued\n"

    filteredUniversities.forEach((uni) => {
      csvContent += `"${uni.uniName}",${uni.applied},${uni.conditionalOffer},${uni.unconditionalOffer},${uni.deposite},${uni.numOfCAS},${uni.enrolled},${uni.visaIssued}\n`
    })

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" })
    const url = URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.setAttribute("href", url)
    link.setAttribute("download", "university_performance_report.csv")
    link.style.visibility = "hidden"
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  useEffect(() => {
    if (selectedChartUniversity) {
      const alreadySelected = selectedUniversities.some((uni) => String(uni.id) === String(selectedChartUniversity.id))

      if (!alreadySelected) {
        setSelectedUniversities([selectedChartUniversity])
        setFiltersChanged(true)
      }
    }
  }, [selectedChartUniversity])

  useEffect(() => {
    if (
      selectedUniversities.length === 1 &&
      (!selectedChartUniversity || String(selectedChartUniversity.id) !== String(selectedUniversities[0].id))
    ) {
      setSelectedChartUniversity(selectedUniversities[0])
    } else if (selectedUniversities.length === 0 && selectedChartUniversity) {
      setSelectedChartUniversity(null)
    }
  }, [selectedUniversities])


  useEffect(() => {
    if (selectedChartUniversity && universities.length > 0) {
      console.log("Selected University ID:", selectedChartUniversity.id)
      console.log("Selected University Name:", selectedChartUniversity.name)

      const matchingUni = universities.find(
        (uni) =>
          String(uni.uniId) === String(selectedChartUniversity.id) ||
          String(uni.UniId) === String(selectedChartUniversity.id),
      )
      console.log("Matching University Data:", matchingUni)

      if (!matchingUni) {
        console.log(
          "All University IDs:",
          universities.map((uni) => uni.uniId || uni.UniId),
        )
      }
    }
  }, [selectedChartUniversity, universities])

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-50 to-blue-100 py-8">
      {loading && (
        <div className="fixed inset-0 bg-white bg-opacity-70 flex items-center justify-center z-50">
          <div className="relative">
            <div className="w-12 h-12 rounded-full border-4 border-indigo-100 border-l-indigo-600 animate-spin"></div>
          </div>
        </div>
      )}
    
    {!hasAccess && (
      <ErrorPopup error="You do not have permission to access the University Performance Report!" />
    )}
    
    {error && (
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={() => setError(null)} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    )}
    
    {hasAccess && (
      <div className="max-w-full mx-auto">
        <motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
          <Paper elevation={0} className="bg-white rounded-xl shadow-md p-6 mb-6">
            <Breadcrumbs aria-label="breadcrumb" className="mb-4">
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/"
                className="text-gray-600 hover:text-gray-800 transition-colors duration-200"
              >
                <Home sx={{ mr: 0.5 }} fontSize="small" />
                Home
              </Link>
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center" }}
                color="inherit"
                href="/reportpanel"
                className="text-gray-600 hover:text-gray-800 transition-colors duration-200"
              >
                Reports
              </Link>
              <Typography color="text.primary" className="font-medium">
                University Performance Report
              </Typography>
            </Breadcrumbs>

            <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-4">
              <div className="flex items-center">
                <div className="bg-indigo-100 p-3 rounded-full text-indigo-600 mr-4">
                  <School className="h-8 w-8" />
                </div>
                <div>
                  <Typography variant="h4" component="h1" className="text-2xl font-bold text-gray-800">
                    University Performance Analysis
                  </Typography>
                  <Typography variant="subtitle1" className="text-gray-600 mt-1">
                    Global & Regional Partner Institution Performance Metrics
                  </Typography>
                </div>
              </div>

              <div className="flex flex-col sm:flex-row gap-3">
                <div className="flex flex-col sm:flex-row items-start sm:items-center gap-3">
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Button
                      variant={viewMode === "chart" ? "contained" : "outlined"}
                      onClick={() => setViewMode("chart")}
                      className={viewMode === "chart" ? "bg-indigo-600" : ""}
                      size="small"
                      startIcon={<BarChartIcon fontSize="small" />}
                    >
                      Chart
                    </Button>
                    <Button
                      variant={viewMode === "table" ? "contained" : "outlined"}
                      onClick={() => setViewMode("table")}
                      className={viewMode === "table" ? "bg-indigo-600" : ""}
                      size="small"
                      startIcon={<TableChartIcon fontSize="small" />}
                    >
                      Table
                    </Button>
                    <Button
                      variant={viewMode === "both" ? "contained" : "outlined"}
                      onClick={() => setViewMode("both")}
                      className={viewMode === "both" ? "bg-indigo-600" : ""}
                      size="small"
                      startIcon={<CombinedViewIcon fontSize="small" />}
                    >
                      Both
                    </Button>
                  </Box>
                </div>

                <Button
                  variant="outlined"
                  startIcon={<FilterAlt />}
                  onClick={() => setShowFilters(!showFilters)}
                  className="self-start md:self-center"
                >
                  {showFilters ? "Hide Filters" : "Show Filters"}
                </Button>

                {dataLoaded && (
                  <Button
                    variant="contained"
                    startIcon={<Download />}
                    className="self-start md:self-center bg-indigo-600 hover:bg-indigo-700"
                    onClick={exportReport}
                  >
                    Export Report
                  </Button>
                )}
              </div>
            </div>
          </Paper>
        </motion.div>

        {dataLoaded && (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 0.1 }}
            >
              <Paper
                elevation={0}
                className="bg-white rounded-xl shadow-md p-4 border-l-4 border-indigo-500 hover:shadow-lg transition-shadow duration-300"
              >
                <div className="flex items-center justify-between">
                  <div>
                    <Typography className="text-sm font-medium text-gray-500">Total Applications</Typography>
                    <Typography className="text-2xl font-bold mt-1 text-indigo-800">
                      {summaryMetrics.totalApplications.toLocaleString()}
                    </Typography>
                  </div>
                  <div className="bg-indigo-100 p-2 rounded-full">
                    <Assignment className="text-indigo-600 h-6 w-6" />
                  </div>
                </div>
              </Paper>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 0.2 }}
            >
              <Paper
                elevation={0}
                className="bg-white rounded-xl shadow-md p-4 border-l-4 border-blue-500 hover:shadow-lg transition-shadow duration-300"
              >
                <div className="flex items-center justify-between">
                  <div>
                    <Typography className="text-sm font-medium text-gray-500">Total Offers</Typography>
                    <Typography className="text-2xl font-bold mt-1 text-blue-800">
                      {summaryMetrics.totalOffers.toLocaleString()}
                    </Typography>
                  </div>
                  <div className="bg-blue-100 p-2 rounded-full">
                    <CheckCircle className="text-blue-600 h-6 w-6" />
                  </div>
                </div>
              </Paper>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 0.3 }}
            >
              <Paper
                elevation={0}
                className="bg-white rounded-xl shadow-md p-4 border-l-4 border-emerald-500 hover:shadow-lg transition-shadow duration-300"
              >
                <div className="flex items-center justify-between">
                  <div>
                    <Typography className="text-sm font-medium text-gray-500">Total Enrollments</Typography>
                    <Typography className="text-2xl font-bold mt-1 text-emerald-800">
                      {summaryMetrics.totalEnrollments.toLocaleString()}
                    </Typography>
                  </div>
                  <div className="bg-emerald-100 p-2 rounded-full">
                    <Person className="text-emerald-600 h-6 w-6" />
                  </div>
                </div>
              </Paper>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: 0.4 }}
            >
              <Paper
                elevation={0}
                className="bg-white rounded-xl shadow-md p-4 border-l-4 border-amber-500 hover:shadow-lg transition-shadow duration-300"
              >
                <div className="flex items-center justify-between">
                  <div>
                    <Typography className="text-sm font-medium text-gray-500">Visas Issued</Typography>
                    <Typography className="text-2xl font-bold mt-1 text-amber-800">
                      {summaryMetrics.totalVisas.toLocaleString()}
                    </Typography>
                  </div>
                  <div className="bg-amber-100 p-2 rounded-full">
                    <Flight className="text-amber-600 h-6 w-6" />
                  </div>
                </div>
              </Paper>
            </motion.div>
          </div>
        )}

        {showFilters && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, delay: 0.5 }}
          >
            <Paper elevation={0} className="bg-white rounded-xl shadow-md p-6 mb-6">
              <div className="flex items-center mb-4">
                <FilterList className="text-indigo-600 mr-2" />
                <Typography variant="h6" className="font-medium text-gray-800">
                  Filter Universities
                </Typography>
              </div>

              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth size="small" required>
                    <InputLabel id="date-type-label">Date Type</InputLabel>
                    <Select
                      labelId="date-type-label"
                      id="date-type"
                      value={dateType}
                      label="Date Type *"
                      onChange={(e) => setDateType(e.target.value)}
                    >
                      <MenuItem value={1}>Actual Status</MenuItem>
                      <MenuItem value={2}>Current Status</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={3}>
                  <TextField
                    label="Start Date"
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    size="small"
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <TextField
                    label="End Date"
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    size="small"
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="year-filter-label">Year</InputLabel>
                    <Select
                      labelId="year-filter-label"
                      id="year-filter"
                      value={selectedYear}
                      label="Year"
                      onChange={(e) => setSelectedYear(e.target.value)}
                    >
                      <MenuItem value="">All</MenuItem>
                      {intakeYearList.map((year) => (
                        <MenuItem key={year.year} value={year.year.toString()}>
                          {year.year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="intake-years-label">Intake Years</InputLabel>
                    <Select
                      labelId="intake-years-label"
                      id="intake-years"
                      multiple
                      value={intakeYears}
                      input={<OutlinedInput label="Intake Years" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={value} size="small" />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                      onChange={(e) => setIntakeYears(e.target.value)}
                    >
                      {intakeYearList.map((year) => (
                        <MenuItem key={year.year} value={year.year.toString()}>
                          <Checkbox checked={intakeYears.indexOf(year.year.toString()) > -1} />
                          <ListItemText primary={year.year} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="intake-month-label">Intake Months</InputLabel>
                    <Select
                      labelId="intake-month-label"
                      id="intake-month"
                      multiple
                      value={intakeMonths}
                      input={<OutlinedInput label="Intake Months" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={INTAKES.find((intake) => intake.id.toString() === value)?.type}
                              size="small"
                            />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                      onChange={(e) => setIntakeMonths(e.target.value)}
                    >
                      {INTAKES.map((intake) => (
                        <MenuItem key={intake.id} value={intake.id.toString()}>
                          <Checkbox checked={intakeMonths.indexOf(intake.id.toString()) > -1} />
                          <ListItemText primary={intake.type} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Autocomplete
                    multiple
                    id="destinations"
                    options={Countries.filter((country) => country.id !== 0)}
                    getOptionLabel={(option) => option.name}
                    value={selectedDestinations}
                    onChange={(event, newValue) => {
                      setSelectedDestinations(newValue)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Study Destinations"
                        placeholder="Search destinations"
                        size="small"
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip key={option.id} label={option.name} size="small" {...getTagProps({ index })} />
                      ))
                    }
                  />
                </Grid>

                {/* Third row of filters */}
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="study-level-label">Course Level</InputLabel>
                    <Select
                      labelId="study-level-label"
                      id="study-level"
                      multiple
                      value={selectedStudyLevels}
                      input={<OutlinedInput label="Course Level" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value.id} label={value.type} size="small" />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                      onChange={(e) => setSelectedStudyLevels(e.target.value)}
                    >
                      {STUDY_LEVELS.filter((level) => level.display).map((level) => (
                        <MenuItem key={level.id} value={level}>
                          <Checkbox checked={selectedStudyLevels.some((selected) => selected.id === level.id)} />
                          <ListItemText primary={level.type} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="sources-label">Lead Sources</InputLabel>
                    <Select
                      labelId="sources-label"
                      id="sources"
                      multiple
                      value={selectedSources}
                      input={<OutlinedInput label="Lead Sources" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value.id} label={value.type} size="small" />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                      onChange={(e) => setSelectedSources(e.target.value)}
                    >
                      {STUDENTSOURCES.map((source) => (
                        <MenuItem key={source.id} value={source}>
                          <Checkbox checked={selectedSources.some((s) => s.id === source.id)} />
                          <ListItemText primary={source.type} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="branches-label">Branches</InputLabel>
                    <Select
                      labelId="branches-label"
                      id="branches"
                      multiple
                      value={selectedBranches}
                      input={<OutlinedInput label="Branches" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value.id} label={value.name} size="small" />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                      onChange={(e) => setSelectedBranches(e.target.value)}
                    >
                      {branches.map((branch) => (
                        <MenuItem key={branch.id} value={branch}>
                          <Checkbox checked={selectedBranches.some((b) => b.id === branch.id)} />
                          <ListItemText primary={branch.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Institution Type</InputLabel>
                    <Select
                      value={institutionType}
                      label="Institution Type"
                      onChange={(e) => setInstitutionType(e.target.value)}
                    >
                      <MenuItem value="">All Types</MenuItem>
                      {UNI_TYPES.map((type) => (
                        <MenuItem key={type.id} value={type.type}>
                          {type.type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Agreement Type</InputLabel>
                    <Select
                      value={agreementType}
                      label="Agreement Type"
                      onChange={(e) => setAgreementType(e.target.value)}
                    >
                      <MenuItem value="">All Types</MenuItem>
                      <MenuItem value={1}>Non-partner university</MenuItem>
                      <MenuItem value={2}>Partner University</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Order By</InputLabel>
                    <Select value={orderBy} label="Order By" onChange={(e) => setOrderBy(e.target.value)}>
                      <MenuItem value={1}>University ID</MenuItem>
                      <MenuItem value={2}>Applications</MenuItem>
                      <MenuItem value={3}>Conditional Offers</MenuItem>
                      <MenuItem value={4}>Unconditional Offers</MenuItem>
                      <MenuItem value={5}>Application Withdrawals</MenuItem>
                      <MenuItem value={6}>Rejections</MenuItem>
                      <MenuItem value={7}>Deposits</MenuItem>
                      <MenuItem value={8}>CAS Issued</MenuItem>
                      <MenuItem value={9}>Enrollments</MenuItem>
                      <MenuItem value={10}>Visas Issued</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Autocomplete
                    multiple
                    id="universities"
                    options={allInstitutions}
                    getOptionLabel={(option) => option.name || ""}
                    value={selectedUniversities}
                    onChange={(event, newValue) => {
                      setSelectedUniversities(newValue)
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Universities"
                        placeholder="Search universities"
                        size="small"
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip key={option.id} label={option.name} size="small" {...getTagProps({ index })} />
                      ))
                    }
                  />
                </Grid>
              </Grid>

              <div className="flex gap-3 justify-end mt-6">
                <Button
                  variant="outlined"
                  startIcon={<Refresh />}
                  onClick={resetFilters}
                  className="text-indigo-600 border-indigo-200 hover:bg-indigo-50"
                >
                  Reset Filters
                </Button>
                <Button
                  variant="contained"
                  startIcon={<FilterList />}
                  onClick={applyFilters}
                  disabled={loading || !filtersChanged}
                  className={`${loading || !filtersChanged ? "bg-gray-300" : "bg-indigo-600 hover:bg-indigo-700"}`}
                >
                  {loading ? "Generating..." : "Generate Report"}
                </Button>
              </div>
            </Paper>
          </motion.div>
        )}

        {/* Charts Section - Only show if data is loaded */}
        {dataLoaded && (viewMode === "chart" || viewMode === "both") && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, delay: 0.6 }}
            className="mb-6"
          >
            <Paper elevation={0} className="bg-white rounded-xl shadow-md p-6">
              <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-6">
                <div className="flex items-center mb-4 sm:mb-0">
                  <InsertChart className="text-indigo-600 mr-2" />
                  <Typography variant="h6" className="font-medium text-gray-800">
                    Performance Analytics
                  </Typography>
                </div>
              </div>

              {/* Chart Controls */}
              <div className="flex flex-wrap justify-between items-center mb-6 bg-gray-50 p-4 rounded-lg">
                <div className="flex flex-wrap items-center gap-4 mb-2 md:mb-0">
                  <div className="flex items-center gap-2">
                    <Typography variant="subtitle2" className="text-gray-700 font-medium whitespace-nowrap">
                      Order By:
                    </Typography>
                    <FormControl size="small" sx={{ minWidth: 180 }}>
                      <Select
                        value={orderBy}
                        onChange={(e) => setOrderBy(e.target.value)}
                        displayEmpty
                      >
                        <MenuItem value={1}>University ID</MenuItem>
                        <MenuItem value={2}>Applications</MenuItem>
                        <MenuItem value={3}>Conditional Offers</MenuItem>
                        <MenuItem value={4}>Unconditional Offers</MenuItem>
                        <MenuItem value={5}>Application Withdrawals</MenuItem>
                        <MenuItem value={6}>Rejections</MenuItem>
                        <MenuItem value={7}>Deposits</MenuItem>
                        <MenuItem value={8}>CAS Issued</MenuItem>
                        <MenuItem value={9}>Enrollments</MenuItem>
                        <MenuItem value={10}>Visas Issued</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div className="flex items-center gap-2">
                    <Typography variant="subtitle2" className="text-gray-700 font-medium whitespace-nowrap">
                      University:
                    </Typography>
                    <FormControl size="small" sx={{ minWidth: 220 }}>
                      <Autocomplete
                        id="chart-university"
                        options={allInstitutions}
                        getOptionLabel={(option) => option.name || ""}
                        value={selectedChartUniversity}
                        onChange={(event, newValue) => {
                          console.log("Selected university from dropdown:", newValue);
                          setSelectedChartUniversity(newValue);
                          if (newValue) {
                            // Apply filter immediately when selecting a university
                            setSelectedUniversities([newValue]);
                            setFiltersChanged(true);
                            // Log the payload before applying filters
                            console.log("University filter payload:", [newValue].map(uni => uni.id));
                            // Delay applying filters to ensure state updates have completed
                            setTimeout(() => {
                              applyFilters();
                            }, 100);
                          } else {
                            setSelectedUniversities([]);
                            setFiltersChanged(true);
                            setTimeout(() => {
                              applyFilters();
                            }, 100);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select University"
                            placeholder="Search university"
                            size="small"
                          />
                        )}
                        sx={{ width: 250 }}
                      />
                    </FormControl>
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <Typography variant="subtitle2" className="text-gray-700 font-medium whitespace-nowrap">
                    Chart Type:
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Button
                      variant={chartType === "bar" ? "contained" : "outlined"}
                      onClick={() => setChartType("bar")}
                      className={chartType === "bar" ? "bg-indigo-600" : ""}
                      size="small"
                      startIcon={<BarChartIcon fontSize="small" />}
                    >
                      Bar
                    </Button>
                    <Button
                      variant={chartType === "line" ? "contained" : "outlined"}
                      onClick={() => setChartType("line")}
                      className={chartType === "line" ? "bg-indigo-600" : ""}
                      size="small"
                      startIcon={<LineAxis fontSize="small" />}
                    >
                      Line
                    </Button>
                    {/* <Button
                      variant={chartType === "spider" ? "contained" : "outlined"}
                      onClick={() => setChartType("spider")}
                      className={chartType === "spider" ? "bg-indigo-600" : ""}
                      size="small"
                      startIcon={<Radar fontSize="small" />}
                    >
                      Spider
                    </Button> */}
                  </Box>
                </div>
              </div>

              <Grid container spacing={4}>
  {chartType === "bar" && (
    <>
      <Grid item xs={12} md={6}>
        <ApplicationsBarChart
          universities={universities}
          chartType={chartType}
          orderBy={orderBy}
          openUniversityDetails={openUniversityDetails}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        {selectedChartUniversity ? (
          <Card className="shadow-md hover:shadow-lg transition-shadow h-full">
            <CardContent>
              <div className="flex items-center mb-4">
                <School className="text-indigo-600 mr-2" />
                <Typography variant="h6" className="font-medium text-gray-800">
                  {selectedChartUniversity.name} Metrics
                </Typography>
              </div>
              
<Box sx={{ height: 400 }}>
  {(() => {
    // Find the matching university data
    const uniData = universities.find(uni => 
      String(uni.UniId) === String(selectedChartUniversity.id) || 
      String(uni.uniId) === String(selectedChartUniversity.id)
    );
    
    console.log("Found university data:", uniData);
    
    // If no data found, show empty chart
    if (!uniData) {
      return (
        <div className="h-full flex items-center justify-center">
          <Typography variant="body1" className="text-gray-600">
            No data available for this university
          </Typography>
        </div>
      );
    }
    
    // Prepare the data array
    const metricsData = [
      uniData.applied,
      uniData.conditionalOffer,
      uniData.unconditionalOffer,
      uniData.deposite,
      uniData.numOfCAS,
      uniData.enrolled,
      uniData.visaIssued
    ];
    
    console.log("Metrics data for chart:", metricsData);
    
    return (
      <Bar
        data={{
          labels: ["Applications", "Conditional Offers", "Unconditional Offers", "Deposits", "CAS", "Enrolled", "Visas"],
          datasets: [{
            label: "Performance Metrics",
            data: metricsData,
            backgroundColor: [
              "rgba(79, 70, 229, 0.8)",
              "rgba(59, 130, 246, 0.8)",
              "rgba(16, 185, 129, 0.8)",
              "rgba(245, 158, 11, 0.8)",
              "rgba(239, 68, 68, 0.8)",
              "rgba(139, 92, 246, 0.8)",
              "rgba(20, 184, 166, 0.8)"
            ],
            borderColor: [
              "rgba(79, 70, 229, 1)",
              "rgba(59, 130, 246, 1)",
              "rgba(16, 185, 129, 1)",
              "rgba(245, 158, 11, 1)",
              "rgba(239, 68, 68, 1)",
              "rgba(139, 92, 246, 1)",
              "rgba(20, 184, 166, 1)"
            ],
            borderWidth: 1
          }]
        }}
        options={{
          indexAxis: 'y',  
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            },
            title: {
              display: true,
              text: `${selectedChartUniversity.name} Performance Metrics`,
              font: {
                size: 16
              }
            },
            tooltip: {
              callbacks: {
                label: (context) => `${context.label}: ${context.raw}`
              }
            }
          },
          scales: {
            x: {
              beginAtZero: true,
              title: {
                display: true,
                text: "Count"
              }
            },
            y: {
              ticks: {
                font: {
                  weight: 'bold'
                }
              }
            }
          }
        }}
      />
    );
  })()}
</Box>
            </CardContent>
          </Card>
        ) : (
          <Card className="shadow-md hover:shadow-lg transition-shadow h-full">
            <CardContent>
              <div className="flex items-center mb-4">
                <School className="text-indigo-600 mr-2" />
                <Typography variant="h6" className="font-medium text-gray-800">
                  University Details
                </Typography>
              </div>
              <Box sx={{ height: 400 }} className="flex items-center justify-center">
                <div className="text-center">
                  <Typography variant="body1" className="text-gray-600 mb-4">
                    Select a university from the dropdown to view detailed metrics
                  </Typography>
                  <Button 
                    variant="outlined" 
                    startIcon={<School />}
                    onClick={() => {
                      // Scroll to the university dropdown
                      const universityDropdown = document.getElementById('chart-university');
                      if (universityDropdown) {
                        universityDropdown.scrollIntoView({ behavior: 'smooth' });
                        universityDropdown.focus();
                      }
                    }}
                  >
                    Select University
                  </Button>
                </div>
              </Box>
            </CardContent>
          </Card>
        )}
      </Grid>
    </>
  )}
  {chartType === "line" && (
  <Grid item xs={12}>
    <ConversionRateLineChart
      universities={universities}
      chartType={chartType}
      orderBy={orderBy}
    />
  </Grid>
)}
  {chartType === "spider" && (
    <Grid item xs={12}>
      <ConversionRateLineChart
        universities={universities}
        chartType={chartType}
        orderBy={orderBy}
      />
    </Grid>
  )}
</Grid>
            </Paper>
          </motion.div>
        )}

        {dataLoaded && (viewMode === "table" || viewMode === "both") && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, delay: 0.7 }}
          >
            <Paper elevation={0} className="bg-white rounded-xl shadow-md overflow-hidden">
              {loading ? (
                <div className="flex flex-col justify-center items-center p-12">
                  <CircularProgress className="text-indigo-600 mb-4" />
                  <Typography className="text-gray-600">Loading university performance data...</Typography>
                </div>
              ) : (
                <>
                  <TableContainer>
                    <Table>
                      <TableHead className="bg-indigo-50">
                        <TableRow>
                          <TableCell className="font-semibold">University</TableCell>
                          <TableCell className="font-semibold text-center">Applications</TableCell>
                          <TableCell className="font-semibold text-center">Conditional Offers</TableCell>
                          <TableCell className="font-semibold text-center">Unconditional Offers</TableCell>
                          <TableCell className="font-semibold text-center">Deposits</TableCell>
                          <TableCell className="font-semibold text-center">CAS</TableCell>
                          <TableCell className="font-semibold text-center">Enrolled</TableCell>
                          <TableCell className="font-semibold text-center">Visas Issued</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredUniversities
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((university) => (
                            <TableRow
                              key={university.UniId}
                              hover
                              className="hover:bg-indigo-50/50 transition-colors duration-150"
                              onClick={() => openUniversityDetails(university)}
                              sx={{ cursor: "pointer" }}
                            >
                              <TableCell>
                                <div className="font-medium text-indigo-900">{university.uniName}</div>
                                <div className="text-xs text-gray-500 flex items-center gap-1 mt-1">
                                 
                                </div>
                              </TableCell>
                              <TableCell className="text-center font-medium">
                                {university.applied.toLocaleString()}
                              </TableCell>
                              <TableCell className="text-center">{university.conditionalOffer}</TableCell>
                              <TableCell className="text-center">{university.unconditionalOffer}</TableCell>
                              <TableCell className="text-center">{university.deposite}</TableCell>
                              <TableCell className="text-center">{university.numOfCAS}</TableCell>
                              <TableCell className="text-center font-medium text-emerald-700">
                                {university.enrolled}
                              </TableCell>
                              <TableCell className="text-center">{university.visaIssued}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <TablePagination
                    component="div"
                    count={filteredUniversities.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[5, 10, 25]}
                  />
                </>
              )}
            </Paper>
          </motion.div>
        )}

        {!dataLoaded && !loading && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, delay: 0.6 }}
          >
            <Paper elevation={0} className="bg-white rounded-xl shadow-md p-12 text-center">
              <School className="text-indigo-300 mb-4" style={{ fontSize: 64 }} />
              <Typography variant="h5" className="font-medium text-gray-800 mb-2">
                No Data Available
              </Typography>
              <Typography className="text-gray-600 mb-6 max-w-md mx-auto">
                Please apply filters and click &quot;Generate Report&quot; to view university performance data. Select
                study destinations, intake periods, or other criteria to begin.
              </Typography>
              <Button
                variant="contained"
                startIcon={<FilterList />}
                onClick={applyFilters}
                disabled={!filtersChanged}
                className={`${!filtersChanged ? "bg-gray-300" : "bg-indigo-600 hover:bg-indigo-700"}`}
              >
                Generate Report
              </Button>
            </Paper>
          </motion.div>
        )}
        <Dialog
          open={dialogOpen}
          onClose={closeUniversityDetails}
          maxWidth="md"
          fullWidth
          PaperProps={{
            elevation: 0,
            sx: {
              borderRadius: "0.75rem",
              overflow: "hidden",
            },
          }}
        >
          {selectedUniversity && (
            <>
              <DialogTitle className="bg-gradient-to-r from-indigo-600 to-blue-500 text-white flex justify-between items-center">
                <Box>
                  <Typography variant="h5" className="font-bold">
                    {selectedUniversity.uniName}
                  </Typography>
                  <Typography variant="body2" className="text-indigo-100 mt-1">
                    {selectedUniversity.destination} • {selectedUniversity.institutionType}
                  </Typography>
                </Box>
                <IconButton onClick={closeUniversityDetails} sx={{ color: "white" }}>
                  <Close />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers className="p-0">
                <div className="p-6">
                  <div className="flex justify-end mb-4 gap-2">
                    <Chip
                      label={selectedUniversity.agreementType}
                      color={selectedUniversity.agreementType === "Direct" ? "primary" : "default"}
                      icon={<LocalLibrary fontSize="small" />}
                    />
                    <Chip
                      label={selectedUniversity.category}
                      color={selectedUniversity.category === "B2B" ? "success" : "info"}
                      icon={<Groups fontSize="small" />}
                    />
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
                    <Card className="border-l-4 border-indigo-500 shadow-md hover:shadow-lg transition-shadow">
                      <CardContent>
                        <div className="flex items-center gap-2 mb-2">
                          <BarChartIcon className="text-indigo-600" />
                          <Typography className="text-sm font-medium text-gray-700">Offer Rate</Typography>
                        </div>
                        <Typography className="text-2xl font-bold mt-1 text-indigo-800">
                          {(
                            ((selectedUniversity.conditionalOffer + selectedUniversity.unconditionalOffer) /
                              selectedUniversity.applied) *
                            100
                          ).toFixed(1)}
                          %
                        </Typography>
                        <Typography className="text-xs text-gray-500 mt-1">
                          {selectedUniversity.conditionalOffer + selectedUniversity.unconditionalOffer} offers from{" "}
                          {selectedUniversity.applied} applications
                        </Typography>
                      </CardContent>
                    </Card>

                    <Card className="border-l-4 border-emerald-500 shadow-md hover:shadow-lg transition-shadow">
                      <CardContent>
                        <div className="flex items-center gap-2 mb-2">
                          <TrendingUp className="text-emerald-600" />
                          <Typography className="text-sm font-medium text-gray-700">Enrollment Rate</Typography>
                        </div>
                        <Typography className="text-2xl font-bold mt-1 text-emerald-800">
                          {(
                            (selectedUniversity.enrolled /
                              (selectedUniversity.conditionalOffer + selectedUniversity.unconditionalOffer)) *
                            100
                          ).toFixed(1)}
                          %
                        </Typography>
                        <Typography className="text-xs text-gray-500 mt-1">
                          {selectedUniversity.enrolled} enrollments from{" "}
                          {selectedUniversity.conditionalOffer + selectedUniversity.unconditionalOffer} offers
                        </Typography>
                      </CardContent>
                    </Card>

                    <Card className="border-l-4 border-rose-500 shadow-md hover:shadow-lg transition-shadow">
                      <CardContent>
                        <div className="flex items-center gap-2 mb-2">
                          <Visibility className="text-rose-600" />
                          <Typography className="text-sm font-medium text-gray-700">Rejection Rate</Typography>
                        </div>
                        <Typography className="text-2xl font-bold mt-1 text-rose-800">
                          {((selectedUniversity.rejection / selectedUniversity.applied) * 100).toFixed(1)}%
                        </Typography>
                        <Typography className="text-xs text-gray-500 mt-1">
                          {selectedUniversity.rejection} rejections from {selectedUniversity.applied} applications
                        </Typography>
                      </CardContent>
                    </Card>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
                    <Card className="shadow-md hover:shadow-lg transition-shadow">
                      <CardContent>
                        <Typography className="font-medium mb-3 text-indigo-900 flex items-center">
                          <Assignment className="mr-2 text-indigo-600" />
                          Application Overview
                        </Typography>
                        <div className="space-y-3">
                          <div className="flex justify-between items-center p-2 hover:bg-indigo-50 rounded-md transition-colors">
                            <Typography variant="body2">Applications</Typography>
                            <Typography className="font-medium">
                              {selectedUniversity.applied.toLocaleString()}
                            </Typography>
                          </div>
                          <div className="flex justify-between items-center p-2 hover:bg-indigo-50 rounded-md transition-colors">
                            <Typography variant="body2">Conditional Offers</Typography>
                            <Typography className="font-medium">{selectedUniversity.conditionalOffer}</Typography>
                          </div>
                          <div className="flex justify-between items-center p-2 hover:bg-indigo-50 rounded-md transition-colors">
                            <Typography variant="body2">Unconditional Offers</Typography>
                            <Typography className="font-medium">{selectedUniversity.unconditionalOffer}</Typography>
                          </div>
                          <div className="flex justify-between items-center p-2 hover:bg-indigo-50 rounded-md transition-colors">
                            <Typography variant="body2">Deposits Paid</Typography>
                            <Typography className="font-medium">{selectedUniversity.deposite}</Typography>
                          </div>
                          <div className="flex justify-between items-center p-2 hover:bg-indigo-50 rounded-md transition-colors">
                            <Typography variant="body2">CAS Issued</Typography>
                            <Typography className="font-medium">{selectedUniversity.numOfCAS}</Typography>
                          </div>
                          <div className="flex justify-between items-center p-2 hover:bg-indigo-50 rounded-md transition-colors">
                            <Typography variant="body2">Visas Issued</Typography>
                            <Typography className="font-medium">{selectedUniversity.visaIssued}</Typography>
                          </div>
                          <div className="flex justify-between items-center p-2 hover:bg-indigo-50 rounded-md transition-colors">
                            <Typography variant="body2">Enrolled</Typography>
                            <Typography className="font-medium text-emerald-700">
                              {selectedUniversity.enrolled}
                            </Typography>
                          </div>
                        </div>
                      </CardContent>
                    </Card>

                    {/*
                    <Card className="shadow-md hover:shadow-lg transition-shadow">
                      <CardContent>
                        <Typography className="font-medium mb-3 text-indigo-900 flex items-center">
                          <BarChartIcon className="mr-2 text-indigo-600" />
                          Conversion Metrics
                        </Typography>
                        <div className="space-y-3">
                          <div className="flex justify-between items-center p-2 hover:bg-indigo-50 rounded-md transition-colors">
                            <Typography variant="body2">Application to Offer</Typography>
                            <Typography className="font-medium">
                              {(
                                ((selectedUniversity.conditionalOffer + selectedUniversity.unconditionalOffer) /
                                  selectedUniversity.applied) *
                                100
                              ).toFixed(1)}
                              %
                            </Typography>
                          </div>
                          <div className="flex justify-between items-center p-2 hover:bg-indigo-50 rounded-md transition-colors">
                            <Typography variant="body2">Offer to Deposit</Typography>
                            <Typography className="font-medium">
                              {(
                                (selectedUniversity.deposite /
                                  (selectedUniversity.conditionalOffer + selectedUniversity.unconditionalOffer)) *
                                100
                              ).toFixed(1)}
                              %
                            </Typography>
                          </div>
                          <div className="flex justify-between items-center p-2 hover:bg-indigo-50 rounded-md transition-colors">
                            <Typography variant="body2">Deposit to CAS</Typography>
                            <Typography className="font-medium">
                              {((selectedUniversity.numOfCAS / selectedUniversity.deposite) * 100).toFixed(1)}%
                            </Typography>
                          </div>
                          <div className="flex justify-between items-center p-2 hover:bg-indigo-50 rounded-md transition-colors">
                            <Typography variant="body2">CAS to Visa</Typography>
                            <Typography className="font-medium">
                              {((selectedUniversity.visaIssued / selectedUniversity.numOfCAS) * 100).toFixed(1)}%
                            </Typography>
                          </div>
                          <div className="flex justify-between items-center p-2 hover:bg-indigo-50 rounded-md transition-colors">
                            <Typography variant="body2">Visa to Enrollment</Typography>
                            <Typography className="font-medium">
                              {((selectedUniversity.enrolled / selectedUniversity.visaIssued) * 100).toFixed(1)}%
                            </Typography>
                          </div>
                          <div className="flex justify-between items-center p-2 hover:bg-indigo-50 rounded-md transition-colors">
                            <Typography variant="body2">Application Withdrawal Rate</Typography>
                            <Typography className="font-medium">
                              {((selectedUniversity.appWithdrawal / selectedUniversity.applied) * 100).toFixed(1)}%
                            </Typography>
                          </div>
                          <div className="flex justify-between items-center p-2 hover:bg-indigo-50 rounded-md transition-colors">
                            <Typography variant="body2">Overall Conversion Rate</Typography>
                            <Typography className="font-medium text-indigo-700 font-bold">
                              {((selectedUniversity.enrolled / selectedUniversity.applied) * 100).toFixed(1)}%
                            </Typography>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                    */}
                  </div>

                  <Card className="shadow-md hover:shadow-lg transition-shadow mb-6">
                    <CardContent>
                      <Typography className="font-medium mb-3 text-indigo-900 flex items-center">
                        <Groups className="mr-2 text-indigo-600" />
                        Branch Distribution
                      </Typography>
                      
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        {selectedUniversity.appliedBranch && (
                          <div className="border rounded-md p-3 hover:bg-indigo-50 transition-colors">
                            <Typography variant="subtitle2" className="font-medium text-indigo-700 mb-2">
                              Applications by Branch
                            </Typography>
                            <div className="space-y-2 max-h-40 overflow-y-auto">
                              {selectedUniversity.appliedBranch.split(',').map((branch, index) => {
                                const [branchName, count] = branch.split('(');
                                return (
                                  <div key={`applied-${index}`} className="flex justify-between items-center">
                                    <Typography variant="body2" className="text-gray-700">{branchName.trim()}</Typography>
                                    <Chip 
                                      label={count ? count.replace(')', '') : '0'} 
                                      size="small" 
                                      color="primary" 
                                      variant="outlined"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                        
                        {selectedUniversity.conditionalOfferBranch && (
                          <div className="border rounded-md p-3 hover:bg-indigo-50 transition-colors">
                            <Typography variant="subtitle2" className="font-medium text-blue-700 mb-2">
                              Conditional Offers by Branch
                            </Typography>
                            <div className="space-y-2 max-h-40 overflow-y-auto">
                              {selectedUniversity.conditionalOfferBranch.split(',').map((branch, index) => {
                                const [branchName, count] = branch.split('(');
                                return (
                                  <div key={`cond-${index}`} className="flex justify-between items-center">
                                    <Typography variant="body2" className="text-gray-700">{branchName.trim()}</Typography>
                                    <Chip 
                                      label={count ? count.replace(')', '') : '0'} 
                                      size="small" 
                                      color="info" 
                                      variant="outlined"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                        
                        {selectedUniversity.unconditionalOfferBranch && selectedUniversity.unconditionalOfferBranch.trim() !== '' && (
                          <div className="border rounded-md p-3 hover:bg-indigo-50 transition-colors">
                            <Typography variant="subtitle2" className="font-medium text-green-700 mb-2">
                              Unconditional Offers by Branch
                            </Typography>
                            <div className="space-y-2 max-h-40 overflow-y-auto">
                              {selectedUniversity.unconditionalOfferBranch.split(',').map((branch, index) => {
                                const [branchName, count] = branch.split('(');
                                return (
                                  <div key={`uncond-${index}`} className="flex justify-between items-center">
                                    <Typography variant="body2" className="text-gray-700">{branchName.trim()}</Typography>
                                    <Chip 
                                      label={count ? count.replace(')', '') : '0'} 
                                      size="small" 
                                      color="success" 
                                      variant="outlined"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                        
                        {selectedUniversity.appWithdrawalBranch && (
                          <div className="border rounded-md p-3 hover:bg-indigo-50 transition-colors">
                            <Typography variant="subtitle2" className="font-medium text-amber-700 mb-2">
                              Withdrawals by Branch
                            </Typography>
                            <div className="space-y-2 max-h-40 overflow-y-auto">
                              {selectedUniversity.appWithdrawalBranch.split(',').map((branch, index) => {
                                const [branchName, count] = branch.split('(');
                                return (
                                  <div key={`withdraw-${index}`} className="flex justify-between items-center">
                                    <Typography variant="body2" className="text-gray-700">{branchName.trim()}</Typography>
                                    <Chip 
                                      label={count ? count.replace(')', '') : '0'} 
                                      size="small" 
                                      color="warning" 
                                      variant="outlined"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                        
                        {selectedUniversity.rejectionBranch && selectedUniversity.rejectionBranch.trim() !== '' && (
                          <div className="border rounded-md p-3 hover:bg-indigo-50 transition-colors">
                            <Typography variant="subtitle2" className="font-medium text-red-700 mb-2">
                              Rejections by Branch
                            </Typography>
                            <div className="space-y-2 max-h-40 overflow-y-auto">
                              {selectedUniversity.rejectionBranch.split(',').map((branch, index) => {
                                const [branchName, count] = branch.split('(');
                                return (
                                  <div key={`reject-${index}`} className="flex justify-between items-center">
                                    <Typography variant="body2" className="text-gray-700">{branchName.trim()}</Typography>
                                    <Chip 
                                      label={count ? count.replace(')', '') : '0'} 
                                      size="small" 
                                      color="error" 
                                      variant="outlined"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                        
                        {selectedUniversity.depositeBranch && selectedUniversity.depositeBranch.trim() !== '' && (
                          <div className="border rounded-md p-3 hover:bg-indigo-50 transition-colors">
                            <Typography variant="subtitle2" className="font-medium text-purple-700 mb-2">
                              Deposits by Branch
                            </Typography>
                            <div className="space-y-2 max-h-40 overflow-y-auto">
                              {selectedUniversity.depositeBranch.split(',').map((branch, index) => {
                                const [branchName, count] = branch.split('(');
                                return (
                                  <div key={`deposit-${index}`} className="flex justify-between items-center">
                                    <Typography variant="body2" className="text-gray-700">{branchName.trim()}</Typography>
                                    <Chip 
                                      label={count ? count.replace(')', '') : '0'} 
                                      size="small" 
                                      color="secondary" 
                                      variant="outlined"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                        
                        {selectedUniversity.numOfCASBranch && selectedUniversity.numOfCASBranch.trim() !== '' && (
                          <div className="border rounded-md p-3 hover:bg-indigo-50 transition-colors">
                            <Typography variant="subtitle2" className="font-medium text-cyan-700 mb-2">
                              CAS by Branch
                            </Typography>
                            <div className="space-y-2 max-h-40 overflow-y-auto">
                              {selectedUniversity.numOfCASBranch.split(',').map((branch, index) => {
                                const [branchName, count] = branch.split('(');
                                return (
                                  <div key={`cas-${index}`} className="flex justify-between items-center">
                                    <Typography variant="body2" className="text-gray-700">{branchName.trim()}</Typography>
                                    <Chip 
                                      label={count ? count.replace(')', '') : '0'} 
                                      size="small" 
                                      color="info" 
                                      variant="outlined"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                        
                        {selectedUniversity.enrolledBranch && selectedUniversity.enrolledBranch.trim() !== '' && (
                          <div className="border rounded-md p-3 hover:bg-indigo-50 transition-colors">
                            <Typography variant="subtitle2" className="font-medium text-emerald-700 mb-2">
                              Enrolled by Branch
                            </Typography>
                            <div className="space-y-2 max-h-40 overflow-y-auto">
                              {selectedUniversity.enrolledBranch.split(',').map((branch, index) => {
                                const [branchName, count] = branch.split('(');
                                return (
                                  <div key={`enrolled-${index}`} className="flex justify-between items-center">
                                    <Typography variant="body2" className="text-gray-700">{branchName.trim()}</Typography>
                                    <Chip 
                                      label={count ? count.replace(')', '') : '0'} 
                                      size="small" 
                                      color="success" 
                                      variant="outlined"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                        
                        {selectedUniversity.visaIssuedBranch && selectedUniversity.visaIssuedBranch.trim() !== '' && (
                          <div className="border rounded-md p-3 hover:bg-indigo-50 transition-colors">
                            <Typography variant="subtitle2" className="font-medium text-indigo-700 mb-2">
                              Visas by Branch
                            </Typography>
                            <div className="space-y-2 max-h-40 overflow-y-auto">
                              {selectedUniversity.visaIssuedBranch.split(',').map((branch, index) => {
                                const [branchName, count] = branch.split('(');
                                return (
                                  <div key={`visa-${index}`} className="flex justify-between items-center">
                                    <Typography variant="body2" className="text-gray-700">{branchName.trim()}</Typography>
                                    <Chip 
                                      label={count ? count.replace(')', '') : '0'} 
                                      size="small" 
                                      color="primary" 
                                      variant="outlined"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </DialogContent>
            </>
          )}
        </Dialog>
      </div>
    )}
  </div>
)
}

const mapStateToProps = (state) => ({
  permissions: state.auth?.permissions || {},
})

export default connect(mapStateToProps)(UniversityReport)

