/* eslint-disable jsx-a11y/no-autofocus  */
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Paper,
  TextField,
  Autocomplete,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { styled } from "@mui/material/styles"
import DialogTitle from "@mui/material/DialogTitle"
import Draggable from "react-draggable"
import { COMPLIANCE_STATUS, LEADCHANNELS, LEADORIGINS, STUDENTSOURCES, STUDENTSTATUS } from "../../../Util/Constants"
import Agent from "../../../api/agent"
import { Countries } from "../../../Util/Util"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuDialogActions-root": {
    padding: theme.spacing(1),
  },
}))

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

const DialogTitleWithClose = (props) => {
  const { children, onClose, ...other } = props

  return (
    <div className="flex items-center  p-4 bg-gray-200 border-b border-gray-300" {...other}>
      <Typography variant="h6" color="secondary">
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose} className="text-gray-600" size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </div>
  )
}

DialogTitleWithClose.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
}

export const StudentAssignment = ({
  users,
  agents,
  action,
  role,
  cancel,
  student,
  bulkUpdate,
  referralList,
  setReferralReload,
  setUpdatedStudent,
  events,
}) => {
  users.sort((a, b) => {
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    return 0
  })
  console.log("role", agents)
  const [ready, setReady] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(true)
  const [openAgent, setOpenAgent] = useState(false)
  const [openAdmin, setOpenAdmin] = useState(false)
  const [openRegManager, setopenRegManager] = useState(false)
  const [openCountryManager, setopenCountryManager] = useState(false)
  const [openBranchManager, setopenBranchManager] = useState(false)
  const [openComplianceStatus, setopenComplianceStatus] = useState(false)
  const [openVirtualTeam, setopenVirtualTeam] = useState(false)
  const [openAssess, setOpenAssess] = useState(false)
  const [openFollow, setOpenFollow] = useState(false)
  const [openCom, setOpenCom] = useState(false)
  const [agentChanged, setAgentChanged] = useState(false)
  const [statusChanged, setStatusChanged] = useState(false)
  const [appUserDetailsChanged, setAppUserDetailsChanged] = useState(false)
  const [assessmentTeamChanged, setAssessmentTeamChanged] = useState(false)
  const [followupTeamChanged, setFollowupTeamChanged] = useState(false)
  const [complianceTeamChanged, setComplianceTeamChanged] = useState(false)
  const [branchOfficeChanged, setBranchOfficeChanged] = useState(false)
  const [sourceChanged, setSourceChanged] = useState(false)
  const [channelChanged, setChannelChanged] = useState(false)
  const [originChanged, setOriginChanged] = useState(false)
  const [campaignChanged, setCampaignChanged] = useState(false)
  const [complianceStatusChanged, setComplianceStatusChanged] = useState(false)
  const [regionalManagerChanged, setRegionalManagerChanged] = useState(false)
  const [countryManagerChanged, setCountryManagerChanged] = useState(false)
  const [referralChanged, setReferralChanged] = useState(false)
  const [branchManagerChanged, setBranchManagerChanged] = useState(false)
  const [virtualTeamChanged, setVirtualTeamChanged] = useState(false)
  const [openReferral, setopenReferral] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const [agentRecord, setAgentRecord] = useState(agents.find(({ id }) => id == (student ? student.agentId : 0)))
  const [complianceStatus, setComplianceStatus] = useState(
    COMPLIANCE_STATUS.find(({ id }) => id == (student ? student.complianceStatus : null)),
  )
  const [adminRecord, setAdminRecord] = useState(users.find(({ id }) => id == (student ? student.appUserDetailsId : 0)))
  const [assessmentRecord, setAssessmentRecord] = useState(
    users.find(({ id }) => id == (student ? student.assessmentTeamId : 0)),
  )
  const [followUpRecord, setFollowUpRecord] = useState(
    users.find(({ id }) => id == (student ? student.followupTeamId : 0)),
  )
  const [complianceRecord, setComplianceRecord] = useState(
    users.find(({ id }) => id == (student ? student.complianceTeamId : 0)),
  )
  const [regionalManagerRecord, setRegionalManagerRecord] = useState(
    users.find(({ id }) => id == (student ? student.regionalManagerId : 0)),
  )
  const [countryManagerRecord, setCountryManagerRecord] = useState(
    users.find(({ id }) => id == (student ? student.countryManagerId : 0)),
  )
  const [branchManagerRecord, setBranchManagerRecord] = useState(
    users.find(({ id }) => id == (student ? student.branchManagerId : 0)),
  )
  const [virtualTeamRecord, setVirtualTeamRecord] = useState(
    users.find(({ id }) => id == (student ? student.virtualTeamId : 0)),
  )
  const [referralAssignId, setReferralAssignId] = useState(null)
  const [status, setStatus] = useState((student && student.status) || 1)
  const [branchOfficeId, setBranchOfficeId] = useState((student && student.branchOfficeId) || 0)
  const [source, setSource] = useState(0)
  const [origin, setOrigin] = useState(student ? student.originId : 0)
  const [channel, setChannel] = useState(student ? student.channelId : 0)
  const [campaign, setCampaign] = useState(student ? student.campaign : "")
  const [eventId, setEventId] = useState(student ? student.eventId : null)

  const [eventChanged, setEventChanged] = useState(false)

  useEffect(() => {
    if (student && student.eventId) {
      setEventId(student.eventId)
    }
    if (student) {
      setSource(student.sourceId || 0)
    }
  }, [student])

  useEffect(() => {
    if (referralList.length > 0 && student) {
      const foundReferral = referralList.find(({ id }) => id === student.referralId)
      setReferralAssignId(foundReferral || null)
    }
  }, [referralList, student])

  const handleClose = () => {
    setDialogOpen(false)
    cancel(false)
  }

  const [offices, setOffices] = useState([])
  useEffect(() => {
    Agent.Cache.getBranchOffices().then(setOffices)
  }, [])

  const confirm = () => {
    setIsLoading(true)
    const updatedStudentData = {
      status,
      agentId: agentChanged ? (agentRecord ? agentRecord.id : null) : student ? student.agentId : null,
      agent: agentChanged ? (agentRecord ? agentRecord.name : "") : student ? student.agentCompanyName : "",
      appUserDetailsId: adminRecord ? adminRecord.id : null,
      admin: adminRecord ? adminRecord.name : "",
      assessmentTeamId: assessmentRecord ? assessmentRecord.id : null,
      ReferralId: referralAssignId?.id ? referralAssignId?.id : null,
      assessment: assessmentRecord ? assessmentRecord.name : "",
      followupTeamId: followUpRecord ? followUpRecord.id : null,
      followUp: followUpRecord ? followUpRecord.name : "",
      complianceTeamId: complianceRecord ? complianceRecord.id : null,
      compliance: complianceRecord ? complianceRecord.name : "",
      complianceStatus: complianceStatus ? complianceStatus.id : null,
      branchOfficeId: branchOfficeId || null,
      branchOffice: branchOfficeId > 0 ? offices.find(({ id }) => id == branchOfficeId)?.name : "",
      campaign,
      source,
      origin,
      channel,
      agentChanged,
      statusChanged,
      appUserDetailsChanged,
      assessmentTeamChanged,
      followupTeamChanged,
      referralChanged,
      complianceTeamChanged,
      branchOfficeChanged,
      sourceChanged,
      channelChanged,
      originChanged,
      campaignChanged,
      complianceStatusChanged,
      regionalManagerId: regionalManagerRecord ? regionalManagerRecord.id : null,
      regionalManager: regionalManagerRecord ? regionalManagerRecord.name : "",
      regionalManagerChanged,
      countryManagerId: countryManagerRecord ? countryManagerRecord.id : null,
      countryManager: countryManagerRecord ? countryManagerRecord.name : "",
      countryManagerChanged,
      branchManagerId: branchManagerRecord ? branchManagerRecord.id : null,
      branchManager: branchManagerRecord ? branchManagerRecord.name : "",
      branchManagerChanged,
      virtualTeamId: virtualTeamRecord ? virtualTeamRecord.id : null,
      virtualTeam: virtualTeamRecord ? virtualTeamRecord.name : "",
      virtualTeamChanged,
      eventId: eventId,
      eventChanged,
    }

    action(updatedStudentData)
      .then(() => {
        setSuccessMessage("Updated successfully")
        setUpdatedStudent(updatedStudentData)
        // Force a re-render of the parent component
        if (typeof setReferralReload === "function") {
          setReferralReload(true)
        }
        setTimeout(() => {
          setIsLoading(false)
          setDialogOpen(false)
          cancel(false)
        }, 1)
      })
      .catch((error) => {
        console.error("Error updating student assignment:", error)
        setIsLoading(false)
      })
  }

  const formFieldClass =
    "bg-white rounded-md shadow-sm border border-gray-300 hover:border-indigo-500 transition-colors duration-200"
  const labelClass = "text-sm font-medium text-gray-700 mb-1"
  const buttonClass = "px-4 py-2 rounded-md transition-colors duration-200"
  const primaryButtonClass = `${buttonClass} bg-sky-600 text-white hover:bg-green-700`
  const secondaryButtonClass = `${buttonClass} bg-gray-200 text-gray-800 hover:bg-gray-300`

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        open={dialogOpen}
        maxWidth="md"
        fullWidth
        PaperComponent={PaperComponent}
        PaperProps={{
          style: {
            width: "100%",
            maxWidth: "700px",
            backgroundColor: "#f8fafc",
          },
        }}
      >
        <div className="flex items-center justify-between p-1 bg-sky-600 text-white mb-4">
          <Typography variant="h6" id="draggable-dialog-title" className="font-semibold text-lg mx-auto">
            {bulkUpdate ? "Bulk Update" : student.name}
          </Typography>
          <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent className="p-2 bg-white">
          <div className="space-y-4 max-w-lg mx-auto">
            <FormControl fullWidth variant="outlined" size="small" className={formFieldClass}>
              <InputLabel className="font-medium text-gray-700">Status</InputLabel>
              <Select
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value)
                  setStatusChanged(true)
                  setReady(true)
                }}
                label="Status"
              >
                {STUDENTSTATUS.filter((item) => item.display).map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {offices.length > 0 && role.isSuperAdmin && (
              <FormControl fullWidth variant="outlined" size="small" className={formFieldClass}>
                <InputLabel className="font-medium text-gray-700">Branch Office</InputLabel>
                <Select
                  value={branchOfficeId}
                  onChange={(e) => {
                    setBranchOfficeId(e.target.value)
                    setBranchOfficeChanged(true)
                    setReady(true)
                  }}
                  label="Branch Office"
                >
                  <MenuItem value={0}>--Pick a branch--</MenuItem>
                  {offices.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {offices.length > 0 && role.isSuperAdmin && (
              <Autocomplete
                fullWidth
                size="small"
                open={openAgent}
                onOpen={() => setOpenAgent(true)}
                onClose={() => setOpenAgent(false)}
                value={agentRecord}
                onChange={(_, newVal) => {
                  setAgentRecord(newVal)
                  setReady(true)
                  setAgentChanged(true)
                }}
                getOptionLabel={(user) =>
                  `${user.name} ${
                    user && user.country ? "(" + Countries.find(({ id }) => id == user.country).name + ")" : ""
                  }`
                }
                options={agents.filter((agent) => !agent.isDisabled)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Agent"
                    variant="outlined"
                    className={formFieldClass}
                    InputLabelProps={{
                      className: "font-medium text-gray-700",
                    }}
                  />
                )}
              />
            )}

            {!role.isApplicationTeam && !role.isAdmin && (
              <Autocomplete
                fullWidth
                size="small"
                open={openReferral}
                onOpen={() => setopenReferral(true)}
                onClose={() => setopenReferral(false)}
                value={referralAssignId}
                onChange={(_, newVal) => {
                  setReferralAssignId(newVal)
                  setReferralChanged(true)
                  setReady(true)
                }}
                options={referralList}
                getOptionLabel={(referral) => (referral ? `${referral.firstName} ${referral.lastName}` : "")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Referral"
                    variant="outlined"
                    className={formFieldClass}
                    InputLabelProps={{
                      className: "font-medium text-gray-700",
                    }}
                  />
                )}
              />
            )}

            {!role.isAdmin && (
              <Autocomplete
                fullWidth
                size="small"
                value={events.find((event) => event.id === eventId) || null}
                onChange={(_, newValue) => {
                  setEventId(newValue ? newValue.id : null)
                  setEventChanged(true)
                  setReady(true)
                }}
                options={events}
                getOptionLabel={(option) => option.eventName || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Event"
                    variant="outlined"
                    className={formFieldClass}
                    InputLabelProps={{
                      className: "font-medium text-gray-700",
                    }}
                  />
                )}
              />
            )}

            {!role.isApplicationTeam && !role.isAdmin && (
              <Autocomplete
                fullWidth
                size="small"
                open={openAdmin}
                onOpen={() => setOpenAdmin(true)}
                onClose={() => setOpenAdmin(false)}
                value={adminRecord}
                onChange={(_, newVal) => {
                  setAdminRecord(newVal)
                  setAppUserDetailsChanged(true)
                  setReady(true)
                }}
                options={users}
                getOptionLabel={(user) => user.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Team Leader"
                    variant="outlined"
                    className={formFieldClass}
                    InputLabelProps={{
                      className: "font-medium text-gray-700",
                    }}
                  />
                )}
              />
            )}

            {!role.isApplicationTeam && !role.isAdmin && (
              <Autocomplete
                fullWidth
                size="small"
                open={openAssess}
                onOpen={() => setOpenAssess(true)}
                onClose={() => setOpenAssess(false)}
                value={assessmentRecord}
                onChange={(_, newVal) => {
                  setAssessmentRecord(newVal)
                  setAssessmentTeamChanged(true)
                  setReady(true)
                }}
                options={users}
                getOptionLabel={(user) => user.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Assessment Team Member"
                    variant="outlined"
                    className={formFieldClass}
                    InputLabelProps={{
                      className: "font-medium text-gray-700",
                    }}
                  />
                )}
              />
            )}

            {!role.isApplicationTeam && !role.isAdmin && (
              <>
                <Autocomplete
                  fullWidth
                  size="small"
                  open={openRegManager}
                  onOpen={() => setopenRegManager(true)}
                  onClose={() => setopenRegManager(false)}
                  value={regionalManagerRecord}
                  onChange={(_, newVal) => {
                    setRegionalManagerRecord(newVal)
                    setRegionalManagerChanged(true)
                    setReady(true)
                  }}
                  options={users.filter((item) => item.userType == 2)}
                  getOptionLabel={(user) => user.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Regional Manager"
                      variant="outlined"
                      className={formFieldClass}
                      InputLabelProps={{
                        className: "font-medium text-gray-700",
                      }}
                    />
                  )}
                />

                <Autocomplete
                  fullWidth
                  size="small"
                  open={openCountryManager}
                  onOpen={() => setopenCountryManager(true)}
                  onClose={() => setopenCountryManager(false)}
                  value={countryManagerRecord}
                  onChange={(_, newVal) => {
                    setCountryManagerRecord(newVal)
                    setCountryManagerChanged(true)
                    setReady(true)
                  }}
                  options={users.filter((item) => item.userType == 2)}
                  getOptionLabel={(user) => user.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Country Manager"
                      variant="outlined"
                      className={formFieldClass}
                      InputLabelProps={{
                        className: "font-medium text-gray-700",
                      }}
                    />
                  )}
                />

                <Autocomplete
                  fullWidth
                  size="small"
                  open={openBranchManager}
                  onOpen={() => setopenBranchManager(true)}
                  onClose={() => setopenBranchManager(false)}
                  value={branchManagerRecord}
                  onChange={(_, newVal) => {
                    setBranchManagerRecord(newVal)
                    setBranchManagerChanged(true)
                    setReady(true)
                  }}
                  options={users.filter((item) => item.userType == 2)}
                  getOptionLabel={(user) => user.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Branch Manager"
                      variant="outlined"
                      className={formFieldClass}
                      InputLabelProps={{
                        className: "font-medium text-gray-700",
                      }}
                    />
                  )}
                />
              </>
            )}

            {!role.isApplicationTeam && (
              <>
                <Autocomplete
                  fullWidth
                  size="small"
                  open={openFollow}
                  onOpen={() => setOpenFollow(true)}
                  onClose={() => setOpenFollow(false)}
                  value={followUpRecord}
                  onChange={(_, newVal) => {
                    setFollowUpRecord(newVal)
                    setFollowupTeamChanged(true)
                    setReady(true)
                  }}
                  options={users}
                  getOptionLabel={(user) => user.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Follow Up Team Member"
                      variant="outlined"
                      className={formFieldClass}
                      InputLabelProps={{
                        className: "font-medium text-gray-700",
                      }}
                    />
                  )}
                />

                <Autocomplete
                  fullWidth
                  size="small"
                  open={openCom}
                  onOpen={() => setOpenCom(true)}
                  onClose={() => setOpenCom(false)}
                  value={complianceRecord}
                  onChange={(_, newVal) => {
                    setComplianceRecord(newVal)
                    setComplianceTeamChanged(true)
                    setReady(true)
                  }}
                  options={users}
                  getOptionLabel={(user) => user.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Compliance Team Member"
                      variant="outlined"
                      className={formFieldClass}
                      InputLabelProps={{
                        className: "font-medium text-gray-700",
                      }}
                    />
                  )}
                />
              </>
            )}

            {!role.isApplicationTeam && (
              <Autocomplete
                fullWidth
                size="small"
                open={openComplianceStatus}
                onOpen={() => setopenComplianceStatus(true)}
                onClose={() => setopenComplianceStatus(false)}
                value={complianceStatus}
                onChange={(_, newVal) => {
                  setComplianceStatus(newVal)
                  setComplianceStatusChanged(true)
                  setReady(true)
                }}
                options={COMPLIANCE_STATUS}
                getOptionLabel={(status) => status.type}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Compliance Status"
                    variant="outlined"
                    className={formFieldClass}
                    InputLabelProps={{
                      className: "font-medium text-gray-700",
                    }}
                  />
                )}
              />
            )}

            {role.isSuperAdmin && (
              <>
                <TextField
                  fullWidth
                  size="small"
                  label="Campaign & Referral"
                  value={campaign}
                  onChange={(e) => {
                    setCampaign(e.target.value)
                    setCampaignChanged(true)
                    setReady(true)
                  }}
                  variant="outlined"
                  className={formFieldClass}
                  InputLabelProps={{
                    className: "font-medium text-gray-700",
                  }}
                />
                <FormControl fullWidth variant="outlined" size="small" className={formFieldClass}>
                  <InputLabel className="font-medium text-gray-700">Lead Source</InputLabel>
                  <Select
                    value={source}
                    onChange={(e) => {
                      setSource(e.target.value)
                      setSourceChanged(true)
                      setReady(true)
                    }}
                    label="Lead Source"
                  >
                    <MenuItem value={0}>--Lead Source--</MenuItem>
                    {STUDENTSOURCES.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions className="p-4 bg-gray-50 flex justify-end space-x-4 rounded-b-lg">
          <Button onClick={handleClose} className={`${secondaryButtonClass} w-full sm:w-auto`}>
            Cancel
          </Button>
          <Button onClick={confirm} disabled={!ready || isLoading} className={`${primaryButtonClass} w-full sm:w-auto`}>
            {isLoading ? <CircularProgress size={24} color="inherit" /> : "Confirm Selection"}
          </Button>
        </DialogActions>
        {bulkUpdate && isLoading && (
          <div className="absolute inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-50">
            <CircularProgress size={48} color="primary" />
          </div>
        )}
      </Dialog>
      <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={() => setSuccessMessage("")}>
        <Alert onClose={() => setSuccessMessage("")} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
    </div>
  )
}

