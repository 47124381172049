/* eslint-disable import/namespace */
import React, { useState, useCallback, useMemo, useEffect } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  TextField,
  Select,
  MenuItem,
  Chip,
  Box,
  Drawer,
  IconButton,
  FormControl,
  InputLabel,
  CircularProgress,
  Autocomplete,
} from "@mui/material"
import { FilterList, Close, BarChart as BarChartIcon, ArrowBack } from "@mui/icons-material"
import { Bar } from "react-chartjs-2"
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js"
import { intakeYearList, INTAKES, STUDENTSOURCES } from "../../../Util/Constants"
import { Countries } from "../../../Util/Util"
import Agent from "../../../api/agent"
import ErrorPopup from "../RegionalReport/ErrorPopUp"
import { connect } from "react-redux"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"]

const MonthSelector = ({ selectedMonths, onMonthToggle }) => (
  <div className="flex flex-wrap gap-2 mb-6">
    {months.map((month) => (
      <button
        key={month}
        onClick={() => onMonthToggle(month)}
        className={`px-4 py-2 rounded-full text-sm font-medium transition-all duration-200 ease-in-out ${
          selectedMonths.includes(month)
            ? "bg-blue-600 text-white shadow-md"
            : "bg-gray-200 text-gray-700 hover:bg-gray-300"
        }`}
      >
        {month.toUpperCase()}
      </button>
    ))}
  </div>
)

const SourcePerformanceChart = ({ permissions }) =>{
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingBranches, setLoadingBranches] = useState(true)
  const [error, setError] = useState(null)
  const [showErrorPopup, setShowErrorPopup] = useState(false)
  const [branches, setBranches] = useState([])
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    year: "",
    leadType: "2",
    intakeYears: [],
    intakeMonths: [],
    citizenship: [],
    selectedBranches: [],
  })
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false)
  const [showFilters, setShowFilters] = useState(true)
  const [selectedMonths, setSelectedMonths] = useState(months)

  const hasAccess = permissions.CanAccessGlobalLeadSourcePerformanceReportB2C

  useEffect(() => {
    if (hasAccess) {
      const fetchBranches = async () => {
        setLoadingBranches(true)
        try {
          const response = await Agent.Cache.getBranchOffices()
          if (Array.isArray(response)) {
            setBranches(response.map((branch) => ({ id: branch.id.toString(), label: branch.name })))
          } else {
            console.error("Unexpected response format for branch offices:", response)
          }
        } catch (err) {
          console.error("Error fetching branch offices:", err)
          setError("Failed to fetch branch offices. Please try again later.")
        } finally {
          setLoadingBranches(false)
        }
      }

      fetchBranches()
    }
  }, [hasAccess])

  const fetchData = useCallback(async () => {
    if (!hasAccess) return

    setLoading(true)
    setError(null)
    try {
      const response = await Agent.Report.leadsBySource({
        intakeYears: filters.intakeYears,
        intakeMonths: filters.intakeMonths,
        startDate: filters.startDate,
        endDate: filters.endDate,
        branches: filters.selectedBranches.map((branchId) => Number.parseInt(branchId, 10)),
        year: filters.year ? [filters.year] : [],
        citizenship: filters.citizenship,
        type: Number.parseInt(filters.leadType, 10),
      })
      if (Array.isArray(response) && response.length > 0) {
        setData(response)
      } else {
        setError("No data available for the selected criteria.")
        setShowErrorPopup(true)
      }
    } catch (err) {
      setError(err.message || "Failed to fetch data. Please try again.")
      setShowErrorPopup(true)
      console.error("Error fetching data:", err)
    } finally {
      setLoading(false)
    }
  }, [hasAccess, filters])

  const handleGenerateReport = () => {
    fetchData()
  }

  const handleResetFilters = () => {
    setFilters({
      startDate: "",
      endDate: "",
      year: "",
      leadType: "2",
      intakeYears: [],
      intakeMonths: [],
      citizenship: [],
      selectedBranches: [],
    })
    setData([])
    setError(null)
    setShowErrorPopup(false)
    setSelectedMonths(months)
  }

  const handleCloseErrorPopup = () => {
    setShowErrorPopup(false)
    setError(null)
  }

  const handleMonthToggle = (month) => {
    setSelectedMonths((prev) => (prev.includes(month) ? prev.filter((m) => m !== month) : [...prev, month]))
  }

  const activeFiltersCount = useMemo(() => Object.values(filters).flat().filter(Boolean).length, [filters])

  const chartData = {
    labels: data.map((item) => item.source),
    datasets: selectedMonths.map((month, index) => ({
      label: month.toUpperCase(),
      data: data.map((item) => item[month]),
      backgroundColor: `hsl(${index * (360 / selectedMonths.length)}, 70%, 50%)`,
    })),
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          padding: 20,
          font: {
            size: 14,
            weight: "bold",
          },
          generateLabels: (chart) => {
            const datasets = chart.data.datasets
            return datasets.map((dataset, i) => ({
              text: dataset.label,
              fillStyle: dataset.backgroundColor,
              hidden: !chart.isDatasetVisible(i),
              lineCap: "round",
              lineDash: [],
              lineDashOffset: 0,
              lineJoin: "round",
              lineWidth: 0,
              strokeStyle: dataset.backgroundColor,
              pointStyle: "circle",
              rotation: 0,
              datasetIndex: i,
            }))
          },
        },
        onHover: (event, legendItem, legend) => {
          event.native.target.style.cursor = "pointer"
        },
        onLeave: (event, legendItem, legend) => {
          event.native.target.style.cursor = "default"
        },
      },
      title: {
        display: true,
        text: "Source Performance Analysis",
        font: {
          size: 24,
          weight: "bold",
        },
        padding: {
          top: 20,
          bottom: 20,
        },
        color: "#333",
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
            weight: "bold",
          },
          color: "#555",
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        ticks: {
          font: {
            size: 12,
            weight: "bold",
          },
          color: "#555",
        },
        grid: {
          color: "rgba(0, 0, 0, 0.1)",
        },
      },
    },
    barThickness: 30,
    onHover: (event, chartElement) => {
      event.native.target.style.cursor = chartElement[0] ? "pointer" : "default"
    },
  }

  if (!hasAccess) {
    return (
      <Card className="max-w-7xl mx-auto mt-4 bg-gray-50 shadow-lg">
        <CardContent>
          <Typography variant="body1" className="text-red-500">
            You do not have permission to access the Source Performance Report.
          </Typography>
        </CardContent>
      </Card>
    )
  }

  return (
    <Card className="max-w-full mx-auto mt-4 bg-white shadow-xl rounded-lg">
      <CardContent className="p-8">
        <Box className="flex justify-between items-center mb-8">
          <Typography variant="h4" component="h1" className="text-gray-800 font-bold">
            Source Performance Analysis
          </Typography>
          <Box className="flex space-x-4">
            <Link
              to="/reportpanel"
              className="flex items-center px-4 py-2 text-sm font-medium text-blue-600 bg-blue-100 rounded-md hover:bg-blue-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <ArrowBack className="w-5 h-5 mr-2" />
              Back to Reporting Panel
            </Link>
            <Button
              variant="outlined"
              startIcon={<FilterList />}
              onClick={() => setShowFilters(!showFilters)}
              className="text-gray-700 bg-white border border-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200 ease-in-out"
            >
              {showFilters ? "Hide Filters" : "Show Filters"}
            </Button>
          </Box>
        </Box>
        {showFilters && (
          <Box className="mb-8 bg-gray-50 p-6 rounded-lg shadow-inner">
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Start Date"
                  type="date"
                  value={filters.startDate}
                  onChange={(e) => setFilters({ ...filters, startDate: e.target.value })}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  size="small"
                  className="bg-white"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="End Date"
                  type="date"
                  value={filters.endDate}
                  onChange={(e) => setFilters({ ...filters, endDate: e.target.value })}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  size="small"
                  className="bg-white"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth size="small">
                  <InputLabel id="year-select-label">Year</InputLabel>
                  <Select
                    labelId="year-select-label"
                    id="year-select"
                    value={filters.year}
                    label="Year"
                    onChange={(e) => setFilters({ ...filters, year: e.target.value })}
                    className="bg-white"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {intakeYearList.map((y) => (
                      <MenuItem key={y.year} value={y.year.toString()}>
                        {y.year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth size="small">
                  <InputLabel id="lead-type-select-label">Lead Type</InputLabel>
                  <Select
                    labelId="lead-type-select-label"
                    id="lead-type-select"
                    value={filters.leadType}
                    label="Lead Type"
                    onChange={(e) => setFilters({ ...filters, leadType: e.target.value })}
                    className="bg-white"
                  >
                    <MenuItem value="1">Paid Leads</MenuItem>
                    <MenuItem value="2">Organic Leads</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  multiple
                  id="intake-years"
                  options={intakeYearList.map((year) => ({ id: year.year.toString(), label: year.year.toString() }))}
                  value={filters.intakeYears.map((id) => ({ id, label: id }))}
                  onChange={(_, newValue) => setFilters({ ...filters, intakeYears: newValue.map((v) => v.id) })}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Intake Years"
                      placeholder="Select intake years"
                      className="bg-white"
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option.id}
                        variant="outlined"
                        label={option.label}
                        size="small"
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  multiple
                  id="intake-months"
                  options={[
                    { id: "", label: "All Months" },
                    ...INTAKES.map((month) => ({ id: month.id.toString(), label: month.type })),
                  ]}
                  value={filters.intakeMonths.map((id) => ({
                    id,
                    label: INTAKES.find((m) => m.id.toString() === id)?.type || id,
                  }))}
                  onChange={(_, newValue) => setFilters({ ...filters, intakeMonths: newValue.map((v) => v.id) })}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Intake Months"
                      placeholder="Select intake months"
                      className="bg-white"
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option.id}
                        variant="outlined"
                        label={option.label}
                        size="small"
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  multiple
                  id="citizenship"
                  options={Countries.map((country) => ({ id: country.id.toString(), label: country.name }))}
                  value={filters.citizenship.map((id) => ({
                    id,
                    label: Countries.find((c) => c.id.toString() === id)?.name || id,
                  }))}
                  onChange={(_, newValue) => setFilters({ ...filters, citizenship: newValue.map((v) => v.id) })}
                  renderInput={(params) => (
                    <TextField {...params} label="Citizenship" placeholder="Select citizenship" className="bg-white" />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option.id}
                        variant="outlined"
                        label={option.label}
                        size="small"
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  multiple
                  id="branches"
                  options={branches}
                  loading={loadingBranches}
                  value={filters.selectedBranches.map((id) => branches.find((b) => b.id === id) || { id, label: id })}
                  onChange={(_, newValue) => setFilters({ ...filters, selectedBranches: newValue.map((v) => v.id) })}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Branches"
                      placeholder={loadingBranches ? "Loading branches..." : "Select branches"}
                      className="bg-white"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingBranches ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option.id}
                        variant="outlined"
                        label={option.label}
                        size="small"
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  size="small"
                />
              </Grid>
            </Grid>
            <Box className="mt-6 flex justify-end space-x-4">
              <Button
                onClick={handleResetFilters}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors duration-200 ease-in-out"
              >
                Reset Filters
              </Button>
              <Button
                onClick={handleGenerateReport}
                disabled={loading}
                className={`px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200 ease-in-out ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {loading ? "Generating..." : "Generate Report"}
              </Button>
            </Box>
          </Box>
        )}
        {loading ? (
          <Box className="flex justify-center my-8">
            <CircularProgress size={60} thickness={4} />
          </Box>
        ) : (
          <>
            <MonthSelector selectedMonths={selectedMonths} onMonthToggle={handleMonthToggle} />
            <Box className="h-[600px] mt-4 bg-white p-4 rounded-lg shadow-md">
              <Bar options={chartOptions} data={chartData} />
            </Box>
            {error && (
              <Typography color="error" className="mt-4 text-center">
                {error}
              </Typography>
            )}
          </>
        )}
      </CardContent>
      {showErrorPopup && <ErrorPopup error={error} onClose={handleCloseErrorPopup} />}
    </Card>
  )
}

const mapStateToProps = (state) => ({
  permissions: state.auth.permissions,
});

export default connect(mapStateToProps)(SourcePerformanceChart);
