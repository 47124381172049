/* eslint-disable import/namespace */
import React from "react"
import { Dialog, DialogTitle, DialogContent } from "@mui/material"
import { Pie } from "react-chartjs-2"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import CloseIcon from "@mui/icons-material/Close"

ChartJS.register(ArcElement, Tooltip, Legend)

const SourceDetailDialog = ({ open, onClose, sourceData, statusTypes, months }) => {
  if (!sourceData) return null

  const parseSourceData = (sourceString) => {
    if (!sourceString) return []
    return sourceString.split(",").map((source) => {
      const [name, countStr] = source.trim().split("(")
      return { name: name.trim(), count: Number.parseInt(countStr) }
    })
  }

  const organicSources = parseSourceData(sourceData.organicSources)
  const paidSources = parseSourceData(sourceData.paidSources)
  const allSources = [...organicSources, ...paidSources]

  const colors = [
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4BC0C0",
    "#9966FF",
    "#FF9F40",
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4BC0C0",
    "#9966FF",
    "#FF9F40",
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4BC0C0",
    "#9966FF",
    "#FF9F40",
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4BC0C0",
    "#9966FF",
    "#FF9F40",
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4BC0C0",
  ]

  const pieData = {
    labels: allSources.map((source) => source.name),
    datasets: [
      {
        data: allSources.map((source) => source.count),
        backgroundColor: colors,
        borderColor: colors.map((color) => color.replace("0.8", "1")),
        borderWidth: 1,
      },
    ],
  }

  const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  const totalLeads = sourceData.totalLead
  const organicLeads = sourceData.organicLead
  const paidLeads = sourceData.paidLead

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle className="bg-sky-600 text-white p-4 text-center relative">
        <h2 className="text-2xl font-bold">
          Monthly Overview - <span className="text-yellow-300">{months[sourceData.month - 1]}</span>
        </h2>
        <button onClick={onClose} className="absolute top-2 right-2 text-white hover:text-gray-200" aria-label="close">
          <CloseIcon />
        </button>
      </DialogTitle>
      <DialogContent className="p-6">
        <div className="mb-6 bg-gray-100 rounded-lg p-4 shadow-sm">
          <h3 className="text-xl font-semibold mb-2 text-gray-800">Lead Summary</h3>
          <p className="text-gray-600 mb-2">
            Total Leads: <span className="font-bold text-gray-800">{totalLeads}</span>
          </p>
          <div className="flex justify-between items-center">
            <div className="bg-blue-100 rounded-full px-3 py-1 text-sm font-semibold text-blue-800">
              Organic: {organicLeads} ({((organicLeads / totalLeads) * 100).toFixed(1)}%)
            </div>
            <div className="bg-green-100 rounded-full px-3 py-1 text-sm font-semibold text-green-800">
              Paid: {paidLeads} ({((paidLeads / totalLeads) * 100).toFixed(1)}%)
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-6">
          <div className="flex-1 bg-white rounded-lg shadow-md p-4 overflow-hidden">
            <h3 className="text-lg font-semibold mb-4">Performance Metrics</h3>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Metric
                    </th>
                    <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Value
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {statusTypes.map((status) => (
                    <tr key={status.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{status.type}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                        {sourceData[status.key] !== undefined ? sourceData[status.key] : "N/A"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex-1 bg-white rounded-lg shadow-md p-4">
            <h3 className="text-lg font-semibold mb-4">Lead Source Distribution</h3>
            <div className="flex flex-col items-center">
              <div className="w-full max-w-[400px] h-[400px]">
                <Pie data={pieData} options={pieOptions} />
              </div>
              <div className="mt-4 w-full">
                <div className="flex flex-wrap justify-center">
                  {allSources.map((source, index) => (
                    <div key={index} className="flex items-center m-2">
                      <div className="w-3 h-3 mr-2" style={{ backgroundColor: colors[index % colors.length] }}></div>
                      <span className="text-sm font-semibold">{source.name}: </span>
                      <span className="text-sm font-bold ml-1" style={{ color: colors[index % colors.length] }}>
                        {source.count}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default SourceDetailDialog

