"use client"

import React, { useState, useCallback, useEffect } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import Agent from "../../../api/agent"
import { INTAKES, intakeYearList, STUDENTSOURCES, STUDY_LEVELS } from "../../../Util/Constants"
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Autocomplete,
  Chip,
  Paper,
  Grid,
  Typography,
  CircularProgress,
  ToggleButtonGroup,
  ToggleButton,
  Box,
  Checkbox,
  ListItemText,
} from "@mui/material"
import { Countries } from "../../../Util/Util"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import FilterListIcon from "@mui/icons-material/FilterList"
import BarChartIcon from "@mui/icons-material/BarChart"
import TableChartIcon from "@mui/icons-material/TableChart"
import ErrorPopup from "../RegionalReport/ErrorPopUp"
import ProjectionComparisonGraph from "./ProjectionComparisonGraph"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const statusTypes = [
  { id: 1, type: "Lead To Application", key: "leadToApplication" },
  { id: 2, type: "Lead To Enrollment", key: "leadtoEnrollment" },
  { id: 3, type: "Application To Enrollment", key: "applicationtoEnrollment" },
  { id: 4, type: "Application To Offer Letter", key: "applicationToOfferLetter" },
  { id: 6, type: "Conditional To Unconditional Offer", key: "conditionalToUnconditional" },
  { id: 7, type: "Unconditional Offer To Enrollment", key: "unconditionalToEnrollment" },
  { id: 8, type: "Conditional Offer To Enrollment", key: "conditionalToEnrollment" },
  { id: 9, type: "Unconditional Offer To Deposit", key: "unconditionalToDeposit" },
  { id: 10, type: "Deposit To CAS", key: "depositToCAS" },
  { id: 11, type: "CAS to Visa Received", key: "casToVisa" },
  { id: 12, type: "CAS To Enrollment", key: "casToEnrollment" },
  { id: 13, type: "Visa To Enrollment", key: "visatoEnrollment" },
]

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

const LoadingAnimation = () => (
  <div className="flex justify-center items-center h-64">
    <CircularProgress />
  </div>
)

const formatValue = (value) => {
  if (value === undefined || value === null || value === "") return ""
  const numValue = Number(value)
  if (isNaN(numValue)) return ""
  const cappedValue = Math.min(numValue, 100) // Capped the value at 100 as per the manager requirement
  const formattedValue = cappedValue.toFixed(2).replace(/\.?0+$/, "")
  return `${formattedValue}%`
}

const AdmissionProjectionReport = ({ permissions }) => {
  const [intakeYears, setIntakeYears] = useState([])
  const [intakeMonths, setIntakeMonths] = useState([])
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [sources, setSources] = useState([])
  const [branches, setBranches] = useState([])
  const [selectedBranches, setSelectedBranches] = useState([])
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [year, setYear] = useState("")
  const [citizenship, setCitizenship] = useState([])
  const [loadingBranches, setLoadingBranches] = useState(true)
  const [showFilters, setShowFilters] = useState(true)
  const [showErrorPopup, setShowErrorPopup] = useState(false)
  const [type, setType] = useState(1)
  const [viewMode, setViewMode] = useState("both")
  const [chartType, setChartType] = useState("bar")
  const [monthlyForecasts, setMonthlyForecasts] = useState([])
  const [selectedMetric, setSelectedMetric] = useState(statusTypes[0].key)
  const [filtersChanged, setFiltersChanged] = useState(false)
  const [monthlyTargets, setMonthlyTargets] = useState([])
  const [selectedUniversities, setSelectedUniversities] = useState([])
  const [selectedDestinations, setSelectedDestinations] = useState([])
  const [selectedStudyLevels, setSelectedStudyLevels] = useState([])
  const [allInstitutions, setAllInstitutions] = useState([])

  const hasAccess = permissions.CanAccessAdmissionProjectionReport

  useEffect(() => {
    if (hasAccess) {
      const fetchBranches = async () => {
        setLoadingBranches(true)
        try {
          const response = await Agent.Cache.getBranchOffices()
          if (Array.isArray(response)) {
            setBranches(
              response.map((branch) => ({
                id: branch.id.toString(),
                label: branch.name,
              })),
            )
          } else {
            console.error("Unexpected response format for branch offices:", response)
          }
        } catch (err) {
          console.error("Error fetching branch offices:", err)
          setError("Failed to fetch branch offices. Please try again later.")
        } finally {
          setLoadingBranches(false)
        }
      }

      fetchBranches()
    }
  }, [hasAccess])

  useEffect(() => {
    if (allInstitutions.length === 0) {
      Agent.Universities.list()
        .then((universities) => {
          setAllInstitutions(universities)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [allInstitutions.length])

  const fetchData = useCallback(async () => {
    if (!hasAccess) return

    setLoading(true)
    setError(null)
    try {
      const params = {
        intakeYears,
        intakeMonths,
        startDate,
        endDate,
        sources: sources.map((source) => source.id),
        branches: selectedBranches.map((branchId) => Number.parseInt(branchId, 10)),
        year: year ? [year] : [],
        citizenship,
        type,
        university: selectedUniversities.map((uni) => uni.id),
        destination: selectedDestinations.map((dest) => dest.id),
        studylevel: selectedStudyLevels.map((level) => level.id.toString()),
      }

      const [progressResponse, forecastResponse, targetResponse] = await Promise.all([
        Agent.Report.admissionProjection(params),
        Agent.Report.admissionProjectionForecast(params),
        Agent.Report.admissionProjectionTarget(params),
      ])

      if (Array.isArray(progressResponse) && progressResponse.length > 0) {
        setData(progressResponse)
      } else {
        setError("No data available for the selected criteria.")
      }

      if (Array.isArray(forecastResponse) && forecastResponse.length > 0) {
        setMonthlyForecasts(forecastResponse)
      }

      if (Array.isArray(targetResponse) && targetResponse.length > 0) {
        setMonthlyTargets(targetResponse)
      }
    } catch (err) {
      const errorMessage = err.message || "Failed to fetch data. Please try again."
      setError(errorMessage)
      // Only show error popup for permission-related errors
      setShowErrorPopup(errorMessage.includes("permission") || errorMessage.includes("access"))
      console.error("Error fetching data:", err)
    } finally {
      setLoading(false)
    }
  }, [
    hasAccess,
    intakeYears,
    intakeMonths,
    startDate,
    endDate,
    sources,
    selectedBranches,
    year,
    citizenship,
    type,
    selectedUniversities,
    selectedDestinations,
    selectedStudyLevels,
  ])

  const handleCloseErrorPopup = () => {
    setShowErrorPopup(false)
    setError(null)
  }

  const handleGenerateReport = () => {
    if (selectedBranches.length === 0) {
      setError("Please select at least one branch before generating the report.")
      return
    }
    setLoading(true)
    fetchData()
  }

  const handleResetFilters = () => {
    setIntakeYears([])
    setIntakeMonths([])
    setStartDate("")
    setEndDate("")
    setSources([])
    setSelectedBranches([])
    setYear("")
    setCitizenship([])
    setData([])
    setError(null)
    setShowErrorPopup(false)
    setType(1)
    setMonthlyForecasts([])
    setSelectedUniversities([])
    setSelectedDestinations([])
    setSelectedStudyLevels([])
    setFiltersChanged(false)
  }

  const handleFilterChange = () => {
    setFiltersChanged(true)
  }

  const handleViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode)
    }
  }

  const handleChartTypeChange = (newType) => {
    setChartType(newType)
  }

  if (!hasAccess) {
    return (
      <Paper elevation={3} className="p-6 bg-gray-50">
        <ErrorPopup error="You do not have permission to access the Admission Projection Report!" />
      </Paper>
    )
  }

  return (
    <div className="bg-gray-100 min-h-screen p-4 md:p-8">
      <div className="w-full mx-auto px-2 sm:px-4">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl md:text-3xl font-bold text-gray-800">
            Admission Projection Report ({type === 1 ? "Overall" : type === 2 ? "B2C" : "B2B"})
          </h1>
          <Link to="/reportpanel" className="flex items-center text-blue-600 hover:text-blue-800">
            <ArrowBackIcon className="h-5 w-5 mr-1" />
            Back to Reporting Panel
          </Link>
        </div>
        <div className="bg-white rounded-lg shadow-md p-2 md:p-4">
          <div className="mb-6">
            <div className="flex justify-between items-center">
              <FormControl size="small" className="min-w-[150px]">
                <InputLabel id="type-select-label">Student Type</InputLabel>
                <Select
                  labelId="type-select-label"
                  id="type-select"
                  value={type}
                  label="Report Type"
                  onChange={(e) => {
                    setType(e.target.value)
                    handleFilterChange()
                  }}
                >
                  <MenuItem value={1}>Overall</MenuItem>
                  <MenuItem value={2}>B2C</MenuItem>
                  <MenuItem value={3}>B2B</MenuItem>
                </Select>
              </FormControl>
              <div className="flex items-center space-x-2">
                <ToggleButtonGroup value={viewMode} exclusive onChange={handleViewModeChange} aria-label="view mode">
                  <ToggleButton value="graph" aria-label="graph view">
                    <BarChartIcon />
                  </ToggleButton>
                  <ToggleButton value="table" aria-label="table view">
                    <TableChartIcon />
                  </ToggleButton>
                  <ToggleButton value="both" aria-label="both views">
                    <BarChartIcon />
                    <TableChartIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
                <button
                  onClick={() => setShowFilters(!showFilters)}
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <FilterListIcon className="w-5 h-5 mr-2" />
                  {showFilters ? "Hide Filters" : "Show Filters"}
                </button>
              </div>
            </div>
          </div>
          {showFilters && (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Start Date"
                    type="date"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value)
                      handleFilterChange()
                    }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="End Date"
                    type="date"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value)
                      handleFilterChange()
                    }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="year-select-label">Year</InputLabel>
                    <Select
                      labelId="year-select-label"
                      id="year-select"
                      value={year}
                      label="Year"
                      onChange={(e) => {
                        setYear(e.target.value)
                        handleFilterChange()
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {intakeYearList.map((y) => (
                        <MenuItem key={y.year} value={y.year.toString()}>
                          {y.year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="intake-years-label">Intake Years</InputLabel>
                    <Select
                      labelId="intake-years-label"
                      id="intake-years"
                      multiple
                      value={intakeYears}
                      label="Intake Years"
                      onChange={(e) => {
                        setIntakeYears(e.target.value)
                        handleFilterChange()
                      }}
                      renderValue={(selected) => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={value} size="small" />
                          ))}
                        </Box>
                      )}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: ITEM_HEIGHT * 10.5 + ITEM_PADDING_TOP,
                            width: 250,
                          },
                        },
                      }}
                    >
                      {intakeYearList.map((year) => (
                        <MenuItem key={year.year} value={year.year.toString()}>
                          <Checkbox checked={intakeYears.indexOf(year.year.toString()) > -1} />
                          <ListItemText primary={year.year} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="intake-month-label">Intake Months</InputLabel>
                    <Select
                      labelId="intake-month-label"
                      id="intake-month"
                      multiple
                      value={intakeMonths}
                      label="Intake Months"
                      onChange={(e) => {
                        setIntakeMonths(e.target.value)
                        handleFilterChange()
                      }}
                      renderValue={(selected) => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={INTAKES.find((intake) => intake.id.toString() === value)?.type}
                              size="small"
                            />
                          ))}
                        </Box>
                      )}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: ITEM_HEIGHT * 12 + ITEM_PADDING_TOP,
                            width: 250,
                          },
                        },
                      }}
                    >
                      {INTAKES.map((intake) => (
                        <MenuItem key={intake.id} value={intake.id.toString()}>
                          <Checkbox checked={intakeMonths.indexOf(intake.id.toString()) > -1} />
                          <ListItemText primary={intake.type} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    multiple
                    id="sources"
                    options={STUDENTSOURCES.map((source) => ({
                      id: source.id.toString(),
                      label: source.type,
                    }))}
                    value={sources}
                    onChange={(_, newValue) => {
                      setSources(newValue)
                      handleFilterChange()
                    }}
                    renderInput={(params) => <TextField {...params} label="Sources" placeholder="Select sources" />}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={index}
                          variant="outlined"
                          label={option.label}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    size="small"
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Autocomplete
                    multiple
                    id="citizenship"
                    options={Countries.map((country) => ({
                      id: country.id.toString(),
                      label: country.name,
                    }))}
                    value={citizenship.map((id) => ({
                      id,
                      label: Countries.find((c) => c.id.toString() === id)?.name || id,
                    }))}
                    onChange={(_, newValue) => {
                      setCitizenship(newValue.map((v) => v.id))
                      handleFilterChange()
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Citizenship" placeholder="Select citizenship" />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={index}
                          variant="outlined"
                          label={option.label}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    multiple
                    id="universities-filter"
                    options={allInstitutions}
                    getOptionLabel={(option) => option.name}
                    value={selectedUniversities}
                    onChange={(_, newValue) => {
                      setSelectedUniversities(newValue)
                      handleFilterChange()
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Universities" placeholder="Select universities" />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip key={index} label={option.name} size="small" {...getTagProps({ index })} />
                      ))
                    }
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    multiple
                    id="destinations-filter"
                    options={Countries}
                    getOptionLabel={(option) => option.name}
                    value={selectedDestinations}
                    onChange={(_, newValue) => {
                      setSelectedDestinations(newValue)
                      handleFilterChange()
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Destinations" placeholder="Select destinations" />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip key={index} label={option.name} size="small" {...getTagProps({ index })} />
                      ))
                    }
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="study-level-label">Study Level</InputLabel>
                    <Select
                      labelId="study-level-label"
                      id="study-level"
                      multiple
                      value={selectedStudyLevels}
                      label="Study Level"
                      onChange={(e) => {
                        setSelectedStudyLevels(e.target.value)
                        handleFilterChange()
                      }}
                      renderValue={(selected) => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip
                              key={value.id}
                              label={value.type}
                              size="small"
                              onDelete={() => {
                                setSelectedStudyLevels(selectedStudyLevels.filter((level) => level.id !== value.id))
                                handleFilterChange()
                              }}
                            />
                          ))}
                        </Box>
                      )}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: ITEM_HEIGHT * 10.5 + ITEM_PADDING_TOP,
                            width: 250,
                          },
                        },
                      }}
                    >
                      {STUDY_LEVELS.filter((level) => level.display).map((level) => (
                        <MenuItem key={level.id} value={level}>
                          <Checkbox checked={selectedStudyLevels.some((selected) => selected.id === level.id)} />
                          <ListItemText primary={level.type} />
                        </MenuItem>
                      ))}
                      {selectedStudyLevels.length > 0 && (
                        <MenuItem
                          sx={{ justifyContent: "center", borderTop: "1px solid #ccc" }}
                          onClick={() => {
                            setSelectedStudyLevels([])
                            handleFilterChange()
                          }}
                        >
                          <ListItemText primary="Clear Selection" />
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Autocomplete
                    multiple
                    id="branches"
                    options={branches}
                    loading={loadingBranches}
                    value={selectedBranches.map((id) => branches.find((b) => b.id === id) || { id, label: id })}
                    onChange={(_, newValue) => {
                      setSelectedBranches(newValue.map((v) => v.id))
                      handleFilterChange()
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Branches"
                        placeholder={loadingBranches ? "Loading branches..." : "Select branches"}
                        required
                        error={selectedBranches.length === 0}
                        helperText={selectedBranches.length === 0 ? "At least one branch is required" : ""}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingBranches ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={index}
                          variant="outlined"
                          label={option.label}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    size="small"
                  />
                </Grid>
              </Grid>
              {error && !showErrorPopup && (
                <div className="mt-4 p-3 bg-red-50 border-l-4 border-red-500 text-red-700">
                  <p className="font-medium">Error</p>
                  <p>{error}</p>
                </div>
              )}
              {showErrorPopup && (
                <Paper elevation={3} className="mt-6 p-4">
                  <ErrorPopup error={error} onClose={handleCloseErrorPopup} />
                </Paper>
              )}
              <div className="mt-6 flex justify-end space-x-4">
                <button
                  onClick={handleResetFilters}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                >
                  Reset Filters
                </button>
                <button
                  onClick={handleGenerateReport}
                  disabled={loading || !filtersChanged || selectedBranches.length === 0}
                  className={`px-4 py-2 text-sm font-medium text-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                    loading || !filtersChanged || selectedBranches.length === 0
                      ? "bg-gray-300 cursor-not-allowed"
                      : "bg-blue-600 hover:bg-blue-700"
                  }`}
                >
                  {loading ? "Generating..." : "Generate Report"}
                </button>
              </div>
            </>
          )}

          {loading ? (
            <Paper elevation={3} className="mt-6 p-4">
              <Typography variant="body1" className="mb-2 text-center">
                Generating report...
              </Typography>
              <LoadingAnimation />
            </Paper>
          ) : error ? (
            <Paper elevation={3} className="mt-6 p-4">
              {showErrorPopup && <ErrorPopup error={error} onClose={handleCloseErrorPopup} />}
            </Paper>
          ) : (
            !loading &&
            !error &&
            data.length > 0 && (
              <Paper elevation={3} className="mt-6 overflow-x-auto max-w-full shadow-lg rounded-lg">
                {(viewMode === "graph" || viewMode === "both") && (
                  <div className="mt-8 mb-8 w-full">
                    <ProjectionComparisonGraph
                      data={data}
                      statusTypes={statusTypes}
                      months={months}
                      monthlyTargets={monthlyTargets}
                      monthlyForecasts={monthlyForecasts}
                      selectedMetric={selectedMetric}
                      onMetricChange={(metric) => setSelectedMetric(metric)}
                      chartType={chartType}
                      onChartTypeChange={handleChartTypeChange}
                    />
                  </div>
                )}
                {(viewMode === "table" || viewMode === "both") && (
                  <div className="overflow-x-auto">
                    <table className="w-full text-xs border-collapse">
                      <thead>
                        <tr>
                          <th className="py-3 px-2 text-left sticky left-0 z-20 bg-gray-100 font-bold text-sm border-b-2 border-r-2 border-gray-300 w-40">
                            Status Type
                          </th>
                          {months.map((month) => (
                            <th
                              key={month}
                              className="py-2 px-1 text-center font-bold text-xs border-b-2 border-gray-300 bg-blue-200"
                              colSpan={3}
                            >
                              {month.slice(0, 3)}
                            </th>
                          ))}
                          <th
                            className="py-2 px-1 text-center font-bold text-xs border-b-2 border-gray-300 bg-amber-200"
                            colSpan={3}
                          >
                            Total
                          </th>
                        </tr>
                        <tr>
                          <th className="py-2 px-2 text-left sticky left-0 z-20 bg-gray-100 font-bold text-xs border-b-2 border-r-2 border-gray-300"></th>
                          {[...months, "Total"].map((month) => (
                            <React.Fragment key={`header-${month}`}>
                              <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-red-100">
                                Target
                              </th>
                              <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-green-100">
                                Achieve
                              </th>
                              <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-blue-100">
                                Forecast
                              </th>
                            </React.Fragment>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {statusTypes.map((status, index) => {
                          const totalTarget =
                            monthlyTargets.reduce((sum, item) => sum + (Number.parseFloat(item[status.key]) || 0), 0) /
                            12
                          const totalAchieve =
                            data.reduce((sum, item) => sum + (Number.parseFloat(item[status.key]) || 0), 0) / 12
                          const totalForecast =
                            monthlyForecasts.reduce(
                              (sum, item) => sum + (Number.parseFloat(item[status.key]) || 0),
                              0,
                            ) / 12

                          return (
                            <tr
                              key={status.id}
                              className={index % 2 === 0 ? "bg-white hover:bg-sky-50" : "bg-gray-50 hover:bg-sky-100"}
                            >
                              <td
                                className="py-2 px-2 font-semibold text-xs sticky left-0 z-10 border-r border-gray-300"
                                style={{
                                  backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F9FAFB",
                                }}
                              >
                                {status.type}
                              </td>
                              {months.map((month, monthIndex) => {
                                const monthData = data.find((d) => d.month === monthIndex + 1) || {}
                                const forecastData = monthlyForecasts.find((f) => f.month === monthIndex + 1) || {}
                                const targetData = monthlyTargets.find((t) => t.month === monthIndex + 1) || {}
                                return (
                                  <React.Fragment key={`${status.id}-${month}`}>
                                    <td className="py-1 px-1 text-center border-r border-gray-300">
                                      <div className="font-medium text-red-600 text-xs">
                                        {formatValue(targetData[status.key])}
                                      </div>
                                    </td>
                                    <td className="py-1 px-1 text-center border-r border-gray-300">
                                      <div className="font-medium text-green-600 text-xs">
                                        {formatValue(monthData[status.key])}
                                      </div>
                                    </td>
                                    <td className="py-1 px-1 text-center border-r border-gray-300">
                                      <div className="font-medium text-blue-600 text-xs">
                                        {formatValue(forecastData[status.key])}
                                      </div>
                                    </td>
                                  </React.Fragment>
                                )
                              })}
                              <td className="py-1 px-1 text-center border-r border-gray-300">
                                <div className="font-medium text-red-600 text-xs">{formatValue(totalTarget)}</div>
                              </td>
                              <td className="py-1 px-1 text-center border-r border-gray-300">
                                <div className="font-medium text-green-600 text-xs">{formatValue(totalAchieve)}</div>
                              </td>
                              <td className="py-1 px-1 text-center border-r border-gray-300">
                                <div className="font-medium text-blue-600 text-xs">{formatValue(totalForecast)}</div>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </Paper>
            )
          )}
          {!loading && !error && data.length === 0 && (
            <Paper elevation={3} className="mt-6 p-4">
              <Typography variant="body1" className="text-center">
                No data available. Please adjust your filters and generate the report.
              </Typography>
            </Paper>
          )}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  permissions: state.auth.permissions,
})

export default connect(mapStateToProps)(AdmissionProjectionReport)

