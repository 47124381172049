/* eslint-disable import/namespace */
import React from "react"
import { Card, CardContent, Typography, Box } from "@mui/material"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
} from "chart.js"
import { Bar, Pie, Line } from "react-chartjs-2"
import { School, TrendingUp, Assignment } from "@mui/icons-material"

// Register ChartJS components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, PointElement, LineElement)

export const ApplicationsBarChart = ({ universities, chartType, orderBy = 2, openUniversityDetails }) => {
  // Sort universities based on orderBy parameter
  const sortedUniversities = [...universities]

  // Map orderBy values to corresponding properties
  const orderByMap = {
    1: "UniId",
    2: "applied",
    3: "conditionalOffer",
    4: "unconditionalOffer",
    5: "appWithdrawal",
    6: "rejection",
    7: "deposite",
    8: "numOfCAS",
    9: "enrolled",
    10: "visaIssued",
  }

  const sortProperty = orderByMap[orderBy] || "applied"
  sortedUniversities.sort((a, b) => b[sortProperty] - a[sortProperty])

  // Take top 10 universities
  const topUniversities = sortedUniversities.slice(0, 10)

  // Get chart title based on orderBy
  const getTitleByOrderBy = () => {
    switch (Number.parseInt(orderBy)) {
      case 1:
        return "Top 10 Universities by ID"
      case 2:
        return "Top 10 Universities by Applications"
      case 3:
        return "Top 10 Universities by Conditional Offers"
      case 4:
        return "Top 10 Universities by Unconditional Offers"
      case 5:
        return "Top 10 Universities by Application Withdrawals"
      case 6:
        return "Top 10 Universities by Rejections"
      case 7:
        return "Top 10 Universities by Deposits"
      case 8:
        return "Top 10 Universities by CAS Issued"
      case 9:
        return "Top 10 Universities by Enrollments"
      case 10:
        return "Top 10 Universities by Visas Issued"
      default:
        return "Top 10 Universities by Applications"
    }
  }

  const data = {
    labels: topUniversities.map((uni) => uni.uniName),
    datasets: [
      {
        label: getTitleByOrderBy().replace("Top 10 Universities by ", ""),
        data: topUniversities.map((uni) => uni[sortProperty]),
        backgroundColor: "rgba(79, 70, 229, 0.8)",
        borderColor: "rgba(79, 70, 229, 1)",
        borderWidth: 1,
      },
    ],
  }

  const options = {
    indexAxis: "y", // This makes it a horizontal bar chart
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: getTitleByOrderBy(),
        font: {
          size: 16,
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw}`,
          footer: () => "Click for details",
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Count",
        },
      },
      y: {
        ticks: {
          callback: function (value, index) {
            // Truncate long university names
            const label = this.getLabelForValue(value)
            if (label.length > 20) {
              return label.substr(0, 20) + "..."
            }
            return label
          },
          font: {
            weight: "bold",
          },
        },
      },
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const index = elements[0].index
        if (openUniversityDetails && typeof openUniversityDetails === "function") {
          openUniversityDetails(topUniversities[index])
        }
      }
    },
  }

  return (
    <Card className="shadow-md hover:shadow-lg transition-shadow h-full">
      <CardContent>
        <div className="flex items-center mb-4">
          <School className="text-indigo-600 mr-2" />
          <Typography variant="h6" className="font-medium text-gray-800">
            {getTitleByOrderBy()}
          </Typography>
        </div>
        <Box sx={{ height: 400 }}>
          <Bar data={data} options={options} />
        </Box>
      </CardContent>
    </Card>
  )
}

export const EnrollmentPieChart = ({ universities, chartType, orderBy = 9 }) => {
  // Map orderBy values to corresponding properties and labels
  const orderByMap = {
    1: { property: "UniId", label: "University ID" },
    2: { property: "applied", label: "Applications" },
    3: { property: "conditionalOffer", label: "Conditional Offers" },
    4: { property: "unconditionalOffer", label: "Unconditional Offers" },
    5: { property: "appWithdrawal", label: "Application Withdrawals" },
    6: { property: "rejection", label: "Rejections" },
    7: { property: "deposite", label: "Deposits" },
    8: { property: "numOfCAS", label: "CAS Issued" },
    9: { property: "enrolled", label: "Enrollments" },
    10: { property: "visaIssued", label: "Visas Issued" },
  }

  const { property, label } = orderByMap[orderBy] || orderByMap[9]

  // Calculate totals by destination
  const dataByDestination = universities.reduce((acc, uni) => {
    const destination = uni.destination || "Unknown"
    if (!acc[destination]) {
      acc[destination] = 0
    }
    acc[destination] += uni[property] || 0
    return acc
  }, {})

  const data = {
    labels: Object.keys(dataByDestination),
    datasets: [
      {
        data: Object.values(dataByDestination),
        backgroundColor: [
          "rgba(79, 70, 229, 0.8)",
          "rgba(59, 130, 246, 0.8)",
          "rgba(16, 185, 129, 0.8)",
          "rgba(245, 158, 11, 0.8)",
          "rgba(239, 68, 68, 0.8)",
          "rgba(139, 92, 246, 0.8)",
        ],
        borderColor: [
          "rgba(79, 70, 229, 1)",
          "rgba(59, 130, 246, 1)",
          "rgba(16, 185, 129, 1)",
          "rgba(245, 158, 11, 1)",
          "rgba(239, 68, 68, 1)",
          "rgba(139, 92, 246, 1)",
        ],
        borderWidth: 1,
        spacing: 6, // Add spacing between segments
      },
    ],
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "right",
        labels: {
          padding: 20, // Increase padding between legend items
        },
      },
      title: {
        display: true,
        text: `${label} by Destination`,
        font: {
          size: 16,
        },
      },
      // Add doughnut chart center text plugin
      doughnutLabel: {
        labels: [
          {
            text: "Total",
            font: {
              size: 20,
              weight: "bold",
            },
          },
          {
            text: Object.values(dataByDestination)
              .reduce((a, b) => a + b, 0)
              .toString(),
            font: {
              size: 16,
            },
          },
        ],
      },
    },
    cutout: "50%", // Convert to doughnut chart
  }

  return (
    <Card className="shadow-md hover:shadow-lg transition-shadow h-full">
      <CardContent>
        <div className="flex items-center mb-4">
          <Assignment className="text-indigo-600 mr-2" />
          <Typography variant="h6" className="font-medium text-gray-800">
            {`${label} by Destination`}
          </Typography>
        </div>
        <Box sx={{ height: 400 }}>
          <Pie data={data} options={options} />
        </Box>
      </CardContent>
    </Card>
  )
}

export const ConversionRateLineChart = ({ universities, chartType, orderBy = 3 }) => {
  // Map orderBy values to corresponding properties and labels
  const orderByMap = {
    1: { property: "UniId", label: "University ID" },
    2: { property: "applied", label: "Applications" },
    3: { property: "conditionalOffer", label: "Conditional Offers" },
    4: { property: "unconditionalOffer", label: "Unconditional Offers" },
    5: { property: "appWithdrawal", label: "Application Withdrawals" },
    6: { property: "rejection", label: "Rejections" },
    7: { property: "deposite", label: "Deposits" },
    8: { property: "numOfCAS", label: "CAS Issued" },
    9: { property: "enrolled", label: "Enrollments" },
    10: { property: "visaIssued", label: "Visas Issued" },
  }

  const { property, label } = orderByMap[orderBy] || orderByMap[3]

  // Get top 5 universities for the selected metric
  const topUniversities = [...universities].sort((a, b) => b[property] - a[property]).slice(0, 5)

  // Define metrics to display
  const metrics = [
    "applied",
    "conditionalOffer",
    "unconditionalOffer",
    "appWithdrawal",
    "rejection",
    "deposite",
    "numOfCAS",
    "enrolled",
    "visaIssued",
  ]

  const metricLabels = [
    "Applications",
    "Conditional Offers",
    "Unconditional Offers",
    "Withdrawals",
    "Rejections",
    "Deposits",
    "CAS",
    "Enrolled",
    "Visas",
  ]

  // Create datasets for the chart
  const datasets = topUniversities.map((uni, index) => {
    // Generate a unique color for each university
    const hue = (index * 60) % 360
    const color = `hsla(${hue}, 80%, 50%, 1)`

    return {
      label: uni.uniName,
      data: metrics.map((metric) => uni[metric] || 0),
      borderColor: color,
      backgroundColor: `hsla(${hue}, 80%, 50%, 0.1)`,
      borderWidth: 2,
      tension: 0.3,
      pointRadius: 5,
      pointHoverRadius: 8,
    }
  })

  const data = {
    labels: metricLabels,
    datasets: datasets,
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {
          usePointStyle: true,
          padding: 15,
          font: {
            size: 12,
          },
        },
      },
      title: {
        display: true,
        text: `Top Universities Performance Metrics`,
        font: {
          size: 16,
          weight: "bold",
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw}`,
        },
        padding: 10,
        titleFont: {
          size: 14,
        },
        bodyFont: {
          size: 13,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Count",
          font: {
            weight: "bold",
          },
        },
        grid: {
          color: "rgba(0, 0, 0, 0.05)",
        },
      },
      x: {
        title: {
          display: true,
          text: "Response Metrics",
          font: {
            weight: "bold",
          },
        },
        grid: {
          display: false,
        },
        ticks: {
          font: {
            weight: "bold",
          },
        },
      },
    },
  }

  return (
    <Card className="shadow-md hover:shadow-lg transition-shadow h-full">
      <CardContent>
        <div className="flex items-center mb-4">
          <TrendingUp className="text-emerald-600 mr-2" />
          <Typography variant="h6" className="font-medium text-gray-800">
            University Performance Metrics
          </Typography>
        </div>
        <Box sx={{ height: 500 }}>
          <Line data={data} options={options} />
        </Box>
      </CardContent>
    </Card>
  )
}

