/* eslint-disable import/namespace */
import React, { useState, useCallback, useMemo } from "react"
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Autocomplete,
  Chip,
  Paper,
  Grid,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material"
import FilterListIcon from "@mui/icons-material/FilterList"
import BarChartIcon from "@mui/icons-material/BarChart"
import PieChartIcon from "@mui/icons-material/PieChart"
import ShowChartIcon from "@mui/icons-material/ShowChart"
import { Bar, Pie, Line } from "react-chartjs-2"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
} from "chart.js"

import Agent from "../../../api/agent"
import { INTAKES, intakeYearList } from "../../../Util/Constants"
import { Countries } from "../../../Util/Util"
import { motion } from "framer-motion"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, PointElement, LineElement)

const months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"]

const LoadingAnimation = () => (
  <div className="flex justify-center items-center h-64">
    <motion.div
      className="w-24 h-24 flex items-center justify-center"
      animate={{
        rotate: [0, 360],
      }}
      transition={{
        duration: 2,
        ease: "linear",
        repeat: Number.POSITIVE_INFINITY,
      }}
    >
      <motion.span
        className="block w-12 h-12 border-t-4 border-blue-500 rounded-full"
        animate={{
          scale: [1, 1.5, 1],
          opacity: [1, 0.5, 1],
        }}
        transition={{
          duration: 1.5,
          ease: "easeInOut",
          repeat: Number.POSITIVE_INFINITY,
        }}
      />
    </motion.div>
  </div>
)

const AllBranchesSourceReport = ({ country, branches }) => {
  const [intakeYears, setIntakeYears] = useState([])
  const [intakeMonths, setIntakeMonths] = useState([])
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [data, setData] = useState([])
  const [targetData, setTargetData] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [year, setYear] = useState("")
  const [citizenship, setCitizenship] = useState([])
  const [showFilters, setShowFilters] = useState(true)
  const [leadType, setLeadType] = useState("2")
  const [chartType, setChartType] = useState("bar")
  const [selectedMonth, setSelectedMonth] = useState("all")

  const fetchData = useCallback(async () => {
    setLoading(true)
    setError(null)
    try {
      const params = {
        intakeYears,
        intakeMonths,
        startDate,
        endDate,
        branches: branches.map((branch) => branch.id),
        year: year ? [year] : [],
        citizenship,
        type: Number.parseInt(leadType, 10),
      }
      const [sourceResponse, targetResponse] = await Promise.all([
        Agent.Report.leadsBySource(params),
        Agent.Report.leadsBySourceTarget(params),
      ])
      if (Array.isArray(sourceResponse) && sourceResponse.length > 0) {
        setData(sourceResponse)
        setTargetData(targetResponse)
      } else {
        setError("No data available for the selected criteria.")
      }
    } catch (err) {
      setError("Failed to fetch data. Please try again.")
      console.error("Error fetching data:", err)
    } finally {
      setLoading(false)
    }
  }, [intakeYears, intakeMonths, startDate, endDate, branches, year, citizenship, leadType])

  const handleGenerateReport = () => {
    fetchData()
  }

  const handleResetFilters = () => {
    setIntakeYears([])
    setIntakeMonths([])
    setStartDate("")
    setEndDate("")
    setYear("")
    setCitizenship([])
    setLeadType("2")
    setData([])
    setTargetData([])
    setError(null)
    setSelectedMonth("all")
  }

  const calculateTotal = (source) => {
    if (!source) return 0
    return months.reduce((sum, month) => sum + (source[month] || 0), 0)
  }

  const formatValue = (value) => {
    return value > 0 ? value.toString() : ""
  }

  const chartData = useMemo(() => {
    if (data.length === 0) return null

    const labels = data.map((item) => item.source)
    const achievementData = data.map((item) =>
      selectedMonth === "all" ? calculateTotal(item) : item[selectedMonth] || 0,
    )
    const targetDataValues = targetData.map((item) =>
      selectedMonth === "all" ? calculateTotal(item) : item[selectedMonth] || 0,
    )

    return {
      labels,
      datasets: [
        {
          label: "Target",
          data: targetDataValues,
          backgroundColor: "rgba(255, 99, 132, 0.6)",
          borderColor: "rgba(255, 99, 132, 1)",
          borderWidth: 1,
        },
        {
          label: "Achievement",
          data: achievementData,
          backgroundColor: "rgba(75, 192, 192, 0.6)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
      
      ],
    }
  }, [data, targetData, selectedMonth])

  const pieChartData = useMemo(() => {
    if (data.length === 0) return null

    const labels = data.map((item) => item.source)
    const achievementData = data.map((item) =>
      selectedMonth === "all" ? calculateTotal(item) : item[selectedMonth] || 0,
    )

    return {
      labels,
      datasets: [
        {
          data: achievementData,
          backgroundColor: [
            "rgba(255, 99, 132, 0.6)",
            "rgba(54, 162, 235, 0.6)",
            "rgba(255, 206, 86, 0.6)",
            "rgba(75, 192, 192, 0.6)",
            "rgba(153, 102, 255, 0.6)",
            "rgba(255, 159, 64, 0.6)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
          ],
          borderWidth: 1,
        },
      ],
    }
  }, [data, selectedMonth])

  const lineChartData = useMemo(() => {
    if (data.length === 0) return null

    return {
      labels: months,
      datasets: data.map((item, index) => ({
        label: item.source,
        data: months.map((month) => item[month] || 0),
        borderColor: `hsl(${index * 30}, 70%, 50%)`,
        backgroundColor: `hsla(${index * 30}, 70%, 50%, 0.5)`,
        fill: false,
      })),
    }
  }, [data])

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: `Source Performance Comparison - ${selectedMonth.toUpperCase()} ${year}`,
      },
    },
  }

  const pieChartOptions = {
    ...chartOptions,
    plugins: {
      ...chartOptions.plugins,
      legend: {
        position: "right",
      },
    },
  }

  const lineChartOptions = {
    ...chartOptions,
    plugins: {
      ...chartOptions.plugins,
      title: {
        ...chartOptions.plugins.title,
        text: `Source Performance Trend - ${year}`,
      },
    },
  }

  return (
    <Paper elevation={3} className="p-6 bg-gray-50">
      <div className="flex justify-between items-center mb-6">
        <Typography variant="h5" component="h2" className="text-gray-800 font-bold">
          All Branches Source Report: {country.toUpperCase()}
        </Typography>
        <div className="flex items-center space-x-4">
          <ToggleButtonGroup
            value={chartType}
            exclusive
            onChange={(event, newChartType) => {
              if (newChartType !== null) {
                setChartType(newChartType)
              }
            }}
            aria-label="chart type"
          >
            <ToggleButton value="bar" aria-label="bar chart">
              <BarChartIcon />
            </ToggleButton>
            <ToggleButton value="pie" aria-label="pie chart">
              <PieChartIcon />
            </ToggleButton>
            <ToggleButton value="line" aria-label="line chart">
              <ShowChartIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          <button
            onClick={() => setShowFilters(!showFilters)}
            className="flex items-center px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <FilterListIcon className="w-5 h-5 mr-1" />
            {showFilters ? "Hide Filters" : "Show Filters"}
          </button>
        </div>
      </div>

      <div className="mb-6">
        <div className="flex flex-wrap gap-2">
          <button
            onClick={() => setSelectedMonth("all")}
            className={`px-3 py-2 text-sm rounded-full ${
              selectedMonth === "all" ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-700 hover:bg-gray-300"
            }`}
          >
            All
          </button>
          {months.map((month) => (
            <button
              key={month}
              onClick={() => setSelectedMonth(month)}
              className={`px-3 py-2 text-sm rounded-full ${
                selectedMonth === month ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-700 hover:bg-gray-300"
              }`}
            >
              {month.toUpperCase()}
            </button>
          ))}
        </div>
      </div>

      {showFilters && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <TextField
                label="Start Date"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                label="End Date"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth size="small">
                <InputLabel id="year-select-label">Year</InputLabel>
                <Select
                  labelId="year-select-label"
                  id="year-select"
                  value={year}
                  label="Year"
                  onChange={(e) => setYear(e.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {intakeYearList.map((y) => (
                    <MenuItem key={y.year} value={y.year.toString()}>
                      {y.year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth size="small">
                <InputLabel id="lead-type-select-label">Lead Type</InputLabel>
                <Select
                  labelId="lead-type-select-label"
                  id="lead-type-select"
                  value={leadType}
                  label="Lead Type"
                  onChange={(e) => setLeadType(e.target.value)}
                >
                  <MenuItem value="1">Paid Leads</MenuItem>
                  <MenuItem value="2">Organic Leads</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                id="intake-years"
                options={intakeYearList.map((year) => ({ id: year.year.toString(), label: year.year.toString() }))}
                value={intakeYears.map((id) => ({ id, label: id }))}
                onChange={(_, newValue) => setIntakeYears(newValue.map((v) => v.id))}
                renderInput={(params) => (
                  <TextField {...params} label="Intake Years" placeholder="Select intake years" />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option.id}
                      variant="outlined"
                      label={option.label}
                      size="small"
                      {...getTagProps({ index })}
                    />
                  ))
                }
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                id="intake-months"
                options={[
                  { id: "", label: "All Months" },
                  ...INTAKES.map((month) => ({ id: month.id.toString(), label: month.type })),
                ]}
                value={intakeMonths.map((id) => ({
                  id,
                  label: INTAKES.find((m) => m.id.toString() === id)?.type || id,
                }))}
                onChange={(_, newValue) => setIntakeMonths(newValue.map((v) => v.id))}
                renderInput={(params) => (
                  <TextField {...params} label="Intake Months" placeholder="Select intake months" />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option.id}
                      variant="outlined"
                      label={option.label}
                      size="small"
                      {...getTagProps({ index })}
                    />
                  ))
                }
                size="small"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                multiple
                id="citizenship"
                options={Countries.map((country) => ({ id: country.id.toString(), label: country.name }))}
                value={citizenship.map((id) => ({
                  id,
                  label: Countries.find((c) => c.id.toString() === id)?.name || id,
                }))}
                onChange={(_, newValue) => setCitizenship(newValue.map((v) => v.id))}
                renderInput={(params) => <TextField {...params} label="Citizenship" placeholder="Select citizenship" />}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option.id}
                      variant="outlined"
                      label={option.label}
                      size="small"
                      {...getTagProps({ index })}
                    />
                  ))
                }
                size="small"
              />
            </Grid>
          </Grid>

          <div className="mt-6 flex justify-end space-x-4">
            <button
              onClick={handleResetFilters}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Reset Filters
            </button>
            <button
              onClick={handleGenerateReport}
              disabled={loading}
              className={`px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              {loading ? "Generating..." : "Generate Report"}
            </button>
          </div>
        </>
      )}

      {error && (
        <Paper elevation={2} className="mt-4 p-4 bg-red-50">
          <Typography color="error">{error}</Typography>
        </Paper>
      )}

      {loading ? (
        <Paper elevation={3} className="mt-6 p-4">
          <Typography variant="body1" className="mb-2 text-center">
            Generating report...
          </Typography>
          <LoadingAnimation />
        </Paper>
      ) : (
        !error &&
        data.length > 0 && (
          <>
            <Paper elevation={3} className="mt-6 p-4">
              <div
                style={{
                  height: chartType === "pie" ? "600px" : "700px",
                  width: chartType === "pie" ? "50%" : "100%",
                  margin: "0 auto",
                }}
              >
                {chartType === "bar" && <Bar data={chartData} options={chartOptions} />}
                {chartType === "pie" && <Pie data={pieChartData} options={pieChartOptions} />}
                {chartType === "line" && <Line data={lineChartData} options={lineChartOptions} />}
              </div>
            </Paper>
            <Paper elevation={3} className="mt-6 overflow-x-auto max-w-full shadow-lg rounded-lg">
              <div className="overflow-x-auto">
                <table className="w-full text-xs border-collapse">
                  <thead className="bg-gray-100">
                    <tr>
                      <th
                        className="py-3 px-2 text-left sticky left-0 z-20 bg-gray-100 font-bold text-sm border-b-2 border-r-2 border-gray-300 w-40"
                        rowSpan={2}
                      >
                        Lead Source
                      </th>
                      {months.map((month) => (
                        <th
                          key={month}
                          className="py-2 px-1 text-center font-bold text-xs border-b-2 border-gray-300 bg-blue-200"
                          colSpan={2}
                        >
                          {month.toUpperCase()}
                        </th>
                      ))}
                      <th
                        className="py-2 px-1 text-center font-bold text-xs border-b-2 border-gray-300 bg-amber-200"
                        colSpan={2}
                      >
                        Total
                      </th>
                    </tr>
                    <tr>
                      {months.map((month) => (
                        <React.Fragment key={`${month}-header`}>
                          <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-red-100">
                            Target
                          </th>
                          <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-green-100">
                            Achieve
                          </th>
                        </React.Fragment>
                      ))}
                      <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-red-100">
                        Target
                      </th>
                      <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-green-100">
                        Achieve
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((source, index) => (
                      <tr
                        key={source.source}
                        className={index % 2 === 0 ? "bg-white hover:bg-sky-50" : "bg-gray-50 hover:bg-sky-100"}
                      >
                        <td
                          className="py-2 px-2 font-semibold text-xs sticky left-0 z-10 border-r border-gray-300"
                          style={{ backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F9FAFB" }}
                        >
                          {source.source.trim()}
                        </td>
                        {months.map((month) => (
                          <React.Fragment key={`${source.source}-${month}`}>
                            <td className="py-1 px-1 text-center border-r border-gray-300">
                              <div className="font-medium text-red-600 text-xs">
                                {formatValue(targetData[index]?.[month])}
                              </div>
                            </td>
                            <td className="py-1 px-1 text-center border-r border-gray-300">
                              <div className="font-medium text-green-600 text-xs">{formatValue(source[month])}</div>
                            </td>
                          </React.Fragment>
                        ))}
                        <td className="py-1 px-1 text-center border-r border-gray-300">
                          <div className="font-medium text-red-600 text-xs">
                            {formatValue(calculateTotal(targetData[index]))}
                          </div>
                        </td>
                        <td className="py-1 px-1 text-center border-r border-gray-300">
                          <div className="font-medium text-green-600 text-xs">
                            {formatValue(calculateTotal(source))}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Paper>
          </>
        )
      )}
    </Paper>
  )
}

export default AllBranchesSourceReport

