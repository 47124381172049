import { Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ErrorMessage, Form, Formik } from 'formik';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import React, { useState } from 'react';
import Agent from '../../../api/agent';
import MyTextInput from '../../Common/Form/MyTextInput';
import PhotoUploadWidget from '../../Common/ImageUpload/PhotoUploadWidget';
import AgentCompanyLogo from './AgentCompanyLogo';
import * as Yup from 'yup';
import Agreements from './Agreements';
import LinearIndeterminate from '../../Common/Progress/LinearIndeterminate';
import Documents from '../../Common/Documents/Documents';
import MyCountryListFormik from '../../Common/Form/MyCountryListFormik';
import { LoadingButton } from '@mui/lab';
import SocialMediaForm from './SocialMedia/SocialMediaForm';
import * as actions from '../../../store/actions';
import {
  Facebook,
  Twitter,
  LinkedIn,
  YouTube,
  Language,
} from '@mui/icons-material';
import { connect } from 'react-redux';

const PREFIX = 'AgentGeneralInfo';

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  paper: `${PREFIX}-paper`,
  agentInfo: `${PREFIX}-agentInfo`,
  form: `${PREFIX}-form`,
  documentContainer: `${PREFIX}-documentContainer`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingRight: '20px',
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },

  [`& .${classes.agentInfo}`]: {
    width: '100%',
  },

  [`& .${classes.form}`]: {
    paddingLeft: 0,
  },

  [`& .${classes.documentContainer}`]: {
    paddingTop: -15,
    marginLeft: 12,
    border: '1px solid gray',
    borderRadius: 5,
  },
}));

const AgentGeneralInfo = (props) => {
  const [agent, setAgent] = useState({ ...props.agent });
  const [urlDialogOpen, setUrlDialogOpen] = useState(false);

  const [socialMediaUrls, setSocialMediaUrls] = useState({
    facebookUrl: agent.agentSocialMediaInfos[0]?.faceBookURL || '',
    twitterUrl: agent.agentSocialMediaInfos[0]?.twitterURL || '',
    linkedinUrl: agent.agentSocialMediaInfos[0]?.linkedInURL || '',
    youTubeURL: agent.agentSocialMediaInfos[0]?.youTubeURL || '',
    otherUrl: agent.agentSocialMediaInfos[0]?.othersURL || '',
  });

  const handleChange = (event, fieldName) => {
    setSocialMediaUrls({
      ...socialMediaUrls,
      [fieldName]: event.target.value,
    });
  };

  const handleSaveUrls = async () => {
    try {
      let agentId = agent?.id;
      let updatePayload = {};
  
      // Get the current date and format it
      const currentDate = new Date().toISOString().slice(0, 23);
  
      // Check if any of the social media URLs have been updated
      if (
        socialMediaUrls.facebookUrl ||
        socialMediaUrls.twitterUrl ||
        socialMediaUrls.linkedinUrl ||
        socialMediaUrls.youTubeURL ||
        socialMediaUrls.otherUrl
      ) {
        updatePayload = {
          FaceBookURL:
            socialMediaUrls.facebookUrl ||
            agent.agentSocialMediaInfos[0]?.faceBookURL,
          TwitterURL:
            socialMediaUrls.twitterUrl ||
            agent.agentSocialMediaInfos[0]?.twitterURL,
          LinkedInURL:
            socialMediaUrls.linkedinUrl ||
            agent.agentSocialMediaInfos[0]?.linkedInURL,
          YouTubeURL:
            socialMediaUrls.youTubeURL ||
            agent.agentSocialMediaInfos[0]?.youTubeURL,
          OthersUrl:
            socialMediaUrls.otherUrl ||
            agent.agentSocialMediaInfos[0]?.othersURL,
          AgentId: agentId,
          Id:
            agent.agentSocialMediaInfos.length > 0
              ? agent.agentSocialMediaInfos[0].id
              : 0,
          AddedBy:
            agent.agentSocialMediaInfos[0]?.addedBy !== null
              ? agent.agentSocialMediaInfos[0]?.addedBy
              : agent.agentSocialMediaInfos[0]?.addedBy,   //as the app user id already providing  from api
          AddedOn:
            agent.agentSocialMediaInfos[0]?.addedOn !== null
              ? agent.agentSocialMediaInfos[0]?.addedOn
              : currentDate,
        };
      } else {
        updatePayload = {
          AgentId: agentId,
        };
      }
  
      const res = await Agent.Agents.saveSocialMedia(updatePayload);
      props.updateAlert('Saved successfully', true);
    } catch (error) {
      console.log(error);
      props.updateAlert('Failed to save', false);
    } finally {
      setUrlDialogOpen(false);
    }
  };
  
  

  const handleUrlDialogOpen = () => {
    setUrlDialogOpen(true);
  };

  const handleUrlDialogClose = () => {
    setUrlDialogOpen(false);
  };

  const [files, setFiles] = useState(
    agent.agentCloudFiles
      .filter((file) => file.documentType == 1)
      .map((item) => ({ ...item, state: 1 }))
  );

  var logoFiles = agent.agentCloudFiles.filter(
    (file) => file.documentType == 4
  );
  const [showLogo, setShowLogo] = useState(logoFiles.length > 0);
  const [logoUrl, setLogoUrl] = useState(
    logoFiles.length > 0 ? logoFiles[0].fileUrl : ''
  );

  const [termsAgreed, setTermsAgreed] = useState(agent.termsAgreed);
  const [dataPrivacyAgreed, setDataPrivacyAgreed] = useState(
    agent.dataPrivacyAgreed
  );
  const [updating, setUpdating] = useState(false);

  const [focused, setFocused] = useState(false);

  let initValues = {
    ...agent,
    error: '',
  };

  const validationSchema = Yup.object({
    companyName: Yup.string()
      .required('Company name is required')
      .min(3, 'Must be 3 characters or more')
      .max(100, 'Must be 100 characters or less')
      .typeError('Compnay name is required'),
    companyWebsite: Yup.string()
      .required('Company website is required')
      .min(5, 'Must be 5 characters or more')
      .max(50, 'Must be 50 characters or less')
      .typeError('Compnay website is required'),
    agentPhoneNumber: Yup.string()
      .required('Company phone number is required')
      .min(7, 'Phone number too short')
      .max(45, 'Phone number too big')
      .typeError('Please enter a valid phone number including country code'),
    companyPhoneNumber: Yup.string()
      .required('Company phone number is required')
      .min(7, 'Phone number too short')
      .max(45, 'Phone number too big')
      .typeError('Please enter a valid phone number including country code'),
    companyEmail: Yup.string()
      .email('Invalid email')
      .required('Email address is required')
      .max(60)
      .typeError('Compnay email is required'),
    applicationUpdateEmail: Yup.string()
      .email('Invalid application update email')
      .required('Application Update Email is required')
      .max(60)
      .typeError('Application Update Email is required'),
    streetAddress: Yup.string()
      .required('Street address is required')
      .max(100, 'Must be 100 characters or less')
      .typeError('Street address is required'),
    city: Yup.string()
      .required('City is required')
      .max(50, 'Must be 50 characters or less')
      .typeError('City is required'),
    agentNationality: Yup.string()
      .matches(/^\d+$/)
      .typeError('Please select a valid nationality'),
    country: Yup.string()
      .matches(/^\d+$/)
      .typeError('Please select a valid country'),
    postcode: Yup.string()
      .required('Postcode is required')
      .min(4, 'Must be 4 characters or more')
      .max(50, 'Must be 50 characters or less')
      .typeError('Postcode is required'),

    admissionPhone: Yup.string()
      // .required('admissionPhone no. is required')
      .min(4, 'Must be 6 characters or more')
      .max(50, 'Phone no. too big!')
      .typeError('Admission Phone no. is required'),
    alternativeEmail: Yup.string()
      .email('Invalid alternative email')
      // .required('alternativeEmail is required')
      .max(100)
      .typeError('alternativeEmail is required'),
    //     });
  });

  const save = async (updatedAgent) => {
    setUpdating(true);
    await Agent.Agents.updateAgent(updatedAgent)
      .then(() => {
        setUpdating(false);
        setFocused(false);
        props.setUnsaved(false);
        props.updateAlert('Saved successfully', true);
      })
      .catch((error) => {
        console.log(error);
        setUpdating(false);
        props.updateAlert('Failed to save', false);
        //debugger;
      });
  };

  async function handleFormSubmit(updatedAgent) {
    debugger;
    const modifiedAgent = {
      ...updatedAgent,
      ...{
        status: updatedAgent.status == 1 ? 2 : updatedAgent.status,
        termsAgreed,
        dataPrivacyAgreed,
      },
    };
    setUpdating(true);
    await Agent.Agents.updateAgent(modifiedAgent)
      .then(async () => {
        // we will now deal with any file add/delete
        if (files.some((file) => file.state != 1)) {
          let finalFiles = [];
          let processedFiles = 0;
          await files.forEach(async (file) => {
            processedFiles++;
            if (file.state == 1) {
              //nothing to do as original file is still intact
              finalFiles.push(file);
            } else if (file.state == 2) {
              // new file
              await Agent.CloudFiles.upload(file.file, 1, agent.id)
                .then((doc) => {
                  file.fileName = doc.blobName;
                  file.containerName = doc.containerName;
                  file.state = 1;
                  finalFiles.push(file);
                  if (processedFiles == files.length) {
                    submitSuccess(modifiedAgent, finalFiles);
                  }
                })
                .catch((error) => {
                  console.log(error);
                  setUpdating(false);
                  props.updateAlert('Failed to upload file', false);
                  return Promise.reject(error);
                });
            } else if (file.state == 3) {
              await Agent.CloudFiles.delete(file.fileName, agent.id, 0, 0)
                .then(() => {
                  console.log('file deleted');
                  if (processedFiles == files.length) {
                    submitSuccess(modifiedAgent, finalFiles);
                  }
                })
                .catch((error) => {
                  console.log(error);
                  setUpdating(false);
                  props.updateAlert('Failed to delete file', false);
                  return Promise.reject(error);
                });
            }
          });
        } else {
          submitSuccess(modifiedAgent, files);
        }
      })
      .catch((error) => {
        console.log(error);
        setUpdating(false);
        props.updateAlert('Failed to submit profile', false);
        return Promise.reject(error);
      });
  }

  const submitSuccess = (modifiedAgent, finalFiles) => {
    setFiles(finalFiles);
    setAgent(modifiedAgent);
    const modifiedAgentWithFiles = {
      ...modifiedAgent,
      ...{
        agentCloudFiles: [
          ...agent.agentCloudFiles.filter((file) => file.documentType != 1),
          ...finalFiles,
        ],
      },
    };
    props.setAgentParent(modifiedAgentWithFiles);
    props.updateAlert('Profile submitted successfully!', true);
    setFocused(false);
    props.setUnsaved(false);
    setUpdating(false);
  };

  const handleFileDelete = (fileName) => {
    let temp = [];
    files.map((file) => {
      if (file.fileName == fileName) {
        if (file.state != 2) {
          file.state = 3;
          temp.push(file);
        }
      } else {
        temp.push(file);
      }
    });
    setFiles(temp);
  };

  const uploadFile = (file) => {
    const fileToAdd = {
      file: file,
      fileName: file.name,
      documentType: 1,
      fileNameLocal: file.name,
      id: 0,
      state: 2,
    };
    setFiles((oldFiles) => [...oldFiles, fileToAdd]);
  };

  const setLogoVisibility = (visible, file) => {
    if (!visible) {
      setShowLogo(false);
    } else {
      var newFile = {
        fileName: file.fileName,
        fileUrl: file.fileUrl,
        documentType: 4,
        fileNameLocal: file.fileNameLocal,
      };
      const modifiedAgent = {
        ...agent,
        ...{
          agentCloudFiles: files.concat(newFile),
        },
      };
      props.setAgentParent(modifiedAgent);
      setShowLogo(true);
    }
  };

  const hideLogo = () => {
    setShowLogo(false);
    const modifiedAgent = {
      ...agent,
      ...{
        agentCloudFiles: agent.agentCloudFiles.filter(
          (file) => file.documentType != 4
        ),
      },
    };
    props.setAgentParent(modifiedAgent);
  };
  const handleOnFocus = () => {
    setFocused(true);
    props.setUnsaved(true);
  };

  return (
    <>
      <Root
        when={focused}
        message={() =>
          'Are you sure you want to leave? Any unsaved data will be lost'
        }
      />
      <Grid container>
        <Grid item container direction="column" spacing={2} xs={10}>
          <Grid item>
            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={initValues}
              onSubmit={(values, { setErrors, setSubmitting }) => {
                //alert(JSON.stringify(values, null, 2));
                setSubmitting(false);
                handleFormSubmit(values)
                  .then(() => {
                    setSubmitting(false);
                  })
                  .catch((errors) => {
                    setSubmitting(false);
                    let err = [];
                    if (typeof errors === 'string') {
                      err.push(errors);
                    } else {
                      err = errors;
                    }
                    setErrors({
                      error: err,
                    });
                  });
              }}
            >
              {({
                handleSubmit,
                isValid,
                isSubmitting,
                dirty,
                errors,
                setFieldValue,
                values,
              }) => (
                <Form
                  onSubmit={handleSubmit}
                  autoComplete="off"
                  onFocus={handleOnFocus}
                >
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    item
                  >
                    <Grid item className={classes.agentInfo}>
                      <div className={classes.root}>
                        <Grid
                          container
                          direction="column"
                          spacing={2}
                          className={classes.form}
                          // alignItems="center"

                          // width="180%"
                        >
                          <Grid item>
                            <Typography variant="h6" align="center">
                              Company Info
                            </Typography>
                          </Grid>

                          <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                              <MyTextInput
                                label="Company Name"
                                name="companyName"
                                placeholder="Company Name"
                                size="small"
                                variant="standard"
                                required
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <MyTextInput
                                label="Company Registration Number"
                                name="companyRegNumber"
                                placeholder="Company Registration Number"
                                variant="standard"
                                size="small"
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <MyTextInput
                                label="Company Website"
                                name="companyWebsite"
                                placeholder="Company Website"
                                size="small"
                                variant="standard"
                                required
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <MyCountryListFormik
                                name="country"
                                label="Company HQ"
                                value={values.country}
                                setFieldValue={setFieldValue}
                                errorMessage="Country is invalid"
                                required={true}
                              />
                            </Grid>

                            {/* <Grid item style={{ marginTop: 15 }}>
                            <Typography variant="h6" align="center">
                              Contact Info
                            </Typography>
                          </Grid> */}

                            <Grid item xs={12} sm={6}>
                              <MyTextInput
                                requiredFirst
                                label="Application Update Email"
                                name="applicationUpdateEmail"
                                placeholder="Application Update Email"
                                type="email"
                                variant="standard"
                                size="small"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <MyTextInput
                                label="Agent Phone Number"
                                name="agentPhoneNumber"
                                placeholder="Agent Phone Number"
                                size="small"
                                variant="standard"
                                required
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <MyCountryListFormik
                                name="agentNationality"
                                label="Agent Nationality"
                                value={values.agentNationality}
                                setFieldValue={setFieldValue}
                                errorMessage="Agent nationality is invalid"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <MyTextInput
                                label="Company Phone Number"
                                name="companyPhoneNumber"
                                placeholder="Company Phone Number"
                                size="small"
                                variant="standard"
                                required
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <MyTextInput
                                required
                                label="Company Email"
                                name="companyEmail"
                                placeholder="Company Email Address"
                                type="email"
                                variant="standard"
                                size="small"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <MyTextInput
                                required
                                label="Street Address"
                                name="streetAddress"
                                placeholder="Street Address"
                                size="small"
                                variant="standard"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <MyTextInput
                                required
                                label="City"
                                name="city"
                                placeholder="City"
                                size="small"
                                variant="standard"
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <MyTextInput
                                required
                                label="Postal Code"
                                name="postcode"
                                placeholder="Postal Code"
                                size="small"
                                variant="standard"
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <MyTextInput
                                required
                                label="Admission Phone"
                                name="admissionPhone"
                                placeholder="Admission"
                                size="small"
                                variant="standard"
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <MyTextInput
                                required
                                label="Alternative Email"
                                name="alternativeEmail"
                                placeholder="Alternative Email"
                                size="small"
                                variant="standard"
                              />
                            </Grid>
                            {props.role.isSuperAdmin && (
                            <Grid item xs={12} sm={6}>
                              <MyTextInput
                                label="Owner Phone Number"
                                name="ownerPhone"
                                placeholder="Owner Phone Number"
                                size="small"
                                variant="standard"
                                
                              />
                            </Grid>)}
                            <Grid item xs={12} sm={6}>
                            </Grid>
                          </Grid>

                          {/* <Grid item>
                            <Typography variant="h6" align="center">
                              Second Point Of Contact
                            </Typography>
                          </Grid> */}

                          {/* <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                              <MyTextInput
                                label="First Name"
                                name="FirstName2"
                                size="small"
                                variant="standard"
                            
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <MyTextInput
                                label="Last Name"
                                name="lastName2"
                                variant="standard"
                                size="small"
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                              <MyTextInput
                                label="Designation"
                                name="Designation2"
                                size="small"
                                variant="standard"
                                required
                              />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                            <MyTextInput
                                label="Contact No."
                                name="Contact2"
                                size="small"
                                variant="standard"
                                required
                              />
                            </Grid>
             
                          </Grid> */}

                          <Grid item style={{ marginTop: 15 }}>
                            <Typography variant="h6" align="center">
                              Documents *
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            container
                            direction="column"
                            className={classes.documentContainer}
                            xs={6}
                          >
                            <Grid
                              item
                              container
                              justifyContent="space-between"
                              style={{ marginBottom: 20 }}
                            >
                              <Grid item xs={10}>
                                <Typography variant="h6">
                                Upload Business identification documents 
                              </Typography>
                              <Typography>(Trade license, certificate of incorporation, or business license)</Typography>  

                              </Grid>
                              <Grid
                                item
                                xs={2}
                                container
                                justifyContent="flex-end"
                              >
                                <Grid item>
                                  <input
                                    style={{ display: 'none' }}
                                    id="contained-button-file"
                                    type="file"
                                    onChange={(event) => {
                                      uploadFile(event.currentTarget.files[0]);
                                    }}
                                  />
                                  <label htmlFor="contained-button-file">
                                    <Button
                                      size="small"
                                      variant="contained"
                                      color="primary"
                                      component="span"
                                      startIcon={<CloudUploadIcon />}
                                    >
                                      Upload
                                    </Button>
                                  </label>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item>
                              <Documents
                                files={files.filter((file) => file.state != 3)}
                                handleFileDelete={handleFileDelete}
                                agentId={agent.id}
                                studentId={0}
                                disabled={
                                  agent.status === 5 &&
                                  props.isInternal == false
                                }
                                sizes={[9, 0]}
                                softDelete={true}
                              />
                            </Grid>
                          </Grid>
                          <Grid item style={{ marginTop: 15 }}>
                            <Typography variant="h6" align="center">
                              Terms & Conditions
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Agreements
                              termsAgreed={termsAgreed}
                              setTermsAgreed={setTermsAgreed}
                              dataPrivacyAgreed={dataPrivacyAgreed}
                              setDataPrivacyAgreed={setDataPrivacyAgreed}
                            />
                          </Grid>
                          <Grid item>
                            <ErrorMessage
                              name="error"
                              render={() => (
                                <ul>
                                  {errors.error &&
                                    errors.error.map((error, i) => {
                                      return (
                                        <li key={i} style={{ color: 'red' }}>
                                          <Typography>{error}</Typography>
                                        </li>
                                      );
                                    })}
                                </ul>
                              )}
                            />
                          </Grid>
                          {updating && (
                            <Grid item style={{ paddingRight: 10 }}>
                              <LinearIndeterminate />
                            </Grid>
                          )}
                          <Grid
                            item
                            style={{ marginBottom: 20 }}
                            container
                            justifyContent={
                              props.isInternal ? 'space-between' : 'flex-end'
                            }
                          >
                            {props.isInternal && (
                              <Grid item>
                                <LoadingButton
                                  disabled={!dirty}
                                  loading={updating}
                                  onClick={() => save(values)}
                                >
                                  Save
                                </LoadingButton>
                              </Grid>
                            )}
                            <Grid item>
                              <Button
                                disabled={
                                  isSubmitting ||
                                  (!dirty &&
                                    !files.some(
                                      (file) =>
                                        file.state == 2 || file.state == 3
                                    )) ||
                                  !isValid ||
                                  !termsAgreed ||
                                  !dataPrivacyAgreed ||
                                  (agent.status === 5 &&
                                    props.isInternal == false) ||
                                  !files.some((file) => file.documentType == 1)
                                }
                                type="submit"
                                fullWidth
                                variant="contained"
                                className="bg-sky-400 hover:bg-sky-500"
                              >
                                Submit Profile
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
        <Grid item xs={2} height="40%">
          {showLogo ? (
            <AgentCompanyLogo
              url={logoUrl}
              hideLogo={hideLogo}
              agentId={agent.id}
              studentId={0}
            />
          ) : (
            <PhotoUploadWidget
              setLogoVisibility={setLogoVisibility}
              setLogoUrl={setLogoUrl}
              agentId={agent.id}
              studentId={0}
            />
          )}
          <Button
            onClick={handleUrlDialogOpen}
            className="flex items-center space-x-3 mx-auto focus:outline-none rounded-md p-2 mt-2 transition duration-300 ease-in-out bg-white bg-opacity-80 backdrop-blur-md hover:bg-opacity-30 hover:shadow-lg hover:bg-gradient-to-r from-purple-200 to-sky-500 transform hover:scale-105"
            style={{ width: 'fit-content' }}
          >
            <Facebook className="text-blue-700" style={{ fontSize: 24 }} />
            <Twitter className="text-blue-400" style={{ fontSize: 24 }} />
            <YouTube className="text-pink-600" style={{ fontSize: 24 }} />
            <LinkedIn className="text-blue-800" style={{ fontSize: 24 }} />
            <Language className="text-orange-800" style={{ fontSize: 24 }} />
          </Button>

          <SocialMediaForm
            agent={props.agent}
            agentId={agent?.id}
            open={urlDialogOpen}
            onClose={handleUrlDialogClose}
            socialMediaUrls={socialMediaUrls}
            setSocialMediaUrls={setSocialMediaUrls}
            onSave={handleSaveUrls}
            handleChange={handleChange}
          />
        </Grid>
      </Grid>
    </>
  );
};


const mapStateToProps = (state) => {
  return {
    appUserId: state.auth.id,
    isInternal: state.auth.isInternal,
    role: state.auth.role,
    users: state.users,
    name: state.auth.name,
    permissions: state.auth.permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AgentGeneralInfo);

