/* eslint-disable jsx-a11y/label-has-associated-control */
"use client"

import React,{ useState, useRef, useEffect } from "react"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import PhoneInput from "react-phone-number-input"
import "react-phone-number-input/style.css"
import {
  CheckCircleIcon,
  XCircleIcon,
  PlusCircleIcon,
  TrashIcon,
  AcademicCapIcon,
  LanguageIcon,
  BookOpenIcon,
  UserIcon,
} from "@heroicons/react/24/solid"
import { Autocomplete, TextField } from "@mui/material"
import Agent from "../../../api/agent"

import { Countries } from "../../../Util/Util"
import MySnackbarAlert from "../AlertMessage/MySnackbarAlert"
import { GENDER, EDUCATION_LEVELS } from "../../../Util/Constants"

// Import the connect function
import { connect } from "react-redux"

// Form steps
const STEPS = {
  PERSONAL_INFO: 0,
  EDUCATION: 1,
  LANGUAGE: 2,
  STUDY_PLANS: 3,
}

// Employment status options
const EMPLOYMENT_OPTIONS = [
  { id: 1, type: "Student" },
  { id: 2, type: "Employed (Full-time/Part-time)" },
  { id: 3, type: "Unemployed" },
  { id: 4, type: "Freelancer" },
  { id: 5, type: "Other" },
]

// Yes/No options
const YES_NO_OPTIONS = [
  { id: 0, type: "No" },
  { id: 1, type: "Yes" },
]

// Study level options
const STUDY_LEVELS = [
  { id: 1, type: "Undergraduate / Bachelor's" },
  { id: 2, type: "Postgraduate / Master's" },
  { id: 3, type: "PhD / Doctorate" },
  { id: 4, type: "Diploma / Certificate" },
  { id: 5, type: "Foundation / Pathway" },
]

// Course domain options
const COURSE_DOMAINS = [
  { id: 1, type: "Business & Management" },
  { id: 2, type: "Computer Science & IT" },
  { id: 3, type: "Engineering" },
  { id: 4, type: "Medicine & Health Sciences" },
  { id: 5, type: "Arts & Humanities" },
  { id: 6, type: "Social Sciences" },
  { id: 7, type: "Natural Sciences" },
  { id: 8, type: "Law" },
  { id: 9, type: "Education" },
  { id: 10, type: "Other" },
]

// Form Error Message Component
const FormErrorMessage = ({ children }) => {
  return <div className="mt-1 text-sm text-red-600">{children}</div>
}

function StudentRegistrationForm({ appUserDetailsId }) {
  const [branchOffices, setBranchOffices] = useState([])
  const [step, setStep] = useState(STEPS.PERSONAL_INFO)
  const [studentId, setStudentId] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [searchQuery, setSearchQuery] = useState("")
  const [searchResults, setSearchResults] = useState(null)
  const [isSearching, setIsSearching] = useState(false)
  const [searchError, setSearchError] = useState("")
  const [isUpdate, setIsUpdate] = useState(false)
  const [formikRef] = useState(useRef(null))
  const [isSubmitted, setIsSubmitted] = useState(false)

  const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
  const [openFailureAlert, setOpenFailureAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alert, setAlert] = useState(null)

  const [showResponseDialog, setShowResponseDialog] = useState(false)
  const [apiResponse, setApiResponse] = useState(null)
  const [responseType, setResponseType] = useState("success") // "success" or "error"

  useEffect(() => {
    Agent.Cache.getBranchOffices().then((offices) => {
      setBranchOffices(offices)
      if (formikRef.current && offices && offices.length > 0) {
        formikRef.current.setFieldValue("BranchId", 0) // Default to branch ID 4
      }
    })
  }, [])

  // Helper function to extract value from objects with isSuccess property
  const extractValue = (obj) => {
    if (obj === null || obj === undefined) return ""
    if (typeof obj === "object" && obj.isSuccess !== undefined) {
      return obj.value || ""
    }
    return obj
  }

  const extractErrorMessage = (obj) => {
    if (obj === null || obj === undefined) return "Unknown error occurred"

    if (typeof obj === "object" && obj.isSuccess === false) {
      if (obj.error) return obj.error
      if (obj.message) return obj.message
    }

    if (typeof obj === "object" && obj.message) {
      return obj.message
    }

    if (typeof obj === "string") {
      return obj
    }

    return "An error occurred during the operation"
  }

  const isApiResponseSuccessful = (response) => {
    if (response === null || response === undefined) return false
    if (typeof response === "object" && response.isSuccess === false) return false
    return true
  }

  const safeString = (value) => {
    const extracted = extractValue(value)
    if (extracted === null || extracted === undefined) return ""
    return String(extracted)
  }

  const safeNumber = (value) => {
    const extracted = extractValue(value)
    if (extracted === null || extracted === undefined) return ""
    const num = Number(extracted)
    return isNaN(num) ? "" : num
  }

  const formatDate = (dateString) => {
    if (!dateString) return ""
    try {
      const date = new Date(dateString)
      if (isNaN(date.getTime())) return ""
      return date.toISOString().split("T")[0]
    } catch (e) {
      console.error("Error formatting date:", e)
      return ""
    }
  }

  const phoneValidation = Yup.string()
    .required("Phone number is required")
    .test("is-valid-phone", "Please enter a valid phone number with country code", (value) => {
      return value && value.startsWith("+") && value.length >= 8
    })

  const personalInfoSchema = Yup.object({
    FirstName: Yup.string()
      .trim()
      .required("First name is required")
      .min(2, "First name must be at least 2 characters"),
    LastName: Yup.string().trim().required("Last name is required").min(2, "Last name must be at least 2 characters"),
    Email: Yup.string().email("Please enter a valid email address").required("Email address is required"),
    PhoneNumber: phoneValidation,
    GuardianPhone: Yup.string().nullable(), // Optional
    Dob: Yup.date().required("Date of birth is required").max(new Date(), "Date of birth cannot be in the future"),
    Gender: Yup.number().required("Please select your gender"),
    Nationality: Yup.number().required("Please select your nationality"),
    CurrentLocation: Yup.string()
      .trim()
      .required("Current location is required")
      .min(3, "Please enter a valid location"),
    EmploymentStatus: Yup.number().required("Please select your employment status"),
    Designation: Yup.string().when("EmploymentStatus", {
      is: 2,
      then: (schema) => schema.required("Designation is required when employed"),
      otherwise: (schema) => schema.notRequired(),
    }),
    CompanyName: Yup.string().when("EmploymentStatus", {
      is: 2,
      then: (schema) => schema.required("Company name is required when employed"),
      otherwise: (schema) => schema.notRequired(),
    }),
    BranchId: Yup.number().required("Please select a branch office"),
  })

  const educationSchema = Yup.object({
    educationRecords: Yup.array().of(
      Yup.object({
        EducationLevel: Yup.number()
          .required("Education level is required")
          .typeError("Please select an education level"),
        ProgramName: Yup.string().required("Program name is required"),
        Gpa: Yup.number()
          .required("GPA is required")
          .min(0, "GPA must be positive")
          .max(10, "GPA cannot exceed maximum value")
          .typeError("GPA must be a number"),
        PassingYear: Yup.number()
          .required("Passing year is required")
          .min(1950, "Year must be after 1950")
          .max(new Date().getFullYear())
          .typeError("Passing year must be a number"),
      }),
    ),
  })

  // Update the languageSchema validation to handle both numeric and string values for scores
  // Replace the languageSchema with:

  const languageSchema = Yup.object({
    Qualification: Yup.string().required("Certificate type is required"),
    Band: Yup.string()
      .required("Overall score is required")
      .test("valid-score", "Please enter a valid score", (value) => {
        // Allow both numeric scores (e.g., 7.5) and string scores (e.g., B2)
        return (
          value &&
          (!isNaN(Number(value)) || // Numeric score
            /^[A-C][1-3]$/i.test(value)) // String score like B2, C1, etc.
        )
      }),
    Reading: Yup.string()
      .required("Reading score is required")
      .test("valid-score", "Please enter a valid score", (value) => {
        return value && (!isNaN(Number(value)) || /^[A-C][1-3]$/i.test(value))
      }),
    Writing: Yup.string()
      .required("Writing score is required")
      .test("valid-score", "Please enter a valid score", (value) => {
        return value && (!isNaN(Number(value)) || /^[A-C][1-3]$/i.test(value))
      }),
    Speaking: Yup.string()
      .required("Speaking score is required")
      .test("valid-score", "Please enter a valid score", (value) => {
        return value && (!isNaN(Number(value)) || /^[A-C][1-3]$/i.test(value))
      }),
    Listening: Yup.string()
      .required("Listening score is required")
      .test("valid-score", "Please enter a valid score", (value) => {
        return value && (!isNaN(Number(value)) || /^[A-C][1-3]$/i.test(value))
      }),
  })

  const studyPlansSchema = Yup.object({
    StudyLevel: Yup.number().required("Study level is required"),
    CourseDomain: Yup.number().required("Course domain is required"),
    BudgetAmount: Yup.number()
      .required("Budget amount is required")
      .min(0, "Amount must be positive")
      .typeError("Budget amount must be a number"),
  })

  // Initial form values
  const initialValues = {
    // Personal Info
    FirstName: "",
    LastName: "",
    Email: "",
    PhoneNumber: "",
    GuardianPhone: "",
    Dob: "",
    Gender: "",
    Nationality: "",
    CurrentLocation: "",
    EmploymentStatus: "",
    Designation: "",
    CompanyName: "",
    BranchId: 0,

    StudyLevel: 0, 
    CourseDomain: 0, 

    // Education
    educationRecords: [
      {
        EducationLevel: "",
        ProgramName: "",
        Degree: "",
        Gpa: "",
        PassingYear: "",
        country: "",
        institute: "",
        maximumGpa: "",
        description: "",
        studyLanguage: "",
      },
    ],

    Qualification: "1",
    Band: "",
    Reading: "",
    Writing: "",
    Speaking: "",
    Listening: "",
    ExamDate: "",

    BudgetAmount: "",
    BudgetCurrency: "USD",
    IntakePeriod: "Fall",
    IntakeYear: "",
    RequireScholarship: "",
    FinanciallySponsored: false,
    AppliedVisaBefore: "",
    VisaRejection: "",
    NeedAccommodation: "0",
    SpecificRequirements: "",
    DesiredCountries: [],
    HearAboutUs: "Website",
  }

  const handleResetForm = (resetForm) => {
    setIsSubmitted(false)
    setStep(STEPS.PERSONAL_INFO)
    if (resetForm) resetForm()
    setIsUpdate(false)
    setSearchResults(null)
    setShowResponseDialog(false)
  }

  const handleContinueEditing = () => {
    setShowResponseDialog(false)
    setIsSubmitted(false)
  }

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      setIsSubmitting(true)

      const formattedData = {
        FirstName: safeString(values.FirstName),
        LastName: safeString(values.LastName),
        Email: safeString(values.Email),
        PhoneNumber: safeString(values.PhoneNumber),
        AdditionalPhoneNumber: safeString(values.GuardianPhone) || null,
        Dob: values.Dob ? new Date(values.Dob + "T00:00:00Z").toISOString() : new Date().toISOString(),
        Gender: safeNumber(values.Gender),
        Citizenship: safeNumber(values.Nationality),
        StreetAddress: safeString(values.CurrentLocation),
        EmploymentStatus: safeNumber(values.EmploymentStatus),
        Designation: safeString(values.Designation) || null,
        CompanyName: safeString(values.CompanyName) || null,
        BranchId: safeNumber(values.BranchId) || 0,
        Source: 10, 
  
        AppUserDetailsId: appUserDetailsId,

        StudyLevel: safeNumber(values.StudyLevel || 0),
        CourseDomain: safeNumber(values.CourseDomain || 0),

        BudgetCurrency: safeString(values.BudgetCurrency),
        BudgetAmount: Number.parseFloat(values.BudgetAmount) || 0, 
        IntakePeriod: safeString(values.IntakePeriod),
        IntakeYear: safeNumber(values.IntakeYear || new Date().getFullYear()),
        RequireScholarship: safeNumber(values.RequireScholarship || 0),
        FinanciallySponsored: Boolean(values.FinanciallySponsored),
        AppliedVisaBefore: safeNumber(values.AppliedVisaBefore || 0),
        VisaRejection: safeNumber(values.VisaRejection || 0),
        NeedAccommodation: safeNumber(values.NeedAccommodation || 0),
        SpecificRequirements: safeString(values.SpecificRequirements) || null,
        HearAboutUs: safeString(values.HearAboutUs) || "Website",

        // Convert education fields to expected API format
        EducationRecords: values.educationRecords.map((record) => ({
          EducationLevel: safeNumber(record.EducationLevel),
          ProgramName: safeString(record.ProgramName),
          Degree: safeString(record.Degree || record.ProgramName),
          Gpa: safeNumber(record.Gpa),
          PassingYear: safeNumber(record.PassingYear),
          Country: safeNumber(record.country || 0), 
          Institute: safeString(record.institute || ""), 
          MaximumGpa: safeString(record.maximumGpa || ""),
          Description: safeString(record.description || ""), 
          StudyLanguage: safeString(record.studyLanguage || ""), 
        })),

        LanguageRecords: [
          {
 
            Qualification: (() => {
              if (!isNaN(Number(values.Qualification))) {
                return Number(values.Qualification)
              }

              const qualMap = {
                IELTS: 1,
                TOEFL: 2,
                PTE: 3,
                ESOL: 4,
                Duolingo: 5,
                GCSE: 6,
                Others: 7,
              }

              return qualMap[values.Qualification] || 1
            })(),

            Band: (() => {
              const val = safeString(values.Band)
              return !isNaN(Number(val)) ? Number(val) : 0
            })(),
            Reading: (() => {
              const val = safeString(values.Reading)
              return !isNaN(Number(val)) ? Number(val) : 0
            })(),
            Writing: (() => {
              const val = safeString(values.Writing)
              return !isNaN(Number(val)) ? Number(val) : 0
            })(),
            Speaking: (() => {
              const val = safeString(values.Speaking)
              return !isNaN(Number(val)) ? Number(val) : 0
            })(),
            Listening: (() => {
              const val = safeString(values.Listening)
              return !isNaN(Number(val)) ? Number(val) : 0
            })(),
            ExamDate: values.ExamDate ? new Date(values.ExamDate + "T00:00:00Z").toISOString() : null,
          },
        ],

        StudentPreferenceCountryRecords:
          values.DesiredCountries && values.DesiredCountries.length > 0
            ? values.DesiredCountries.map((country) => ({
                CountryId: safeNumber(country.id) || 0,
              }))
            : [{ CountryId: 0 }],
      }


      if (isUpdate) {
        if (searchResults?.id) {
          formattedData.Id = extractValue(searchResults.id)
        } else if (studentId) {
          formattedData.Id = studentId
        }

        if (searchResults?.educationRecords?.length > 0) {
          formattedData.EducationRecords = formattedData.EducationRecords.map((record, index) => {
            if (index < searchResults.educationRecords.length && searchResults.educationRecords[index].id) {
              return {
                ...record,
                Id: extractValue(searchResults.educationRecords[index].id),
              }
            }
            return record
          })
        }

        if (searchResults?.languageRecords?.length > 0) {
          formattedData.LanguageRecords = formattedData.LanguageRecords.map((record, index) => {
            if (index < searchResults.languageRecords.length && searchResults.languageRecords[index].id) {
              return {
                ...record,
                Id: extractValue(searchResults.languageRecords[index].id),
              }
            }
            return record
          })
        }

        if (searchResults?.studentPreferenceCountryRecords?.length > 0) {
          const countryIdToRecordId = {}
          searchResults.studentPreferenceCountryRecords.forEach((record) => {
            if (record.countryId && record.id) {
              countryIdToRecordId[extractValue(record.countryId)] = extractValue(record.id)
            }
          })

          formattedData.StudentPreferenceCountryRecords = formattedData.StudentPreferenceCountryRecords.map(
            (record) => {
              const recordId = countryIdToRecordId[record.CountryId]
              if (recordId) {
                return {
                  ...record,
                  Id: recordId,
                }
              }
              return record
            },
          )
        }

        if (searchResults?.appUserDetailsId) {
          formattedData.AppUserDetailsId = extractValue(searchResults.appUserDetailsId)
        }
      }

      console.log("Is update mode:", isUpdate)
      console.log("Student ID for update:", formattedData.Id)
      console.log("AppUserDetailsId:", formattedData.AppUserDetailsId)

      console.log("Submitting data:", formattedData)

      const response = await Agent.Students.saveStudentInsight(formattedData)
      console.log("API Response:", response)

      setApiResponse(response)

      if (isApiResponseSuccessful(response)) {
        setResponseType("success")

        const newStudentId =
          typeof response === "object" && response.value !== undefined ? response.value : extractValue(response)
        setStudentId(newStudentId)

        setIsUpdate(true)

        setAlert({
          message: `API Response: ${JSON.stringify(response)}`,
          type: "success",
        })
      } else {
        setResponseType("error")
        const errorMessage = extractErrorMessage(response)

        const studentIdMatch = typeof errorMessage === "string" ? errorMessage.match(/Student Id: (\d+)/) : null
        if (studentIdMatch && studentIdMatch[1]) {
          const extractedStudentId = studentIdMatch[1]
          setStudentId(extractedStudentId)
          setIsUpdate(true) 

          setAlert({
            message: `This email is already registered (Student ID: ${extractedStudentId}). You can update the existing record.`,
            type: "warning", 
          })
        } else {
          setAlert({
            message: errorMessage,
            type: "error",
          })
        }
      }

      setShowResponseDialog(true)
    } catch (error) {
      console.error("Error submitting form:", error)

      setResponseType("error")

      let errorMessage = "Error submitting form"

      if (error?.response?.data) {
        if (typeof error.response.data === "object") {
          if (error.response.data.isSuccess === false && error.response.data.error) {
            errorMessage = error.response.data.error
          } else if (error.response.data.message) {
            errorMessage = error.response.data.message
          }
        } else if (typeof error.response.data === "string") {
          errorMessage = error.response.data
        }
      } else if (error.message) {
        errorMessage = error.message
      }

      setApiResponse({ error: errorMessage })
      setAlert({ message: errorMessage, type: "error" })
      setShowResponseDialog(true)
    } finally {
      setIsSubmitting(false)
      setSubmitting(false)
    }
  }

  const handleAddEducationRecord = (values, setFieldValue) => {
    const newEducationRecords = [
      ...values.educationRecords,
      {
        EducationLevel: "",
        ProgramName: "",
        Degree: "",
        Gpa: "",
        PassingYear: "",
        country: "",
        institute: "",
        maximumGpa: "",
        description: "",
        studyLanguage: "",
      },
    ]
    setFieldValue("educationRecords", newEducationRecords)
  }

  const handleRemoveEducationRecord = (values, setFieldValue, index) => {
    if (values.educationRecords.length > 1) {
      const newEducationRecords = [...values.educationRecords]
      newEducationRecords.splice(index, 1)
      setFieldValue("educationRecords", newEducationRecords)
    }
  }

  const handleSearch = async () => {
    if (!searchQuery.trim()) {
      setSearchError("Please enter an email to search")
      return
    }

    try {
      setIsSearching(true)
      setSearchError("")

      const response = await Agent.Students.getStudentInfo(`?email=${searchQuery}`)
      console.log("Search API Response:", response)

      if (response) {
        console.log("API Response - citizenship:", extractValue(response.citizenship))
        console.log("API Response - streetAddress:", extractValue(response.streetAddress))
        console.log("API Response - additionalPhoneNumber:", extractValue(response.additionalPhoneNumber))
        console.log("API Response - dateOfBirth:", extractValue(response.dateOfBirth))
      }

      if (response) {
        setSearchResults(response)
        setIsUpdate(true) // Enable update mode
        populateFormWithSearchResults(response)
        setAlert({ message: "Student Found!", type: "success" })
      } else {
        setSearchResults(null)
        setSearchError("No student found with the provided email")
        setIsUpdate(false)
        setAlert({ message: "Student Not Found!", type: "error" })
      }
    } catch (error) {
      console.error("Search error:", error)
      setSearchResults(null)
      setSearchError("An error occurred while searching. Please try again.")
      setIsUpdate(false)

      let errorMessage = "Error searching for student"

      if (error?.response?.data) {
        if (typeof error.response.data === "object") {
          if (error.response.data.isSuccess === false && error.response.data.error) {
            errorMessage = error.response.data.error
          } else if (error.response.data.message) {
            errorMessage = error.response.data.message
          }
        } else if (typeof error.response.data === "string") {
          errorMessage = error.response.data
        }
      } else if (error.message) {
        errorMessage = error.message
      }

      setAlert({ message: errorMessage, type: "error" })
    } finally {
      setIsSearching(false)
    }
  }


  const handleStudentIdFromError = async (studentId) => {
    try {
      setIsSearching(true)

      // Call the API with the student ID parameter
      const response = await Agent.Students.getStudentInfo(`?id=${studentId}`)
      console.log("Search by ID API Response:", response)

      if (response) {
        setSearchResults(response)
        setIsUpdate(true) // Enable update mode
        populateFormWithSearchResults(response)
        setAlert({ message: `Student Found! ID: ${studentId}`, type: "success" })
      } else {
        setAlert({
          message: `Student with ID ${studentId} exists but details could not be loaded. Please search by email.`,
          type: "warning",
        })
      }
    } catch (error) {
      console.error("Error fetching student by ID:", error)
      setAlert({
        message: `Error loading student details for ID ${studentId}. Please search by email.`,
        type: "error",
      })
    } finally {
      setIsSearching(false)
    }
  }

  const populateFormWithSearchResults = (studentData) => {
    if (formikRef.current) {
      console.log("Populating form with data:", studentData)

      let dobFormatted = ""
      if (studentData.dateOfBirth) {
        dobFormatted = formatDate(extractValue(studentData.dateOfBirth))
        console.log("DOB formatted:", dobFormatted)
      }

      console.log("API citizenship (for Nationality):", extractValue(studentData.citizenship))
      console.log("API streetAddress (for CurrentLocation):", extractValue(studentData.streetAddress))
      console.log("API additionalPhoneNumber (for GuardianPhone):", extractValue(studentData.additionalPhoneNumber))
      console.log("API dateOfBirth:", extractValue(studentData.dateOfBirth))

      if (studentData.appUserDetailsId) {
        console.log("API appUserDetailsId:", extractValue(studentData.appUserDetailsId))
      }

      const formValues = {
        FirstName: extractValue(studentData.firstName),
        LastName: extractValue(studentData.lastName),
        Email: extractValue(studentData.email),
        PhoneNumber: extractValue(studentData.phoneNumber),
        GuardianPhone: extractValue(studentData.additionalPhoneNumber),
        Dob: dobFormatted,
        Gender: extractValue(studentData.gender),
        Nationality: extractValue(studentData.citizenship),
        CurrentLocation: extractValue(studentData.streetAddress),
        EmploymentStatus: extractValue(studentData.employmentStatus),
        Designation: extractValue(studentData.designation),
        CompanyName: extractValue(studentData.companyName),
        BranchId: extractValue(studentData.branchId) || 0,

        // Study Plans
        StudyLevel: extractValue(studentData.studyLevel) || 0,
        CourseDomain: extractValue(studentData.courseDomain) || 0,
        BudgetAmount: extractValue(studentData.budgetAmount),
        BudgetCurrency: extractValue(studentData.budgetCurrency) || "USD",
        IntakePeriod: extractValue(studentData.intakePeriod) || "Fall",
        IntakeYear: extractValue(studentData.intakeYear) || new Date().getFullYear(),
        RequireScholarship: extractValue(studentData.requireScholarship) || 0,
        FinanciallySponsored: Boolean(extractValue(studentData.financiallySponsored)),
        AppliedVisaBefore: extractValue(studentData.appliedVisaBefore) || 0,
        VisaRejection: extractValue(studentData.visaRejection) || 0,
        NeedAccommodation: extractValue(studentData.needAccommodation) || "0",
        SpecificRequirements: extractValue(studentData.specificRequirements),
        HearAboutUs: extractValue(studentData.hearAboutUs) || "Website",
        ExamDate: studentData.languageRecords?.[0]?.examDate
          ? formatDate(extractValue(studentData.languageRecords[0].examDate))
          : "",

        educationRecords:
          studentData.educationRecords?.length > 0
            ? studentData.educationRecords.map((record) => ({
                EducationLevel: extractValue(record.educationLevel),
                ProgramName: extractValue(record.programName),
                Degree: extractValue(record.degree),
                Gpa: extractValue(record.gpa),
                PassingYear: extractValue(record.passingYear),
                country: extractValue(record.country),
                institute: extractValue(record.institute),
                maximumGpa: extractValue(record.maximumGpa),
                description: extractValue(record.description),
                studyLanguage: extractValue(record.studyLanguage),
              }))
            : [
                {
                  EducationLevel: "",
                  ProgramName: "",
                  Degree: "",
                  Gpa: "",
                  PassingYear: "",
                  country: "",
                  institute: "",
                  maximumGpa: "",
                  description: "",
                  studyLanguage: "",
                },
              ],

        Qualification: studentData.languageRecords?.[0]?.qualification
          ? (() => {
              const qual = extractValue(studentData.languageRecords[0].qualification)
              // If it's already a number as string, return it
              if (!isNaN(Number(qual))) return qual.toString()
              // Otherwise map the string to a number
              const qualMap = {
                IELTS: "1",
                TOEFL: "2",
                PTE: "3",
                ESOL: "4",
                Duolingo: "5",
                GCSE: "6",
                Others: "7",
              }
              return qualMap[qual] || "1"
            })()
          : "1",
        Band: studentData.languageRecords?.[0]?.band ? extractValue(studentData.languageRecords[0].band) : "",
        Reading: studentData.languageRecords?.[0]?.reading ? extractValue(studentData.languageRecords[0].reading) : "",
        Writing: studentData.languageRecords?.[0]?.writing ? extractValue(studentData.languageRecords[0].writing) : "",
        Speaking: studentData.languageRecords?.[0]?.speaking
          ? extractValue(studentData.languageRecords[0].speaking)
          : "",
        Listening: studentData.languageRecords?.[0]?.listening
          ? extractValue(studentData.languageRecords[0].listening)
          : "",

        // Country preferences - Extract from studentPreferenceCountryRecords
        DesiredCountries:
          studentData.studentPreferenceCountryRecords?.length > 0
            ? studentData.studentPreferenceCountryRecords
                .map((record) => {
                  const countryId = extractValue(record.countryId)
                  return Countries.find((c) => c.id === Number(countryId)) || null
                })
                .filter(Boolean)
            : [],

        // Add this line to capture AppUserDetailsId
        AppUserDetailsId: extractValue(studentData.appUserDetailsId),
      }

      console.log("Setting form values:", formValues)
      console.log("Form values - Nationality:", formValues.Nationality)
      console.log("Form values - CurrentLocation:", formValues.CurrentLocation)
      console.log("Form values - GuardianPhone:", formValues.GuardianPhone)
      console.log("Form values - Dob:", formValues.Dob)

      formikRef.current.setValues(formValues)

      setTimeout(() => {
        if (formikRef.current) {
          formikRef.current.setFieldValue("Nationality", extractValue(studentData.citizenship))
          formikRef.current.setFieldValue("CurrentLocation", extractValue(studentData.streetAddress))
          formikRef.current.setFieldValue("GuardianPhone", extractValue(studentData.additionalPhoneNumber))
          formikRef.current.setFieldValue("Dob", dobFormatted)
        }
      }, 100)
    }
  }

  const getValidationSchema = () => {
    switch (step) {
      case STEPS.PERSONAL_INFO:
        return personalInfoSchema
      case STEPS.EDUCATION:
        return educationSchema
      case STEPS.LANGUAGE:
        return languageSchema
      case STEPS.STUDY_PLANS:
        return studyPlansSchema
      default:
        return Yup.object({})
    }
  }

  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1)
    window.scrollTo(0, 0)
  }

  const handlePreviousStep = () => {
    setStep((prevStep) => prevStep - 1)
    window.scrollTo(0, 0)
  }

  const renderStepIndicator = () => {
    const steps = [
      { name: "Personal Info", step: STEPS.PERSONAL_INFO, icon: <UserIcon className="w-5 h-5" /> },
      { name: "Education", step: STEPS.EDUCATION, icon: <AcademicCapIcon className="w-5 h-5" /> },
      { name: "Language", step: STEPS.LANGUAGE, icon: <LanguageIcon className="w-5 h-5" /> },
      { name: "Study Plans", step: STEPS.STUDY_PLANS, icon: <BookOpenIcon className="w-5 h-5" /> },
    ]

    return (
      <div className="mb-8">
        <div className="flex justify-between items-center">
          {steps.map((stepInfo) => (
            <div
              key={stepInfo.step}
              className={`flex flex-col items-center ${
                stepInfo.step < step ? "text-sky-600" : stepInfo.step === step ? "text-sky-600" : "text-gray-400"
              }`}
            >
              <div
                className={`w-14 h-14 rounded-full flex items-center justify-center mb-2 shadow-sm transition-all ${
                  stepInfo.step < step
                    ? "bg-sky-600 text-white"
                    : stepInfo.step === step
                      ? "border-2 border-sky-600 text-sky-600"
                      : "border-2 border-gray-300 text-gray-400"
                }`}
              >
                {stepInfo.step < step ? <CheckCircleIcon className="w-6 h-6" /> : stepInfo.icon}
              </div>
              <span className="text-sm font-medium hidden sm:block">{stepInfo.name}</span>
            </div>
          ))}
        </div>
        <div className="relative mt-3">
          <div className="absolute top-0 left-0 right-0 h-1.5 bg-gray-200 rounded-full">
            <div
              className="h-1.5 bg-sky-600 rounded-full transition-all duration-300"
              style={{ width: `${(step / (Object.keys(STEPS).length - 1)) * 100}%` }}
            ></div>
          </div>
        </div>
      </div>
    )
  }

  const formatDisplayDate = (dateString) => {
    if (!dateString) return "N/A"
    try {
      const date = new Date(extractValue(dateString))
      if (isNaN(date.getTime())) return "N/A"
      return date.toISOString().split("T")[0]
    } catch (e) {
      return "N/A"
    }
  }

  return (
    <div className="bg-white shadow-lg rounded-lg overflow-hidden">
      <MySnackbarAlert
        opnSuccess={openSuccessAlert}
        opnFailure={openFailureAlert}
        message={alertMessage}
        opnSuccessParent={setOpenSuccessAlert}
        opnFailureParent={setOpenFailureAlert}
      />

      {showResponseDialog && apiResponse && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 shadow-xl">
            <div className="text-center mb-4">
              <div
                className={`inline-flex items-center justify-center w-16 h-16 rounded-full ${
                  responseType === "success" ? "bg-green-100 text-green-600" : "bg-red-100 text-red-600"
                } mb-4`}
              >
                {responseType === "success" ? (
                  <CheckCircleIcon className="h-10 w-10" />
                ) : (
                  <XCircleIcon className="h-10 w-10" />
                )}
              </div>
              <h3 className="text-lg font-medium text-gray-900">{responseType === "success" ? "Success" : "Error"}</h3>
            </div>

            <div
              className={`p-4 rounded-md mb-4 border ${
                responseType === "success" ? "bg-green-50 border-green-200" : "bg-red-50 border-red-200"
              }`}
            >
              {responseType === "success" ? (
                <div className="text-center">
                  <p className="font-mono text-2xl font-bold text-gray-900 tracking-wide mb-3">
                    <span className="bg-green-100 px-4 py-2 rounded">
                      {typeof apiResponse === "object" && apiResponse.value !== undefined
                        ? apiResponse.value
                        : studentId || extractValue(apiResponse)}
                    </span>
                  </p>
                  <p className="text-md text-gray-700">
                    {isUpdate
                      ? "Student record has been updated successfully."
                      : "New student has been created successfully."}
                  </p>
                </div>
              ) : (
                <div className="text-center">
                  <p className="text-sm text-red-500 mb-1">Error Message</p>
                  <p className="font-medium text-red-700">
                    {typeof apiResponse === "object" && apiResponse.error
                      ? apiResponse.error
                      : typeof apiResponse === "object" && apiResponse.isSuccess === false && apiResponse.error
                        ? apiResponse.error
                        : "An error occurred during the operation"}
                  </p>
                </div>
              )}
            </div>

            <div className="flex justify-center space-x-3">
              {responseType === "success" && (
                <>
                  <button
                    onClick={() => handleContinueEditing()}
                    className="px-4 py-2 bg-sky-600 text-white rounded-md hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 transition-colors"
                  >
                    Continue Editing
                  </button>
                  <button
                    onClick={() => handleResetForm(formikRef.current?.resetForm)}
                    className="px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 transition-colors"
                  >
                    New Form
                  </button>
                </>
              )}
              {responseType === "error" && (
                <button
                  onClick={() => setShowResponseDialog(false)}
                  className="px-6 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-colors"
                >
                  Close
                </button>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="bg-gradient-to-r from-sky-50 to-white p-6 border-b">
        <h2 className="text-lg font-medium text-gray-900 mb-4 flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-2 text-sky-600"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clipRule="evenodd"
            />
          </svg>
          Find Existing Student
        </h2>
        <div className="flex flex-col sm:flex-row gap-4">
          <input
            type="email"
            placeholder="Search by Email Address"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value)
              if (searchError) setSearchError("")
            }}
            className="flex-1 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500"
          />
          <button
            type="button"
            onClick={handleSearch}
            disabled={isSearching}
            className="px-4 py-2 bg-sky-600 text-white rounded-md hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 disabled:opacity-50"
          >
            {isSearching ? "Searching..." : "Search"}
          </button>
        </div>

        {searchError && <div className="mt-2 text-sm text-red-600">{searchError}</div>}

        {searchResults && (
          <div className="mt-4 p-4 border rounded-md bg-white shadow-sm">
            <h3 className="font-medium text-gray-900 mb-3 pb-2 border-b">Search Results</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
              <div className="p-2 bg-gray-50 rounded">
                <span className="font-medium text-gray-700">Name:</span> {extractValue(searchResults.firstName)}{" "}
                {extractValue(searchResults.lastName)}
              </div>
              <div className="p-2 bg-gray-50 rounded">
                <span className="font-medium text-gray-700">Email:</span> {extractValue(searchResults.email)}
              </div>
              <div className="p-2 bg-gray-50 rounded">
                <span className="font-medium text-gray-700">Phone:</span> {extractValue(searchResults.phoneNumber)}
              </div>
              <div className="p-2 bg-gray-50 rounded">
                <span className="font-medium text-gray-700">Student ID:</span>{" "}
                <span className="font-mono font-semibold">{extractValue(searchResults.id) || "N/A"}</span>
              </div>
              <div className="p-2 bg-gray-50 rounded">
                <span className="font-medium text-gray-700">Location:</span>{" "}
                {extractValue(searchResults.streetAddress) || "N/A"}
              </div>
              <div className="p-2 bg-gray-50 rounded">
                <span className="font-medium text-gray-700">Date of Birth:</span>{" "}
                {formatDate(extractValue(searchResults.dateOfBirth)) || "N/A"}
              </div>
              <div className="p-2 bg-gray-50 rounded">
                <span className="font-medium text-gray-700">Nationality:</span>{" "}
                {Countries.find((c) => c.id === Number(extractValue(searchResults.citizenship)))?.name || "N/A"}
              </div>
              <div className="p-2 bg-gray-50 rounded">
                <span className="font-medium text-gray-700">Additional Phone:</span>{" "}
                {extractValue(searchResults.additionalPhoneNumber) || "N/A"}
              </div>
            </div>
            {isUpdate && (
              <div className="mt-4 flex justify-end">
                <button
                  type="button"
                  onClick={() => setStep(STEPS.PERSONAL_INFO)}
                  className="px-4 py-2 bg-sky-600 text-white rounded-md hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 transition-colors"
                >
                  Edit Student Information
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={getValidationSchema()}
        onSubmit={handleSubmit}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize
        innerRef={formikRef}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue, handleBlur, setTouched, resetForm }) => (
          <Form className="p-6">
            {renderStepIndicator()}

            {/* Step 1: Personal Information */}
            {step === STEPS.PERSONAL_INFO && (
              <div className="space-y-6">
                <h2 className="text-xl font-semibold text-gray-900 mb-4 pb-2 border-b flex items-center">
                  <UserIcon className="h-5 w-5 mr-2 text-sky-600" />
                  Personal Information
                </h2>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label htmlFor="FirstName" className="block text-sm font-medium text-gray-700 mb-1">
                      First Name <span className="text-red-500">*</span>
                    </label>
                    <Field
                      id="FirstName"
                      name="FirstName"
                      type="text"
                      className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 ${
                        errors.FirstName && touched.FirstName ? "border-red-500" : "border-gray-300"
                      }`}
                    />
                    <ErrorMessage name="FirstName" component={FormErrorMessage} />
                  </div>

                  <div>
                    <label htmlFor="LastName" className="block text-sm font-medium text-gray-700 mb-1">
                      Last Name <span className="text-red-500">*</span>
                    </label>
                    <Field
                      id="LastName"
                      name="LastName"
                      type="text"
                      className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 ${
                        errors.LastName && touched.LastName ? "border-red-500" : "border-gray-300"
                      }`}
                    />
                    <ErrorMessage name="LastName" component={FormErrorMessage} />
                  </div>

                  <div>
                    <label htmlFor="Email" className="block text-sm font-medium text-gray-700 mb-1">
                      Email Address <span className="text-red-500">*</span>
                    </label>
                    <Field
                      id="Email"
                      name="Email"
                      type="email"
                      className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 ${
                        errors.Email && touched.Email ? "border-red-500" : "border-gray-300"
                      }`}
                    />
                    <ErrorMessage name="Email" component={FormErrorMessage} />
                  </div>

                  <div>
                    <label htmlFor="PhoneNumber" className="block text-sm font-medium text-gray-700 mb-1">
                      Contact Number (Personal Mobile) <span className="text-red-500">*</span>
                    </label>
                    <div
                      className={`border rounded-md ${
                        errors.PhoneNumber && touched.PhoneNumber ? "border-red-500" : "border-gray-300"
                      }`}
                    >
                      <PhoneInput
                        international
                        defaultCountry="US"
                        value={values.PhoneNumber}
                        onChange={(value) => setFieldValue("PhoneNumber", value)}
                        onBlur={handleBlur}
                        className="w-full px-4 py-2 focus:outline-none focus:ring-2 focus:ring-sky-500"
                      />
                    </div>
                    <ErrorMessage name="PhoneNumber" component={FormErrorMessage} />
                    <p className="text-xs text-gray-500 mt-1">Please include country code</p>
                  </div>

                  <div>
                    <label htmlFor="GuardianPhone" className="block text-sm font-medium text-gray-700 mb-1">
                      Additional Phone Number (Guardian) - Optional
                    </label>
                    <PhoneInput
                      international
                      defaultCountry="US"
                      value={values.GuardianPhone}
                      onChange={(value) => setFieldValue("GuardianPhone", value)}
                      className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500"
                    />
                    <p className="text-xs text-gray-500 mt-1">Please include country code</p>
                  </div>

                  <div>
                    <label htmlFor="Dob" className="block text-sm font-medium text-gray-700 mb-1">
                      Date of Birth <span className="text-red-500">*</span>
                    </label>
                    <Field
                      id="Dob"
                      name="Dob"
                      type="date"
                      className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 ${
                        errors.Dob && touched.Dob ? "border-red-500" : "border-gray-300"
                      }`}
                    />
                    <ErrorMessage name="Dob" component={FormErrorMessage} />
                  </div>

                  <div>
                    <label htmlFor="Gender" className="block text-sm font-medium text-gray-700 mb-1">
                      Gender <span className="text-red-500">*</span>
                    </label>
                    <Field
                      as="select"
                      id="Gender"
                      name="Gender"
                      className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 ${
                        errors.Gender && touched.Gender ? "border-red-500" : "border-gray-300"
                      }`}
                    >
                      <option value="">Select Gender</option>
                      {GENDER.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.type}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name="Gender" component={FormErrorMessage} />
                  </div>

                  <div>
                    <label htmlFor="Nationality" className="block text-sm font-medium text-gray-700 mb-1">
                      Nationality/Citizenship <span className="text-red-500">*</span>
                    </label>
                    <Field name="Nationality">
                      {({ field, form }) => (
                        <Autocomplete
                          id="nationality-select"
                          options={Countries || []}
                          getOptionLabel={(option) => {
                            if (typeof option === "string") return ""
                            if (option && option.name) return option.name
                            return ""
                          }}
                          isOptionEqualToValue={(option, value) => {
                            if (!option || !value) return false
                            return option.id === value.id
                          }}
                          value={Countries.find((country) => country.id === Number(field.value)) || null}
                          onChange={(event, newValue) => {
                            form.setFieldValue("Nationality", newValue ? newValue.id : "")
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(errors.Nationality && touched.Nationality)}
                              placeholder="Select nationality"
                              variant="outlined"
                              fullWidth
                              size="small"
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "0.375rem",
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#0ea5e9",
                                    borderWidth: "2px",
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#0ea5e9",
                                  },
                                },
                              }}
                            />
                          )}
                        />
                      )}
                    </Field>
                    <ErrorMessage name="Nationality" component={FormErrorMessage} />
                  </div>

                  <div>
                    <label htmlFor="CurrentLocation" className="block text-sm font-medium text-gray-700 mb-1">
                      Current Location <span className="text-red-500">*</span>
                    </label>
                    <Field
                      id="CurrentLocation"
                      name="CurrentLocation"
                      type="text"
                      placeholder="e.g., New York, United States"
                      className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 ${
                        errors.CurrentLocation && touched.CurrentLocation ? "border-red-500" : "border-gray-300"
                      }`}
                    />
                    <ErrorMessage name="CurrentLocation" component={FormErrorMessage} />
                  </div>

                  <div>
                    <label htmlFor="EmploymentStatus" className="block text-sm font-medium text-gray-700 mb-1">
                      What is your current Employment? <span className="text-red-500">*</span>
                    </label>
                    <Field
                      as="select"
                      id="EmploymentStatus"
                      name="EmploymentStatus"
                      className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 ${
                        errors.EmploymentStatus && touched.EmploymentStatus ? "border-red-500" : "border-gray-300"
                      }`}
                    >
                      <option value="">Select Employment Status</option>
                      {EMPLOYMENT_OPTIONS.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.type}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name="EmploymentStatus" component={FormErrorMessage} />
                  </div>

                  {(values.EmploymentStatus === "2" || values.EmploymentStatus === 2) && (
                    <>
                      <div>
                        <label htmlFor="Designation" className="block text-sm font-medium text-gray-700 mb-1">
                          Designation <span className="text-red-500">*</span>
                        </label>
                        <Field
                          id="Designation"
                          name="Designation"
                          type="text"
                          placeholder="e.g., Marketing Manager"
                          className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 ${
                            errors.Designation && touched.Designation ? "border-red-500" : "border-gray-300"
                          }`}
                        />
                        <ErrorMessage name="Designation" component={FormErrorMessage} />
                      </div>

                      <div>
                        <label htmlFor="CompanyName" className="block text-sm font-medium text-gray-700 mb-1">
                          Company Name <span className="text-red-500">*</span>
                        </label>
                        <Field
                          id="CompanyName"
                          name="CompanyName"
                          type="text"
                          placeholder="e.g., ABC Company"
                          className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 ${
                            errors.CompanyName && touched.CompanyName ? "border-red-500" : "border-gray-300"
                          }`}
                        />
                        <ErrorMessage name="CompanyName" component={FormErrorMessage} />
                      </div>
                    </>
                  )}
                  <div>
                    <label htmlFor="BranchId" className="block text-sm font-medium text-gray-700 mb-1">
                      Branch Office <span className="text-red-500">*</span>
                    </label>
                    <Field
                      as="select"
                      id="BranchId"
                      name="BranchId"
                      className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 ${
                        errors.BranchId && touched.BranchId ? "border-red-500" : "border-gray-300"
                      }`}
                    >
                      <option value="">Select Branch Office</option>
                      {branchOffices.map((office) => (
                        <option key={office.id} value={office.id}>
                          {office.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name="BranchId" component={FormErrorMessage} />
                  </div>
                </div>
              </div>
            )}

            {/* Step 2: Education Background */}
            {step === STEPS.EDUCATION && (
              <div className="space-y-6">
                <h2 className="text-xl font-semibold text-gray-900 mb-4 pb-2 border-b flex items-center">
                  <AcademicCapIcon className="h-5 w-5 mr-2 text-sky-600" />
                  Education Background
                </h2>

                {values.educationRecords.map((record, index) => (
                  <div
                    key={index}
                    className="mb-6 p-4 border rounded-md shadow-sm bg-white hover:shadow-md transition-shadow"
                  >
                    <div className="flex justify-between items-center mb-3">
                      <h3 className="text-lg font-medium text-gray-800 flex items-center">
                        <span className="w-7 h-7 bg-sky-100 text-sky-700 rounded-full flex items-center justify-center mr-2 text-sm font-bold">
                          {index + 1}
                        </span>
                        Education Record {index + 1}
                      </h3>
                      {values.educationRecords.length > 1 && (
                        <button
                          type="button"
                          onClick={() => handleRemoveEducationRecord(values, setFieldValue, index)}
                          className="text-red-600 hover:text-red-800 flex items-center"
                        >
                          <TrashIcon className="w-4 h-4 mr-1" />
                          Remove
                        </button>
                      )}
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <div>
                        <label
                          htmlFor={`educationRecords[${index}].EducationLevel`}
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Highest Education Level <span className="text-red-500">*</span>
                        </label>
                        <Field
                          as="select"
                          name={`educationRecords[${index}].EducationLevel`}
                          id={`educationRecords[${index}].EducationLevel`}
                          className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 ${
                            errors.educationRecords?.[index]?.EducationLevel &&
                            touched.educationRecords?.[index]?.EducationLevel
                              ? "border-red-500"
                              : "border-gray-300"
                          }`}
                        >
                          <option value="">Select Education Level</option>
                          {EDUCATION_LEVELS.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.type}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage name={`educationRecords[${index}].EducationLevel`} component={FormErrorMessage} />
                      </div>

                      <div>
                        <label
                          htmlFor={`educationRecords[${index}].ProgramName`}
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Field of Study <span className="text-red-500">*</span>
                        </label>
                        <Field
                          type="text"
                          name={`educationRecords[${index}].ProgramName`}
                          id={`educationRecords[${index}].ProgramName`}
                          className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 ${
                            errors.educationRecords?.[index]?.ProgramName &&
                            touched.educationRecords?.[index]?.ProgramName
                              ? "border-red-500"
                              : "border-gray-300"
                          }`}
                        />
                        <ErrorMessage name={`educationRecords[${index}].ProgramName`} component={FormErrorMessage} />
                      </div>

                      <div>
                        <label
                          htmlFor={`educationRecords[${index}].Degree`}
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Degree
                        </label>
                        <Field
                          type="text"
                          name={`educationRecords[${index}].Degree`}
                          id={`educationRecords[${index}].Degree`}
                          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500"
                        />
                      </div>

                      <div>
                        <label
                          htmlFor={`educationRecords[${index}].PassingYear`}
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Graduation Year <span className="text-red-500">*</span>
                        </label>
                        <Field
                          type="number"
                          name={`educationRecords[${index}].PassingYear`}
                          id={`educationRecords[${index}].PassingYear`}
                          min="1950"
                          max={new Date().getFullYear()}
                          className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 ${
                            errors.educationRecords?.[index]?.PassingYear &&
                            touched.educationRecords?.[index]?.PassingYear
                              ? "border-red-500"
                              : "border-gray-300"
                          }`}
                        />
                        <ErrorMessage name={`educationRecords[${index}].PassingYear`} component={FormErrorMessage} />
                      </div>

                      <div>
                        <label
                          htmlFor={`educationRecords[${index}].Gpa`}
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          GPA / Grade <span className="text-red-500">*</span>
                        </label>
                        <Field
                          type="text"
                          name={`educationRecords[${index}].Gpa`}
                          id={`educationRecords[${index}].Gpa`}
                          className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 ${
                            errors.educationRecords?.[index]?.Gpa && touched.educationRecords?.[index]?.Gpa
                              ? "border-red-500"
                              : "border-gray-300"
                          }`}
                        />
                        <ErrorMessage name={`educationRecords[${index}].Gpa`} component={FormErrorMessage} />
                      </div>

                      <div>
                        <label
                          htmlFor={`educationRecords[${index}].maximumGpa`}
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Maximum GPA
                        </label>
                        <Field
                          type="text"
                          name={`educationRecords[${index}].maximumGpa`}
                          id={`educationRecords[${index}].maximumGpa`}
                          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500"
                        />
                      </div>

                      <div>
                        <label
                          htmlFor={`educationRecords[${index}].studyLanguage`}
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Study Language
                        </label>
                        <Field
                          type="text"
                          name={`educationRecords[${index}].studyLanguage`}
                          id={`educationRecords[${index}].studyLanguage`}
                          placeholder="e.g., English, French, etc."
                          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500"
                        />
                      </div>

                      <div>
                        <label
                          htmlFor={`educationRecords[${index}].institute`}
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Institution Name
                        </label>
                        <Field
                          type="text"
                          name={`educationRecords[${index}].institute`}
                          id={`educationRecords[${index}].institute`}
                          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500"
                        />
                      </div>

                      <div>
                        <label
                          htmlFor={`educationRecords[${index}].country`}
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Country
                        </label>
                        <Field
                          as="select"
                          name={`educationRecords[${index}].country`}
                          id={`educationRecords[${index}].country`}
                          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500"
                        >
                          <option value="">Select Country</option>
                          {Countries.filter((item) => item.id !== 0).map((country) => (
                            <option key={country.id} value={country.id}>
                              {country.name}
                            </option>
                          ))}
                        </Field>
                      </div>

                      <div className="md:col-span-2">
                        <label
                          htmlFor={`educationRecords[${index}].description`}
                          className="block text-sm font-medium text-gray-700 mb-1"
                        >
                          Description
                        </label>
                        <Field
                          as="textarea"
                          name={`educationRecords[${index}].description`}
                          id={`educationRecords[${index}].description`}
                          rows="3"
                          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500"
                        />
                      </div>
                    </div>
                  </div>
                ))}

                <button
                  type="button"
                  onClick={() => handleAddEducationRecord(values, setFieldValue)}
                  className="px-4 py-2 bg-sky-600 text-white rounded-md hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 transition-colors flex items-center"
                >
                  <PlusCircleIcon className="w-5 h-5 mr-2" />
                  Add Education Record
                </button>
              </div>
            )}

            {/* Step 3: Language Proficiency */}
            {step === STEPS.LANGUAGE && (
              <div className="space-y-6">
                <h2 className="text-xl font-semibold text-gray-900 mb-4 pb-2 border-b flex items-center">
                  <LanguageIcon className="h-5 w-5 mr-2 text-sky-600" />
                  Language Proficiency
                </h2>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label htmlFor="Qualification" className="block text-sm font-medium text-gray-700 mb-1">
                      Certificate Type <span className="text-red-500">*</span>
                    </label>
                    <Field
                      as="select"
                      name="Qualification"
                      id="Qualification"
                      className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 ${
                        errors.Qualification && touched.Qualification ? "border-red-500" : "border-gray-300"
                      }`}
                    >
                      <option value="">Select Certificate Type</option>
                      <option value="1">IELTS</option>
                      <option value="2">TOEFL</option>
                      <option value="3">PTE</option>
                      <option value="4">ESOL</option>
                      <option value="5">Duolingo</option>
                      <option value="6">GCSE/IGCSE/O-Level English</option>
                      <option value="7">Others</option>
                    </Field>
                    <ErrorMessage name="Qualification" component={FormErrorMessage} />
                  </div>

                  <div>
                    <label htmlFor="Band" className="block text-sm font-medium text-gray-700 mb-1">
                      Overall Score <span className="text-red-500">*</span>
                    </label>
                    <Field
                      type="text"
                      name="Band"
                      id="Band"
                      placeholder="e.g., 7.0, B2"
                      className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 ${
                        errors.Band && touched.Band ? "border-red-500" : "border-gray-300"
                      }`}
                    />
                    <ErrorMessage name="Band" component={FormErrorMessage} />
                  </div>

                  <div>
                    <label htmlFor="Reading" className="block text-sm font-medium text-gray-700 mb-1">
                      Reading Score <span className="text-red-500">*</span>
                    </label>
                    <Field
                      type="text"
                      name="Reading"
                      id="Reading"
                      placeholder="e.g., 7.0, B2"
                      className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 ${
                        errors.Reading && touched.Reading ? "border-red-500" : "border-gray-300"
                      }`}
                    />
                    <ErrorMessage name="Reading" component={FormErrorMessage} />
                  </div>

                  <div>
                    <label htmlFor="Writing" className="block text-sm font-medium text-gray-700 mb-1">
                      Writing Score <span className="text-red-500">*</span>
                    </label>
                    <Field
                      type="text"
                      name="Writing"
                      id="Writing"
                      placeholder="e.g., 7.0, B2"
                      className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 ${
                        errors.Writing && touched.Writing ? "border-red-500" : "border-gray-300"
                      }`}
                    />
                    <ErrorMessage name="Writing" component={FormErrorMessage} />
                  </div>

                  <div>
                    <label htmlFor="Speaking" className="block text-sm font-medium text-gray-700 mb-1">
                      Speaking Score <span className="text-red-500">*</span>
                    </label>
                    <Field
                      type="text"
                      name="Speaking"
                      id="Speaking"
                      placeholder="e.g., 7.0, B2"
                      className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 ${
                        errors.Speaking && touched.Speaking ? "border-red-500" : "border-gray-300"
                      }`}
                    />
                    <ErrorMessage name="Speaking" component={FormErrorMessage} />
                  </div>

                  <div>
                    <label htmlFor="Listening" className="block text-sm font-medium text-gray-700 mb-1">
                      Listening Score <span className="text-red-500">*</span>
                    </label>
                    <Field
                      type="text"
                      name="Listening"
                      id="Listening"
                      placeholder="e.g., 7.0, B2"
                      className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 ${
                        errors.Listening && touched.Listening ? "border-red-500" : "border-gray-300"
                      }`}
                    />
                    <ErrorMessage name="Listening" component={FormErrorMessage} />
                  </div>
                  <div>
                    <label htmlFor="ExamDate" className="block text-sm font-medium text-gray-700 mb-1">
                      Exam Date
                    </label>
                    <Field
                      type="date"
                      name="ExamDate"
                      id="ExamDate"
                      className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500"
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Step 4: Study Plans */}
            {step === STEPS.STUDY_PLANS && (
              <div className="space-y-6">
                <h2 className="text-xl font-semibold text-gray-900 mb-4 pb-2 border-b flex items-center">
                  <BookOpenIcon className="h-5 w-5 mr-2 text-sky-600" />
                  Study Plans
                </h2>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label htmlFor="DesiredCountries" className="block text-sm font-medium text-gray-700 mb-1">
                      Desired Study Countries <span className="text-red-500">*</span>
                    </label>
                    <Field name="DesiredCountries">
                      {({ field, form }) => (
                        <Autocomplete
                          multiple
                          id="desired-countries-select"
                          options={Countries || []}
                          getOptionLabel={(option) => {
                            if (typeof option === "string") return ""
                            if (option && option.name) return option.name
                            return ""
                          }}
                          isOptionEqualToValue={(option, value) => {
                            if (!option || !value) return false
                            return option.id === value.id
                          }}
                          value={field.value || []}
                          onChange={(event, newValue) => {
                            form.setFieldValue("DesiredCountries", newValue)
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(errors.DesiredCountries && touched.DesiredCountries)}
                              placeholder="Select countries"
                              variant="outlined"
                              fullWidth
                              size="small"
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "0.375rem",
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#0ea5e9",
                                    borderWidth: "2px",
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#0ea5e9",
                                  },
                                },
                              }}
                            />
                          )}
                        />
                      )}
                    </Field>
                    <ErrorMessage name="DesiredCountries" component={FormErrorMessage} />
                  </div>

                  <div>
                    <label htmlFor="BudgetAmount" className="block text-sm font-medium text-gray-700 mb-1">
                      Budget Amount <span className="text-red-500">*</span>
                    </label>
                    <div className="flex space-x-2">
                      <div className="flex-1">
                        <Field
                          type="number"
                          step="0.01" // Allow decimal values
                          name="BudgetAmount"
                          id="BudgetAmount"
                          min="0"
                          className={`w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500 ${
                            errors.BudgetAmount && touched.BudgetAmount ? "border-red-500" : "border-gray-300"
                          }`}
                        />
                        <ErrorMessage name="BudgetAmount" component={FormErrorMessage} />
                      </div>
                      <div className="w-1/3">
                        <Field
                          as="select"
                          name="BudgetCurrency"
                          id="BudgetCurrency"
                          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500"
                        >
                          <option value="USD">USD</option>
                          <option value="EUR">EUR</option>
                          <option value="GBP">GBP</option>
                          <option value="CAD">CAD</option>
                          <option value="AUD">AUD</option>
                          <option value="INR">INR</option>
                        </Field>
                      </div>
                    </div>
                  </div>

                  <div>
                    <label htmlFor="IntakeYear" className="block text-sm font-medium text-gray-700 mb-1">
                      Intake Year
                    </label>
                    <Field
                      as="select"
                      name="IntakeYear"
                      id="IntakeYear"
                      className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500"
                    >
                      <option value="">Select Year</option>
                      {[...Array(5)].map((_, i) => {
                        const year = new Date().getFullYear() + i
                        return (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        )
                      })}
                    </Field>
                  </div>

                  <div>
                    <label htmlFor="RequireScholarship" className="block text-sm font-medium text-gray-700 mb-1">
                      Student require a scholarship? <span className="text-red-500">*</span>
                    </label>
                    <Field
                      as="select"
                      name="RequireScholarship"
                      id="RequireScholarship"
                      className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500"
                    >
                      <option value="">Select</option>
                      {YES_NO_OPTIONS.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.type}
                        </option>
                      ))}
                    </Field>
                  </div>

                  <div>
                    <label htmlFor="FinanciallySponsored" className="block text-sm font-medium text-gray-700 mb-1">
                    Student financially sponsored?
                    </label>
                    <div className="mt-2">
                      <label className="inline-flex items-center">
                        <Field
                          type="checkbox"
                          name="FinanciallySponsored"
                          className="rounded border-gray-300 text-sky-600 shadow-sm focus:border-sky-300 focus:ring focus:ring-sky-200 focus:ring-opacity-50"
                        />
                        <span className="ml-2">Yes, I am financially sponsored</span>
                      </label>
                    </div>
                  </div>
                  <div>
                    <label htmlFor="HearAboutUs" className="block text-sm font-medium text-gray-700 mb-1">
                      How did  student here about us?
                    </label>
                    <Field
                      as="select"
                      name="HearAboutUs"
                      id="HearAboutUs"
                      className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500"
                    >
                      <option value="Website">Website</option>
                      <option value="SocialMedia">Social Media</option>
                      <option value="Friend">Friend/Family</option>
                      <option value="Event">Event/Exhibition</option>
                      <option value="Advertisement">Advertisement</option>
                      <option value="Other">Other</option>
                    </Field>
                  </div>
                  <div>
                    <label htmlFor="NeedAccommodation" className="block text-sm font-medium text-gray-700 mb-1">
                    Student need accommodation assistance?
                    </label>
                    <Field
                      as="select"
                      name="NeedAccommodation"
                      id="NeedAccommodation"
                      className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-sky-500"
                    >
                      <option value="">Select</option>
                      {YES_NO_OPTIONS.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.type}
                        </option>
                      ))}
                    </Field>
                  </div>
                </div>
              </div>
            )}

            {/* Navigation buttons */}
            <div className="flex justify-between mt-8">
              {step > STEPS.PERSONAL_INFO && (
                <button
                  type="button"
                  onClick={handlePreviousStep}
                  className="px-4 py-2 border border-gray-300 text-gray-700 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 transition-colors"
                >
                  Previous
                </button>
              )}

              <div className="flex gap-2 ml-auto">
                {/* Save button on every page */}
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="px-6 py-2 bg-sky-600 text-white rounded-md hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                >
                  {isSubmitting ? (
                    <span className="flex items-center">
                      <svg
                        className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      {isUpdate ? "Updating..." : "Saving..."}
                    </span>
                  ) : isUpdate ? (
                    "Update Student"
                  ) : (
                    "Save Student"
                  )}
                </button>

                {step < STEPS.STUDY_PLANS && (
                  <button
                    type="button"
                    onClick={handleNextStep}
                    className="px-6 py-2 bg-sky-600 text-white rounded-md hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 transition-colors"
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {alert && <MySnackbarAlert message={alert.message} type={alert.type} onClose={() => setAlert(null)} />}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    appUserDetailsId: state.auth.appUserDetailsId,
  }
}

export default connect(mapStateToProps, null)(StudentRegistrationForm)

