
import React,{ useEffect, useState } from "react"
import { CloudArrowDownIcon, TrashIcon } from "@heroicons/react/24/outline"
import PhotoWidgetDropzone from "../../../Common/ImageUpload/PhotoWidgetDropzone"
import MySnackbarAlert from "../../../Common/AlertMessage/MySnackbarAlert"
import ConfirmationModal from "../../../Common/Modal/ConfirmationModal"
import { DownloadCloudFile } from "../../../../Util/FileProcessing"
import { ResizeFile } from "../../../../Util/ResizeFile"
import Agent from "../../../../api/agent"

const UniversityImages = ({ profile }) => {
  const [loading, setLoading] = useState(false)
  const [logo, setLogo] = useState(null)
  const [logoTemp, setLogoTemp] = useState(null)
  const [coverImage, setCoverImage] = useState(null)
  const [coverImageTemp, setCoverImageTemp] = useState(null)
  const [openConfirm, setOpenConfirm] = useState(false)
  const [isLogo, setIsLogo] = useState(true)
  const [openSuccess, setOpenSuccess] = useState(false)
  const [openFailure, setOpenFailure] = useState(false)
  const [alertMsg, setAlertMsg] = useState("")

  useEffect(() => {
    if (profile?.fileName && profile?.fileUrl) {
      setLogo({
        fileName: profile.fileName,
        fileNameLocal: profile.fileNameLocal,
        fileUrl: profile.fileUrl,
        containerName: profile.containerName,
        fileSize: profile.fileSize,
      })
    }
    if (profile.fileNameBackground && profile.fileUrlBackground) {
      setCoverImage({
        fileName: profile.fileNameBackground,
        fileNameLocal: profile.fileNameLocalBackground,
        fileUrl: profile.fileUrlBackground,
        containerName: profile.containerNameBackground,
        fileSize: profile.fileSizeBackground,
      })
    }
  }, [profile])

  const deleteLogo = () => {
    const fileToDelete = isLogo ? logo : coverImage
    Agent.CloudFiles.delete(fileToDelete.fileName, 0, 0, 0, profile.id)
      .then(() => {
        if (isLogo) {
          setLogoTemp(null)
          setLogo(null)
        } else {
          setCoverImageTemp(null)
          setCoverImage(null)
        }
        setOpenConfirm(false)
        setAlert(`${isLogo ? "Logo" : "Cover Image"} deleted successfully`)
      })
      .catch((error) => {
        console.log(error)
        setAlert(`Failed to delete ${isLogo ? "logo" : "cover image"}. Refresh the page and try again`, false)
      })
  }

  const MAX_LOGO_SIZE_KB = 100
  const MAX_COVER_SIZE_KB = 300

  const uploadLogo = (file) => {
    const fileSizeKB = file.size / 1024
    if (fileSizeKB > MAX_LOGO_SIZE_KB) {
      setAlert(`Logo file size exceeds ${MAX_LOGO_SIZE_KB}KB limit. Please choose a smaller file.`, false)
      return
    }
    ResizeFile(file, 250, 250, 250, 250, "png").then(setTempImageState)
  }

  const uploadCoverImage = (file) => {
    const fileSizeKB = file.size / 1024
    if (fileSizeKB > MAX_COVER_SIZE_KB) {
      setAlert(`Cover image file size exceeds ${MAX_COVER_SIZE_KB}KB limit. Please choose a smaller file.`, false)
      return
    }
    ResizeFile(file, 900, 285, 900, 285, "png").then(setTempCoverImageState)
  }

  const setTempImageState = (file) => setLogoTemp(file)
  const setTempCoverImageState = (file) => setCoverImageTemp(file)

  const deleteTempLogo = () => {
    setLogoTemp(null)
    setLogo(null)
  }

  const deleteTempCoverImage = () => {
    setCoverImageTemp(null)
    setCoverImage(null)
  }

  const saveLogo = () => {
    Agent.CloudFiles.upload(logoTemp, 21, 0, 0, "", 0, profile.id, 0)
      .then((file) => {
        setLogoTemp(null)
        setLogo({
          fileName: file.blobName,
          fileNameLocal: file.fileNameLocal,
          fileUrl: file.blobUri,
          containerName: file.containerName,
          fileSize: file.fileSize,
        })
        setAlert("Logo saved successfully")
      })
      .catch((error) => {
        console.log(error)
        setAlert("Failed to save logo. Check the logo and try again", false)
      })
  }

  const saveCoverImage = () => {
    Agent.CloudFiles.upload(coverImageTemp, 22, 0, 0, "", 0, profile.id, 0)
      .then((file) => {
        setCoverImageTemp(null)
        setCoverImage({
          fileName: file.blobName,
          fileNameLocal: file.fileNameLocalBackground,
          fileUrl: file.blobUri,
          containerName: file.containerNameBackground,
          fileSize: file.fileSizeBackground,
        })
        setAlert("Cover Image saved successfully")
      })
      .catch((error) => {
        console.log(error)
        setAlert("Failed to save Cover Image. Check the image and try again", false)
      })
  }

  const setAlert = (msg = "Successfully updated", isSuccess = true) => {
    setAlertMsg(msg)
    setOpenSuccess(isSuccess)
    setOpenFailure(!isSuccess)
  }

  const getFileSize = (image, imageTemp) => {
    if (imageTemp && imageTemp.size) {
      return (imageTemp.size / 1024).toFixed(2)
    }
    if (image && image.fileSize) {
      return (image.fileSize / 1024).toFixed(2)
    }
    return null
  }

  const ImageSection = ({
    title,
    subtitle,
    upload,
    save,
    image,
    imageTemp,
    deleteTemp,
    isLogo,
    maxSizeKB,
    dimensions,
  }) => {
    const fileSize = getFileSize(image, imageTemp)

    return (
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-2xl font-semibold text-indigo-600 mb-2">{title}</h2>
        <p className="text-lg text-gray-600 mb-4">{subtitle}</p>
        <div className="flex flex-col lg:flex-row gap-6">
          <div className="flex-1">
            <PhotoWidgetDropzone
              className="border-2 border-dashed border-gray-300 rounded-lg hover:border-indigo-500 transition-colors duration-300"
              crop={false}
              upload={upload}
              message={`Drop ${title.toLowerCase()} here or click to upload`}
              height={200}
              disabled={image || imageTemp}
            />
            <p className="text-sm text-gray-500 mt-2">
              Max size: {maxSizeKB}KB | Dimensions: {dimensions}
            </p>
          </div>
          <div className="flex-1 flex flex-col justify-between">
            <button
              className={`py-2 px-4 rounded-md text-lg w-full mb-4 transition-colors duration-300 ${
                imageTemp
                  ? "bg-indigo-600 hover:bg-indigo-700 text-white"
                  : "bg-gray-300 text-gray-500 cursor-not-allowed"
              }`}
              disabled={!imageTemp}
              onClick={save}
            >
              Save {title}
            </button>
            {(image || imageTemp) && (
              <div className="flex flex-col items-center mb-4">
                <img
                  className="rounded-lg max-h-[170px] max-w-[200px] object-contain mb-2 border border-gray-200"
                  alt={title.toLowerCase()}
                  src={imageTemp ? URL.createObjectURL(imageTemp) : image?.fileUrl}
                />
                <p className="text-sm text-gray-600">
                  {fileSize ? `File Size: ${fileSize} KB` : "File size not available"}
                </p>
              </div>
            )}
            <div className="flex justify-center space-x-2">
              {image && (
                <>
                  <button
                    className="flex items-center justify-center px-4 py-2 border border-indigo-600 text-indigo-600 rounded-md hover:bg-indigo-50 transition-colors duration-300"
                    onClick={() => {
                      setLoading(true)
                      DownloadCloudFile(image.fileNameLocal, image.fileName, image.containerName).finally(() => {
                        setLoading(false)
                      })
                    }}
                  >
                    {loading ? (
                      <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                          fill="none"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                    ) : (
                      <CloudArrowDownIcon className="h-5 w-5 mr-2" />
                    )}
                    Download
                  </button>
                  <button
                    className="flex items-center justify-center px-4 py-2 border border-red-600 text-red-600 rounded-md hover:bg-red-50 transition-colors duration-300"
                    onClick={() => {
                      setIsLogo(isLogo)
                      setOpenConfirm(true)
                    }}
                  >
                    <TrashIcon className="h-5 w-5 mr-2" />
                    Delete
                  </button>
                </>
              )}
              {imageTemp && (
                <button
                  onClick={deleteTemp}
                  className="px-4 py-2 border border-indigo-500 text-indigo-500 rounded-md hover:bg-indigo-50 transition-colors duration-300"
                >
                  Change
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="max-w-9xl mx-auto p-6 bg-gray-100">
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h1 className="text-3xl font-bold text-indigo-600 mb-4 text-center">University Images</h1>
        <div className="text-gray-600 mb-6">
          <p className="font-medium text-lg mb-2">Please note the following:</p>
          <ul className="list-disc list-inside space-y-1">
            <li>
              Upload only <strong>PNG</strong> files with transparent background
            </li>
            <li>
              Logo must be <strong>250px</strong> by <strong>250px</strong> (square) and max{" "}
              <strong>{MAX_LOGO_SIZE_KB}KB</strong>
            </li>
            <li>
              Cover image must be <strong>900px</strong> by <strong>285px</strong> (wide) and max{" "}
              <strong>{MAX_COVER_SIZE_KB}KB</strong>
            </li>
            <li>Images will be automatically resized to fit the required dimensions</li>
          </ul>
        </div>
      </div>

      <ImageSection
        title="University Logo"
        subtitle="Please upload a square university logo"
        upload={uploadLogo}
        save={saveLogo}
        image={logo}
        imageTemp={logoTemp}
        deleteTemp={deleteTempLogo}
        isLogo={true}
        maxSizeKB={MAX_LOGO_SIZE_KB}
        dimensions="250x250px"
      />

      <ImageSection
        title="University Cover Image"
        subtitle="Please upload a wide university cover image"
        upload={uploadCoverImage}
        save={saveCoverImage}
        image={coverImage}
        imageTemp={coverImageTemp}
        deleteTemp={deleteTempCoverImage}
        isLogo={false}
        maxSizeKB={MAX_COVER_SIZE_KB}
        dimensions="900x285px"
      />

      <ConfirmationModal
        open={openConfirm}
        cancel={setOpenConfirm}
        confirm={() => deleteLogo(isLogo)}
        confirmText="Confirm Action"
        content={`Are you sure that you would like to delete the ${isLogo ? "logo" : "cover image"}?`}
      />

      {(openSuccess || openFailure) && (
        <MySnackbarAlert
          opnSuccess={openSuccess}
          opnFailure={openFailure}
          message={alertMsg}
          opnSuccessParent={setOpenSuccess}
          opnFailureParent={setOpenFailure}
        />
      )}
    </div>
  )
}

export default UniversityImages

