/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useRef, useState } from 'react';
import Agent from '../../../api/agent';
import {
  Select,
  MenuItem,
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Grid,
} from '@mui/material';
import { LocalizationProvider, DateTimePicker, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ArrowBack, ArrowRight, CampaignOutlined } from '@mui/icons-material';
import {
  Announcement_TYPE,
  User_TYPE_Announcement,
} from '../../../Util/Constants';
import * as actions from '../../../store/actions/index';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { formats, modules } from '../../../Util/Util';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';


const CreateAnnouncement = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [content, setContent] = useState('');
  const [announcementTypeList, setAnnouncementTypeList] = useState([]);
  const currentDate = new Date();
  const [templateData, setTemplateData] = useState({
    Title: '',
    Content: '',
    PublishDate: null,
    Link: '',
    ExpiryDate: null,
    AnnouncementTypeId: 0,
    UserTypeId: 0,
  });
  const editor = useRef(null);

const navigate= useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      ...templateData,
      Content : content,
      PublishDate: currentDate,
      ExpiryDate: templateData.ExpiryDate
        ? templateData.ExpiryDate.toISOString()
        : '',
    };

    try {
      await Agent.Announcement.postAnnouncement(payload);
      setSuccessMessage('Announcement created successfully');
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
        setTemplateData({
          Title: '',
          Content: '',
          PublishDate: null,
          Link: '',
          ExpiryDate: null,
          AnnouncementTypeId: 0,
          UserTypeId: 0,
        });
      }, 3000);
    } catch (error) {
      setErrorMessage('Error: ' + error.message);
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
      }, 3000);
    }
  };

  useEffect(()=>{
    Agent.Announcement.getAnnouncementType().then((res)=>{
     const temp= res.sort(function (a, b) {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    })
      setAnnouncementTypeList(temp);
    })
  },[]);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    const val = type === 'number' ? parseInt(value) : value;
    setTemplateData((prevData) => ({
      ...prevData,
      [name]: val,
    }));
  };

  const handleDateChange = (name, date) => {
    setTemplateData((prevData) => ({
      ...prevData,
      [name]: date,
    }));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="flex justify-center items-center bg-gradient-to-b from-sky-100 to-sky-200 p-4">
     
        <div className="bg-white px-10 py-6 rounded-lg shadow-md w-full max-w-4xl">
          <div className="flex items-center justify-center mb-6">


            
            <CampaignOutlined className="text-3xl text-blue-500 mr-2" />
            <h2 className="text-3xl font-bold text-blue-500">
              Create Announcement
            </h2>

            <Button
              onClick={() => navigate("/announcementmenu")}
              variant="outlined"
            className='ml-auto'
            >
             Go To Announcement List
             <ArrowRight className="text-3xl text-blue-500 mr-2" />

            </Button>
          </div>
          <form onSubmit={handleSubmit} className="space-y-4">
            <TextField
              label="Title"
              variant="outlined"
              fullWidth
              value={templateData.Title}
              onChange={handleChange}
              name="Title"
              required
              className="focus:ring-2 focus:ring-blue-500"
            />
            {/* <TextField
              label="Link"
              variant="outlined"
              fullWidth
              size="small"
              value={templateData.Link}
              onChange={handleChange}
              name="Link"
              className="focus:ring-2 focus:ring-blue-500"
            /> */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <FormControl fullWidth variant="outlined">
                <InputLabel>Announcement Type</InputLabel>
                <Select
                  label="Announcement Type"
                  value={templateData.AnnouncementTypeId}
                  onChange={(e) =>
                    handleChange({
                      target: {
                        name: 'AnnouncementTypeId',
                        value: e.target.value,
                      },
                    })
                  }
                  className="focus:ring-2 focus:ring-blue-500"
                >
                  <MenuItem value={0}>Select Announcement Type</MenuItem>
                  {announcementTypeList.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* <DatePicker
                label="Publish Date"
                value={templateData.PublishDate}
                size="small"
                onChange={(date) => handleDateChange('PublishDate', date)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    className="focus:ring-2 focus:ring-blue-500"
                  />
                )}
              /> */}
              <DateTimePicker
                label="Expiry Date"
                value={templateData.ExpiryDate}
                onChange={(date) => handleDateChange('ExpiryDate', date)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    className="focus:ring-2 focus:ring-blue-500"
                  />
                )}
              />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              
              {/* <FormControl fullWidth variant="outlined">
                <InputLabel>Applicable User</InputLabel>
                <Select
                  label="Applicable User"
                  value={templateData.UserTypeId}
                  onChange={(e) =>
                    handleChange({
                      target: { name: 'UserTypeId', value: e.target.value },
                    })
                  }
                  className="focus:ring-2 focus:ring-blue-500"
                >
                  <MenuItem value={0}>Select Applicable User</MenuItem>
                  {User_TYPE_Announcement.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            </div>
            <ReactQuill 
                theme="snow" 
                required
                fullWidth
                value={content} 
                placeholder={'Write something '}
                modules={modules}
                formats={formats}
                onChange={newContent => setContent(newContent)}
                className='h-[460px] pb-12'
              />
            <Grid item container justifyContent="end">
            <Button
              type="submit"
              variant="contained"
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 w-full"
            >
              Publish Announcement
            </Button>
          </Grid>
           
          </form>
          <br/>
          
          {showModal && (
            <div className="fixed z-10 inset-0 overflow-y-auto flex justify-center items-center">
              <div className="absolute bg-black opacity-50 inset-0"></div>
              <div className="bg-white p-6 rounded shadow-md relative z-20">
                <Typography
                  variant="h6"
                  className={`font-semibold ${
                    successMessage ? 'text-green-600' : 'text-red-600'
                  }`}
                >
                  {successMessage ? 'Success' : 'Error'}
                </Typography>
                <Typography variant="body2" className="text-gray-500 mt-2">
                  {successMessage || errorMessage}
                </Typography>
              </div>
            </div>
          )}
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default CreateAnnouncement;
