/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Draggable from 'react-draggable';
import {
  AGENTSOURCES,
  AGENTSTATUS,
  EVENT_CATEGORIES,
  EVENT_STATUSES,
  LEADCHANNELS,
  LEADORIGINS,
  REFERRALSOURCE,
  STUDENTSOURCES,
  StudentLead_Status,
} from '../../../Util/Constants';
import Agent from '../../../api/agent';
import { Countries } from '../../../Util/Util';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Users from '../../Users/Users';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const ReferralsAssignment = ({
  users,
  role,
  cancel,
  referral,
  action,
}) => {
  console.log('referral', referral);

  const [successMessage, setSuccessMessage] = useState('');
  const [referralName, setReferralName] = useState(referral.referralName);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [id, setId] = useState(referral.id);
  const [ready, setReady] = useState(false);
  const [admin, setAdmin] = useState(referral ? referral.admin : '');
  const [source, setSource] = useState((referral && referral.source) || 0);
  const [sourceChanged, setSourceChanged] = useState(false);
  const [parentId, setParentId] = useState(referral ? referral.adminId : 0);
  const [adminChanged, setAdminChanged] = useState(false);
  const [businessDevAppUserDetailsId, setBusinessDevAppUserDetailsId] =
    useState(referral ? referral.businessDevAppUserDetailsId : 0);
  const [
    businessDevAppUserDetailsIdChanged,
    setBusinessDevAppUserDetailsIdChanged,
  ] = useState(false);
  const [status, setStatus] = useState(referral ? referral.status : '');
  const [statusChanged, setStatusChanged] = useState(false);
  const [openAddMessage, setOpenAddMessage] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(true);

  const handleClose = () => {
    setDialogOpen(false);
    cancel(false);
  };

  const confirm = () => {
    action({
      source: source,
      status: status,
      businessDevAppUserDetailsId: businessDevAppUserDetailsId,
      parentId: parentId,
      sourceChanged,
      statusChanged,
      adminChanged,
      businessDevAppUserDetailsIdChanged,
      id: id,
    });
  };
  //   console.log('eventStatus', eventStatusId);
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        open={dialogOpen}
        maxWidth="sm"
        fullWidth
        PaperComponent={PaperComponent}
      >
        <BootstrapDialogTitle onClose={handleClose} style={{ cursor: 'move' }}>
          <Typography align="center" color="secondary" variant="h6">
            {referralName}
          </Typography>
          <Divider />
        </BootstrapDialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={2}
            style={{ paddingTop: 4 }}
          >
            {role?.isSuperAdmin && (
              <>
                <br />
                <Grid item xs={6}>
                  {/* <FormControl fullWidth> */}
                  <InputLabel>Source</InputLabel>
                  <Select
                    native
                    fullWidth
                    size="small"
                    value={source}
                    label="Source"
                    onChange={(e) => {
                      setSource(e.target.value);
                      setSourceChanged(true);
                      setReady(true);
                    }}
                  >
                    {/* <option value={0}>--select--</option> */}
                    {REFERRALSOURCE.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.type}
                      </option>
                    ))}
                  </Select>
                  {/* </FormControl> */}
                </Grid>
                <Grid item xs={6}>
                  {/* <FormControl fullWidth> */}
                  <InputLabel>Admin</InputLabel>
                  <Select
                    native
                    fullWidth
                    size="small"
                    value={parentId}
                    label="Admin"
                    onChange={(e) => {
                      setParentId(e.target.value);
                      setAdminChanged(true);
                      setReady(true);
                    }}
                  >
                    <option value={0}>--Select--</option>
                    {users.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                  {/* </FormControl> */}
                </Grid>
                <Grid item xs={6}>
                  {/* <FormControl fullWidth> */}
                  <InputLabel>BDM</InputLabel>
                  <Select
                    native
                    fullWidth
                    size="small"
                    value={businessDevAppUserDetailsId}
                    label="BDM"
                    onChange={(e) => {
                      setBusinessDevAppUserDetailsId(e.target.value);
                      setBusinessDevAppUserDetailsIdChanged(true);
                      setReady(true);
                    }}
                  >
                    <option value={0}>--select--</option>
                    {users.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                  {/* </FormControl> */}
                </Grid>
                <Grid item xs={6}>
                  {/* <FormControl fullWidth> */}
                  <InputLabel>Status</InputLabel>
                  <Select
                    size="small"
                    native
                    fullWidth
                    label="Status"
                    placeholder="Status"
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value);
                      setStatusChanged(true);
                      setReady(true);
                    }}
                  >
                    <option value={0}>All</option>
                    {role.isApplicationTeam || role.isApplicationTeamLeader
                      ? AGENTSTATUS.filter((item) => item.displayInApp).map(
                          (item) => (
                            <option key={item.id} value={item.id}>
                              {item.type}
                            </option>
                          )
                        )
                      : AGENTSTATUS.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.type}
                          </option>
                        ))}
                  </Select>
                  {/* </FormControl> */}
                </Grid>
              </>
            )}
            <br />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!ready}
            autoFocus
            onClick={confirm}
            variant="contained"
            className="bg-sky-400 hover:bg-sky-500 mr-4"
          >
            Update
          </Button>
        </DialogActions>
        <br />
      </BootstrapDialog>
    </div>
  );
};
