/* eslint-disable import/namespace */
/* eslint-disable react/react-in-jsx-scope */

import React,{ useState } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Paper,
  IconButton,
} from "@mui/material"
import { Pie, Bar, Line } from "react-chartjs-2"
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
} from "chart.js"
import CloseIcon from "@mui/icons-material/Close"
import { BarChart, LineAxis, PieChart } from "@mui/icons-material"

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, PointElement, LineElement)

const SourcePerformanceDialog = ({ open, onClose, sourceData, selectedMonth }) => {
  const [chartType, setChartType] = useState("pie")

  if (!sourceData) return null

  const parseBranchData = (branchString) => {
    if (!branchString) return []
    return branchString
      .split(",")
      .map((branch) => {
        const [name, countStr] = branch.trim().split("(")
        return { name: name.trim(), count: Number.parseInt(countStr, 10) }
      })
      .filter((branch) => branch.name !== "N/A")
  }

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  const monthKey = selectedMonth.toLowerCase()
  const monthData = {
    month: selectedMonth === "ALL" ? "All Months" : selectedMonth,
    value:
      monthKey === "all"
        ? Object.keys(sourceData)
            .filter((key) => months.map((m) => m.toLowerCase()).includes(key))
            .reduce((sum, month) => sum + (Number(sourceData[month]) || 0), 0)
        : Number(sourceData[monthKey]) || 0,
    target:
      monthKey === "all"
        ? Object.keys(sourceData)
            .filter((key) => months.map((m) => m.toLowerCase() + "Target").includes(key))
            .reduce((sum, month) => sum + (Number(sourceData[month + "Target"]) || 0), 0)
        : Number(sourceData[`${monthKey}Target`]) || 0,
    branches:
      monthKey === "all"
        ? Object.keys(sourceData)
            .filter((key) => key.endsWith("_Branches"))
            .reduce((acc, key) => {
              const branchData = parseBranchData(sourceData[key] || "")
              branchData.forEach((branch) => {
                const existingBranch = acc.find((b) => b.name === branch.name)
                if (existingBranch) {
                  existingBranch.count += branch.count
                } else {
                  acc.push({ ...branch })
                }
              })
              return acc
            }, [])
        : parseBranchData(sourceData[`${monthKey}_Branches`] || ""),
  }

  monthData.totalLeads = monthData.branches.reduce((sum, branch) => sum + branch.count, 0)

  console.log("monthData:", JSON.stringify(monthData, null, 2))
  console.log("sourceData:", JSON.stringify(sourceData, null, 2))

  const colors = [
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4BC0C0",
    "#9966FF",
    "#FF9F40",
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4BC0C0",
    "#9966FF",
    "#FF9F40",
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4BC0C0",
    "#9966FF",
    "#FF9F40",
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4BC0C0",
    "#9966FF",
    "#FF9F40",
  ]

  const getChartData = (data) => {
    const labels = data.branches.map((branch) => branch.name)
    const values = data.branches.map((branch) => branch.count)

    return {
      labels,
      datasets: [
        {
          label: "Achieved",
          data: values,
          backgroundColor: colors.slice(0, values.length),
          borderColor: colors.slice(0, values.length).map((color) => color.replace("0.8", "1")),
          borderWidth: 1,
        },
      ],
    }
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "right",
        labels: {
          boxWidth: 15,
          padding: 15,
          font: {
            size: 10,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || ""
            const value = context.parsed.y || context.parsed || 0
            const total = context.dataset.data.reduce((a, b) => a + b, 0)
            const percentage = ((value / total) * 100).toFixed(1)
            return `${label}: ${value} (${percentage}%)`
          },
        },
      },
    },
    scales:
      chartType !== "pie"
        ? {
            x: { stacked: true },
            y: { stacked: true },
          }
        : undefined,
  }

  const renderChart = (data) => {
    if (data.branches.length === 0) {
      return (
        <Box className="h-full flex items-center justify-center">
          <Typography variant="h6" color="textSecondary">
            No data available for chart visualization
          </Typography>
        </Box>
      )
    }
    const chartData = getChartData(data)
    switch (chartType) {
      case "bar":
        return <Bar data={chartData} options={chartOptions} />
      case "line":
        return <Line data={chartData} options={chartOptions} />
      default:
        return <Pie data={chartData} options={chartOptions} />
    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle className="bg-sky-600 text-white p-4 text-center relative">
        <Typography variant="h6" component="div" className="pr-8">
          Source Details: <span className="text-yellow-300">{sourceData.source}</span>
        </Typography>
        <Typography variant="subtitle1" component="div" className="text-yellow-100">
          Years: {sourceData.years}
        </Typography>
        <IconButton
          onClick={onClose}
          className="absolute top-2 right-2 text-white hover:text-gray-200"
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="p-6">
        <Typography variant="h6" className="mb-4 flex flex-col items-start">
          <span>
            {monthData.month} Overview{" "}
            {/* {selectedMonth !== "ALL" && sourceData.years ? `(${sourceData.years.split(",")[0]})` : ""} */}
          </span>
          <span className="text-blue-600 font-bold mt-2">
            Total Leads:{" "}
            {monthData.totalLeads.toLocaleString()}
          </span>

        </Typography>
        <div className="flex flex-col md:flex-row gap-6">
          <Paper elevation={3} className="flex-1 p-4">
            <div className="flex justify-between items-center mb-4">
              <Typography variant="subtitle1">Branch Wise Source Analysis</Typography>
              <div className="flex space-x-2">
                <IconButton
                  onClick={() => setChartType("bar")}
                  color={chartType === "bar" ? "primary" : "default"}
                  aria-label="bar chart"
                >
                  <BarChart />
                </IconButton>
                <IconButton
                  onClick={() => setChartType("line")}
                  color={chartType === "line" ? "primary" : "default"}
                  aria-label="line chart"
                >
                  <LineAxis />
                </IconButton>
                <IconButton
                  onClick={() => setChartType("pie")}
                  color={chartType === "pie" ? "primary" : "default"}
                  aria-label="pie chart"
                >
                  <PieChart />
                </IconButton>
              </div>
            </div>
            <div className="h-[400px]">{renderChart(monthData)}</div>
          </Paper>
          <Paper elevation={3} className="flex-1 p-4 overflow-hidden">
            <Typography variant="subtitle1" className="mb-2">
              Branch Details
            </Typography>
            {monthData.branches.length > 0 ? (
              <div className="overflow-x-auto max-h-[400px]">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Branch
                      </th>
                      <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Achieved
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {monthData.branches.map((branch, branchIndex) => (
                      <tr key={branchIndex} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-normal text-sm font-medium text-gray-900">{branch.name}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-right">{branch.count}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <Box className="p-8 text-center">
                <Typography variant="body1" color="textSecondary">
                  No branch data available for the selected period.
                </Typography>
              </Box>
            )}
          </Paper>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default SourcePerformanceDialog

