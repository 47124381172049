
import React, { useState, useCallback, useEffect } from "react"
import { useParams, Link } from "react-router-dom"
import Agent from "../../../api/agent"
import {
  INTAKES,
  intakeYearList,
  STUDENTSOURCES,
  months,
  ReportStatusTypes,
  STUDY_LEVELS,
} from "../../../Util/Constants"
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Autocomplete,
  Chip,
  Paper,
  Grid,
  Typography,
  CircularProgress,
  ToggleButtonGroup,
  ToggleButton,
  Box,
  Checkbox,
  ListItemText,
} from "@mui/material"
import { Countries } from "../../../Util/Util"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import FilterListIcon from "@mui/icons-material/FilterList"
import BarChartIcon from "@mui/icons-material/BarChart"
import TableChartIcon from "@mui/icons-material/TableChart"
import ComparisonGraph from "../GlobalReport/ComparisonGraph"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const LoadingAnimation = () => (
  <div className="flex justify-center items-center h-64">
    <CircularProgress />
  </div>
)

const BranchReport = () => {
  const { branchId } = useParams()
  const [intakeYears, setIntakeYears] = useState([])
  const [intakeMonths, setIntakeMonths] = useState([])
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [sources, setSources] = useState([])
  const [data, setData] = useState([])
  const [monthlyTargets, setMonthlyTargets] = useState([])
  const [monthlyForecasts, setMonthlyForecasts] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [citizenship, setCitizenship] = useState([])
  const [branchName, setBranchName] = useState("")
  const [showFilters, setShowFilters] = useState(true)
  const [viewMode, setViewMode] = useState("both")
  const [chartType, setChartType] = useState("bar")
  const [selectedYear, setSelectedYear] = useState("")
  const [selectedSources, setSelectedSources] = useState([])
  const [selectedUniversities, setSelectedUniversities] = useState([])
  const [selectedDestinations, setSelectedDestinations] = useState([])
  const [selectedStudyLevels, setSelectedStudyLevels] = useState([])
  const [allInstitutions, setAllInstitutions] = useState([])
  const [filtersChanged, setFiltersChanged] = useState(false)
  const [selectedMetric, setSelectedMetric] = useState("organicLead")
  const [dateType, setDateType] = useState(1) //default value 1 (ActualOn)

  useEffect(() => {
    const fetchBranchData = async () => {
      try {
        const branches = await Agent.Cache.getBranchOffices()
        const branch = branches.find((b) => b.id === Number.parseInt(branchId, 10))
        if (branch) {
          setBranchName(branch.name)
        } else {
          setError("Branch not found")
        }
      } catch (err) {
        console.error("Error fetching branch data:", err)
        setError("Failed to fetch branch information")
      }
    }

    fetchBranchData()
  }, [branchId])

  useEffect(() => {
    if (allInstitutions.length === 0) {
      Agent.Universities.list()
        .then((universities) => {
          setAllInstitutions(universities)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [allInstitutions.length])

  const fetchData = useCallback(async () => {
    setLoading(true)
    setError(null)
    try {
      const params = {
        intakeYears,
        intakeMonths,
        startDate,
        endDate,
        sources: selectedSources.map((source) => source.id),
        branches: [Number.parseInt(branchId, 10)],
        year: selectedYear ? [selectedYear] : [],
        citizenship: citizenship.map((country) => country.id),
        type: 2,
        university: selectedUniversities.map((uni) => uni.id),
        destination: selectedDestinations.map((dest) => dest.id),
        studylevel: selectedStudyLevels,
        dateType: dateType, 
      }

      const [progressResponse, targetResponse, forecastResponse] = await Promise.all([
        Agent.Report.globalSalesProgress(params),
        Agent.Report.globalSalesTarget(params),
        Agent.Report.globalSalesForecast(params),
      ])

      if (Array.isArray(progressResponse) && progressResponse.length > 0) {
        setData(progressResponse)
      } else {
        setError("No data available for the selected criteria.")
      }

      if (Array.isArray(targetResponse)) {
        setMonthlyTargets(targetResponse)
      }

      if (Array.isArray(forecastResponse)) {
        setMonthlyForecasts(forecastResponse)
      }
    } catch (err) {
      setError("Failed to fetch data. Please try again.")
      console.error("Error fetching data:", err)
    } finally {
      setLoading(false)
    }
  }, [
    intakeYears,
    intakeMonths,
    startDate,
    endDate,
    selectedSources,
    branchId,
    selectedYear,
    citizenship,
    selectedUniversities,
    selectedDestinations,
    selectedStudyLevels,
    dateType, // Add dateType to dependency array
  ])

  const handleFilterChange = useCallback(() => {
    setFiltersChanged(true)
  }, [])

  const handleGenerateReport = () => {
    if (filtersChanged) {
      fetchData()
      setFiltersChanged(false)
    }
  }

  const handleResetFilters = () => {
    setIntakeYears([])
    setIntakeMonths([])
    setStartDate("")
    setEndDate("")
    setSelectedSources([])
    setSelectedYear("")
    setCitizenship([])
    setSelectedUniversities([])
    setSelectedDestinations([])
    setSelectedStudyLevels([])
    setData([])
    setError(null)
    setFiltersChanged(false)
    setDateType(1) 
  }

  const handleViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode)
    }
  }

  const handleChartTypeChange = (newChartType) => {
    setChartType(newChartType)
  }

  const formatValue = (value, isRatio) => {
    if (value === undefined || value === null || value === 0 || value === "") return ""
    if (isRatio) {
      const formattedValue = Number(value).toFixed(2)
      return formattedValue === "0.00" ? "" : `${formattedValue}%`
    }
    return value.toString()
  }

  const calculateTotal = (statusKey) => {
    return data.reduce((sum, monthData) => {
      const value = monthData[statusKey]
      return sum + (value !== undefined && value !== null ? Number(value) : 0)
    }, 0)
  }

  return (
    <Paper elevation={3} className="p-6 bg-gray-50">
      <div className="flex flex-col space-y-6">
        <div className="flex justify-between items-center">
          <Typography variant="h5" component="h2" className="text-gray-800 font-bold">
            Branch Report: {branchName || `Branch ID: ${branchId}`}
          </Typography>
          <div className="flex items-center space-x-4">
            <Link
              to="/regionalreport"
              className="flex items-center px-3 py-2 text-sm font-medium text-blue-600 bg-blue-100 rounded-md hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <ArrowBackIcon className="w-5 h-5 mr-1" />
              Back to Regional Report
            </Link>
            <ToggleButtonGroup value={viewMode} exclusive onChange={handleViewModeChange} aria-label="view mode">
              <ToggleButton value="chart" aria-label="chart view">
                <BarChartIcon />
              </ToggleButton>
              <ToggleButton value="table" aria-label="table view">
                <TableChartIcon />
              </ToggleButton>
              <ToggleButton value="both" aria-label="both views">
                <BarChartIcon />
                <TableChartIcon />
              </ToggleButton>
            </ToggleButtonGroup>
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="flex items-center px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <FilterListIcon className="w-5 h-5 mr-1" />
              {showFilters ? "Hide Filters" : "Show Filters"}
            </button>
          </div>
        </div>

        {showFilters && (
          <Paper elevation={2} className="p-4 bg-white">
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth size="small" required>
                  <InputLabel id="date-type-label">Date Type</InputLabel>
                  <Select
                    labelId="date-type-label"
                    id="date-type"
                    value={dateType}
                    label="Date Type *"
                    onChange={(e) => {
                      setDateType(e.target.value)
                      handleFilterChange()
                    }}
                  >
                    <MenuItem value={1}>Actual Status</MenuItem>
                    <MenuItem value={2}>Current Status</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Start Date"
                  type="date"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value)
                    handleFilterChange()
                  }}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="End Date"
                  type="date"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value)
                    handleFilterChange()
                  }}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth size="small">
                  <InputLabel id="year-select-label">Year</InputLabel>
                  <Select
                    labelId="year-select-label"
                    id="year-select"
                    value={selectedYear}
                    label="Year"
                    onChange={(e) => {
                      setSelectedYear(e.target.value)
                      handleFilterChange()
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {intakeYearList.map((y) => (
                      <MenuItem key={y.year} value={y.year.toString()}>
                        {y.year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth size="small">
                  <InputLabel id="intake-years-label">Intake Years</InputLabel>
                  <Select
                    labelId="intake-years-label"
                    id="intake-years"
                    multiple
                    value={intakeYears}
                    label="Intake Years"
                    onChange={(e) => {
                      setIntakeYears(e.target.value)
                      handleFilterChange()
                    }}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} size="small" />
                        ))}
                      </Box>
                    )}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: ITEM_HEIGHT * 10.5 + ITEM_PADDING_TOP,
                          width: 250,
                        },
                      },
                    }}
                  >
                    {intakeYearList.map((year) => (
                      <MenuItem key={year.year} value={year.year.toString()}>
                        <Checkbox checked={intakeYears.indexOf(year.year.toString()) > -1} />
                        <ListItemText primary={year.year} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth size="small">
                  <InputLabel id="intake-months-label">Intake Months</InputLabel>
                  <Select
                    labelId="intake-months-label"
                    id="intake-months"
                    multiple
                    value={intakeMonths}
                    label="Intake Months"
                    onChange={(e) => {
                      setIntakeMonths(e.target.value)
                      handleFilterChange()
                    }}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={INTAKES.find((m) => m.id.toString() === value)?.type || value}
                            size="small"
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: ITEM_HEIGHT * 10.5 + ITEM_PADDING_TOP,
                          width: 250,
                        },
                      },
                    }}
                  >
                    {INTAKES.map((month) => (
                      <MenuItem key={month.id} value={month.id.toString()}>
                        <Checkbox checked={intakeMonths.indexOf(month.id.toString()) > -1} />
                        <ListItemText primary={month.type} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  multiple
                  id="sources"
                  options={STUDENTSOURCES.map((source) => ({
                    id: source.id.toString(),
                    label: source.type,
                  }))}
                  value={selectedSources}
                  getOptionLabel={(option) => option.label}
                  onChange={(_, newValue) => {
                    setSelectedSources(newValue)
                    handleFilterChange()
                  }}
                  renderInput={(params) => <TextField {...params} label="Sources" placeholder="Select sources" />}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option.id}
                        variant="outlined"
                        label={option.label}
                        size="small"
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  multiple
                  id="citizenship"
                  options={Countries}
                  getOptionLabel={(option) => option.name}
                  value={citizenship}
                  onChange={(_, newValue) => {
                    setCitizenship(newValue)
                    handleFilterChange()
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Citizenship" placeholder="Select citizenship" />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option.id}
                        variant="outlined"
                        label={option.name}
                        size="small"
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  multiple
                  id="universities-filter"
                  options={allInstitutions}
                  getOptionLabel={(option) => option.name}
                  value={selectedUniversities}
                  onChange={(_, newValue) => {
                    setSelectedUniversities(newValue)
                    handleFilterChange()
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Universities" placeholder="Select universities" />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip key={index} label={option.name} size="small" {...getTagProps({ index })} />
                    ))
                  }
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  multiple
                  id="destinations-filter"
                  options={Countries}
                  getOptionLabel={(option) => option.name}
                  value={selectedDestinations}
                  onChange={(_, newValue) => {
                    setSelectedDestinations(newValue)
                    handleFilterChange()
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Destinations" placeholder="Select destinations" />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip key={index} label={option.name} size="small" {...getTagProps({ index })} />
                    ))
                  }
                  size="small"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth size="small">
                  <InputLabel id="study-levels-label">Study Levels</InputLabel>
                  <Select
                    labelId="study-levels-label"
                    id="study-levels"
                    multiple
                    value={selectedStudyLevels}
                    onChange={(e) => {
                      setSelectedStudyLevels(e.target.value)
                      handleFilterChange()
                    }}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={STUDY_LEVELS.find((level) => level.id.toString() === value)?.type || value}
                            size="small"
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: ITEM_HEIGHT * 10.5 + ITEM_PADDING_TOP,
                          width: 250,
                        },
                      },
                    }}
                  >
                    {STUDY_LEVELS.filter((level) => level.display).map((level) => (
                      <MenuItem key={level.id} value={level.id.toString()}>
                        <Checkbox checked={selectedStudyLevels.indexOf(level.id.toString()) > -1} />
                        <ListItemText primary={level.type} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <div className="mt-6 flex justify-end space-x-4">
              <button
                onClick={handleResetFilters}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                Reset Filters
              </button>
              <button
                onClick={handleGenerateReport}
                disabled={loading || !filtersChanged}
                className={`px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                  loading || !filtersChanged ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {loading ? "Generating..." : "Generate Report"}
              </button>
            </div>
          </Paper>
        )}

        {error && (
          <Paper elevation={2} className="p-4 bg-red-50">
            <Typography color="error">{error}</Typography>
          </Paper>
        )}

        {loading ? (
          <Paper elevation={3} className="p-4">
            <Typography variant="body1" className="mb-2 text-center">
              Generating report...
            </Typography>
            <LoadingAnimation />
          </Paper>
        ) : (
          !error &&
          data.length > 0 && (
            <div className="space-y-8">
              {(viewMode === "chart" || viewMode === "both") && (
                <Paper elevation={2} className="p-4 bg-white">
                  <div className="h-auto">
                    <ComparisonGraph
                      data={data}
                      statusTypes={ReportStatusTypes}
                      months={months}
                      monthlyTargets={monthlyTargets}
                      monthlyForecasts={monthlyForecasts}
                      selectedMetric={selectedMetric}
                      onMetricChange={(metric) => setSelectedMetric(metric)}
                      chartType={chartType}
                      onChartTypeChange={handleChartTypeChange}
                    />
                  </div>
                </Paper>
              )}
              {(viewMode === "table" || viewMode === "both") && (
                <Paper elevation={2} className="overflow-x-auto bg-white rounded-lg">
                  <div className="overflow-x-auto">
                    <table className="w-full text-xs border-collapse">
                      <thead className="bg-gray-100">
                        <tr>
                          <th
                            className="py-3 px-2 text-left sticky left-0 z-20 bg-gray-100 font-bold text-sm border-b-2 border-r-2 border-gray-300 w-40"
                            rowSpan={2}
                          >
                            Status Type
                          </th>
                          {INTAKES.map((month) => (
                            <th
                              key={month.id}
                              className="py-2 px-1 text-center font-bold text-xs border-b-2 border-gray-300 bg-blue-200"
                              colSpan={3}
                            >
                              {month.type.slice(0, 3)}
                            </th>
                          ))}
                          <th
                            className="py-2 px-1 text-center font-bold text-xs border-b-2 border-gray-300 bg-green-200"
                            colSpan={3}
                          >
                            Total
                          </th>
                        </tr>
                        <tr>
                          {INTAKES.map((month) => (
                            <React.Fragment key={`header-${month.id}`}>
                              <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-red-100">
                                Target
                              </th>
                              <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-green-100">
                                Achieve
                              </th>
                              <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-blue-100">
                                Forecast
                              </th>
                            </React.Fragment>
                          ))}
                          <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-red-100">
                            Target
                          </th>
                          <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-green-100">
                            Achieve
                          </th>
                          <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-blue-100">
                            Forecast
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {ReportStatusTypes.map((status, index) => (
                          <tr
                            key={status.id}
                            className={index % 2 === 0 ? "bg-white hover:bg-sky-50" : "bg-gray-50 hover:bg-sky-100"}
                          >
                            <td
                              className="py-2 px-2 font-semibold text-xs sticky left-0 z-10 border-r border-gray-300"
                              style={{
                                backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F9FAFB",
                              }}
                            >
                              {status.type}
                            </td>
                            {INTAKES.map((month) => {
                              const monthData = data.find((d) => d.month === month.id) || {}
                              const targetData = monthlyTargets.find((t) => t.month === month.id) || {}
                              const forecastData = monthlyForecasts.find((f) => f.month === month.id) || {}
                              const achievement = monthData[status.key]
                              const target = targetData[status.key] || 0
                              const forecast = forecastData[status.key] || 0
                              return (
                                <React.Fragment key={`${status.id}-${month.id}`}>
                                  <td className="py-1 px-1 text-center border-r border-gray-300">
                                    <div className="font-medium text-red-600 text-xs">
                                      {formatValue(target, status.isRatio)}
                                    </div>
                                  </td>
                                  <td className="py-1 px-1 text-center border-r border-gray-300">
                                    <div className="font-medium text-green-600 text-xs">
                                      {formatValue(achievement, status.isRatio)}
                                    </div>
                                  </td>
                                  <td className="py-1 px-1 text-center border-r border-gray-300">
                                    <div className="font-medium text-blue-600 text-xs">
                                      {formatValue(forecast, status.isRatio)}
                                    </div>
                                  </td>
                                </React.Fragment>
                              )
                            })}
                            <td className="py-1 px-1 text-center border-r border-gray-300">
                              <div className="font-medium text-red-600 text-xs">
                                {formatValue(
                                  monthlyTargets.reduce((sum, month) => sum + (month[status.key] || 0), 0),
                                  status.isRatio,
                                )}
                              </div>
                            </td>
                            <td className="py-1 px-1 text-center border-r border-gray-300">
                              <div className="font-medium text-green-600 text-xs">
                                {formatValue(calculateTotal(status.key), status.isRatio)}
                              </div>
                            </td>
                            <td className="py-1 px-1 text-center border-r border-gray-300">
                              <div className="font-medium text-blue-600 text-xs">
                                {formatValue(
                                  monthlyForecasts.reduce((sum, month) => sum + (month[status.key] || 0), 0),
                                  status.isRatio,
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Paper>
              )}
            </div>
          )
        )}
      </div>
    </Paper>
  )
}

export default BranchReport

