
import React, { useState, useCallback, useEffect } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Autocomplete,
  Chip,
  Paper,
  Grid,
  Typography,
  CircularProgress,
  ToggleButtonGroup,
  ToggleButton,
  Box,
  Checkbox,
  ListItemText,
} from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import FilterListIcon from "@mui/icons-material/FilterList"
import BarChartIcon from "@mui/icons-material/BarChart"
import TableChartIcon from "@mui/icons-material/TableChart"
import Agent from "../../../api/agent"
import { Countries } from "../../../Util/Util"
import {
  INTAKES,
  intakeYearList,
  STUDENTSOURCES,
  months,
  ReportStatusTypes,
  STUDY_LEVELS,
} from "../../../Util/Constants"
import ComparisonGraph from "../GlobalReport/ComparisonGraph"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const LoadingAnimation = () => (
  <div className="flex justify-center items-center h-64">
    <CircularProgress />
  </div>
)

const RegionalReport = ({ permissions }) => {
  const [countries, setCountries] = useState([])
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [branches, setBranches] = useState([])
  const getFlagUrl = (countryCode) => `https://flagcdn.com/w40/${countryCode.toLowerCase()}.png`

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const branchData = await Agent.Cache.getBranchOffices()
        setBranches(branchData)

        const uniqueCountries = Array.from(
          new Set(
            branchData
              .filter((branch) => branch.countryId !== 0)
              .map((branch) => {
                const countryInfo = Countries.find((c) => c.id === branch.countryId)
                return countryInfo && permissions[`CanAccessRegionalReport${countryInfo.name.replace(/\s+/g, "")}`]
                  ? JSON.stringify({
                      id: branch.countryId,
                      name: countryInfo.name,
                      code: countryInfo.code,
                    })
                  : null
              })
              .filter(Boolean),
          ),
        ).map((country) => JSON.parse(country))

        setCountries(uniqueCountries)

        if (uniqueCountries.length > 0) {
          setSelectedCountry(uniqueCountries[0])
        }
      } catch (err) {
        console.error("Error fetching branch data:", err)
      }
    }

    fetchBranches()
  }, [permissions])

  return (
    <div className="bg-gray-100 min-h-screen p-4 md:p-8">
      <div className="w-full mx-auto px-2 sm:px-4">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl md:text-3xl font-bold text-gray-800">Regional Report (Country)</h1>
          <Link to="/reportpanel" className="flex items-center text-blue-600 hover:text-blue-800">
            <ArrowBackIcon className="h-5 w-5 mr-1" />
            Back to Reporting Panel
          </Link>
        </div>
        <div className="mb-6">
          <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-7 gap-2">
            {countries.map((country) => (
              <button
                key={country.id}
                onClick={() => setSelectedCountry(country)}
                className={`px-2 py-1 rounded-full text-xs sm:text-sm font-medium transition-colors duration-200 flex items-center justify-center gap-1 ${
                  selectedCountry && selectedCountry.id === country.id
                    ? "bg-blue-500 text-white"
                    : "bg-white text-gray-700 hover:bg-blue-100"
                }`}
              >
                <img
                  src={getFlagUrl(country.code) || "/placeholder.svg"}
                  alt={`${country.name} flag`}
                  className="w-4 h-3 object-cover rounded-sm shadow-sm"
                  loading="lazy"
                />
                <span className="truncate">
                  {selectedCountry && selectedCountry.id === country.id ? country.name.toUpperCase() : country.name}
                </span>
              </button>
            ))}
          </div>
        </div>
        {selectedCountry && (
          <CountryReport
            country={selectedCountry}
            branches={branches.filter((branch) => branch.countryId === selectedCountry.id)}
            Countries={Countries}
            getFlagUrl={getFlagUrl}
          />
        )}
      </div>
    </div>
  )
}

const CountryReport = ({ country, branches, Countries, getFlagUrl }) => {
  const [activeTab, setActiveTab] = useState("allBranches")

  return (
    <div className="bg-white rounded-lg shadow-md p-2 md:p-4">
      <div className="flex flex-col md:flex-row md:items-center justify-between mb-6">
        <h2 className="text-xl md:text-2xl font-semibold text-gray-800 flex items-center gap-3 mb-4 md:mb-0">
          <img
            src={getFlagUrl(country.code) || "/placeholder.svg"}
            alt={`${country.name} flag`}
            className="w-6 h-4 md:w-8 md:h-6 object-cover rounded-sm shadow-sm"
          />
          {country.name.toUpperCase()}
        </h2>
        <div className="flex space-x-2">
          <button
            onClick={() => setActiveTab("branches")}
            className={`px-3 py-1 rounded-md text-sm font-medium transition-colors duration-200 ${
              activeTab === "branches" ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-700 hover:bg-gray-300"
            }`}
          >
            Branches
          </button>
          <button
            onClick={() => setActiveTab("allBranches")}
            className={`px-3 py-1 rounded-md text-sm font-medium transition-colors duration-200 ${
              activeTab === "allBranches" ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-700 hover:bg-gray-300"
            }`}
          >
            All Branches Report
          </button>
        </div>
      </div>

      {activeTab === "branches" && (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {branches.map((branch) => (
            <div
              key={branch.id}
              className="bg-gray-50 rounded-lg p-4 shadow transition-all duration-200 hover:shadow-lg"
            >
              <h3 className="text-lg font-medium mb-2 text-gray-700">{branch.name}</h3>
              <p className="text-sm text-gray-500 mb-4">{branch.branchEmail || "No email available"}</p>
              <Link
                to={`/branchreport/${branch.id}`}
                className="w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors duration-300 inline-block text-center"
              >
                View Report
              </Link>
            </div>
          ))}
        </div>
      )}

      {activeTab === "allBranches" && <AllBranchesReport country={country} branches={branches} />}
    </div>
  )
}

const AllBranchesReport = ({ country, branches }) => {
  const [intakeYears, setIntakeYears] = useState([])
  const [intakeMonths, setIntakeMonths] = useState([])
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [sources, setSources] = useState([])
  const [data, setData] = useState([])
  const [monthlyTargets, setMonthlyTargets] = useState([])
  const [monthlyForecasts, setMonthlyForecasts] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [citizenship, setCitizenship] = useState([])
  const [showFilters, setShowFilters] = useState(true)
  const [viewMode, setViewMode] = useState("both")
  // const [selectedMetrics, setSelectedMetrics] = useState([{ key: "organicLead", type: "Organic Lead" }])
  const [filtersChanged, setFiltersChanged] = useState(false)
  const [chartType, setChartType] = useState("bar")
  const [selectedYear, setSelectedYear] = useState("")
  const [selectedSources, setSelectedSources] = useState([])
  const [selectedUniversities, setSelectedUniversities] = useState([])
  const [selectedDestinations, setSelectedDestinations] = useState([])
  const [selectedStudyLevels, setSelectedStudyLevels] = useState([])
  const [allInstitutions, setAllInstitutions] = useState([])
  const [selectedMetric, setSelectedMetric] = useState("organicLead")
  const [dateType, setDateType] = useState(1) // default value 1 (ActualOn)

  useEffect(() => {
    if (allInstitutions.length === 0) {
      Agent.Universities.list()
        .then((universities) => {
          setAllInstitutions(universities)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [allInstitutions.length])

  const fetchData = useCallback(async () => {
    setLoading(true)
    setError(null)
    try {
      const params = {
        intakeYears: intakeYears.map((year) => year.toString()),
        intakeMonths: intakeMonths.map((month) => month.toString()),
        startDate,
        endDate,
        sources: selectedSources.map((source) => source.id),
        branches: branches.map((branch) => branch.id),
        year: selectedYear ? [selectedYear] : [],
        citizenship: citizenship.map((country) => country.id.toString()),
        type: 2,
        university: selectedUniversities.map((uni) => uni.id),
        destination: selectedDestinations.map((dest) => dest.id),
        studylevel: selectedStudyLevels.map((level) => level.id.toString()),
        dateType: dateType,
      }

      const [progressResponse, targetResponse, forecastResponse] = await Promise.all([
        Agent.Report.globalSalesProgress(params),
        Agent.Report.globalSalesTarget(params),
        Agent.Report.globalSalesForecast(params),
      ])

      if (Array.isArray(progressResponse) && progressResponse.length > 0) {
        setData(progressResponse)
      } else {
        setError("No data available for the selected criteria.")
      }

      if (Array.isArray(targetResponse)) {
        setMonthlyTargets(targetResponse)
      }

      if (Array.isArray(forecastResponse)) {
        setMonthlyForecasts(forecastResponse)
      }
      setFiltersChanged(false)
    } catch (err) {
      setError("Failed to fetch data. Please try again.")
      console.error("Error fetching data:", err)
    } finally {
      setLoading(false)
    }
  }, [
    intakeYears,
    intakeMonths,
    startDate,
    endDate,
    selectedSources,
    branches,
    selectedYear,
    citizenship,
    selectedUniversities,
    selectedDestinations,
    selectedStudyLevels,
    dateType, 
  ])

  const handleGenerateReport = () => {
    fetchData()
  }

  const handleResetFilters = () => {
    setIntakeYears([])
    setIntakeMonths([])
    setStartDate("")
    setEndDate("")
    setSelectedSources([])
    setSelectedYear("")
    setSelectedSources([])
    setSelectedYear("")
    setCitizenship([])
    // setSelectedMetrics([{ key: "organicLead", type: "Organic Lead" }])
    setSelectedMetric("organicLead")
    setSelectedUniversities([])
    setSelectedDestinations([])
    setSelectedStudyLevels([])
    setData([])
    setError(null)
    setFiltersChanged(false)
    setDateType(1)
  }

  const handleFilterChange = () => {
    setFiltersChanged(true)
  }

  const handleViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode)
    }
  }

  const handleChartTypeChange = (newChartType) => {
    setChartType(newChartType)
  }

  const formatValue = (value, isRatio) => {
    if (value === undefined || value === null || value === 0 || value === "") return ""
    if (isRatio) {
      const formattedValue = Number(value).toFixed(2)
      return formattedValue === "0.00" ? "" : `${formattedValue}%`
    }
    return value.toString()
  }

  const calculateTotal = (statusKey) => {
    return data.reduce((sum, monthData) => {
      const value = monthData[statusKey]
      return sum + (value !== undefined && value !== null ? Number(value) : 0)
    }, 0)
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-2 md:p-4">
      <div className="flex flex-col md:flex-row md:items-center justify-between mb-6">
        <Typography variant="h5" component="h2" className="text-gray-800 font-bold">
          All Branches Report: {country.name.toUpperCase()}
        </Typography>
        <div className="flex items-center space-x-4">
          <ToggleButtonGroup value={viewMode} exclusive onChange={handleViewModeChange} aria-label="view mode">
            <ToggleButton value="chart" aria-label="chart view">
              <BarChartIcon />
            </ToggleButton>
            <ToggleButton value="table" aria-label="table view">
              <TableChartIcon />
            </ToggleButton>
            <ToggleButton value="both" aria-label="both views">
              <BarChartIcon />
              <TableChartIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          <button
            onClick={() => setShowFilters(!showFilters)}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <FilterListIcon className="w-5 h-5 mr-2" />
            {showFilters ? "Hide Filters" : "Show Filters"}
          </button>
        </div>
      </div>

      {showFilters && (
        <div className="mb-6">
          <Grid container spacing={2}>
            {/* Add DateType filter */}
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth size="small" required>
                <InputLabel id="date-type-label">Date Type</InputLabel>
                <Select
                  labelId="date-type-label"
                  id="date-type"
                  value={dateType}
                  label="Date Type *"
                  onChange={(e) => {
                    setDateType(e.target.value)
                    handleFilterChange()
                  }}
                >
                  <MenuItem value={1}>Actual Status</MenuItem>
                  <MenuItem value={2}>Current Status</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="Start Date"
                type="date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value)
                  handleFilterChange()
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="End Date"
                type="date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value)
                  handleFilterChange()
                }}
                InputLabelProps={{ shrink: true }}
                fullWidth
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth size="small">
                <InputLabel id="year-select-label">Year</InputLabel>
                <Select
                  labelId="year-select-label"
                  id="year-select"
                  value={selectedYear}
                  label="Year"
                  onChange={(e) => {
                    setSelectedYear(e.target.value)
                    handleFilterChange()
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {intakeYearList.map((y) => (
                    <MenuItem key={y.year} value={y.year.toString()}>
                      {y.year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth size="small">
                <InputLabel id="intake-years-label">Intake Years</InputLabel>
                <Select
                  labelId="intake-years-label"
                  id="intake-years"
                  multiple
                  value={intakeYears}
                  label="Intake Years"
                  onChange={(e) => {
                    setIntakeYears(e.target.value)
                    handleFilterChange()
                  }}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} size="small" />
                      ))}
                    </Box>
                  )}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                        width: 250,
                      },
                    },
                  }}
                >
                  {intakeYearList.map((year) => (
                    <MenuItem key={year.year} value={year.year.toString()}>
                      <Checkbox checked={intakeYears.indexOf(year.year.toString()) > -1} />
                      <ListItemText primary={year.year} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth size="small">
                <InputLabel id="intake-month-label">Intake Months</InputLabel>
                <Select
                  labelId="intake-month-label"
                  id="intake-month"
                  multiple
                  value={intakeMonths}
                  label="Intake Months"
                  onChange={(e) => {
                    setIntakeMonths(e.target.value)
                    handleFilterChange()
                  }}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={INTAKES.find((intake) => intake.id.toString() === value)?.type}
                          size="small"
                        />
                      ))}
                    </Box>
                  )}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: ITEM_HEIGHT * 12 + ITEM_PADDING_TOP,
                        width: 250,
                      },
                    },
                  }}
                >
                  {INTAKES.map((intake) => (
                    <MenuItem key={intake.id} value={intake.id.toString()}>
                      <Checkbox checked={intakeMonths.indexOf(intake.id.toString()) > -1} />
                      <ListItemText primary={intake.type} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Autocomplete
                multiple
                id="sources"
                options={STUDENTSOURCES}
                getOptionLabel={(option) => option.type}
                value={selectedSources}
                onChange={(_, newValue) => {
                  setSelectedSources(newValue)
                  handleFilterChange()
                }}
                renderInput={(params) => <TextField {...params} label="Sources" placeholder="Select sources" />}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip key={index} label={option.type} size="small" {...getTagProps({ index })} />
                  ))
                }
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Autocomplete
                multiple
                id="citizenship"
                options={Countries}
                getOptionLabel={(option) => option.name}
                value={citizenship}
                onChange={(_, newValue) => {
                  setCitizenship(newValue)
                  handleFilterChange()
                }}
                renderInput={(params) => <TextField {...params} label="Citizenship" placeholder="Select citizenship" />}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip key={index} label={option.name} size="small" {...getTagProps({ index })} />
                  ))
                }
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth size="small">
                <InputLabel id="study-level-label">Study Level</InputLabel>
                <Select
                  labelId="study-level-label"
                  id="study-level"
                  multiple
                  value={selectedStudyLevels}
                  label="Study Level"
                  onChange={(e) => {
                    setSelectedStudyLevels(e.target.value)
                    handleFilterChange()
                  }}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip
                          key={value.id}
                          label={value.type}
                          size="small"
                          onDelete={() => {
                            setSelectedStudyLevels(selectedStudyLevels.filter((level) => level.id !== value.id))
                            handleFilterChange()
                          }}
                        />
                      ))}
                    </Box>
                  )}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
                        width: 250,
                      },
                    },
                  }}
                >
                  {STUDY_LEVELS.filter((level) => level.display).map((level) => (
                    <MenuItem key={level.id} value={level}>
                      <Checkbox checked={selectedStudyLevels.some((selected) => selected.id === level.id)} />
                      <ListItemText primary={level.type} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                multiple
                id="destinations-filter"
                options={Countries}
                getOptionLabel={(option) => option.name}
                value={selectedDestinations}
                onChange={(_, newValue) => {
                  setSelectedDestinations(newValue)
                  handleFilterChange()
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Destinations" placeholder="Select destinations" />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip key={index} label={option.name} size="small" {...getTagProps({ index })} />
                  ))
                }
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                multiple
                id="universities-filter"
                options={allInstitutions}
                getOptionLabel={(option) => option.name}
                value={selectedUniversities}
                onChange={(_, newValue) => {
                  setSelectedUniversities(newValue)
                  handleFilterChange()
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Universities" placeholder="Select universities" />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip key={index} label={option.name} size="small" {...getTagProps({ index })} />
                  ))
                }
                size="small"
              />
            </Grid>
          </Grid>
          <div className="mt-6 flex justify-end space-x-4">
            <button
              onClick={handleResetFilters}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Reset Filters
            </button>
            <button
              onClick={handleGenerateReport}
              disabled={loading || !filtersChanged}
              className={`px-4 py-2 text-sm font-medium text-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                loading || !filtersChanged ? "bg-gray-300 cursor-not-allowed" : "bg-blue-600 hover:bg-blue-700"
              }`}
            >
              {loading ? "Generating..." : "Generate Report"}
            </button>
          </div>
        </div>
      )}

      {error && (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6 rounded-md shadow-md" role="alert">
          <div className="flex items-center">
            <div className="py-1">
              <svg
                className="fill-current h-6 w-6 text-red-500 mr-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
              </svg>
            </div>
            <div>
              <p className="font-bold">Error</p>
              <p className="text-sm">{error}</p>
            </div>
          </div>
        </div>
      )}

      {loading ? (
        <Paper elevation={3} className="p-4">
          <Typography variant="body1" className="mb-2 text-center">
            Generating report...
          </Typography>
          <LoadingAnimation />
        </Paper>
      ) : (
        !error &&
        data.length > 0 && (
          <div className="space-y-8">
            {(viewMode === "chart" || viewMode === "both") && (
              <Paper elevation={3} className="p-4">
                <div className="h-[500px]">
                  <ComparisonGraph
                    data={data}
                    statusTypes={ReportStatusTypes}
                    months={months}
                    monthlyTargets={monthlyTargets}
                    monthlyForecasts={monthlyForecasts}
                    selectedMetric={selectedMetric}
                    onMetricChange={(metric) => setSelectedMetric(metric)}
                    chartType={chartType}
                    onChartTypeChange={handleChartTypeChange}
                  />
                </div>
              </Paper>
            )}
            {(viewMode === "table" || viewMode === "both") && (
              <Paper elevation={3} className="overflow-x-auto max-w-full rounded-lg">
                <div className="overflow-x-auto">
                  <table className="w-full text-xs border-collapse">
                    <thead className="bg-gray-100">
                      <tr>
                        <th
                          className="py-3 px-2 text-left sticky left-0 z-20 bg-gray-100 font-bold text-sm border-b-2 border-r-2 border-gray-300 w-40"
                          rowSpan={2}
                        >
                          Status Type
                        </th>
                        {months.map((month) => (
                          <th
                            key={month}
                            className="py-2 px-1 text-center font-bold text-xs border-b-2 border-gray-300 bg-blue-200"
                            colSpan={3}
                          >
                            {month.slice(0, 3)}
                          </th>
                        ))}
                        <th
                          className="py-2 px-1 text-center font-bold text-xs border-b-2 border-gray-300 bg-green-200"
                          colSpan={3}
                        >
                          Total
                        </th>
                      </tr>
                      <tr>
                        {[...months, "Total"].map((month) => (
                          <React.Fragment key={`${month}-details`}>
                            <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-red-100">
                              Target
                            </th>
                            <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-green-100">
                              Achieve
                            </th>
                            <th className="py-2 px-1 text-center text-[10px] font-semibold text-gray-700 border-r border-gray-300 bg-blue-100">
                              Forecast
                            </th>
                          </React.Fragment>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {ReportStatusTypes.map((status, index) => (
                        <tr
                          key={status.id}
                          className={index % 2 === 0 ? "bg-white hover:bg-sky-50" : "bg-gray-50 hover:bg-sky-100"}
                        >
                          <td
                            className="py-2 px-2 font-semibold text-xs sticky left-0 z-10 border-r border-gray-300"
                            style={{
                              backgroundColor: index % 2 === 0 ? "#FFFFFF" : "#F9FAFB",
                            }}
                          >
                            {status.type}
                          </td>
                          {months.map((_, monthIndex) => {
                            const monthData = data.find((d) => d.month === monthIndex + 1) || {}
                            const targetData = monthlyTargets.find((t) => t.month === monthIndex + 1) || {}
                            const forecastData = monthlyForecasts.find((f) => f.month === monthIndex + 1) || {}
                            return (
                              <React.Fragment key={monthIndex}>
                                <td className="py-1 px-1 text-center border-r border-gray-300">
                                  <div className="font-medium text-red-600 text-xs">
                                    {formatValue(targetData[status.key], status.isRatio)}
                                  </div>
                                </td>
                                <td className="py-1 px-1 text-center border-r border-gray-300">
                                  <div className="font-medium text-green-600 text-xs">
                                    {formatValue(monthData[status.key], status.isRatio)}
                                  </div>
                                </td>
                                <td className="py-1 px-1 text-center border-r border-gray-300">
                                  <div className="font-medium text-blue-600 text-xs">
                                    {formatValue(forecastData[status.key], status.isRatio)}
                                  </div>
                                </td>
                              </React.Fragment>
                            )
                          })}
                          <td className="py-1 px-1 text-center border-r border-gray-300">
                            <div className="font-medium text-red-600 text-xs">
                              {formatValue(
                                monthlyTargets.reduce((sum, month) => sum + (month[status.key] || 0), 0),
                                status.isRatio,
                              )}
                            </div>
                          </td>
                          <td className="py-1 px-1 text-center border-r border-gray-300">
                            <div className="font-medium text-green-600 text-xs">
                              {formatValue(calculateTotal(status.key), status.isRatio)}
                            </div>
                          </td>
                          <td className="py-1 px-1 text-center border-r border-gray-300">
                            <div className="font-medium text-blue-600 text-xs">
                              {formatValue(
                                monthlyForecasts.reduce((sum, month) => sum + (month[status.key] || 0), 0),
                                status.isRatio,
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Paper>
            )}
          </div>
        )
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  permissions: state.auth.permissions,
})

export default connect(mapStateToProps)(RegionalReport)

